import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import ReactPlayer from 'react-player/lazy'
import TopCells from "../TopCells/TopCells";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ContextMenu from "../../../components/ui/ContextMenu/ContextMenu";
import VideoPlayer from "../../../components/ui/VideoPlayer";
class Monitor extends Component {
    player = null;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            context: false
        }

    }


    componentDidMount() {

    }

    render() {
        const {gameTableContext} = this.props;
        return (
            <div className={styles.VideoMonitor}>
                <div className={styles.MonitorContainer}>
                    <div className={styles.MonitorScreen}>
                        <TopCells />
                        <VideoPlayer />
                    </div>
                </div>
            </div>

        );
    }
}

export default withApplicationContext(withGameTableContext(Monitor));