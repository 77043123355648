import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import {Form} from "react-bootstrap";
import moment from "moment";
import Calendar from "react-calendar";
import styles from "../../../../components/ui/PageTitle/PageTitle.module.css";
class YearlyMonthlyOverviewSearch extends Component {
    monthNameList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    constructor(props) {
        super(props);
        let years = [];
        for (let i = 2020 ; i <= new Date().getFullYear(); i++){
            years.push(i);
        }
        this.state = {
            round_number: "",
            date: null,
            start_date: "",
            end_date: "",
            datepicker: false,
            years,
            search: props.searchData
        }
    }

    handleSearch = () => {
        let search = this.state.search;
        this.props.refTable.search(search)
    }

    onChangeDate = (date) => {
        this.setState({
            date,
            search: {
                ...this.state.search,
                start_date: (date)?moment(new Date(date[0])).format('YYYY-MM-DD'):null,
                end_date: (date)?moment(new Date(date[1])).format('YYYY-MM-DD'):null
            },
            datepicker: false
        }, () => {
            this.props.doUpdateSearchData(this.state.search)
        })
    }
    getTypeSelectColumnId = () => {
        if(this.state.search.type === "range" || this.state.search.type === "yearly"){
            return 'table-cell-03';
        }
        return 'table-cell-04';
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.SearchBox}>
                    <Form.Control
                        as={"select"}
                        className={"search-item"}
                        name={"type"}
                        value={this.state.search.type}
                        style={document.getElementById(this.getTypeSelectColumnId())?{ width: document.getElementById(this.getTypeSelectColumnId()).offsetWidth }:null}
                        onChange={(e) => {
                            this.setState({
                                search: {
                                    ...this.state.search,
                                    type: e.target.value
                                }
                            }, () => {
                                this.props.doUpdateSearchData(this.state.search)
                            })
                        }}

                    >
                        <option value={"range"}>RANGE OF DATES</option>
                        <option value={"monthly"}>MONTHLY</option>
                        <option value={"yearly"}>YEARLY</option>
                    </Form.Control>

                    {this.state.search.type === "range" && <div className={"search-item"} style={document.getElementById('table-cell-02')?{ width: document.getElementById('table-cell-02').offsetWidth }:null}>
                        <a href={"javascript:void(0)"} onClick={(e) => this.setState({ datepicker: !this.state.datepicker })}>
                            <React.Fragment>
                                {this.state.search.start_date?<span style={{ fontSize: '11px' }}>{moment(new Date(this.state.search.start_date)).format('MMM D, YY')}</span>:"DATE"}
                                {this.state.search.end_date && this.state.search.end_date !== this.state.search.start_date?<span style={{ fontSize: '11px' }}>-{moment(new Date(this.state.search.end_date)).format('MMM D, YY')}</span>:""}
                            </React.Fragment>
                        </a>
                        {this.state.datepicker && <div className={"float-calendar"}><Calendar value={this.state.date} onChange={this.onChangeDate} selectRange={true}/></div>}
                    </div>}

                    {this.state.search.type === "monthly" && <Form.Control
                        as={"select"}
                        className={"search-item"}
                        name={"month"}
                        value={this.state.search.month}
                        style={document.getElementById('table-cell-03')?{ width: document.getElementById('table-cell-03').offsetWidth }:null}
                        onChange={(e) => {
                            this.setState({
                                search: {
                                    ...this.state.search,
                                    month: e.target.value
                                }
                            }, () => {
                                this.props.doUpdateSearchData(this.state.search)
                            })
                        }}

                    >
                        {this.monthNameList.map((month, index) => {
                            return <option value={index}>{month}</option>
                        })}

                    </Form.Control>}

                    {this.state.search.type !== "range" && <Form.Control
                        as={"select"}
                        className={"search-item"}
                        name={"year"}
                        value={this.state.search.year}
                        style={document.getElementById('table-cell-02')?{ width: document.getElementById('table-cell-02').offsetWidth }:null}
                        onChange={(e) => {
                            this.setState({
                                search: {
                                    ...this.state.search,
                                    year: e.target.value
                                }
                            }, () => {
                                this.props.doUpdateSearchData(this.state.search)
                            })
                        }}

                    >
                        {this.state.years.map((year, index) => {
                            return <option value={year}>{year}</option>
                        })}

                    </Form.Control>}
                    <button className={"btn btn-account search-item"} type={"submit"} onClick={this.handleSearch}
                            style={document.getElementById('table-cell-01')?{ width: document.getElementById('table-cell-01').offsetWidth }:null}
                    >Search</button>

                </div>

            </React.Fragment>
        )
    }
}

export default withRouter(withAdminAccountContext(YearlyMonthlyOverviewSearch));