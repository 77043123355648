import React, {Component} from 'react';
import styles from "../../../components/ui/PageTitle/PageTitle.module.css";
import {NavLink, withRouter} from "react-router-dom";
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {renderPageTitle, renderBackButton} from "../../../components/ui/PageTitle";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class PageTitleContainer extends Component {

    back = (e) => {
        const {history} = this.props;
        if(localStorage.getItem('back_button')){
            let back_url = JSON.parse(localStorage.getItem('back_button'));
            localStorage.removeItem('back_button');
            let u = back_url.url;
            if(back_url.state)
                localStorage.setItem('backed_from_account_state', JSON.stringify(back_url.state))
            if(back_url.tableState)
                localStorage.setItem('backed_from_account_table_state', JSON.stringify(back_url.tableState))
            window.location.href = u;
        }
    }
    render() {
        const {accountContext, back, searchBar, center, fullTitleWidth, full, width} = this.props;
        return (
            <div className={full?styles.FullWidthTitleBar:styles.TitleBar} style={width?{width, margin: "0 auto", padding: "3em 2em 0 2em"}:null}>
                {back && <div className={styles.BackButtonContainer}>
                    <a href={"javascript:void(0)"} onClick={this.back} className={styles.BackNavButton}>
                        <FontAwesomeIcon icon={"chevron-left"} /> Back
                    </a>
                </div>}
                <div className={center?styles.CenterSection:styles.LeftSection}>
                    <div className={classNames(styles.TitleText, full && styles.FullTitleText)} style={fullTitleWidth?{width: `${fullTitleWidth}px`}:null}>
                        {renderPageTitle(this.props)}
                    </div>
                </div>
                {searchBar && <div className={styles.RightSection}>
                    {searchBar}
                </div>}
            </div>
        );
    }
}

export default withAdminAccountContext(withRouter((PageTitleContainer)));