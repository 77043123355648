import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import classNames from "classnames";
import {totalWin, convertWinBoardToGroup} from "../../../helpers/helpers";
class WonBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const {applicationContext, gameTableContext} = this.props;
        let wonTable = convertWinBoardToGroup(gameTableContext.state.winTable)

        return (
            <div className={classNames(styles.WonBoardWrapper, gameTableContext.state.windows.won && styles.OpenWonBoardWrapper)}>
                <table border={0} width="100%">
                    <tr className={styles.WonBoardHeader}>
                        <th className={applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font"}>{applicationContext.translator("Description")}</th>
                        <th className={applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font"}>{applicationContext.translator("Position")}</th>
                        <th className={classNames(styles.TextGreen, applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font")}>{applicationContext.translator("Bet x Payout")}</th>
                        <th className={classNames(styles.TextGreen, styles.TextRight, applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font")}>{applicationContext.translator("Win")}</th>
                    </tr>
                    {wonTable && wonTable.length > 0 &&
                    <React.Fragment>
                        {wonTable.map((formula, index) => {
                            return (
                                <tr key={index}>
                                    <td >{formula.label}</td>
                                    <td >{formula.number}</td>
                                    <td ><span className={classNames(styles.TextGreen)}>{formula.value}</span> x {formula.rate}</td>
                                    <td className={classNames(styles.TextGreen, styles.TextRight)}>{formula.win}</td>
                                </tr>
                            );
                        })}
                        <tr className={styles.WonBoardFooter}>
                            <td colSpan={2}></td>
                            <td>Total</td>
                            <td className={classNames(styles.TextGreen, styles.TextRight)}>{totalWin(gameTableContext.state.winTable)}</td>
                        </tr>
                    </React.Fragment>
                    }

                </table>
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext(WonBoard));