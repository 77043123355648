import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {NavLink, withRouter} from "react-router-dom"
import PanelContainer from "../PanelContainer";
import AccountTable from "../../../components/ui/AccountTable";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import {accountCodeParse, numberWithCommas} from "../../../helpers/helpers";
import {ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW} from "../../../routers/routes";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import AdminService from "../../../services/admin.service";
import PageTitleContainer from "../Search/PageTitleContainer";
import RankingSearch from "./RankingSearch";
class CashOutRanking extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dialog: false,
            history: null,
            search: {
                type: "total",
                start_date: "",
                end_date: "",
                month: new Date().getMonth(),
                year: new Date().getFullYear()
            }
        }
    }
    componentDidMount() {
        if(localStorage.getItem('backed_from_account_state')){
            let state = JSON.parse(localStorage.getItem('backed_from_account_state'));
            localStorage.removeItem('backed_from_account_state');
            this.setState(state)
        }
    }
    onClose = (e) => {
        this.setState({
            dialog: false,
            dialogTitle: "",
            history: null
        })
    }
    onLoadHistory = (e, history) => {
        e.preventDefault();
        this.setState({
            dialog: true,
            history: history.id
        })
    }
    onChangeHistoryRow = (history) => {
        this.setState({
            history: history
        })
    }
    doUpdateSearchData = (data) => {
        this.setState({
            search: data
        })
    }
    render() {
        const {accountContext} = this.props;
        return (
            <PanelContainer>
                <PageTitleContainer width={"90%"}  searchBar={this.state.searchComponent}/>
                <div className={"form-center form-search"}>
                    <AccountTable loadData={AdminService.getRanking} sort={"cash_out"} align={"desc"} header={(onSort, sort, align) => {
                        return <TableRow>
                            <TableColHeader>#</TableColHeader>
                            <TableColHeader id={'table-cell-03'} sort={"cash_out"} align={align} sorted={sort} onSort={onSort}>TOTAL AMOUNT</TableColHeader>
                            <TableColHeader id={'table-cell-02'}>ID NUMBER</TableColHeader>
                            <TableColHeader id={'table-cell-01'}>HOUSE BALANCE</TableColHeader>
                        </TableRow>
                    }}
                                  render={(row, index, number) =>
                                      <TableRow key={index}>
                                          <TableCol>{number}</TableCol>
                                          <TableCol yellow={true}>{numberWithCommas(row['cash_out'], true)}</TableCol>
                                          <TableCol>
                                              <a href={"javascript:void(0)"} className={"underline-link yellow-text"}
                                                 onClick={(e) => accountContext.doGotoProfile(ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW, ":profileId", row['account_code'], this.state, this.refTable.getState())}>
                                                  {accountCodeParse(row['account_code'])}
                                              </a>
                                          </TableCol>
                                          <TableCol>{numberWithCommas(row['house_deposit'], true)}</TableCol>
                                      </TableRow>
                                  }
                                  ref={(ref) => this.refTable = ref}
                                  mobile={(row, index, number, total, onNext, onPrevious) =>
                                      <TableMobileRow key={index}>
                                          <TableMobileCol>
                                              <label>Number</label>
                                              <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                                          </TableMobileCol>
                                          <TableMobileCol>
                                              <label>ID NUMBER</label>
                                              <TableColValue>
                                                  <NavLink className={"underline-link yellow-text"} to={ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW.replaceAll(":profileId", row['account_code'])}>
                                                      {accountCodeParse(row['account_code'])}
                                                  </NavLink>
                                              </TableColValue>
                                          </TableMobileCol>
                                          <TableMobileCol>
                                              <label>TOTAL AMOUNT</label>
                                              <TableColValue>{numberWithCommas(row['cash_out'], true)}</TableColValue>
                                          </TableMobileCol>
                                          <TableMobileCol>
                                              <label>HOUSE BALANCE</label>
                                              <TableColValue>{numberWithCommas(row['house_deposit'], true)}</TableColValue>
                                          </TableMobileCol>
                                      </TableMobileRow>}
                                  search={this.state.search}
                                  loaded={() => {
                                      this.setState({
                                          searchComponent: <RankingSearch searchData={this.state.search} doUpdateSearchData={this.doUpdateSearchData}  refTable={this.refTable} />
                                      })
                                  }}
                    />
                </div>

            </PanelContainer>
        )
    }
}

export default withRouter(withAdminAccountContext(CashOutRanking));