import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import PanelContainer from "../../PanelContainer";
import AccountTable from "../../../../components/ui/AccountTable/AccountTable";
import AdminService from "../../../../services/admin.service";
import TableRow from "../../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../../components/ui/AccountTable/TableCol";
import {
    ROUTE_ADMIN_CEG_SELECTED_ROUND,
    ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS_SELECTED,
    ROUTE_ADMIN_ROUND_SELECTED_OVERVIEW
} from "../../../../routers/routes";
import {numberWithCommas} from "../../../../helpers/helpers";
import moment from "moment";
import TableMobileRow from "../../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../../components/ui/AccountTable/TableColValue";
import PageTitleContainer from "../../Search/PageTitleContainer";
import MonthlyDailyDepositsSearch from "./MonthlyDailyDepositsSearch";
class MonthlyDailyDeposits extends Component {
    refTable = React.createRef();
    refSearch = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            searchComponent: null,
            search: {
                type: "monthly",
                start_date: "",
                end_date: "",
                month: new Date().getMonth(),
                year: new Date().getFullYear()
            }
        }
    }
    componentDidMount() {
        if(localStorage.getItem('backed_from_account_state')){
            let state = JSON.parse(localStorage.getItem('backed_from_account_state'));
            localStorage.removeItem('backed_from_account_state');
            this.setState(state)
        }
    }

    onLoadSelectedRound = (e, row) => {
        const {accountContext} = this.props;
        e.preventDefault();
        accountContext.doGoto(ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS_SELECTED, ":date", row['Date'], this.state, this.refTable.getState());
    }
    doUpdateSearchData = (data) => {
        this.setState({
            ...this.state,
            search: data
        })
    }
    render() {
        let sum = {
            "TotalCount": 0,
            "TotalAmount": 0,
            "WaitCount": 0,
            "ApprovedCount": 0,
            "DeclinedCount": 0
        }
        return (
            <PanelContainer>
                <PageTitleContainer searchBar={this.state.searchComponent}/>
                <div className={"form-center"}>
                    <AccountTable ref={(ref) => this.refTable = ref} full={true} loadData={AdminService.getMonthlyYearlyDepositsReports} sort={"id"} align={"desc"} header={(onSort, sort, align) => {
                        return <TableRow>
                            <TableColHeader>#</TableColHeader>
                            <TableColHeader>Date</TableColHeader>
                            <TableColHeader id={"table-cell-05"}>Total Transactions</TableColHeader>
                            <TableColHeader id={"table-cell-04"}>Total Deposits</TableColHeader>
                            {process.env.REACT_APP_NAME !== "swissco.gaming" && <TableColHeader id={"table-cell-03"}>Wait Approval</TableColHeader>}
                            <TableColHeader id={"table-cell-02"}>Total Approved</TableColHeader>
                            <TableColHeader id={"table-cell-01"}>Total Declined</TableColHeader>
                        </TableRow>
                    }}
                       render={(row, index, number) => {
                           sum['TotalCount'] += parseInt(row['TotalCount']);
                           sum['TotalAmount'] += parseFloat(row['TotalAmount']);
                           sum['WaitCount'] += parseInt(row['WaitCount']);
                           sum['ApprovedCount'] += parseInt(row['ApprovedCount']);
                           sum['DeclinedCount'] += parseInt(row['DeclinedCount']);
                           return <TableRow key={index} onClick={(e) => this.onLoadSelectedRound(e, row)}>
                               <TableCol>{index + 1}</TableCol>
                               <TableCol>{moment(new Date(row['Date'])).format((row['Date'].split("-").length === 3)?'ddd, MMMM DD, YYYY':'MMMM, YYYY')}</TableCol>
                               <TableCol>{row['TotalCount']}</TableCol>
                               <TableCol><span>{numberWithCommas(row['TotalAmount'], true)}</span></TableCol>
                               {process.env.REACT_APP_NAME !== "swissco.gaming" && <TableCol classes={"yellow-text"}>{row['WaitCount']}</TableCol>}
                               <TableCol classes={"green-text"}>{row['ApprovedCount']}</TableCol>
                               <TableCol classes={"red-text"}>{row['DeclinedCount']}</TableCol>
                           </TableRow>
                       }}
                      mobile={(row, index, number, total, onNext, onPrevious) =>
                          <TableMobileRow key={index}>
                              <TableMobileCol>
                                  <label>#</label>
                                  <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{index + 1}</TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Date</label>
                                  <TableColValue>{moment(new Date(row['Date'])).format((row['Date'].split("-").length === 3)?'ddd, MMMM DD, YYYY':'MMMM, YYYY')}</TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Total Transactions</label>
                                  <TableColValue>{row['TotalCount']}</TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Total Deposits</label>
                                  <TableColValue><span className={"yellow-text"}>{numberWithCommas(row['TotalAmount'], true)}</span></TableColValue>
                              </TableMobileCol>
                              {process.env.REACT_APP_NAME !== "swissco.gaming" && <TableMobileCol>
                                  <label>Wait Approval</label>
                                  <TableColValue>{row['WaitCount']}</TableColValue>
                              </TableMobileCol>}
                              <TableMobileCol>
                                  <label>Total Approved</label>
                                  <TableColValue>{row['ApprovedCount']}</TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Total Declined</label>
                                  <TableColValue>{row['DeclinedCount']}</TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <TableColValue>
                                      <button onClick={(e) => this.onLoadSelectedRound(e, row)}>View details ...</button>
                                  </TableColValue>
                              </TableMobileCol>
                          </TableMobileRow>}
                      last_line={() => {
                          return <TableRow>
                              <TableCol colSpan={2}></TableCol>
                              <TableCol>{sum['TotalCount']}</TableCol>
                              <TableCol classes={"yellow-text"}><span>{numberWithCommas(sum['TotalAmount'], true)}</span></TableCol>
                              {process.env.REACT_APP_NAME !== "swissco.gaming" && <TableCol classes={"yellow-text"}>{sum['WaitCount']}</TableCol>}
                              <TableCol classes={"green-text"}>{sum['ApprovedCount']}</TableCol>
                              <TableCol classes={"red-text"}>{sum['DeclinedCount']}</TableCol>
                          </TableRow>
                      }}
                       search={this.state.search}
                       loaded={() => {
                           this.setState({
                               searchComponent: <MonthlyDailyDepositsSearch searchData={this.state.search} doUpdateSearchData={this.doUpdateSearchData} refTable={this.refTable} />
                           })
                       }}
                    />
                </div>
            </PanelContainer>
        )
    }
}

export default withRouter(withAdminAccountContext(MonthlyDailyDeposits));