import React, {Component} from 'react';
import styles from "./LeftSidePopup.module.css";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SmallTable from "../../../scenes/GameTable/Table/SmallTable";
import UserService from "../../../services/user.service";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import AdminTokenService from "../../../services/admin.token.service";
import AdminService from "../../../services/admin.service";
class LeftSidePopup extends Component {
    PopupRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            history: null,
            title: null
        }
    }
    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if(prevProps.history !== this.props.history){
            await this.onClick(this.props.history)
        }

    }

    componentDidMount = async () => {
        const {history} = this.props;
       await this.onClick(history);
        this.PopupRef.style.left = "-60%"
        let interval = setInterval(() => {
            let l = this.PopupRef.style.left.replaceAll("%", "");
            l = parseInt(l);
            if(l === 0) {
                clearInterval(interval);
                this.PopupRef.style.left = "0"
            }else{
                this.PopupRef.style.left = (l + 10) + "%"
            }
        }, 30)

    }
    onClick = async (id, action) => {
        this.setState({
            loading: true
        });
        let playedHistory = null;
        if(AdminTokenService.getAccessToken()){
            playedHistory = await AdminService.getPlayedHistory({
                id
            })
        }else{
            playedHistory = await UserService.getPlayedHistory({
                id
            })
        }
        this.setState({
            history: playedHistory,
            title: `Round #${playedHistory['round_number']} History`,
            loading: false
        })
        this.props.onChangeHistoryRow(id, action)
    }

    render() {
        const {show, onClose, applicationContext, disableClose, large, dark} = this.props;
        return (
            show?(
                <div className={classNames(styles.PopupContainer, !large?styles.SmallPopup:styles.LargePopup, dark && styles.Dark)} ref={(ref) => this.PopupRef = ref}>
                    {this.state.loading?(<div className={styles.LoadingContainer}><img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={150}/></div>):(
                        <React.Fragment>
                            <div className={styles.PopupBody}>
                                <button className={styles.CloseButton} onClick={onClose}><FontAwesomeIcon icon={"times"} /></button>
                                <div className={styles.PopupBodyWrapper}>
                                    <SmallTable ref={(ref) => this.refTable = ref} history={this.state.history}/>
                                </div>
                                <div className={styles.ArrowRightButton}>
                                    {(!applicationContext.state.isMobileVersion)?(
                                        <div className={"wrapper"}>
                                            <button className={classNames(styles.ArrowButton, !this.state.history.next && styles.ArrowDisabled)} onClick={(e) => this.state.history.next && this.onClick(this.state.history.next.id, "next")}>
                                                <FontAwesomeIcon icon={"arrow-alt-circle-up"} />
                                            </button>
                                            <button className={classNames(styles.ArrowButton, !this.state.history.previous && styles.ArrowDisabled)} onClick={(e) => this.state.history.previous && this.onClick(this.state.history.previous.id, "previous")}>
                                                <FontAwesomeIcon icon={"arrow-alt-circle-down"} />
                                            </button>
                                        </div>
                                    ):(
                                        <div className={"wrapper"}>
                                            <button className={classNames(styles.ArrowButton, !this.state.history.previous && styles.ArrowDisabled)} onClick={(e) => this.state.history.previous && this.onClick(this.state.history.previous.id, "previous")}>
                                                <FontAwesomeIcon icon={"chevron-left"} />
                                            </button>
                                            <div className={"round-number"}>ROUND# <span className={"yellow-text"}>{this.state.history['round_number']}</span></div>
                                            <button className={classNames(styles.ArrowButton, !this.state.history.next && styles.ArrowDisabled)} onClick={(e) => this.state.history.next && this.onClick(this.state.history.next.id, "next")}>
                                                <FontAwesomeIcon icon={"chevron-right"} />
                                            </button>
                                        </div>
                                    )}
                                </div>

                            </div>

                        </React.Fragment>
                    )}


                </div>):(null)
        );
    }
}

export default withApplicationContext(LeftSidePopup);