import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import {Form} from "react-bootstrap";
import styles from "../../../components/ui/PageTitle/PageTitle.module.css";
class UsersSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: props.searchData
        }
    }
    onSearch = (e) => {
        this.setState({
            search: {
                keyword: e.target.value
            }
        }, () => {
            this.props.doUpdateSearchData(this.state.search)
        })
    }
    handleSearch = () => {
        let search = this.state.search;
        this.props.refTable.search(search)
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.SearchBox}>
                    <Form.Control
                        aria-describedby="basic-addon2"
                        className={"search-item"}
                        name={"keyword"}
                        onChange={this.onSearch}
                        style={document.getElementById('table-cell-02')?{ width: document.getElementById('table-cell-02').offsetWidth }:null}
                        placeholder={"Keyword"}
                        value={this.state.search.keyword}
                    />
                    <button className={"btn btn-account search-item"} type={"submit"} onClick={this.handleSearch}
                            style={document.getElementById('table-cell-01')?{ width: document.getElementById('table-cell-01').offsetWidth }:null}
                    >Search</button>

                </div>

            </React.Fragment>
        )
    }
}

export default withRouter(withAdminAccountContext(UsersSearch));