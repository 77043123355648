import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import {NavLink, withRouter} from "react-router-dom"
import {withAlert} from "react-alert";
import styled from "./Errors.module.css";
class Error404 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const {accountContext} = this.props;
        return (
            <div className={styled.Container}>
                <div className={styled.Error404}>
                    <div className={styled.Title}>404</div>
                    <div className={styled.Description}>Page not found</div>
                </div>
            </div>
        )
    }
}

export default withAlert()((withApplicationContext(withAccountContext(withRouter(Error404)))));