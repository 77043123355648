import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import {Form} from "react-bootstrap";
import styles from "../../../components/ui/PageTitle/PageTitle.module.css";
class WaitForApprovalDepositsSearch extends Component {
    constructor(props) {
        super(props);
        let years = [];
        for (let i = 2020 ; i <= new Date().getFullYear(); i++){
            years.push(i);
        }
        this.state = {
            round_number: "",
            date: null,
            datepicker: false,
            years,
            search: props.searchData
        }
    }
    onChangeKeyword = (e) => {
        this.setState({
            search: {
                ...this.state.search,
                keyword: e.target.value
            }
        }, () => {
            this.props.doUpdateSearchData(this.state.search)
        });
    }
    handleSearch = () => {
        let search = this.state.search;
        this.props.refTable.search(search)
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.SearchBox}>
                    <Form.Control
                        aria-describedby="basic-addon2"
                        className={"search-item"}
                        name={"keyword"}
                        onChange={this.onChangeKeyword}
                        style={document.getElementById('table-cell-03')?{ width: document.getElementById('table-cell-03').offsetWidth }:null}
                        placeholder={"ACCOUNT ID"}
                        value={this.state.search.keyword}
                    />
                    <Form.Control
                        as={"select"}
                        className={"search-item"}
                        name={"status"}
                        value={this.state.search.status}
                        style={document.getElementById('table-cell-02')?{ width: document.getElementById('table-cell-02').offsetWidth }:null}
                        onChange={(e) => {
                            this.setState({
                                search: {
                                    ...this.state.search,
                                    status: e.target.value
                                }
                            }, () => {
                                this.props.doUpdateSearchData(this.state.search)
                            })
                        }}

                    >
                        <option value={""}>ALL TRANSACTIONS</option>
                        <option value={"0"}>WAIT FOR APPROVALS</option>
                        <option value={"1"}>APPROVED</option>
                        <option value={"2"}>DECLINED</option>
                    </Form.Control>
                    <button className={"btn btn-account search-item"} type={"submit"} onClick={this.handleSearch}
                            style={document.getElementById('table-cell-01')?{ width: document.getElementById('table-cell-01').offsetWidth }:null}
                    >Search</button>

                </div>

            </React.Fragment>
        )
    }
}

export default withRouter(withAdminAccountContext(WaitForApprovalDepositsSearch));