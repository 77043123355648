import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {NavLink, withRouter} from "react-router-dom"
import AccountTable from "../../../components/ui/AccountTable";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import moment from 'moment';
import {accountCodeParse, age, numberWithCommas} from "../../../helpers/helpers";
import ActionButton from "../../../components/ui/ActionButton";
import {ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW} from "../../../routers/routes";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import PanelContainer from "../PanelContainer";
import AdminService from "../../../services/admin.service";
import PageTitleContainer from "../Search/PageTitleContainer";
import UsersSearch from "./UsersSearch";
import RoundBetWinningResultNumberSearch from "../RoundWinningNumber/RoundBetWinningResultNumberSearch";
class Users extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            loading: true,
            search: (urlSearchParams.has('search'))?JSON.parse(urlSearchParams.get('search')):{
                keyword: ""
            },
            keyword: "",
            typingTimeout: null,
            searchComponent: null
        }
    }
    componentDidMount() {
        if(localStorage.getItem('backed_from_account_state')){
            let state = JSON.parse(localStorage.getItem('backed_from_account_state'));
            localStorage.removeItem('backed_from_account_state');
            this.setState(state)
        }
    }
    onActivateClick = async (id) => {
        try{
            await AdminService.doActivateUser(id);
            await this.refTable.refresh();
        }catch (e){

        }
    }
    onDisableClick = async (id) => {
        try{
            await AdminService.doDisableUser(id);
            await this.refTable.refresh();
        }catch (e){

        }
    }
    onSearchKeyword = (value) => {
        this.setState({
            keyword: value
        });
    }
    handleSearchKeyword = () => {
        this.refTable.refresh()
    }
    doUpdateSearchData = (data) => {
        this.setState({
            ...this.state,
            search: data
        })
    }
    render() {
        const {accountContext} = this.props;
        return (
            <PanelContainer>
                <PageTitleContainer searchBar={this.state.searchComponent}/>

                <div className={"form-center"}>
                    <AccountTable  ref={(ref) => this.refTable = ref} full={true} loadData={AdminService.getUsersList} sort={"id"} align={"asc"} header={(onSort, sort, align) => {
                        return <TableRow>
                            <TableColHeader sort={"id"} align={align} sorted={sort} onSort={onSort}>#</TableColHeader>
                            {process.env.REACT_APP_NAME === "swissco.gaming" && <TableColHeader sort={"first_name"} align={align} sorted={sort} onSort={onSort}>FIRST NAME</TableColHeader>}
                            {process.env.REACT_APP_NAME === "swissco.gaming" && <TableColHeader sort={"last_name"}  align={align} sorted={sort} onSort={onSort}>LAST NAME</TableColHeader>}
                            {process.env.REACT_APP_NAME !== "swissco.gaming" && <TableColHeader sort={"email"} align={align} sorted={sort} onSort={onSort}>EMAIL</TableColHeader>}
                            {process.env.REACT_APP_NAME === "swissco.gaming" && <TableColHeader sort={"birth_date"} align={align} sorted={sort} onSort={onSort}>Date of birth</TableColHeader>}
                            <TableColHeader>ID NUMBER</TableColHeader>
                            <TableColHeader>MEMBER SINCE</TableColHeader>
                            <TableColHeader id={"table-cell-02"}>ACCOUNT BALANCE</TableColHeader>
                            <TableColHeader id={"table-cell-01"}>DISABLE OR ACTIVATE</TableColHeader>
                        </TableRow>
                    }}
                   render={(row, index, number) =>
                       <TableRow key={index}>
                           <TableCol>{number}</TableCol>
                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableCol>{row['first_name']}</TableCol>}
                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableCol>{row['last_name']}</TableCol>}
                           {process.env.REACT_APP_NAME !== "swissco.gaming" && <TableCol>{row['email']}</TableCol>}
                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableCol>{row['birth_date']?(moment(new Date(row['birth_date'])).format('MMMM DD, YYYY')+ " ("+age(row['birth_date'])+" years old)"):"-"}</TableCol>}
                           <TableCol>
                               <a href={"javascript:void(0)"} className={"underline-link yellow-text"}
                                  onClick={(e) => accountContext.doGotoProfile(ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW, ":profileId", row['account_code'], this.state, this.refTable.getState())}>
                                   {accountCodeParse(row['account_code'])}
                               </a>
                           </TableCol>
                           <TableCol>{moment(new Date(row['created_at'])).format('ddd, MMMM DD, YYYY')}</TableCol>
                           <TableCol><span className={"yellow-text"}>{numberWithCommas(row['balance'], true)}</span></TableCol>
                           <TableCol>
                               {row['blacklisted_at']?
                                   <ActionButton className={"text-danger"} to={"/"} label={"Disable"} confirmTitle={"Activate User"} confirm={true} onClick={(e) => this.onActivateClick(row.id)} />:
                                   <ActionButton className={"text-success"} to={"/"} label={"Activate"} confirmTitle={"Disable User"} confirm={true} onClick={(e) => this.onDisableClick(row.id)} />
                               }
                           </TableCol>
                       </TableRow>
                   }
                   mobile={(row, index, number, total, onNext, onPrevious) =>
                       <TableMobileRow key={index}>
                           <TableMobileCol>
                               <label>Number</label>
                               <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                           </TableMobileCol>
                           <TableMobileCol>
                               <label>ID NUMBER</label>
                               <TableColValue>
                                   <a href={"javascript:void(0)"} className={"underline-link yellow-text"}
                                      onClick={(e) => accountContext.doGotoProfile(ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW, ":profileId", row['account_code'], this.state, this.refTable.getState())}>
                                       {accountCodeParse(row['account_code'])}
                                   </a>
                               </TableColValue>
                           </TableMobileCol>
                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableMobileCol>
                               <label>FIRST NAME</label>
                               <TableColValue>{row['first_name']}</TableColValue>
                           </TableMobileCol>}
                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableMobileCol>
                               <label>LAST NAME</label>
                               <TableColValue>{row['last_name']}</TableColValue>
                           </TableMobileCol>}
                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableMobileCol>
                               <label>E-MAIL ADDRESS</label>
                               <TableColValue>{row['email']}</TableColValue>
                           </TableMobileCol>}
                           <TableMobileCol>
                               <label>MEMBER SINCE</label>
                               <TableColValue>{moment(new Date(row['created_at'])).format('ddd, MMMM DD, YYYY')}</TableColValue>
                           </TableMobileCol>
                           <TableMobileCol>
                               <label>ACCOUNT BALANCE</label>
                               <TableColValue><span className={"yellow-text"}>{numberWithCommas(row['balance'], true)}</span></TableColValue>
                           </TableMobileCol>
                           <TableMobileCol>
                               <label>DISABLE OR ACTIVATE</label>
                               <TableColValue>
                                   {row['blacklisted_at']?
                                       <ActionButton className={"text-danger"} to={"/"} label={"Disable"} confirmTitle={"Activate User"} confirm={true} onClick={(e) => this.onActivateClick(row.id)} />:
                                       <ActionButton className={"text-success"} to={"/"} label={"Activate"} confirmTitle={"Disable User"} confirm={true} onClick={(e) => this.onDisableClick(row.id)} />
                                   }
                               </TableColValue>
                           </TableMobileCol>
                       </TableMobileRow>}
                   loaded={() => {
                       this.setState({
                           searchComponent: <UsersSearch refTable={this.refTable} searchData={this.state.search} doUpdateSearchData={this.doUpdateSearchData}  />
                       })
                   }}
                   search={this.state.search} params={{
                        keyword: this.state.keyword
                    }} />
                </div>
            </PanelContainer>
        )
    }
}

export default withRouter(withAdminAccountContext(Users));