import api from "./api";
import TokenService from "./token.service";
import {queryStringSerialize} from "../helpers/helpers";

const getCountries = () => {
    return api.get("/api/common/countries").then((response) => {
        return response.data;
    });
};
const getCities = (country) => {
    return api.get("/api/common/cities?country="+country).then((response) => {
        return response.data;
    });
};

const CommonService = {
    getCountries,
    getCities,
};

export default CommonService;