import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import Slider from "./Slider";
import AboutUs from "./AboutUs";
import PracticeTable from "./PracticeTable";
import FeaturesAndInterface from "./FeaturesAndInterface";
import OurPartners from "./OurPartners";
import ContactUs from "./ContactUs";
import BaseMaster from "../BaseMaster";
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <BaseMaster>
                <Slider/>
                <AboutUs />
                <PracticeTable />
                <FeaturesAndInterface />
                {/*<OurPartners />*/}
                <ContactUs />
            </BaseMaster>

        );
    }
}

export default withApplicationContext(Home);