import React from "react";

const AdminAccountContext = React.createContext({});
const AdminAccountProvider = AdminAccountContext.Provider;
const AdminAccountConsumer = AdminAccountContext.Consumer;
const withAdminAccountContext = Component => React.forwardRef((props, ref) => (
    <AdminAccountConsumer>
        {(response) => {
            return <Component {...props} accountContext={response} ref={ref} />;
        }}
    </AdminAccountConsumer>
));
export { AdminAccountProvider, AdminAccountConsumer, withAdminAccountContext };
