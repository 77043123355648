import React, {Component} from 'react';
import Echo from "laravel-echo";
import SocketIoClient from "socket.io-client";
import {withGameTableContext} from "../../contexts/GameTableContext"
import TokenService from "../../services/token.service";
import Popup from "../../components/ui/Popup";
import UserService from "../../services/user.service";
import {ROUTE_TABLE_LIVE_PLAY, ROUTE_TABLE_PRACTICE_PLAY} from "../../routers/routes";
import {HOME_WEBSITE} from "../../helpers/constants";


class GameTableContainer extends Component {
    echo = null;
    constructor(props) {
        super(props);
        this.state = {
            socket_connection: false,
            close_sessions_submitting: false,
            connected: false
        }
    }
    handleConnectToSocket = () => {
        const {gameTableContext} = this.props;
        let self = this;
        this.echo = window.Echo = new Echo({
            host: process.env.REACT_APP_SOCKET,
            broadcaster: 'socket.io',
            transports: ["websocket"],
            client: SocketIoClient,
            encrypted: false,
            auth: {
                headers: {
                    'Authorization': `Bearer ${TokenService.getLocalAccessToken()}`,
                }
            },
        });
        this.echo.connector.socket.on('connect', (e) => {
            console.log(this.echo.connector.socket.id + " CONNECTED")
            this.echo.join(`user.play.${gameTableContext.state.userInfo.id}`)
                .here( (data) => {
                    console.log(this.echo.connector.socket.id + " SOCKET CONNECTING")
                    let common = data.filter((user) => user.user.id === gameTableContext.state.userInfo.id);
                    if(common.length > 0){
                        common = common[0];
                        console.log(common)
                        if(!self.state.connected){
                            if(common.user.online){
                                console.log("Connection State: " + self.state.connected)
                                gameTableContext.handleSocketAlreadyConnectedWithAnotherDevice();
                            }else{
                                gameTableContext.handleSocketConnectionErrorFixed(common);
                            }
                            self.setState({ connected: true })
                        }

                        if(common.user.blacklisted_at){
                            gameTableContext.handleUserBlockWindow();
                        }

                    }
                })
                .joining((data) => {
                    console.log(data)
                })
                .listen('.user-calculate-completed', data => {
                    gameTableContext.handleSocket('.user-calculate-completed', data.data);
                })
                .listen('.user-blocked', data => {
                    gameTableContext.handleSocket('.user-blocked', data.data);
                })
                .listen('.user-close-session', data => {
                    if(gameTableContext.state.session_id !== data['device_id']){
                        this.echo.connector.socket.close();
                        window.location.href = HOME_WEBSITE;
                    }
                })
                .listen('.round-state', data => {
                    console.log(data)
                })
                .listen('.broadcast-as', function (e) {
                    console.log(e)
                });
            this.echo.private(`round-channel`)
                .listen('.round-created', data => {
                    gameTableContext.handleSocket('.round-created', data.data);
                })
                .error((error) => {
                    console.log(error)
                });
            this.echo.private(`wheel-state`)
                .listen('.wheel-state-pushed', data => {
                    gameTableContext.handleSocket('.wheel-state-pushed', data.data);
                })
                .error((error) => {
                    console.log(error)
                });
            this.echo.private(`round-state`)
                .listen('.round-state-pushed', data => {
                    gameTableContext.handleSocket('.round-state-pushed', data.data);
                }).error((error) => {
                    console.log(error)
                });
        });

        this.echo.connector.socket.on('connect_error', (e) => {
            console.log("ERROR" ,e);
            gameTableContext.handleSocketConnectionError();
        });

        this.echo.connector.socket.on('reconnecting', () => {

            console.log('CONNECTING');
        });

        this.echo.connector.socket.on('disconnect', async () => {
            this.echo.connector.socket.removeAllListeners('connect');
            // console.log("Round Number:" + gameTableContext.state.roundNumber)
            // try{
            //     let game = await UserService.getPlayedGame({
            //         round_number: gameTableContext.state.roundNumber
            //     });
            //     gameTableContext.handleFromServer(game);
            // }catch (e){
            //     console.log(e)
            // }
            console.log('DISCONNECTED');
        });
    }
    componentDidMount = () => {
        console.log("Try to connect to socket")
        const {live} = this.props;
        if(live){
            this.handleConnectToSocket();
        }
    }
    onDisconnect = async (e) => {
        const {gameTableContext} = this.props;
        if(this.echo.connector.socket.connected){
            let err = this.echo.connector.socket.disconnect();
            console.log(err)
        }else{
            console.log("Round Number:" + gameTableContext.state.roundNumber)
            try{
                let game = await UserService.getPlayedGame({
                    round_number: gameTableContext.state.roundNumber
                });
                gameTableContext.handleFromServer(game);
            }catch (e){
                console.log(e)
            }
        }

    }
    render() {
        const {children, gameTableContext} = this.props;
        return (
            <div>
                {children}
            </div>
        );
    }
}

export default withGameTableContext(GameTableContainer);