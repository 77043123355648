import React, {Component} from "react";
import styles from "./AccountTable.module.css";
import classNames from "classnames";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class TableColValue extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        const { children, pagination, onPrevious, onNext, classes} = this.props
        return (
            <div className={classNames(styles.TableNumberBox, pagination && styles.PaginTableNumberBox, classes)}>
                {pagination && <button onClick={onPrevious} disabled={onPrevious === false}><FontAwesomeIcon icon={"chevron-left"}/></button>}
                <span>{children}</span>
                {pagination && <button onClick={onNext} disabled={onNext === false}><FontAwesomeIcon icon={"chevron-right"}/></button>}
            </div>

        );
    }
}
export default withApplicationContext(TableColValue);