import React, {Component} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import styles from "./Search.module.css";
class SwisscoSearch extends Component {

    render() {
        const {errors, touched, handleChange, values} = this.props;
        return (
            process.env.REACT_APP_NAME === "swissco.gaming"?<React.Fragment>
                <Form.Group className={"mb-3"}>
                    <Col className={"start-center-value"}>
                        <Form.Label className={styles.FormLabel}>MOBILE NUMBER :</Form.Label>
                        <Form.Control type="text"
                                      name={"mobile_number"}
                                      onChange={handleChange}
                                      value={values.mobile_number}
                                      isValid={touched.mobile_number && !errors.mobile_number}
                                      isInvalid={touched.mobile_number && errors.mobile_number}
                                      autoComplete="off"
                        />
                    </Col>
                </Form.Group>
                <Form.Group className={"mb-3"}>
                    <Col className={"start-center-value"}>
                        <Form.Label className={styles.FormLabel}>NAME :</Form.Label>
                        <Form.Control type="text"
                                      name={"name"}
                                      onChange={handleChange}
                                      value={values.name}
                                      isValid={touched.name && !errors.name}
                                      isInvalid={touched.name && errors.name}
                                      autoComplete="off"
                        />
                    </Col>
                </Form.Group>
            </React.Fragment>:null

        )
    }
}

export default SwisscoSearch;