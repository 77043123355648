import React, {Component} from "react";
import styles from "./AccountTable.module.css";
import LoadingContainer from "../../common/LoadingContainer";
import LoadingData from "../../common/LoadingData";
import LoadingTableData from "../../common/LoadingTableData";

import classNames from "classnames";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
class AccountTable extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        let perPage = props.perPage?props.perPage:15;
        let currentPage = 1;
        if(props.position){
            let n = props.position / perPage;
            let s = Math.floor(n);
            if(n % 1 != 0)
                currentPage = s + 1;
            else
                currentPage = s;
        }
        if(params.has('page')){
            currentPage = parseInt(params.get('page'))
        }
        this.state = {
            loading: true,
            current_page: currentPage,
            from: 0,
            last_page: 0,
            per_page: perPage,
            to: 0,
            total: 0,
            data: [],
            min_page: 1,
            max_page: 10,
            align: (props.align)?props.align:"",
            sort: (props.sort)?props.sort:"",
            search: props.search
        }
    }
    refresh = async () => {
        await this.fetchData(this.state.current_page, this.state.search);
    }
    search = async (search) => {
        this.setState({
            search
        }, async () => {
            await this.fetchData(this.state.current_page, search);
        })


    }
    componentDidMount = async () => {
        const {loaded} = this.props;
        if(localStorage.getItem('backed_from_account_table_state')){
            let state = JSON.parse(localStorage.getItem('backed_from_account_table_state'));
            localStorage.removeItem('backed_from_account_table_state');
            this.setState(state, loaded);
        }else{
            await this.fetchData(this.state.current_page, this.state.search);
            if(this.props.data){
                this.setState({
                    data: this.props.data,
                    loading: false
                }, loaded)
            }
        }

    }
    fetchData = async (page = null, search = null, sort = null, align = null) => {
        const {applicationContext, loadData, params, loaded} = this.props;
        if(!loadData) return;
        try{
            let result = await loadData({
                page: (page)?page:this.state.current_page,
                limit: (applicationContext.state.isMobileVersion)?1:this.state.per_page,
                sort: (sort)?sort:this.state.sort,
                align: (align)?align:this.state.align,
                search: JSON.stringify(search),
                ...params
            });
            if(result.hasOwnProperty('data')){
                this.setState({
                    current_page: result.current_page,
                    from: result.from,
                    last_page: result.last_page,
                    per_page: result.per_page,
                    to: result.to,
                    total: result.total,
                    data: result.data,
                    loading: false
                }, loaded)
            }else{
                this.setState({
                    data: result,
                    loading: false
                }, loaded)
            }

        }catch (e){

        }
    }
    onClickFirst = async () => {
        if(this.state.current_page > 1) {
            await this.fetchData(1, this.state.search);
            this.setState({
                min_page: 1,
                max_page: (this.state.last_page > 10)?10:this.state.last_page,
            })
        }
    }
    onClickPrev = async () => {
        if(this.state.current_page > 1){
            await this.fetchData(this.state.current_page - 1, this.state.search);
            if(this.state.current_page < this.state.min_page){
                let min = this.state.min_page - 10;
                let max = this.state.min_page - 1;
                this.setState({
                    min_page: min,
                    max_page: max,
                })
            }
        }

    }
    onClickNavigator = async (page) => {
        await this.fetchData(page, this.state.search);
    }
    onClickLast = async () => {
        if(this.state.last_page > this.state.current_page){
            await this.fetchData(this.state.last_page, this.state.search);
            let n = (this.state.last_page / 10).toString().split(".");
            let m = 10;
            if(n.length == 2)
                m = parseInt(n[1]) - 1
            this.setState({
                min_page: (this.state.last_page) - m,
                max_page: this.state.last_page,
            })
        }

    }
    onClickNext = async () => {
        if(this.state.last_page > this.state.current_page){
            await this.fetchData(this.state.current_page + 1, this.state.search);
            if(this.state.current_page > this.state.max_page){
                this.setState({
                    min_page: this.state.max_page + 1,
                    max_page: (this.state.max_page + 10 <= this.state.last_page)?this.state.max_page + 10:this.state.last_page,
                })
            }
        }

    }
    doUpdateRow = (index, row) => {
        this.state.data[index] = row;
    }
    onClickPrevPage =  (e) => {
        let min = this.state.min_page - 10;
        let max = this.state.min_page - 1;
        this.setState({
            min_page: min,
            max_page: max,
        }, async () => {
            await this.fetchData(this.state.max_page, this.state.search);
        });
    }
    onClickNextPage = (e) => {
        this.setState({
            min_page: this.state.max_page + 1,
            max_page: (this.state.max_page + 10 <= this.state.last_page)?this.state.max_page + 10:this.state.last_page
        }, async () => {
            await this.fetchData(this.state.min_page, this.state.search);
        })

    }
    onSort = async (sort, align) => {
        this.setState({
            sort,
            align
        })
        await this.fetchData(null, this.state.search, sort, align);
    }
    renderRowNumber = (index) => {
        if(this.state.sort === "id" && this.state.align === "asc"){
            return ((this.state.current_page - 1) * this.state.per_page) + (index + 1);
        }else if(this.state.sort === "id" && this.state.align === "desc") {
            return (this.state.total - ((this.state.current_page - 1) * this.state.per_page)) - index;
        }
        if(this.state.sort !== "id")
            return ((this.state.current_page - 1) * this.state.per_page) + (index + 1);
        return (this.state.total - ((this.state.current_page - 1) * this.state.per_page)) - index;
    }
    getLoadingStatus = () => {
        return this.state.loading;
    }
    getState = () => {
        return this.state;
    }
    render() {
        const { applicationContext, header, render, mobile, full, last_line, width } = this.props;
        let items = [];
        if(this.state.last_page <= 10){
            Array.from(Array(this.state.last_page).keys()).map((p) =>
                items.push(<li className={classNames(styles.Navigator, this.state.current_page === (p + 1) && styles.NavigatorActive)} onClick={(e) => this.onClickNavigator(p + 1)}>{p + 1}</li>)
            )
        }else{
            if(this.state.min_page > 1){
                items.push(<li className={classNames(styles.Navigator)} onClick={this.onClickPrevPage}>...</li>)
            }
            for(let p = this.state.min_page; p <= this.state.max_page; p++){
                items.push(<li className={classNames(styles.Navigator, this.state.current_page === (p) && styles.NavigatorActive)} onClick={(e) => this.onClickNavigator(p)}>{p}</li>)
            }
            if(this.state.max_page < this.state.last_page){
                items.push(<li className={classNames(styles.Navigator)} onClick={this.onClickNextPage}>...</li>)
            }
        }


        return (
            <div className={styles.FullWidth}>
                {!applicationContext.state.isMobileVersion?(
                    <React.Fragment>
                        <table className={!full?styles.Table:styles.FullTable} style={width?{width: "60%"}:null}>
                            <thead>
                                {header(this.onSort, this.state.sort, this.state.align)}
                            </thead>
                            <tbody>
                                {!this.state.loading && this.state.data.map((row, index) => render(row, index, this.renderRowNumber(index), this.state.current_page))}
                                {!this.state.loading && last_line?last_line():null}
                            </tbody>
                        </table>
                        {this.state.loading && <LoadingData loading={true} />}
                        {!this.state.loading && this.state.last_page > 1 && <div className={styles.PaginationWrapper}>
                            <ul>
                                <li className={classNames(styles.NavigatorButton, this.state.current_page <= 1 && styles.NavigatorDisabled)} onClick={this.onClickFirst}>First</li>
                                <li className={classNames(styles.NavigatorButton, this.state.current_page <= 1 && styles.NavigatorDisabled)} onClick={this.onClickPrev}>Prev</li>
                                {items}
                                <li className={classNames(styles.NavigatorButton, this.state.last_page <= this.state.current_page && styles.NavigatorDisabled)} onClick={this.onClickNext}>Next</li>
                                <li className={classNames(styles.NavigatorButton, this.state.last_page <= this.state.current_page && styles.NavigatorDisabled)} onClick={this.onClickLast}>Last</li>
                            </ul>
                        </div>}
                    </React.Fragment>
                ):(
                    <div className={styles.Table}>
                        <LoadingData loading={this.state.loading}>
                            {this.state.data.map((row, index) => mobile(
                                row,
                                index,
                                this.renderRowNumber(index),
                                this.state.total,
                                this.state.align === "desc"?(this.onClickPrev):(this.onClickNext),
                                this.state.align === "desc"?(this.onClickNext):(this.onClickPrev)
                            ))}
                        </LoadingData>
                    </div>
                )}

            </div>

        );
    }
}
export default withApplicationContext(AccountTable);