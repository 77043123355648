import React, {Component} from 'react';
import {withGameTableContext} from "../../contexts/GameTableContext";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import classNames from "classnames";
import styles from "./GameTable.module.css";
import {ROUTE_USER_PROFILE} from "../../routers/routes";
import MobileTools from "./Tools/MobileTools";

class MobileGameTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }

    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 3000)
    }

    render() {
        const {gameTableContext} = this.props;
        return (
            <div className={classNames(styles.AccountPageContainer, gameTableContext.state.windows.account && styles.OpenAccountPageContainer)}>
                <iframe src={gameTableContext.state.windows.data?gameTableContext.state.windows.data:ROUTE_USER_PROFILE} style={this.state.loading?{ display: 'none'}:{ display: 'block' }}></iframe>
                <MobileTools />
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext(MobileGameTable));