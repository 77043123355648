import React, {Component} from 'react';
import styled from "./Attachments.module.css";
import {uuid} from "../../../helpers/helpers";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";

class Attachments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attachments: []
        }
    }
    reset = () => {
        this.setState({
            attachments: []
        })
    }
    onSelectFile = (e) => {
        const {callback} = this.props;
        let attachments = this.state.attachments;
        Array.from(e.target.files).map((file) => {
            attachments.push({
                id: uuid(),
                src: URL.createObjectURL(file),
                file
            });
        })
        this.setState({
            attachments
        }, () => {
            callback(attachments)
        })
    }
    onDeleteFile = (id) => {
        const {callback} = this.props;
        let attachments = this.state.attachments;
        this.state.attachments.map((att, i) => {
            if(att.id === id)
                attachments.splice(i, 1);

        })
        this.setState({ attachments }, () => {
            callback(attachments, id)
        })
    }
    render() {
        const {applicationContext, children} = this.props;
        return (
            <div className={applicationContext.translator("direction") === "rtl"?"text-right":"text-left"}>
                <button className={"btn btn-secondary"} type={"button"}  onClick={(e) => this.refFileSelector.click() }>{applicationContext.translator("Upload attachments")}</button>
                <p className={styled.HelpText}>
                    <small>{applicationContext.translator("You can upload just image with png, gif, jpeg formats")}</small>
                    <input type={"file"}
                           name={"files"}
                           multiple={true}
                           style={{ visibility: "hidden"}} ref={(ref) => this.refFileSelector = ref}
                           accept="image/png, image/gif, image/jpeg"
                           onChange={this.onSelectFile}
                    />
                </p>
                {this.state.attachments.length > 0 && <div className={styled.UploadedFiles}>
                    {this.state.attachments.map((attach, index) => {
                        return <div className={styled.AttachItem} key={index}>
                            <button className={styled.AttachItemRemove} type={"button"} onClick={(e) => this.onDeleteFile(attach.id)}>x</button>
                            <img src={attach.src} />
                        </div>;
                    })}
                </div>}
            </div>
        );
    }
}

export default withApplicationContext(Attachments);