import React, {Component} from 'react';
import {Route, Redirect, withRouter} from "react-router-dom";
import {ROUTE_ADMIN_DASHBOARD, ROUTE_LOGIN} from "./routes";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import {AccountProvider} from "../contexts/AccountContext";
import {withApplicationContext} from "../contexts/ApplicationContext"
import TokenService from "../services/token.service";
class AuthRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pageTitle: ""
        }
    }
    componentDidMount = async () => {
        // await this.fetchUserInfo();
    }
    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        // if(this.props.path != prevProps.path){
        //     await this.fetchUserInfo();
        // }
    }
    doUpdatePageTitle = (title) => {
        this.setState({
            pageTitle: title
        })
    }
    doGoto = (url, key, value) => {
        const {history} = this.props;
        if(key && value)
            url = url.replaceAll(key, value);
        history.push(url);
    }
    render() {
        const {path, applicationContext} = this.props;
        if (!localStorage.getItem("token")) {
            let message = {
                action: "unauthenticated"
            };
            window.parent.postMessage(message, "*");
            return <Redirect to={ROUTE_LOGIN + "?r=" + encodeURIComponent(this.props.location.pathname) + "&lang=" + applicationContext.state.lang}/>;
        }

        let token = TokenService.getToken();
        if(!path.startsWith("/admin/") && token.level === "admin")
            return <Redirect to={ROUTE_ADMIN_DASHBOARD+"?r="+encodeURIComponent(this.props.location.pathname)} />;

        return <AccountProvider value={{
            userInfo: applicationContext.state.userInfo,
            updateUserInfo: applicationContext.updateUserInfo,
            loading: false,
            level: (path.startsWith("/admin/"))?"admin":"user",
            pageTitle: this.state.pageTitle,
            doUpdatePageTitle: this.doUpdatePageTitle,
            doGoto: this.doGoto,
        }}>
            <Route {...this.props} />
        </AccountProvider>;

    }
}

export default withApplicationContext(withRouter(AuthRoute));