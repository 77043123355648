import React, {Component} from 'react';
import styles from "./Login.module.css";
import * as Yup from "yup";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {withRouter} from "react-router-dom"
import {
    ROUTE_ADMIN_CEG_SEARCH_PLAYER_ACCOUNT,
    ROUTE_ADMIN_DASHBOARD,
    ROUTE_ADMIN_SEARCH_PLAYER_ACCOUNT, ROUTE_ADMIN_WAIT_FOR_ANSWER_TICKETS
} from "../../../routers/routes";
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {parseErrorMessage} from "../../../helpers/helpers";
import {withAlert} from "react-alert";
import AdminService from "../../../services/admin.service";
import AdminTokenService from "../../../services/admin.token.service";
import {ROLE_AGENT, ROLE_CEG_ADMIN, ROLE_SUPER_ADMIN} from "../../../helpers/constants";
import UserService from "../../../services/user.service";
import TokenService from "../../../services/token.service";
class Login extends Component {
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            close: false,
            loading: false,
            fullLoading: false,
            redirect: (urlSearchParams.has('r'))?decodeURIComponent(urlSearchParams.get('r')):(ROUTE_ADMIN_DASHBOARD),
            isCegPanel: (window.location.href.includes("ceg-panel.maxx.tv") || window.location.href.includes("ceg-panel.swisscogaming.com") || window.location.href.includes("support-panel.swisscogaming.com"))?true:false
        }
    }
    componentDidMount = async () =>{
        const {history} = this.props;
        if(AdminTokenService.getAccessToken()){
            this.setState({ fullLoading: true })
            try {
                const userInfo = await AdminService.getAdminBoard();
                if(userInfo){
                    if(userInfo.role === ROLE_AGENT)
                        history.push(ROUTE_ADMIN_WAIT_FOR_ANSWER_TICKETS)
                    else
                        history.push(ROUTE_ADMIN_DASHBOARD)
                }
            } catch (err) {
                AdminTokenService.logout();
                this.setState({ fullLoading: false })
            }
        }


    }

    handleSubmit = async (values, actions) => {
        const {accountContext, alert, history} = this.props;
        if(this.state.loading) return;
        this.setState({loading: true});
        try {
            const token  = await AdminService.login(values.username, values.password);
            AdminTokenService.setAccessToken(token['access_token']);
            if(token.role === ROLE_CEG_ADMIN)
                history.push(ROUTE_ADMIN_CEG_SEARCH_PLAYER_ACCOUNT)
            else{
                if(token.role === ROLE_AGENT)
                    history.push(ROUTE_ADMIN_WAIT_FOR_ANSWER_TICKETS)
                else
                    history.push(this.state.redirect)

            }


        } catch (err) {
            alert.error(parseErrorMessage(err));
        }
        this.setState({loading: false})
    }
    renderLogo = () => {
        if(this.state.isCegPanel)
            return <img src={`${process.env.PUBLIC_URL}/assets/img/ceg-logo.png`} width={100}/>;
        if(process.env.REACT_APP_NAME !== "swissco.gaming")
            return <img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={250} />;

        return <img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={150}/>;
    }
    render() {

        return (
            <div className={styles.LoginContainer}>
                {this.state.fullLoading?
                    <div className={styles.LoadingContainer}>
                        {process.env.REACT_APP_NAME === "swissco.gaming" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo-light.png`} width={150}/>}
                        {process.env.REACT_APP_NAME !== "swissco.gaming" && <img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={250}/>}
                    </div>:
                    <div className={styles.LoginWrapper}>
                    <div className={"text-center"}>
                        {this.renderLogo()}
                    </div>
                    <div className={styles.LoginBody}>
                        <h4>Enter Login Details</h4>
                        <Formik
                            innerRef={(ref) => this.refForm = ref}
                            initialValues={{
                                username: this.state.username,
                                password: this.state.password,
                            }}
                            validationSchema={Yup.object().shape({
                                username: Yup.string().required("You must enter your username"),
                                password: Yup.string().required("You must enter your password")
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className={!touched.username && "mb-3"}>
                                        <Col md={3} className={styles.StartCenterValue}>
                                            <Form.Label>Username</Form.Label>
                                        </Col>
                                        <Col className={styles.StartCenterValue}>
                                            <Form.Control type="text"
                                                          name={"username"}
                                                          onChange={handleChange}
                                                          value={values.username}
                                                          isValid={touched.username && !errors.username}
                                                          isInvalid={touched.username && errors.username}
                                                          onFocus={(e) => {
                                                              e.target.select()
                                                          }}
                                                          autoComplete="off"
                                                          ref={(ref) => this.refInput = ref}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.username && <Form.Group as={Row} className={"mb-3"}>
                                        <Col md={3}></Col>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.username}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>
                                    }
                                    <Form.Group as={Row} className={!touched.password && "mb-3"}>
                                        <Col md={3} className={styles.StartCenterValue}>
                                            <Form.Label>Password</Form.Label>
                                        </Col>
                                        <Col className={styles.StartCenterValue}>
                                            <Form.Control type="password"
                                                          name={"password"}
                                                          onChange={handleChange}
                                                          value={values.password}
                                                          isValid={touched.password && !errors.password}
                                                          isInvalid={touched.password && errors.password}
                                                          onFocus={(e) => {
                                                              e.target.select()
                                                          }}
                                                          autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.password && <Form.Group as={Row} className={"mb-3"}>
                                        <Col md={3}></Col>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.password}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>}
                                    <Form.Group className={styles.EndCenterValue}>
                                        <button disabled={this.state.loading} className={"btn btn-account"} type={"submit"}>{this.state.loading?<Spinner animation="border" variant="light" size={"sm"} />:"Login"}</button>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>}

            </div>
        )
    }
}

export default withAlert()(withRouter(withAdminAccountContext(Login)));