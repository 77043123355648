import UserService from "./user.service";
const getLocalRefreshToken = () => {
    let token = JSON.parse(localStorage.getItem("token"));
    return token?.refresh_token
};

const getLocalAccessToken = () => {
    let token = JSON.parse(localStorage.getItem("token"));
    return token?.access_token
};


const updateLocalAccessToken = (token) => {
    localStorage.setItem("token", JSON.stringify(token));
};

const getToken = () => {
    return JSON.parse(localStorage.getItem("token"));
};

const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
};

const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
    getToken
};

export default TokenService;