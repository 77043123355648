import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {
    SectionContainer,
    SectionTitle,
    SectionBody, CenterCol,
    PrimaryButton, CenterText,
    ColImage
} from "./style.jsx";
import {Container, Row, Col} from 'react-bootstrap';

class PracticeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SectionContainer id={"section-practice"}>
                <Container>
                    <SectionTitle><div className={"wrapper"}>{applicationContext.translator("Practice Table")}</div></SectionTitle>
                    <SectionBody>
                        <Row>
                            <Col md={6}>
                                <ColImage><img src={"/assets/img/practice-table.jpg"} width={"90%"}/></ColImage>
                            </Col>
                            <CenterCol md={6}>
                                <CenterText>
                                    <p>ما یک میز تمرین رایگان به شما هدیه می کنیم که به شدت برای بازیکنان در همه سطوح مفید است چرا که به شما کمک می کند بدون پرداخت واقعی و ریسک کردن، با نحوه بازی آشنا شوید. همچنین نسبت به بازی آنلاین استاندارد رولت، قابلیت های ویژه و روند بازی سریع تری را برای شما علاقمندان فراهم کرده ایم.</p>
                                    <PrimaryButton to={"/practice?lang="+applicationContext.state.lang}>{applicationContext.translator("Play On Practice Table")}</PrimaryButton>
                                </CenterText>

                            </CenterCol>

                        </Row>
                    </SectionBody>

                </Container>
            </SectionContainer>

        );
    }
}

export default withApplicationContext(PracticeTable);