import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
class DepositStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const {status, applicationContext} = this.props;
        const description = {
            0: {
                label: applicationContext.translator("Waiting for approval"),
                color: "yellow-text"
            },
            1: {
                label: applicationContext.translator("Approved"),
                color: "green-text"
            },
            2: {
                label: applicationContext.translator("Declined"),
                color: "red-text"
            }
        }
        return (
            description.hasOwnProperty(status)?<Form.Text className={description[status].color}>
                {description[status].label.toUpperCase()}
            </Form.Text>:null
        );
    }
}

export default withApplicationContext(DepositStatus);