import React from "react";

const AccountContext = React.createContext({});
const AccountProvider = AccountContext.Provider;
const AccountConsumer = AccountContext.Consumer;
const withAccountContext = Component => React.forwardRef((props, ref) => (
    <AccountConsumer>
        {(response) => {
            return <Component {...props} accountContext={response} ref={ref} />;
        }}
    </AccountConsumer>
));
export { AccountProvider, AccountConsumer, withAccountContext };
