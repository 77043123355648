import React, {Component} from 'react';
import {withGameTableContext} from "../../contexts/GameTableContext";
import styles from "./GameTable.module.css";
import Display from "./Display/Display";
import Monitor from "./Monitor/Monitor";
import WonBoardToolbar from "./WonBoardToolbar/WonBoardToolbar";
import WonBoard from "./WonBoard/WonBoard";
import TopChips from "./TopChips/TopChips";
import Stats from "./Stats/Stats";
import Wheel from "./Wheel/Wheel";
import Table from "./Table/Table";
import {HOME_WEBSITE} from "../../helpers/constants";
import Chips from "./Chips/Chips";
import Tools from "./Tools/Tools";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import {ROUTE_TABLE_LIVE_PLAY, ROUTE_USER_PROFILE} from "../../routers/routes";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import MobileTools from "./Tools/MobileTools";
import MobileTopCells from "./TopCells/MobileTopCells";
import MobileGameTable from "./MobileGameTable";
import MobileVideoStream from "./MobileVideoStream";
import moment from "moment";

class DesktopGameTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            endScroll: false,
        }
    }
    componentDidMount() {
        //window.addEventListener('scroll',  this.handleContainerOnScroll);
    }
    componentWillUnmount() {
        //window.removeEventListener('scroll', this.handleContainerOnScroll);
    }

    handleContainerOnScroll = (e) => {


    }

    render() {
        const {gameTableContext, applicationContext} = this.props;
        //onScroll={gameTableContext.state.isMobileVersion && this.handleContainerOnScroll}

        return (
            <React.Fragment>
                <div className={styles.Container}  id={"WebAppContainer"}>
                    {gameTableContext.state.windowOpened && !gameTableContext.state.windows.video && <div className={styles.OverlayWindow} onClick={(e) => gameTableContext.handleCloseAllWindows()}></div>}
                    <div className={styles.TableContainer}>
                        <div className={styles.RightDisplay}>

                            <div className={styles.RightDisplayContainer}>
                                <div className={styles.ToolsChipsContainer}>
                                    <div className={classNames(styles.LogoRightOfMonitor, process.env.REACT_APP_NAME !== "swissco.gaming" && styles.LogoRightOfMonitorRoulette)}>
                                        <a href={HOME_WEBSITE}><img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={150}/></a>
                                    </div>
                                    {process.env.REACT_APP_NAME !== "swissco.gaming" && <div className={styles.LanguagesRightOfMonitor}>
                                        <NavLink to={"#"} onClick={(e) => gameTableContext.doChangeLanguage("en", true)} className={gameTableContext.state.locale === "en" && styles.LanguagesRightOfMonitorActive}>English</NavLink>
                                        <NavLink to={"#"} onClick={(e) => gameTableContext.doChangeLanguage("fa", true)} className={gameTableContext.state.locale === "fa" && styles.LanguagesRightOfMonitorActive}>فارسی</NavLink>
                                    </div>}
                                    <Display mobile={true}/>
                                    <div className={"d-flex"}>
                                        <Chips ref={(ref) => gameTableContext.updateRef("refChips", ref)}/>
                                        <Tools />
                                    </div>
                                    <div className={styles.SubToolsContainer}>
                                        <button className={classNames(styles.ToolsButton)} disabled={gameTableContext.state.processing} onClick={gameTableContext.handleUndo}>
                                            <div className={classNames(styles.ToolsButtonWrapper, applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font")}>{applicationContext.translator("Undo")}</div>
                                        </button>
                                        <button className={classNames(styles.ToolsButton)} onClick={gameTableContext.handleClear}>
                                            <div className={classNames(styles.ToolsButtonWrapper, applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font")}>{applicationContext.translator("Clear")}</div>
                                        </button>
                                        <div className={"d-flex"}>
                                            <button className={classNames(styles.ToolsButton, gameTableContext.state.autoRepeat && styles.ToolsButtonDisabled)} disabled={gameTableContext.state.autoRepeat} onClick={gameTableContext.handleRepeat}>
                                                <div className={classNames(styles.ToolsButtonWrapper, gameTableContext.state.autoRepeat && styles.TextGreenForce, applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font")}>{applicationContext.translator("Repeat")}</div>
                                            </button>
                                            <button className={classNames(styles.CircleGrayButton, gameTableContext.state.autoRepeat && styles.ActiveCircleGrayButton)} onClick={gameTableContext.handleAutoRepeat}>
                                                <FontAwesomeIcon icon={"sync-alt"} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={classNames(styles.AccountButtons)}>

                                        {!gameTableContext.state.practice &&
                                        <React.Fragment>
                                            <NavLink to={{pathname: process.env.REACT_APP_LIVE_URL.replaceAll("/live/play", "").concat("/account/profile?lang="+gameTableContext.state.locale)}} target={"_blank"} className={classNames(styles.CircleButton, "text-center")} >
                                                <div className={classNames(styles.CircleButtonWrapper, applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font p-font-normal")} dangerouslySetInnerHTML={{ __html: applicationContext.translator("My Account") }} />
                                            </NavLink>
                                            <button className={classNames(styles.CircleButton, styles.RedCircleButton)} onClick={applicationContext.onLogout}>
                                                <div className={classNames(styles.CircleButtonWrapper, applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font p-font-normal")}>{applicationContext.translator("Logout")}</div>
                                            </button>
                                        </React.Fragment>
                                        }
                                        {gameTableContext.state.practice &&
                                        <React.Fragment>
                                            <NavLink to={{pathname: process.env.REACT_APP_LIVE_URL.replaceAll("/live/play", "").concat("/account/profile?lang="+gameTableContext.state.locale)}} target={"_blank"} className={classNames(styles.CircleButton, "text-center")} >
                                                <div className={classNames(styles.CircleButtonWrapper, applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font p-font-normal")} dangerouslySetInnerHTML={{ __html: applicationContext.translator("My Account") }} />
                                            </NavLink>
                                            <NavLink to={ROUTE_TABLE_LIVE_PLAY.concat("?lang="+gameTableContext.state.locale)} className={classNames(styles.CircleButton, "text-center")} >
                                                <div className={classNames(styles.CircleButtonWrapper, applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font p-font-normal")} dangerouslySetInnerHTML={{ __html: applicationContext.translator("Live Table") }} />
                                            </NavLink>
                                        </React.Fragment>
                                        }

                                    </div>
                                </div>
                                <Table ref={(ref) => gameTableContext.updateRef("refTable", ref)}/>
                            </div>

                        </div>
                        <div className={styles.LeftDisplay}>
                            <Display />
                            {!gameTableContext.state.isMobileVersion && <Monitor ref={ref => gameTableContext.updateRef('refMonitor', ref)} />}

                            <div className={styles.WonContainer}>
                                <div className={styles.WonBoard}>
                                    <WonBoardToolbar />
                                    {gameTableContext.state.table === "won" && <WonBoard />}
                                    {gameTableContext.state.table === "top5" && <TopChips />}
                                    {gameTableContext.state.table === "stats" && <Stats />}


                                </div>
                                <div className={styles.WheelContainer}>
                                    <Wheel ref={(ref) => gameTableContext.updateRef('refWheel', ref)}/>
                                </div>
                            </div>

                        </div>
                        <MobileTools />
                        {gameTableContext.state.isMobileVersion && <MobileTopCells />}
                        {gameTableContext.state.isMobileVersion && <WonBoard />}
                        {gameTableContext.state.isMobileVersion && <TopChips />}
                        {gameTableContext.state.isMobileVersion && <Stats />}
                        {gameTableContext.state.isMobileVersion && <MobileGameTable />}
                        {gameTableContext.state.isMobileVersion && !gameTableContext.state.practice && <MobileVideoStream />}
                    </div>
                </div>
                <div className={styles.DeviceNotSupport}>
                    <div className={styles.Wrapper}>
                        <h1>{applicationContext.translator("Mode Not Supported")}</h1>
                        <p>{applicationContext.translator("Resolution not supported. please change your resolution or if you use landscape mode in your mobile device please switch to portrait mode.")}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withApplicationContext(withGameTableContext(DesktopGameTable));