import React, {Component} from 'react';
import styles from "./Login.module.css";
import baseStyles from "../../../assets/css/App.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withGameTableContext} from "../../../contexts/GameTableContext";
import * as Yup from "yup";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {NavLink, withRouter} from "react-router-dom"
import {ROUTE_ADMIN_DASHBOARD, ROUTE_FORGET_PASSWORD, ROUTE_USER_PROFILE} from "../../../routers/routes";
import classNames from "classnames";
import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import {parseErrorMessage} from "../../../helpers/helpers";
import {withAlert} from "react-alert";
import TokenService from "../../../services/token.service";
class ApiLogin extends Component {
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            loading: true,
            redirect: (urlSearchParams.has('r'))?decodeURIComponent(urlSearchParams.get('r')):((props.level == "user")?ROUTE_USER_PROFILE:ROUTE_ADMIN_DASHBOARD)
        }

    }
    componentDidMount = async () =>{
        const {history, level, location, applicationContext, alert} = this.props;
        const urlSearchParams = new URLSearchParams(location.search);

        if(urlSearchParams.has('token')){
            try {
                TokenService.removeUser();

                let token = JSON.parse();
                console.log(token)
                // TokenService.updateLocalAccessToken(token);
                //
                // const userInfo = await UserService.getUserBoard();
                // if(userInfo){
                //     applicationContext.updateUserInfo(userInfo);
                //     history.push(this.state.redirect);
                //     this.setState({loading: false});
                // }else{
                //     TokenService.removeUser();
                // }
            } catch (err) {

            }

        }

    }
    render() {

        return (
            <div className={styles.LoginContainer}>
                {this.state.loading?<div className={styles.LoadingContainer}><img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={150}/></div>:
                    <div className={styles.LoginWrapper}>
                    <div className={"text-center"}><img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={150}/></div>
                    <div className={styles.LoginBody}>
                        <h4>Enter Login Details</h4>
                        <Formik
                            innerRef={(ref) => this.refForm = ref}
                            initialValues={{
                                username: this.state.username,
                                password: this.state.password,
                            }}
                            validationSchema={Yup.object().shape({
                                username: Yup.string().required("You must enter your username"),
                                password: Yup.string().required("You must enter your password")
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className={!touched.username && "mb-3"}>
                                        <Col md={3} className={styles.StartCenterValue}>
                                            <Form.Label>Username</Form.Label>
                                        </Col>
                                        <Col className={styles.StartCenterValue}>
                                            <Form.Control type="text"
                                                          name={"username"}
                                                          onChange={handleChange}
                                                          value={values.username}
                                                          isValid={touched.username && !errors.username}
                                                          isInvalid={touched.username && errors.username}
                                                          onFocus={(e) => {
                                                              e.target.select()
                                                          }}
                                                          autoComplete="off"
                                                          ref={(ref) => this.refInput = ref}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.username && <Form.Group as={Row} className={"mb-3"}>
                                        <Col md={3}></Col>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.username}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>
                                    }
                                    <Form.Group as={Row} className={!touched.password && "mb-3"}>
                                        <Col md={3} className={styles.StartCenterValue}>
                                            <Form.Label>Password</Form.Label>
                                        </Col>
                                        <Col className={styles.StartCenterValue}>
                                            <Form.Control type="password"
                                                          name={"password"}
                                                          onChange={handleChange}
                                                          value={values.password}
                                                          isValid={touched.password && !errors.password}
                                                          isInvalid={touched.password && errors.password}
                                                          onFocus={(e) => {
                                                              e.target.select()
                                                          }}
                                                          autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.password && <Form.Group as={Row} className={"mb-3"}>
                                        <Col md={3}></Col>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.password}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>}
                                    <Form.Group className={classNames(styles.EndCenterValue, "mb-3")}>
                                        <NavLink className={baseStyles.Link} to={ROUTE_FORGET_PASSWORD}>Forgot your password?</NavLink>
                                    </Form.Group>
                                    <Form.Group className={styles.EndCenterValue}>
                                        <button disabled={this.state.loading} className={"btn btn-account"} type={"submit"}>{this.state.loading?<Spinner animation="border" variant="light" size={"sm"} />:"Login"}</button>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>}

            </div>
        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withGameTableContext(ApiLogin))));