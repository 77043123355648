import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import styles from "./PriceInput.module.css";
import {Form, InputGroup} from "react-bootstrap";
import {numberWithCommas} from "../../../helpers/helpers";
import classNames from "classnames";

class PriceInput extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {amount, disabled, yellow, marginBottom, className} = this.props;
        return (
            <InputGroup className={marginBottom}>
                <Form.Control
                    aria-describedby="basic-addon2"
                    className={classNames(yellow && "yellow-text", className)}
                    value={numberWithCommas(amount, true)}
                    autoComplete="off"
                    disabled={disabled}
                />
                <InputGroup.Append>
                    <InputGroup.Text className={styles.Symbol}>USD</InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        );
    }
}

export default withRouter(withAdminAccountContext(PriceInput));