import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import {Form} from "react-bootstrap";
import styles from "../../../components/ui/PageTitle/PageTitle.module.css";
import moment from "moment";
import Calendar from "react-calendar";
class UsersTicketsSearch extends Component {
    constructor(props) {
        super(props);
        let years = [];
        for (let i = 2020 ; i <= new Date().getFullYear(); i++){
            years.push(i);
        }
        this.state = {
            round_number: "",
            date: null,
            start_date: "",
            end_date: "",
            datepicker: false,
            account_code: "",
            years,
            search: props.searchData
        }
    }
    onChangeKeyword = (e) => {
        this.setState({
            search: {
                ...this.state.search,
                keyword: e.target.value
            }
        }, () => {
            this.props.doUpdateSearchData(this.state.search)
        });
    }
    onChangeAccountCode = (e) => {
        this.setState({
            search: {
                ...this.state.search,
                account_code: e.target.value
            }
        }, () => {
            this.props.doUpdateSearchData(this.state.search)
        });
    }
    handleSearch = () => {
        let search = this.state.search;
        this.props.refTable.search(search)
    }
    onChangeDate = (date) => {
        this.setState({
            date,
            search: {
                ...this.state.search,
                start_date: (date)?moment(new Date(date[0])).format('YYYY-MM-DD'):null,
                end_date: (date)?moment(new Date(date[1])).format('YYYY-MM-DD'):null
            },
            datepicker: false
        }, () => {
            this.props.doUpdateSearchData(this.state.search)
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className={styles.SearchBox}>
                    <div className={"search-item"} style={document.getElementById('table-cell-05')?{ width: document.getElementById('table-cell-05').offsetWidth  }:null}>
                        <a href={"javascript:void(0)"} onClick={(e) => this.setState({ datepicker: !this.state.datepicker })}>
                            <React.Fragment>
                                {this.state.search.start_date?<span style={{ fontSize: '11px' }}>{moment(new Date(this.state.search.start_date)).format('MMM D, YY')}</span>:"DATE"}
                                {this.state.search.end_date && this.state.search.end_date !== this.state.search.start_date?<span style={{ fontSize: '11px' }}>-{moment(new Date(this.state.search.end_date)).format('MMM D, YY')}</span>:""}
                            </React.Fragment>
                        </a>
                        {this.state.datepicker && <div className={"float-calendar"}><Calendar value={this.state.date} onChange={this.onChangeDate} selectRange={true}/></div>}
                    </div>



                    <Form.Control
                        as={"select"}
                        className={"search-item"}
                        name={"status"}
                        value={this.state.search.status}
                        style={document.getElementById('table-cell-04')?{ width: document.getElementById('table-cell-04').offsetWidth }:null}
                        onChange={(e) => {
                            this.setState({
                                search: {
                                    ...this.state.search,
                                    status: e.target.value
                                }
                            }, () => {
                                this.props.doUpdateSearchData(this.state.search)
                            })
                        }}

                    >
                        <option value={""}>ALL TICKETS</option>
                        <option value={"0"}>WAIT FOR ANSWERS</option>
                        <option value={"1"}>ANSWERED</option>
                        <option value={"2"}>OPENED</option>
                        <option value={"3"}>RESOLVED</option>
                    </Form.Control>
                    <Form.Control
                        aria-describedby="basic-addon2"
                        className={"search-item"}
                        name={"account_code"}
                        onChange={this.onChangeAccountCode}
                        style={document.getElementById('table-cell-03')?{ width: document.getElementById('table-cell-03').offsetWidth }:null}
                        placeholder={"ACCOUNT ID#"}
                        value={this.state.search.account_code}
                    />
                    <Form.Control
                        aria-describedby="basic-addon2"
                        className={"search-item"}
                        name={"keyword"}
                        onChange={this.onChangeKeyword}
                        style={document.getElementById('table-cell-02')?{ width: document.getElementById('table-cell-02').offsetWidth - 10 }:null}
                        placeholder={"TICKET ID#"}
                        value={this.state.search.keyword}
                    />

                    <button className={"btn btn-account search-item"} type={"submit"} onClick={this.handleSearch}
                            style={document.getElementById('table-cell-01')?{ width: document.getElementById('table-cell-01').offsetWidth }:null}
                    >Search</button>

                </div>

            </React.Fragment>
        )
    }
}

export default withRouter(withAdminAccountContext(UsersTicketsSearch));