import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import PanelContainer from "../PanelContainer";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import {numberWithCommas} from "../../../helpers/helpers";
import styles from "../../../components/common/LoadingContainer/LoadingContainer.module.css";
import moment from "moment";
import PageTitleContainer from "../Search/PageTitleContainer";
import PriceInput from "../../../components/ui/PriceInput/PriceInput";
class Overview extends Component {
    refForm = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            overview: null,
            infoWrapperWidth: null
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.refForm.current && !this.state.infoWrapperWidth){
            this.setState({
                infoWrapperWidth: this.refForm.current.clientWidth
            })
        }

    }

    render() {
        const {accountContext} = this.props;
        return (
            <PanelContainer>
                {this.state.infoWrapperWidth && <PageTitleContainer center={true} fullTitleWidth={this.state.infoWrapperWidth}/>}
                <div className={"form-center"}>
                    <Form className={styles.FullWidth} id={"form-info"} ref={this.refForm}>
                        {accountContext.profileInfo &&
                            <React.Fragment>
                                <Form.Group as={Row} >
                                    <Col md={5} className={"start-center-value"}>
                                        <Form.Label>Registration Date</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <Form.Control
                                            aria-describedby="basic-addon2"
                                            value={moment(new Date(accountContext.profileInfo['created_at'])).format('ddd, MMMM DD, YYYY')}
                                            autoComplete="off"
                                            className="mb-3"
                                        />

                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} >
                                    <Col md={5} className={"start-center-value"}>
                                        <Form.Label>Total Deposit</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <PriceInput amount={accountContext.profileInfo.overview['cash_in']} yellow={true} marginBottom={"mb-3"}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} >
                                    <Col md={5} className={"start-center-value"}>
                                        <Form.Label>Total Winning</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <PriceInput amount={accountContext.profileInfo.overview['winning']} marginBottom={"mb-3"} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} >
                                    <Col md={5} className={"start-center-value"}>
                                        <Form.Label>Total Losing</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <PriceInput amount={accountContext.profileInfo.overview['losing']} marginBottom={"mb-3"} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} >
                                    <Col md={5} className={"start-center-value"}>
                                        <Form.Label>Total Withdrawal</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <PriceInput amount={accountContext.profileInfo.overview['cash_out']} yellow={true} marginBottom={"mb-3"} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} >
                                    <Col md={5} className={"start-center-value"}>
                                        <Form.Label>Total not used balance</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <PriceInput amount={accountContext.profileInfo.overview['not_used_balance']} marginBottom={"mb-3"} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} >
                                    <Col md={5} className={"start-center-value"}>
                                        <Form.Label>House balance</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <PriceInput
                                            amount={accountContext.profileInfo.overview['house_balance']}
                                            className={(accountContext.profileInfo.overview['house_balance'] === 0)?"yellow-text":((accountContext.profileInfo.overview['house_balance'] > 0)?"text-success":"text-danger")}
                                            marginBottom={"mb-3"} />
                                    </Col>
                                </Form.Group>
                            </React.Fragment>
                        }
                    </Form>
                </div>
            </PanelContainer>
        )
    }
}

export default withRouter(withAdminAccountContext(Overview));