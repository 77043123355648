import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import {numberWithCommas} from "../../../helpers/helpers";
import moment from "moment";
import tableStyles from "../../../components/ui/AccountTable/AccountTable.module.css";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class SelectedRoundWinningNumberTotal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const {round, handleChangeRound} = this.props;
        return (
            <div className={"form-center"}>
                <div className={tableStyles.FullWidth}>
                    <table className={tableStyles.FullTable}>
                        <tr>
                            <th className={classNames(tableStyles.TableHeader)} style={document.getElementById('table-cell-01')?{ width: document.getElementById('table-cell-01').offsetWidth }:null}>Round Number</th>
                            <th className={classNames(tableStyles.TableHeader)} style={document.getElementById('table-cell-02')?{ width: document.getElementById('table-cell-02').offsetWidth }:null}>Date</th>
                            <th className={classNames(tableStyles.TableHeader)} style={document.getElementById('table-cell-03')?{ width: document.getElementById('table-cell-03').offsetWidth }:null}>Time</th>
                            <th className={classNames(tableStyles.TableHeader)} style={document.getElementById('table-cell-04')?{ width: document.getElementById('table-cell-04').offsetWidth }:null}>Winning#</th>
                            <th className={classNames(tableStyles.TableHeader)} style={document.getElementById('table-cell-05')?{ width: document.getElementById('table-cell-05').offsetWidth }:null}>Total Players</th>
                        </tr>
                        <tr className={tableStyles.RelativeRow}>
                            <td className={classNames(tableStyles.TableCell, "yellow-text")}>
                                <button disabled={!round['previous_round_id']} className={tableStyles.BackArrow} onClick={(e) => handleChangeRound(round['previous_round_id'])}><FontAwesomeIcon icon={"chevron-left"}/></button>
                                {round['round_number']}
                            </td>
                            <td className={classNames(tableStyles.TableCell)}>{moment(new Date(round['round_date'])).format('ddd, MMMM DD, YYYY')}</td>
                            <td className={classNames(tableStyles.TableCell)}>{round['round_time']}</td>
                            <td className={classNames(tableStyles.TableCell)}>{round['number']}</td>
                            <td className={classNames(tableStyles.TableCell)}>
                                {numberWithCommas(round['total_players'])}
                                <button disabled={!round['next_round_id']} className={tableStyles.NextArrow} onClick={(e) => handleChangeRound(round['next_round_id'])}><FontAwesomeIcon icon={"chevron-right"}/></button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        )
    }
}

export default withRouter(withAdminAccountContext(SelectedRoundWinningNumberTotal));