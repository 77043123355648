import React, {Component} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import styles from "./Search.module.css";
class RouletteSearch extends Component {

    render() {
        const {errors, touched, handleChange, values} = this.props;
        return (
            process.env.REACT_APP_NAME !== "swissco.gaming"?<React.Fragment>
                <Form.Group className={"mb-3"}>
                    <Col className={"start-center-value"}>
                        <Form.Label className={styles.FormLabel}>TRC20 CODE :</Form.Label>
                        <Form.Control type="text"
                                      name={"wallet_code"}
                                      onChange={handleChange}
                                      value={values.wallet_code}
                                      isValid={touched.wallet_code && !errors.wallet_code}
                                      isInvalid={touched.wallet_code && errors.wallet_code}
                                      autoComplete="off"
                        />
                    </Col>
                </Form.Group>
                <Form.Group className={"mb-3"}>
                    <Col className={"start-center-value"}>
                        <Form.Label className={styles.FormLabel}>HASH CODE :</Form.Label>
                        <Form.Control type="text"
                                      name={"hash_code"}
                                      onChange={handleChange}
                                      value={values.hash_code}
                                      isValid={touched.hash_code && !errors.hash_code}
                                      isInvalid={touched.hash_code && errors.hash_code}
                                      autoComplete="off"
                        />
                    </Col>
                </Form.Group>
                <Form.Group className={"mb-3"}>
                    <Col className={"start-center-value"}>
                        <Form.Label className={styles.FormLabel}>RECEIPT NUMBER :</Form.Label>
                        <Form.Control type="text"
                                      name={"receipt_number"}
                                      onChange={handleChange}
                                      value={values.receipt_number}
                                      isValid={touched.receipt_number && !errors.receipt_number}
                                      isInvalid={touched.receipt_number && errors.receipt_number}
                                      autoComplete="off"
                        />
                    </Col>
                </Form.Group>
            </React.Fragment>:null

        )
    }
}

export default RouletteSearch;