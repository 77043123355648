import React, {Component} from 'react';
import styles from "./MenuLabel.module.css";
class MenuLabel extends Component {
    render() {
        return (
            <div className={this.props.label.length >= 30 && styles.SmallText}>
                {this.props.label} {this.props.count && <span className={styles.CountHolder}>({this.props.count})</span>}
            </div>
        );
    }
}

export default MenuLabel;