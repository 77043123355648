import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {
    SliderContainer,
    GetStarted,
    GetStartedWrapper
} from "./style.jsx";
import {Container, Row, Col} from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import {ROUTE_TABLE_LIVE_PLAY} from "../../../routers/routes";

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SliderContainer>
                <GetStarted>
                    <GetStartedWrapper>
                        <h3>{applicationContext.translator("Let's Play Live Roulette")}</h3>
                        <NavLink to={ROUTE_TABLE_LIVE_PLAY.concat("?lang="+applicationContext.state.lang)}>{applicationContext.translator("Play now")}</NavLink>
                    </GetStartedWrapper>
                </GetStarted>
            </SliderContainer>

        );
    }
}

export default withApplicationContext(Slider);