import React, {Component} from 'react';
import styles from "./UpdateEmail.module.css";
import baseStyles from "../../../assets/css/App.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withGameTableContext} from "../../../contexts/GameTableContext";
import * as Yup from "yup";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {NavLink, withRouter} from "react-router-dom"
import {ROUTE_ADMIN_DASHBOARD, ROUTE_FORGET_PASSWORD, ROUTE_USER_PROFILE} from "../../../routers/routes";
import classNames from "classnames";
import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import {parseErrorMessage} from "../../../helpers/helpers";
import {withAlert} from "react-alert";
import TokenService from "../../../services/token.service";
class UpdateEmail extends Component {
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            neighbours: false,
            close: false,
            loading: false,
            fullLoading: false,
            successfully: false,
            email: "",
            url: "",
            redirect: (urlSearchParams.has('r'))?decodeURIComponent(urlSearchParams.get('r')):((props.level == "user")?ROUTE_USER_PROFILE:ROUTE_ADMIN_DASHBOARD)
        }

    }
    componentDidMount = async () =>{
        const {history, level, location, applicationContext, alert} = this.props;
        const urlSearchParams = new URLSearchParams(location.search);
        if(urlSearchParams.has('u')){
            let url = atob(urlSearchParams.get('u'));
            this.setState({
                fullLoading: true,
                url
            });
            this.setState({fullLoading: false});

        }else{
            if(AuthService.getCurrentUser()){
                if(level == "user")
                    history.push(ROUTE_USER_PROFILE);
                else{
                    let token = TokenService.getToken();
                    if(token.level == "admin")
                        history.push(ROUTE_ADMIN_DASHBOARD)
                }

            }
        }

    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, alert, history, level} = this.props;
        if(this.state.loading) return;
        this.setState({loading: true});
        try {
            const token  = await UserService.doUpdateEmail(this.state.url, {
                email: values.email
            });
            this.setState({
                successfully: true,
                email: ""
            })

            // alert.success(token.message)
            // TokenService.removeUser();
            // history.push(this.state.redirect)


        } catch (err) {
            alert.error(applicationContext.translator(parseErrorMessage(err)));
        }
        this.setState({loading: false})
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <div className={styles.LoginContainer}>
                {this.state.fullLoading?
                    <div className={styles.LoadingContainer}>
                        {process.env.REACT_APP_NAME === "swissco.gaming" && <img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={150}/>}
                        {process.env.REACT_APP_NAME === "my.roulette" && <img className={styles.LogoLightRoulette} src={`${process.env.PUBLIC_URL}/assets/img/logo-my.roulette.png`} width={200}/>}
                    </div>:
                    <div className={styles.LoginWrapper}>

                    <div className={styles.LoginBody}>
                        <h4>{applicationContext.translator("Enter New Email To Change")}</h4>
                        <Formik
                            innerRef={(ref) => this.refForm = ref}
                            initialValues={{
                                email: this.state.email
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().required(applicationContext.translator("You must enter your ${path}")).email(applicationContext.translator("E-Mail Address format is invalid")).label(applicationContext.translator("E-Mail Address")),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction")}}>
                                    <Form.Group as={Row} className={!touched.password && "mb-3"}>
                                        <Col md={3} className={styles.StartCenterValue}>
                                            <Form.Label>{applicationContext.translator("E-Mail Address")}</Form.Label>
                                        </Col>
                                        <Col className={styles.StartCenterValue}>
                                            <Form.Control type="text"
                                                          name={"email"}
                                                          onChange={handleChange}
                                                          value={values.email}
                                                          isValid={touched.email && !errors.email}
                                                          isInvalid={touched.email && errors.email}
                                                          onFocus={(e) => {
                                                              e.target.select()
                                                          }}
                                                          autoComplete="off"
                                                          ref={(ref) => this.refInput = ref}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.email && <Form.Group as={Row} className={"mb-3"}>
                                        <Col md={3}></Col>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.email}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>
                                    }

                                    <Form.Group className={styles.EndCenterValue}>
                                        <button disabled={this.state.loading} className={"btn btn-account"} type={"submit"}>{this.state.loading?<Spinner animation="border" variant="light" size={"sm"} />:applicationContext.translator("Change")}</button>
                                    </Form.Group>

                                    {this.state.successfully && <Form.Group as={Row} className={"text-center mt-3"}>
                                        <Col className={"text-center mb-3"}>
                                            <Form.Text className="text-success mt-2">
                                                {applicationContext.translator("PLEASE CHECK YOUR NEW EMAIL INBOX AND VERIFY YOUR E-MAIL ADDRESS")}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>}
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>}

            </div>
        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withGameTableContext(UpdateEmail))));