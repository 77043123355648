import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import classNames from "classnames";
class Chips extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.refChip1 = React.createRef();
        this.refChip5 = React.createRef();
        this.refChip10 = React.createRef();
        this.refChip20 = React.createRef();
        this.refChip50 = React.createRef();
        this.refChip100 = React.createRef();
    }
    onChipSelected = (amount) => {
        const {gameTableContext} = this.props;
        gameTableContext.onUpdateState({
            chipSelected: amount,
            chipSelectedWindow: amount
        });
        if(gameTableContext.state.isMobileVersion){
            gameTableContext.handleCloseAllWindows();
        }
    }
    getChip = (amount) => {
        switch (amount){
            case 1:
                return this.refChip1;
            case 5:
                return this.refChip5;
            case 10:
                return this.refChip10;
            case 20:
                return this.refChip20;
            case 50:
                return this.refChip50;
            default: return this.refChip100;
        }
    }
    render() {
        const {gameTableContext} = this.props;
        return (
            <div className={classNames(styles.Chips, gameTableContext.state.windows.chips && styles.OpenMobileChips)}>
                <div className={styles.ChipsWrapper}>
                    <button className={classNames(styles.ChipButton, gameTableContext.state.chipSelected === 1 && styles.ChipActive)}
                            onClick={(e) => this.onChipSelected(1)}

                    >
                        <img src={`${process.env.PUBLIC_URL}/assets/img/chips-1.png`} ref={(ref) => this.refChip1 = ref} alt={"CHIPS_1$"}/>
                    </button>
                    <button className={classNames(styles.ChipButton, gameTableContext.state.chipSelected === 5 && styles.ChipActive)}
                            onClick={(e) => this.onChipSelected(5)}

                    >
                        <img src={`${process.env.PUBLIC_URL}/assets/img/chips-5.png`} ref={(ref) => this.refChip5 = ref} alt={"CHIPS_5$"}/>
                    </button>
                    <button className={classNames(styles.ChipButton, gameTableContext.state.chipSelected === 10 && styles.ChipActive)}
                            onClick={(e) => this.onChipSelected(10)}

                    >
                        <img src={`${process.env.PUBLIC_URL}/assets/img/chips-10.png`} ref={(ref) => this.refChip10 = ref} alt={"CHIPS_10$"}/>
                    </button>
                    <button className={classNames(styles.ChipButton, gameTableContext.state.chipSelected === 20 && styles.ChipActive)}
                            onClick={(e) => this.onChipSelected(20)}
                    >
                        <img src={`${process.env.PUBLIC_URL}/assets/img/chips-20.png`} ref={(ref) => this.refChip20 = ref} alt={"CHIPS_20$"}/>
                    </button>
                    <button className={classNames(styles.ChipButton, gameTableContext.state.chipSelected === 50 && styles.ChipActive)}
                            onClick={(e) => this.onChipSelected(50)}

                    >
                        <img src={`${process.env.PUBLIC_URL}/assets/img/chips-50.png`} ref={(ref) => this.refChip50 = ref} alt={"CHIPS_50$"}/>
                    </button>
                    <button className={classNames(styles.ChipButton, gameTableContext.state.chipSelected === 100 && styles.ChipActive)}
                            onClick={(e) => this.onChipSelected(100)}

                    >
                        <img src={`${process.env.PUBLIC_URL}/assets/img/chips-100.png`} ref={(ref) => this.refChip100 = ref} alt={"CHIPS_100$"}/>
                    </button>
                </div>


            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext(Chips));