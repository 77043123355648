import React, {Component} from 'react';
import styles from "./AccountContainer.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {NavLink, withRouter} from "react-router-dom"
import {withAccountContext} from "../../../contexts/AccountContext";
import {
    ROUTE_PROFILE_DEPOSIT_HISTORY,
    ROUTE_PROFILE_WITHDRAWAL_HISTORY,
    ROUTE_PROFILE_DEPOSIT,
    ROUTE_PROFILE_PLAYED_HISTORY,
    ROUTE_PROFILE_WITHDRAWAL,
    ROUTE_USER_PROFILE,
    ROUTE_LOGON_HISTORY,
    ROUTE_CREATE_NEW_TICKET,
    ROUTE_MY_TICKETS,
    ROUTE_ACCOUNT_DETAILS_CLOSURE,
    ROUTE_ACCOUNT_DETAILS_SELF_EXCLUSION, ROUTE_ACCOUNT_DETAILS_LIMITS, ROUTE_ACCOUNT_DETAILS_DELETE,
} from "../../../routers/routes";
import {accountCodeParse, numberWithCommas} from "../../../helpers/helpers";
import LoadingContainer from "../../../components/common/LoadingContainer";
import UserService from "../../../services/user.service";
import classNames from "classnames";
import {withProfileContext} from "../../../contexts/ProfileContext";
import Popup from "../../../components/ui/Popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class AccountContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingProfile: true,
            memberReportLoading: true,
            memberReport: null,
            menu: [
                {label: props.applicationContext.translator("Deposit"), path: ROUTE_PROFILE_DEPOSIT},
                {label: props.applicationContext.translator("Withdrawal"), path: ROUTE_PROFILE_WITHDRAWAL},
                {label: props.applicationContext.translator("Deposit History"), path: ROUTE_PROFILE_DEPOSIT_HISTORY},
                {label: props.applicationContext.translator("Withdrawal History"), path: ROUTE_PROFILE_WITHDRAWAL_HISTORY},
                {label: props.applicationContext.translator("Played History"), path: ROUTE_PROFILE_PLAYED_HISTORY},
                {label: props.applicationContext.translator("Logon History"), path: ROUTE_LOGON_HISTORY},
                {label: props.applicationContext.translator("Create new ticket"), path: ROUTE_CREATE_NEW_TICKET},
                {label: props.applicationContext.translator("My tickets"), path: ROUTE_MY_TICKETS},
                {label: props.applicationContext.translator("Ticket Details"), hidden:true, path: ROUTE_MY_TICKETS},
                {label: props.applicationContext.translator("My profile"), path: ROUTE_USER_PROFILE},
                {label: props.applicationContext.translator("Account Settings"), path: "#", bottom: "mb-5", menu: [
                        {label: props.applicationContext.translator("Set Limit"), path: ROUTE_ACCOUNT_DETAILS_LIMITS},
                        {label: props.applicationContext.translator("Take a break"), path: ROUTE_ACCOUNT_DETAILS_CLOSURE},
                        {label: props.applicationContext.translator("Self Exclusion"), path: ROUTE_ACCOUNT_DETAILS_DELETE},
                    ]},
                // {label: "Logout", path: ROUTE_LOGOUT, logout: true},
            ],
            userInfo: null,
            ranking: null,
            blacklisted: false,
            openSidebar: false,
            deleteConfirm: false,
            submitting: false,
            menuOpened: null
        }

    }
    componentDidMount = async () => {
        const {applicationContext, history} = this.props;
        this.onClickCloseSidebar();
        this.state.menu.map((menu) => {
            if(menu.hasOwnProperty('menu') && menu.menu.length > 0){
                let p = menu.menu.filter((m) => history.location.pathname.includes(m.path))
                if(p.length > 0){
                    this.setState({
                        menuOpened: menu.label
                    })
                }
            }
        })
        try{
            const userInfo = await UserService.getUserBoard();
            applicationContext.updateUserInfo(userInfo);

            this.setState({
                loadingProfile: false
            });
        }catch (e){

        }

    }

    renderPageTitle = () => {
        const { history, accountContext} = this.props;
        let page = this.state.menu.filter((menu) => history.location.pathname.includes(menu.path))
        if(page.length > 0){
            return page[0].label;
        }else if(accountContext.pageTitle) {
            return accountContext.pageTitle;
        }else{
            return this.state.menu.map((menu) => {
                if(menu.hasOwnProperty('menu') && menu.menu.length > 0){
                    let p = menu.menu.filter((m) => history.location.pathname.includes(m.path))
                    if(p.length > 0){
                        return p[0].label;
                    }
                }
            })

        }
    }
    onClickCloseSidebar = (e) => {
        this.setState({
            openSidebar: false
        }, () => document.getElementById('root').style['overflow-y'] = "scroll")
    }
    onClickOpenSidebar = (e) => {
        this.setState({
            openSidebar: true
        }, () => document.getElementById('root').style['overflow-y'] = "hidden")
    }
    onClickDeleteAccount = (e) => {
        this.setState({
            deleteConfirm: true
        }, () => document.getElementById('root').style['overflow-y'] = "hidden")
    }

    render() {
        const {accountContext, children, history, applicationContext} = this.props;
        return (
            (accountContext.loading || this.state.loadingProfile)?(
                <div className={styles.LoadingContainer}><img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={150}/></div>
            ):(
            <div className={classNames(styles.Container)} style={{ direction: applicationContext.translator("direction") }}>
                {this.state.openSidebar && <div className={styles.OverlayLock} onClick={this.onClickCloseSidebar}></div>}
                <div className={classNames(styles.Sidebar, this.state.openSidebar && styles.OpenedSidebarMenu)}>
                    <div className={styles.SidebarWrapper}>
                        <br/>
                        <div className={"text-center"}>
                            <a href={process.env.REACT_APP_LIVE_URL}><img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} className={styles.SidebarLogo} /></a>
                        </div>
                        <React.Fragment>
                            <br/>
                            <ul className={styles.SidebarMenu}>
                                <li>
                                    <h5>{applicationContext.translator("Account Balance")}</h5>
                                    <span>$ {numberWithCommas(accountContext.userInfo['balance'])}</span>
                                </li>
                                <li className={styles.AccountInfo}>
                                    <h5 className={applicationContext.translator("direction") === "rtl" && "ml-1"}>{applicationContext.translator("Account ID")}: </h5><span>{accountCodeParse(accountContext.userInfo['account_code'], applicationContext.translator("direction") === "rtl")}</span>
                                </li>

                                {this.state.menu.map((menu, index) => {
                                    return(
                                        !menu.hidden && <li key={index} className={classNames(menu.hasOwnProperty('bottom') && menu.bottom, this.state.menuOpened === menu.label && styles.ActiveSubMenu)}>
                                            {menu.hasOwnProperty('menu') && menu.menu.length > 0?(
                                                <React.Fragment>
                                                    <NavLink to={"#"} onClick={() => this.setState({ menuOpened: this.state.menuOpened === menu.label?null:menu.label })} className={classNames(menu.hasOwnProperty('class') && menu.class, this.state.menuOpened === menu.label && styles.ActiveSubMenuTitle)}>
                                                        {menu.label}
                                                        <div className={styles.HasSubMenu}>
                                                            <FontAwesomeIcon icon={this.state.menuOpened === menu.label?"chevron-up":"chevron-down"} />
                                                        </div>
                                                    </NavLink>
                                                    {this.state.menuOpened === menu.label && <ul className={styles.SubSidebarMenu}>
                                                    {menu.menu.map((subMenu, subMenuIndex) => {
                                                        return (
                                                            <li key={subMenuIndex} className={subMenu.hasOwnProperty('bottom') && subMenu.bottom}>
                                                                <NavLink to={subMenu.path} onClick={subMenu.hasOwnProperty('onClick')?subMenu.onClick:()=>{}} className={classNames(history.location.pathname.includes(subMenu.path) && styles.LinkActive, subMenu.hasOwnProperty('class') && subMenu.class)}>{subMenu.label}</NavLink>
                                                            </li>
                                                        )
                                                    })}
                                                    </ul>}
                                                </React.Fragment>
                                            ):(
                                                <NavLink to={!menu.logout?menu.path:"/"} onClick={menu.hasOwnProperty('onClick')?menu.onClick:(menu.logout && applicationContext.onLogout)} className={classNames(history.location.pathname.includes(menu.path) && styles.LinkActive, menu.hasOwnProperty('class') && menu.class)}>{menu.label}</NavLink>
                                            )}

                                        </li>
                                    )
                                })}


                            </ul>
                        </React.Fragment>

                    </div>
                </div>
                <div className={styles.MainContainer}>
                    <div className={styles.MapDashboard}>
                        {this.renderPageTitle()}
                    </div>
                    <div className={classNames(styles.MainWrapper, applicationContext.translator("direction") === "rtl"?"body-rtl":"")}>
                        <LoadingContainer loading={accountContext.loading}>
                            {children}
                        </LoadingContainer>
                    </div>

                </div>

            </div>)

        )
    }
}

export default withRouter(withAccountContext(withApplicationContext(withProfileContext(AccountContainer))));