import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {NavLink, withRouter} from "react-router-dom"
import PanelContainer from "../PanelContainer";
import {Formik} from "formik";
import * as Yup from "yup";
import {Col, Form, Row, Spinner, Dropdown} from "react-bootstrap";
import {withAlert} from "react-alert";
import styled from "./Profile.module.css";
import CommonService from "../../../services/common.service";
import {accountCodeParse, age} from "../../../helpers/helpers";
import PageTitleContainer from "../Search/PageTitleContainer";
import moment from "moment";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            password_confirmation: "",
            successfully: false,
            changeEmailLoading: false,
            changePasswordLoading: false,
            loading: false,
            countries: [],
            cities: [],
            country_code: null
        }
    }
    componentDidMount = async () => {
        const {accountContext, profileContext} = this.props;
        await this.updateData();
    }
    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        const {accountContext} = this.props;
        if(!prevProps.accountContext.profileInfo && accountContext.profileInfo){
            await this.updateData();
        }
    }

    updateData = async () => {
        const {accountContext} = this.props;
        if(accountContext.profileInfo){
            const countries = await CommonService.getCountries();
            const cities = await CommonService.getCities(accountContext.profileInfo['country']);
            let country_code = accountContext.profileInfo['country_code'];
            if(!country_code){
                let country = countries.filter((country) => country.name === accountContext.profileInfo['country']);
                if(country.length > 0)
                    country_code = country[0].phonecode;
            }

            this.setState({
                loading: false,
                countries,
                cities,
                country_code
            })
        }
    }


    renderCountryCode = (code) => {
        let country_code = this.state.countries.filter((r) => r.phonecode.toString() === code.toString())
        if(country_code.length > 0)
            return country_code[0].code.toLowerCase()
    }
    render() {
        const {accountContext} = this.props;
        let validation = null;
        return (
            <PanelContainer>
                <PageTitleContainer center={true} fullTitleWidth={500}/>
                <div className={"form-center"}>
                    {(!accountContext.loading && !this.state.loading && accountContext.profileInfo) && <Formik
                        innerRef={(ref) => this.refForm = ref}
                        enableReinitialize={true}
                        initialValues={{
                            first_name: (accountContext.profileInfo['first_name'])?accountContext.profileInfo['first_name']:"",
                            last_name: (accountContext.profileInfo['last_name'])?accountContext.profileInfo['last_name']:"",
                            birthdate: (accountContext.profileInfo['birth_date'])?moment(new Date(accountContext.profileInfo['birth_date'])).format('MMMM DD, YYYY')+ " ("+age(accountContext.profileInfo['birth_date'])+" years old)":"",
                            email: accountContext.profileInfo['email'],
                            address: (accountContext.profileInfo['address'])?accountContext.profileInfo['address']:"",
                            zip_code: (accountContext.profileInfo['zip_code'])?accountContext.profileInfo['zip_code']:"",
                            country: (accountContext.profileInfo['country'])?accountContext.profileInfo['country']:"",
                            city: (accountContext.profileInfo['city'])?accountContext.profileInfo['city']:"",
                            country_code: (this.state.country_code)?this.state.country_code:"",
                            phone_number: (accountContext.profileInfo['phone_number'])?accountContext.profileInfo['phone_number']:"",
                            application: process.env.REACT_APP_NAME
                        }}
                        validationSchema={Yup.object().shape(validation)}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <Form onSubmit={handleSubmit} style={{ maxWidth: "70%"}}>
                                <Form.Control type="hidden"
                                              name={"application"}
                                              onChange={handleChange}
                                              value={values.application}
                                />
                                <Form.Group as={Row} className={!touched.last_name && "mb-3"}>
                                    <Col md={4} className={"start-center-value"}>
                                        <Form.Label>ACCOUNT ID</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <Form.Control type="text"
                                                      disabled={true}
                                                      name={"account_code"}
                                                      value={accountCodeParse(accountContext.profileInfo['account_code'])}
                                        />
                                    </Col>
                                </Form.Group>
                                {process.env.REACT_APP_NAME === "swissco.gaming" && <React.Fragment>
                                    <Form.Group as={Row} className={"mb-3"}>
                                        <Col md={4} className={"start-center-value"}>
                                            <Form.Label>Date of birth</Form.Label>
                                        </Col>
                                        <Col className={"start-center-value"}>
                                            <Form.Control type="text"
                                                          name={"birthdate"}
                                                          onChange={handleChange}
                                                          disabled={true}
                                                          value={values.birthdate}
                                                          isValid={touched.birthdate && !errors.birthdate}
                                                          isInvalid={touched.birthdate && errors.birthdate}
                                                          autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className={!touched.first_name && "mb-3"}>
                                        <Col md={4} className={"start-center-value"}>
                                            <Form.Label>First Name</Form.Label>
                                        </Col>
                                        <Col className={"start-center-value"}>
                                            <Form.Control type="text"
                                                          name={"first_name"}
                                                          onChange={handleChange}
                                                          disabled={true}
                                                          value={values.first_name}
                                                          isValid={touched.first_name && !errors.first_name}
                                                          isInvalid={touched.first_name && errors.first_name}
                                                          autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.first_name && <Form.Group as={Row} className={"mb-3"}>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.first_name}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>}
                                    <Form.Group as={Row} className={!touched.last_name && "mb-3"}>
                                        <Col md={4} className={"start-center-value"}>
                                            <Form.Label>Last Name</Form.Label>
                                        </Col>
                                        <Col className={"start-center-value"}>
                                            <Form.Control type="text"
                                                          name={"last_name"}
                                                          onChange={handleChange}
                                                          disabled={true}
                                                          value={values.last_name}
                                                          isValid={touched.last_name && !errors.last_name}
                                                          isInvalid={touched.last_name && errors.last_name}
                                                          autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.last_name && <Form.Group as={Row} className={"mb-3"}>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.last_name}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>}
                                    <Form.Group as={Row} className={!touched.address && "mb-3"}>
                                        <Col md={4} className={"start-center-value"}>
                                            <Form.Label>Address</Form.Label>
                                        </Col>
                                        <Col className={"start-center-value"}>
                                            <Form.Control type="text"
                                                          name={"address"}
                                                          onChange={handleChange}
                                                          value={values.address}
                                                          isValid={touched.address && !errors.address}
                                                          isInvalid={touched.address && errors.address}
                                                          autoComplete="off"
                                                          disabled={true}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.address && <Form.Group as={Row} className={"mb-3"}>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.address}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>}
                                    <Form.Group as={Row} className={!touched.zip_code && "mb-3"}>
                                        <Col md={4} className={"start-center-value"}>
                                            <Form.Label>Zip Code</Form.Label>
                                        </Col>
                                        <Col className={"start-center-value"}>
                                            <Form.Control type="text"
                                                          name={"zip_code"}
                                                          onChange={handleChange}
                                                          value={values.zip_code}
                                                          isValid={touched.zip_code && !errors.zip_code}
                                                          isInvalid={touched.zip_code && errors.zip_code}
                                                          autoComplete="off"
                                                          disabled={true}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.zip_code && <Form.Group as={Row} className={"mb-3"}>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.zip_code}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>}
                                    <Form.Group as={Row} className={!touched.city && "mb-3"}>
                                        <Col md={4} className={"start-center-value"}>
                                            <Form.Label>City</Form.Label>
                                        </Col>
                                        <Col className={"start-center-value"}>
                                            <Form.Control type="text"
                                                          name={"city"}
                                                          onChange={handleChange}
                                                          value={values.city}
                                                          isValid={touched.city && !errors.city}
                                                          isInvalid={touched.city && errors.city}
                                                          autoComplete="off"
                                                          disabled={true}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.city && <Form.Group as={Row} className={"mb-3"}>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.city}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>}
                                    <Form.Group as={Row} className={!touched.country && "mb-3"}>
                                        <Col md={4} className={"start-center-value"}>
                                            <Form.Label>Country</Form.Label>
                                        </Col>
                                        <Col className={"start-center-value"}>
                                            <Form.Control
                                                as={"select"}
                                                name={"country"}
                                                onChange={async (e) => {
                                                    handleChange(e);
                                                    const cities = await CommonService.getCities(e.target.value);
                                                    let country = this.state.countries.filter((country) => country.name === e.target.value);
                                                    if(country.length > 0)
                                                        setFieldValue('country_code', country[0].phonecode);

                                                    this.setState({
                                                        cities
                                                    })
                                                }}
                                                value={values.country}
                                                isValid={touched.country && !errors.country}
                                                isInvalid={touched.country && errors.country}
                                                disabled={true}
                                            >
                                                <option>Please select a country</option>
                                                {this.state.countries.map((country, index) =>
                                                    <option key={index} value={country.name} >{country.name}</option>
                                                )}
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    {touched.country && <Form.Group as={Row} className={"mb-3"}>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.country}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>}

                                    <Form.Group as={Row} className={!touched.phone_number && "mb-3"}>
                                        <Col md={4} className={"start-center-value"}>
                                            <Form.Label>Mobile Number</Form.Label>
                                        </Col>
                                        <Col className={"start-center-value"}>

                                            <Form.Control type="text"
                                                          name={"phone_number"}
                                                          onChange={handleChange}
                                                          value={values.country_code+values.phone_number}
                                                          isValid={touched.phone_number && !errors.phone_number}
                                                          isInvalid={touched.phone_number && errors.phone_number}
                                                          autoComplete="off"
                                                          disabled={true}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.phone_number && <Form.Group as={Row} className={"mb-3"}>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.phone_number}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>}
                                </React.Fragment>}

                                <Form.Group as={Row} className={!touched.email && "mb-3"}>
                                    <Col md={4} className={"start-center-value"}>
                                        <Form.Label>E-Mail</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <Form.Control type="text"
                                                      name={"email"}
                                                      onChange={handleChange}
                                                      value={values.email}
                                                      isValid={touched.email && !errors.email}
                                                      isInvalid={touched.email && errors.email}
                                                      autoComplete="off"
                                                      disabled={true}
                                        />
                                    </Col>
                                </Form.Group>
                                {touched.email && <Form.Group as={Row} className={"mb-3"}>
                                    <Col>
                                        <Form.Text className="text-danger mt-2">
                                            {errors.email}
                                        </Form.Text>
                                    </Col>
                                </Form.Group>
                                }
                            </Form>
                        )}
                    </Formik>}
                </div>
            </PanelContainer>

        )
    }
}

export default withAlert()(withAdminAccountContext(withRouter(Profile)));