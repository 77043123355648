import React, {Component} from 'react';
import styles from "./NumberCounter.module.css";
import classNames from "classnames";
import {withGameTableContext} from "../../../contexts/GameTableContext";
class NumberCounter extends Component {
    render() {
        const {title, number, count, dir} = this.props;
        return (
            <div className={classNames(styles.Counter, "counter-cell")}>
                <h3 className={classNames(styles.CounterLabel, dir === "rtl" && "no-letter-space font-bold rtl-font")}>{title}</h3>
                <div className={classNames(styles.CounterNumber)}>
                    <div className={styles.OffNumber}>
                        {count > number.toString().length && Array((count - number.toString().length)).fill().map((row) => {
                            return ("0");
                        })}

                    </div>
                    <div className={styles.Number}>
                        {number}
                    </div>
                </div>
            </div>
        );
    }
}

export default withGameTableContext(NumberCounter);