import React, {Component} from 'react';
import styles from "./Loading.module.css";

class Loading extends Component {

    render() {
        return (
            <div className={styles.AppLogoContainer}>
                <img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} className={styles.AppLogo} alt="logo" />
            </div>
        );
    }
}

export default Loading;