import React, {Component} from 'react';
import styles from "./UpdatePassword.module.css";
import baseStyles from "../../../assets/css/App.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withGameTableContext} from "../../../contexts/GameTableContext";
import * as Yup from "yup";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {NavLink, withRouter} from "react-router-dom"
import {ROUTE_ADMIN_DASHBOARD, ROUTE_FORGET_PASSWORD, ROUTE_USER_PROFILE} from "../../../routers/routes";
import classNames from "classnames";
import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import {parseErrorMessage} from "../../../helpers/helpers";
import {withAlert} from "react-alert";
import TokenService from "../../../services/token.service";
class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            neighbours: false,
            close: false,
            loading: false,
            fullLoading: false,
            url: "",
            redirect: (urlSearchParams.has('r'))?decodeURIComponent(urlSearchParams.get('r')):((props.level == "user")?ROUTE_USER_PROFILE:ROUTE_ADMIN_DASHBOARD)
        }

    }
    componentDidMount = async () =>{
        const {history, level, location, applicationContext, alert} = this.props;
        const urlSearchParams = new URLSearchParams(location.search);
        if(urlSearchParams.has('u')){
            let url = atob(urlSearchParams.get('u'));
            this.setState({
                fullLoading: true,
                url
            });

            this.setState({fullLoading: false});

        }else{
            if(AuthService.getCurrentUser()){
                if(level == "user")
                    history.push(ROUTE_USER_PROFILE);
                else{
                    let token = TokenService.getToken();
                    if(token.level == "admin")
                        history.push(ROUTE_ADMIN_DASHBOARD)
                }

            }
        }

    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, alert, history, level} = this.props;
        if(this.state.loading) return;
        this.setState({loading: true});
        try {

            const token  = await UserService.doUpdatePassword(this.state.url, {
                password: values.password,
                password_confirmation: values.password_confirmation
            });
            TokenService.updateLocalAccessToken(token);

            const userInfo = await UserService.getUserBoard();
            applicationContext.updateUserInfo(userInfo);
            history.push(this.state.redirect)


        } catch (err) {
            alert.error(parseErrorMessage(err));
        }
        this.setState({loading: false})
    }
    render() {

        return (
            <div className={styles.LoginContainer}>
                {this.state.fullLoading?
                    <div className={styles.LoadingContainer}><img src={`/assets/img/my-roulette-light-logo.png`} width={150}/></div>:
                    <div className={styles.LoginWrapper}>

                    <div className={styles.LoginBody}>
                        <h4>Enter New Password</h4>
                        <Formik
                            innerRef={(ref) => this.refForm = ref}
                            initialValues={{
                                password: this.state.password,
                                password_confirmation: this.state.password_confirmation,
                            }}
                            validationSchema={Yup.object().shape({
                                password: Yup.string().required("You must enter your password").matches(
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                                ),
                                password_confirmation: Yup.string().test('match', 'Passwords do not match', function (password) {
                                    return password === this.parent.password
                                }).required("You must enter your password confirmation")
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className={!touched.password && "mb-3"}>
                                        <Col md={3} className={styles.StartCenterValue}>
                                            <Form.Label>Password</Form.Label>
                                        </Col>
                                        <Col className={styles.StartCenterValue}>
                                            <Form.Control type="password"
                                                          name={"password"}
                                                          onChange={handleChange}
                                                          value={values.password}
                                                          isValid={touched.password && !errors.password}
                                                          isInvalid={touched.password && errors.password}
                                                          onFocus={(e) => {
                                                              e.target.select()
                                                          }}
                                                          autoComplete="off"
                                                          ref={(ref) => this.refInput = ref}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.password && <Form.Group as={Row} className={"mb-3"}>
                                        <Col md={3}></Col>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.password}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>
                                    }
                                    <Form.Group as={Row} className={!touched.password_confirmation && "mb-3"}>
                                        <Col md={3} className={styles.StartCenterValue}>
                                            <Form.Label>Password Confirmation</Form.Label>
                                        </Col>
                                        <Col className={styles.StartCenterValue}>
                                            <Form.Control type="password"
                                                          name={"password_confirmation"}
                                                          onChange={handleChange}
                                                          value={values.password_confirmation}
                                                          isValid={touched.password_confirmation && !errors.password_confirmation}
                                                          isInvalid={touched.password_confirmation && errors.password_confirmation}
                                                          onFocus={(e) => {
                                                              e.target.select()
                                                          }}
                                                          autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    {touched.password_confirmation && <Form.Group as={Row} className={"mb-3"}>
                                        <Col md={3}></Col>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.password_confirmation}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>}
                                    <Form.Group className={styles.EndCenterValue}>
                                        <button disabled={this.state.loading} className={"btn btn-account"} type={"submit"}>{this.state.loading?<Spinner animation="border" variant="light" size={"sm"} />:"Change"}</button>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>}

            </div>
        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withGameTableContext(UpdatePassword))));