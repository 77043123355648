import React, {Component} from "react";
import {Form} from "react-bootstrap";

class CustomMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ""
        }
    }

    render() {
        return (
            <div
            >
                <Form.Control
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={e => this.setState({ value: e.target.value })}
                    value={this.state.value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(this.props.children).filter(
                        (child) =>
                            !this.state.value || child.props.children.toLowerCase().startsWith(this.state.value)
                    )}
                </ul>
            </div>
        );
    }
}
export default CustomMenu;