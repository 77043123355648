import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {NavLink, withRouter} from "react-router-dom"
import PanelContainer from "../PanelContainer";
import {Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AccountTable from "../../../components/ui/AccountTable/AccountTable";
import AdminService from "../../../services/admin.service";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import {
    ROUTE_ADMIN_CEG_SELECTED_ROUND,
    ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW,
    ROUTE_ADMIN_USER_ACCOUNT_PLAYED
} from "../../../routers/routes";
import {accountCodeParse, numberWithCommas, url} from "../../../helpers/helpers";
import moment from "moment";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import PageTitleContainer from "../Search/PageTitleContainer";
import SelectedRoundWinningNumberTotal from "./SelectedRoundWinningNumberTotal";
class SelectedRoundWinningNumber extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            roundNumber: props.match.params.hasOwnProperty('roundNumber')?props.match.params.roundNumber:null,
            loading: true,
            round: null,
            roundTotalComponent: null
        }
    }

    componentDidMount = async () => {
        const {accountContext} = this.props;
        accountContext.doUpdatePageTitle("Selected Round");
        await this.handleChangeRound(this.state.roundNumber)
    }

    handleChangeRound = async (id) => {
        this.setState({ loading: true })
        let round = await AdminService.getRoundDetails(id);
        this.setState({
            round,
            roundNumber: id,
            loading: false
        })
    }

    render() {
        const {accountContext} = this.props;
        return (
            <PanelContainer>
                <PageTitleContainer back={true} center={true}/>
                {!this.state.loading &&
                <React.Fragment>
                    {this.state.roundTotalComponent}
                    <div className={"form-center"}>
                        <AccountTable  ref={(ref) => this.refTable = ref} full={true} loadData={AdminService.getRoundPlayedHistory} sort={"id"} align={"desc"} header={(onSort, sort, align) => {
                            return <TableRow>
                                <TableColHeader width={200} id={"table-cell-01"}>Player</TableColHeader>
                                <TableColHeader width={200} id={"table-cell-02"}>Account ID</TableColHeader>
                                <TableColHeader id={"table-cell-03"}>Total Bet</TableColHeader>
                                <TableColHeader id={"table-cell-04"}>Total Pay-out</TableColHeader>
                                <TableColHeader id={"table-cell-05"}>House Balance</TableColHeader>
                            </TableRow>
                        }}
                       render={(row, index, number, page) =>
                           <TableRow key={index}>
                               <TableCol>{number}</TableCol>
                               <TableCol>
                                   <a href={"javascript:void(0)"} className={"underline-link yellow-text"}
                                      onClick={(e) => accountContext.doGotoProfile(ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW, ":profileId", row['account_code'], this.state, this.refTable.getState())}>
                                       {accountCodeParse(row['account_code'])}
                                   </a>
                               </TableCol>
                               <TableCol><span className={"yellow-text"}>{numberWithCommas(row['table_bet'], true)}</span></TableCol>
                               <TableCol><span className={"yellow-text"}>{numberWithCommas(row['table_pay_out'], true)}</span></TableCol>
                               <TableCol><span className={(row['table_bet'] - row['table_pay_out'] >= 0)?"text-success":"text-danger"}>{numberWithCommas(row['table_bet'] - row['table_pay_out'], true)}</span></TableCol>
                           </TableRow>
                       }
                       mobile={(row, index, number, total, onNext, onPrevious) =>
                           <TableMobileRow key={index}>
                               <TableMobileCol>
                                   <label>Player</label>
                                   <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Account ID</label>
                                   <TableColValue>
                                       <a href={"javascript:void(0)"} className={"underline-link yellow-text"}
                                          onClick={(e) => accountContext.doGotoProfile(ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW, ":profileId", row['account_code'], this.state, this.refTable.getState())}>
                                           {accountCodeParse(row['account_code'])}
                                       </a>
                                   </TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Total Bet</label>
                                   <TableColValue><span className={"yellow-text"}>{numberWithCommas(row['table_bet'], true)}</span></TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Total Pay-out</label>
                                   <TableColValue><span className={"yellow-text"}>{numberWithCommas(row['table_pay_out'], true)}</span></TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>House Balance</label>
                                   <TableColValue><span className={(row['table_bet'] - row['table_pay_out'] >= 0)?"text-success":"text-danger"}>{numberWithCommas(row['table_bet'] - row['table_pay_out'], true)}</span></TableColValue>
                               </TableMobileCol>
                           </TableMobileRow>}
                       params={{
                           round_number: this.state.roundNumber
                       }}
                       loaded={() => {
                           this.setState({
                               roundTotalComponent: <SelectedRoundWinningNumberTotal round={this.state.round} handleChangeRound={this.handleChangeRound}/>
                           });
                       }}
                    />
                    </div>
                </React.Fragment>
                }
            </PanelContainer>
        )
    }
}

export default withRouter(withAdminAccountContext(SelectedRoundWinningNumber));