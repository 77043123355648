import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import VolumeMute from "../../../components/icons/volume-mute.png";
import VolumeLevel1 from "../../../components/icons/volume-level-1.png";
import VolumeLevel2 from "../../../components/icons/volume-level-2.png";
import VolumeLevel3 from "../../../components/icons/volume-level-3.png";
import {VOLUME_LEVEL_1, VOLUME_LEVEL_2, VOLUME_LEVEL_3} from "../../../helpers/constants";
import moment from "moment";

class WonBoardToolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            top5Menu: false
        }
    }

    onChangeNeighbourBet = (neighbourBet) => {
        const {gameTableContext} = this.props;
        gameTableContext.onUpdateState({
            neighbourBet
        })
    }
    handleChangeVolume = (e) => {
        const {gameTableContext} = this.props;
        let {volume} = gameTableContext.state;
        if(volume === VOLUME_LEVEL_3) volume = 0;
        else if(volume === 0) volume = VOLUME_LEVEL_1;
        else if(volume === VOLUME_LEVEL_1) volume = VOLUME_LEVEL_2;
        else if(volume === VOLUME_LEVEL_2) volume = VOLUME_LEVEL_3;
        gameTableContext.handleChangeVolume(volume);
    }
    render() {
        const {applicationContext, gameTableContext} = this.props;
        return (
            <div className={styles.WonBoardToolbar}>
                <button className={classNames(styles.WonBoardButton)} onClick={this.handleChangeVolume}>
                    <div className={styles.WonBoardButtonCircle}>
                    {gameTableContext.state.volume === VOLUME_LEVEL_3 && <img src={VolumeLevel3}/>}
                    {gameTableContext.state.volume === VOLUME_LEVEL_2 && <img src={VolumeLevel2}/>}
                    {gameTableContext.state.volume === VOLUME_LEVEL_1 && <img src={VolumeLevel1}/>}
                    {gameTableContext.state.volume === 0 && <img src={VolumeMute}/>}

                    </div>
                </button>
                <button className={classNames(styles.WonBoardButton, gameTableContext.state.table === "stats" && styles.WonBoardButtonActive)} onClick={(e) => gameTableContext.handleChangeTable("stats")}>
                    <div className={styles.WonBoardButtonCircle}>
                        <FontAwesomeIcon icon={"chart-bar"} />
                    </div>

                </button>
                <button className={classNames(styles.WonBoardButton, gameTableContext.state.table === "won" && styles.WonBoardButtonActive)} onClick={(e) => gameTableContext.handleChangeTable("won")}>
                    <div className={styles.WonBoardButtonCircle}>
                        <FontAwesomeIcon icon={"calculator"} />
                    </div>
                </button>
                <button className={classNames(styles.WonBoardButton, styles.WonBoardLastButton, gameTableContext.state.table === "top5" && styles.WonBoardButtonActive)}
                        onClick={(e) => gameTableContext.handleChangeTable("top5")}>
                    <div className={styles.WonBoardButtonCircle}>
                        <span>TOP<br/>5</span>
                    </div>
                </button>

                <div className={styles.NeighboursBar}>
                    <label>{applicationContext.translator("Neighbours")}</label>
                    <button className={classNames(styles.NeighbourButton, gameTableContext.state.neighbourBet === 0 && styles.NeighbourButtonActive)} onClick={(e) => this.onChangeNeighbourBet(0)}>
                        0
                    </button>
                    <button className={classNames(styles.NeighbourButton, gameTableContext.state.neighbourBet === 1 && styles.NeighbourButtonActive)} onClick={(e) => this.onChangeNeighbourBet(1)}>
                        1
                    </button>
                    <button className={classNames(styles.NeighbourButton, gameTableContext.state.neighbourBet === 2 && styles.NeighbourButtonActive)} onClick={(e) => this.onChangeNeighbourBet(2)}>
                        2
                    </button>
                    <button className={classNames(styles.NeighbourButton, gameTableContext.state.neighbourBet === 3 && styles.NeighbourButtonActive)} onClick={(e) => this.onChangeNeighbourBet(3)}>
                        3
                    </button>
                </div>
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext(WonBoardToolbar));