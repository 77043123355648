import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import {withRouter} from "react-router-dom"
import {Formik} from "formik";
import * as Yup from "yup";
import {Alert, Col, Form, FormText, InputGroup, Row} from "react-bootstrap";
import AccountContainer from "../AccountContainer";
import {accountCodeParse, numberWithCommas} from "../../../helpers/helpers";
import LoadingContainer from "../../../components/common/LoadingContainer";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {withAlert} from "react-alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from "./Deposit.module.css";
import DepositService from "../../../services/deposit.service";
class TrustPaymentDeposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: numberWithCommas("0", true),
            successfully: false,
            application: process.env.REACT_APP_NAME
        }
    }
    handleSubmit = async (values, actions) => {
        const {alert, history, accountContext} = this.props;
        try{
            // let result = await DepositService.doDeposit({
            //     ...values
            // });
            //alert.error("It's not connected to bank now please try again later");
            this.setState({
                successfully: true
            })
            actions.resetForm()
        }catch (e){
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, e.response.data.errors[field][0])
            })

        }


    }
    render() {
        const {accountContext} = this.props;
        return (
            <LoadingContainer loading={accountContext.loading}>
                <AccountContainer>
                    <div className={"form-center"}>

                        {!accountContext.loading && <Formik
                            innerRef={(ref) => this.refForm = ref}
                            enableReinitialize={true}
                            initialValues={this.state}
                            validationSchema={Yup.object().shape({
                                amount: Yup.string().required("You must enter amount").test('is-valid-amount', '${path} must be smaller than $5.000 and bigger than $50', value => {
                                    if(value){
                                        let filtered = value.replaceAll(".", "").replaceAll("$", "").replace(/\D/g, "");
                                        if(parseFloat(filtered) !== NaN && parseFloat(filtered) >= 50 && parseFloat(filtered) <= 5000)
                                            return true;

                                    }
                                    return false;
                                }).label("Amount"),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit} className={styles.FullWidth}>
                                    <Form.Control type="hidden"
                                                  name={"application"}
                                                  onChange={handleChange}
                                                  value={values.application}
                                    />
                                    <Form.Group as={Row} >
                                        <Col md={9} className={"text-center"}>
                                            <Row>
                                                <Col md={4} className={"start-center-value"}>
                                                    <Form.Label>Account ID</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  disabled={true}
                                                                  name={"account_code"}
                                                                  value={accountCodeParse(accountContext.userInfo['account_code'])}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className={"mt-3"}>
                                                <Col md={4} className={"start-center-value"}>

                                                </Col>
                                                <Col>
                                                    <Form.Text className="yellow-text">
                                                        Minimum Deposit 50$
                                                    </Form.Text>
                                                </Col>
                                            </Row>
                                            <Row className={"mt-3"}>
                                                <Col md={4} className={"start-center-value"}>
                                                    <Form.Label>Deposit Amount</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>

                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            aria-describedby="basic-addon2"
                                                            name={"amount"}
                                                            onChange={(e) => {
                                                                let filtered = e.target.value.replaceAll(".", "").replaceAll("$", "").replace(/\D/g, "");
                                                                setFieldValue('amount', filtered)
                                                            }}
                                                            onFocus={(e) => {
                                                                let filtered = e.target.value.replaceAll(".", "").replaceAll("$", "").replace(/\D/g, "");
                                                                this.setState({
                                                                    amount: filtered
                                                                }, () => {
                                                                    setTimeout(() => {
                                                                        e.target.select()
                                                                    }, 100)
                                                                })

                                                            }}
                                                            onKeyUp={(e) => {

                                                            }}
                                                            onBlur={(e) => {
                                                                let filtered = numberWithCommas(parseFloat(e.target.value), true);
                                                                setFieldValue('amount', filtered)
                                                            }}
                                                            value={values.amount}
                                                            isValid={touched.amount && !errors.amount}
                                                            isInvalid={touched.amount && errors.amount}
                                                            autoComplete="off"
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>USD</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            {touched.amount && <Row>
                                                <Col md={4}></Col>
                                                <Col>
                                                    <Form.Text className="text-danger">
                                                        {errors.amount}
                                                    </Form.Text>
                                                </Col>
                                            </Row>}
                                            <Row className={"mt-3"}>
                                                <Col md={4}></Col>
                                                <Col>
                                                    <Form.Group as={Row} className={"d-flex justify-content-center"}>
                                                        <button className={"btn btn-account full-width"} type={"submit"}>Go To Payment</button>
                                                    </Form.Group>

                                                    {this.state.successfully && <Form.Group as={Row} className={styles.TextAlert}>
                                                        <Form.Text className={styles.TextAlert}>
                                                            The payment system not connected yet.
                                                        </Form.Text>

                                                    </Form.Group>}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className={"mt-3"}>
                                        <Col md={9} className={"text-center"}>
                                            <Row>
                                                <Col md={4}></Col>
                                                <Col>
                                                    <div>
                                                        <img className={styles.PaymentImage} src={`${process.env.PUBLIC_URL}/assets/img/payments-gw.png`} />
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Form.Group>


                                </Form>
                            )}
                        </Formik>}
                    </div>
                </AccountContainer>
            </LoadingContainer>
        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(TrustPaymentDeposit))));