import React, {Component} from 'react';
import {Formik} from "formik";
import * as Yup from "yup";
import {Alert, Col, Form, FormText, InputGroup, Row} from "react-bootstrap";
import AccountContainer from "../AccountContainer";
import LoadingContainer from "../../../components/common/LoadingContainer";
import {withAlert} from "react-alert";
import {withRouter} from "react-router-dom";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import WithdrawalService from "../../../services/withdrawal.service";
import {accountCodeParse, isValidIBANNumber, numberWithCommas} from "../../../helpers/helpers";
import styles from "./Withdrawal.module.css";
class SwisscoWithdrawal extends Component {
    refFile = React.createRef();
    refImageBox = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            amount: numberWithCommas("0", true),
            dest_wallet_code: "",
            new_balance: "",
            bank_name: props.accountContext.userInfo['bank_name'],
            swift_bic: props.accountContext.userInfo['swift_bic'],
            iban: props.accountContext.userInfo['iban_no'],
            full_name: props.accountContext.userInfo['full_name'],
            address: props.accountContext.userInfo['address'],
            zip_code: props.accountContext.userInfo['zip_code'],
            city: props.accountContext.userInfo['city'],
            country: props.accountContext.userInfo['country'],
            email: props.accountContext.userInfo['email'],
            phone_number: `+${props.accountContext.userInfo['country_code']}${props.accountContext.userInfo['phone_number']}`,
            balance: props.accountContext.userInfo['balance'],
            application: process.env.REACT_APP_NAME
        }
    }
    handleSubmit = async (values, actions) => {
        const {alert, history, accountContext} = this.props;
        try{
            values.amount = values.amount.replaceAll(".", "").replaceAll("$", "").replace(/\D/g, "");
            let result = await WithdrawalService.doWithdrawal(values);
            alert.success("Your withdrawal will be verified and as soon as possible and will be transfer into your account.");
            actions.resetForm();
            accountContext.updateUserInfo(result.data.user)
            this.setState({
                successfully: true,
                new_balance: result.data.user.balance
            })
            this.refImageBox.style.backgroundImage = ``;
        }catch (e){
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, e.response.data.errors[field][0])
            })

        }


    }
    toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    onSelectFile = async (e, setFieldValue) => {
        let file = e.target.files[0];
        let image = (await this.toBase64(file));
        this.refImageBox.style.backgroundImage = `url(${image})`;
        setFieldValue("image", file);
    }
    render() {
        const {accountContext} = this.props;
        let self = this;
        return (
            <LoadingContainer loading={accountContext.loading}>
                <AccountContainer>
                    <div className={"form-center"}>

                        {!accountContext.loading && <Formik
                            innerRef={(ref) => this.refForm = ref}
                            enableReinitialize={true}
                            initialValues={this.state}
                            validationSchema={Yup.object().shape({
                                amount: Yup.string().required("You must enter ${path}")
                                    .test('is-valid-amount', '${path} must be smaller than available balance and bigger than $50', value => {
                                        if(value){
                                            let filtered = value.replaceAll(".", "").replaceAll("$", "").replace(/\D/g, "");
                                            if(parseFloat(filtered) !== NaN && parseFloat(filtered) >= 50 && parseFloat(filtered) <= parseFloat(self.state.balance))
                                                return true;

                                        }
                                        return false;
                                    }).label("Amount"),
                                bank_name: Yup.string().nullable().required("You must enter your ${path}").min(3).label("Bank Name"),
                                swift_bic: Yup.string().required("You must enter your ${path}").length(11).label("SWIFT-BIC"),
                                iban: Yup.string().required("You must enter your ${path}").label("IBAN"),
                                // swift_bic: Yup.string().nullable().required("You must enter your ${path}").length(11).label("SWIFT-BIC").matches(
                                //     /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
                                //     "${path} format is not valid"
                                // ),
                                // iban: Yup.string().nullable().required("You must enter your ${path}").test("is-iban", "${path} format is not valid", value => isValidIBANNumber(value)).label("IBAN"),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError,
                                  setErrors,
                                  setTouched
                              }) => (
                                <Form onSubmit={handleSubmit} className={styles.FullWidth}>
                                    <Form.Control type="hidden"
                                                  name={"application"}
                                                  onChange={handleChange}
                                                  value={values.application}
                                    />
                                    <Form.Group as={Row} >
                                        <Col md={9} className={"text-center"}>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label>AVAILABLE BALANCE </Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <InputGroup>
                                                        <Form.Control type="text"
                                                                      disabled={true}
                                                                      name={"current_balance"}
                                                                      value={numberWithCommas(this.state.balance, true)}
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>USD</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>

                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={!touched.amount && "mb-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label>TOTAL WITHDRAWAL </Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <InputGroup>
                                                        <Form.Control
                                                            aria-describedby="basic-addon2"
                                                            name={"amount"}
                                                            onKeyUp={(e) => {
                                                                let filtered = e.target.value.replaceAll(".", "").replaceAll("$", "").replace(/\D/g, "");
                                                                if(parseFloat(filtered) !== NaN && parseFloat(filtered) > 0){
                                                                    let new_balance = parseFloat(this.state.balance) - parseFloat(e.target.value);
                                                                    if(new_balance >= 0) {
                                                                        setFieldValue('new_balance', new_balance)
                                                                        setTouched({ amount: false });
                                                                    }else{
                                                                        setFieldValue('new_balance', "")
                                                                        setTouched({ amount: true });
                                                                    }
                                                                }else{
                                                                    setFieldValue('new_balance', "")
                                                                    setTouched({ amount: true });
                                                                }


                                                            }}
                                                            onChange={(e) => {
                                                                let filtered = e.target.value.replaceAll(".", "").replaceAll("$", "").replace(/\D/g, "");
                                                                setFieldValue('amount', filtered)
                                                            }}
                                                            value={values.amount}
                                                            onFocus={(e) => {
                                                                let filtered = e.target.value.replaceAll(".", "").replaceAll("$", "").replace(/\D/g, "");
                                                                this.setState({
                                                                    amount: filtered
                                                                }, () => {
                                                                    setTimeout(() => {
                                                                        e.target.select()
                                                                    }, 100)
                                                                })

                                                            }}
                                                            onBlur={(e) => {
                                                                let filtered = numberWithCommas(parseFloat(e.target.value), true);
                                                                setFieldValue('amount', filtered)
                                                            }}
                                                            isValid={touched.amount && !errors.amount}
                                                            isInvalid={touched.amount && errors.amount}
                                                            autoComplete="off"
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>USD</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>

                                                </Col>
                                            </Form.Group>
                                            {touched.amount && <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4}></Col>
                                                <Col className={"text-left"}>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.amount}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}
                                            <Form.Group as={Row} className={"mb-3 mt-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label className={"yellow-text"}>NEW BALANCE</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <InputGroup>
                                                        <Form.Control
                                                            aria-describedby="basic-addon2"
                                                            value={values.new_balance && numberWithCommas(values.new_balance, true)}
                                                            disabled={true}
                                                            autoComplete="off"
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>USD</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>

                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={"mb-3 mt-4"}>
                                                <Col md={4} className={"end-center-value"}>

                                                </Col>
                                                <Col className={"text-center-value"}>
                                                    <Form.Text className={styles.TitleBox}>
                                                        Beneficiary Bank details:
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={!touched.bank_name && "mb-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label >BANK NAME</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  name={"bank_name"}
                                                                  value={values.bank_name}
                                                                  onChange={handleChange}
                                                                  isValid={touched.bank_name && !errors.bank_name}
                                                                  isInvalid={touched.bank_name && errors.bank_name}
                                                                  autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            {touched.bank_name && <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4}></Col>
                                                <Col className={"text-left"}>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.bank_name}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}
                                            <Form.Group as={Row} className={!touched.swift_bic && "mb-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label >SWIFT-BIC</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  name={"swift_bic"}
                                                                  value={values.swift_bic}
                                                                  maxLength={11}
                                                                  onChange={handleChange}
                                                                  isValid={touched.swift_bic && !errors.swift_bic}
                                                                  isInvalid={touched.swift_bic && errors.swift_bic}
                                                                  autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            {touched.swift_bic && <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4}></Col>
                                                <Col className={"text-left"}>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.swift_bic}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}
                                            <Form.Group as={Row} className={!touched.iban && "mb-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label >IBAN</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  name={"iban"}
                                                                  value={values.iban}
                                                                  onChange={handleChange}
                                                                  isValid={touched.iban && !errors.iban}
                                                                  isInvalid={touched.iban && errors.iban}
                                                                  autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            {touched.iban && <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4}></Col>
                                                <Col className={"text-left"}>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.iban}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}
                                            <Form.Group as={Row} className={"mb-3 mt-4"}>
                                                <Col md={4} className={"end-center-value"}>

                                                </Col>
                                                <Col className={"text-center-value"}>
                                                    <Form.Text className={styles.TitleBox}>
                                                        Beneficiary Details:
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label >FULL NAME</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  name={"full_name"}
                                                                  disabled={true}
                                                                  value={values.full_name}
                                                                  className={"text-center"}
                                                                  autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label >ADDRESS</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  name={"address"}
                                                                  disabled={true}
                                                                  value={values.address}
                                                                  className={"text-center"}
                                                                  autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label >ZIP CODE</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  name={"zip_code"}
                                                                  disabled={true}
                                                                  value={values.zip_code}
                                                                  className={"text-center"}
                                                                  autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label >CITY</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  name={"city"}
                                                                  disabled={true}
                                                                  value={values.city}
                                                                  className={"text-center"}
                                                                  autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label >COUNTRY</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  name={"country"}
                                                                  disabled={true}
                                                                  value={values.country}
                                                                  className={"text-center"}
                                                                  autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label >E-MAIL ADDRESS</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  name={"email"}
                                                                  disabled={true}
                                                                  value={values.email}
                                                                  className={"text-center"}
                                                                  autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label >MOBILE NUMBER</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  name={"phone_number"}
                                                                  disabled={true}
                                                                  className={"text-center"}
                                                                  value={values.phone_number}
                                                                  autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>



                                            <Form.Group className={"d-flex justify-content-center"}>
                                                <Col md={4} className={"end-center-value"}>
                                                    <Form.Label></Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <button className={"btn btn-account full-width"} type={"submit"}>Save And Send</button>
                                                </Col>

                                            </Form.Group>
                                            {this.state.successfully &&
                                            <React.Fragment>
                                                <Form.Group as={Row} className={"text-center mt-3"}>
                                                    <Col md={4} className={"end-center-value"}>
                                                        <Form.Label></Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Text className="text-success mt-2">
                                                            Your withdrawal will be verified and as soon as possible and will be transfer into your account.
                                                        </Form.Text>
                                                    </Col>
                                                </Form.Group>

                                            </React.Fragment>}
                                        </Col>
                                    </Form.Group>

                                </Form>
                            )}
                        </Formik>}
                    </div>
                </AccountContainer>
            </LoadingContainer>
        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(SwisscoWithdrawal))));