import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {
    SectionContainer,
    SectionTitle,
    SectionBody, CenterCol,
    PrimaryButton, CenterText,
    Brands, Brand
} from "./style.jsx";
import {Container, Row, Col} from 'react-bootstrap';

class OurPartners extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SectionContainer  id={"section-partners"}>
                <Container>
                    <SectionTitle><div className={"wrapper"}>{applicationContext.translator("Our partners")}</div></SectionTitle>
                    <SectionBody>
                        <Brands>
                            <Row>
                                <Col md={4}>
                                    <Brand>
                                        <img src={"/assets/img/partners/logo-swisscov2copy-removebg-preview.png"} />
                                        <h6>Swissco Gaming B.V.<br/>{applicationContext.translator("Licensed Operator")}</h6>
                                    </Brand>
                                </Col>
                                <Col md={4}>
                                    <Brand>
                                        <img src={"/assets/img/partners/maxxtvlogopartners-removebg-preview.png"} />
                                        <h6>Maxx T.V<br/>{applicationContext.translator("Satellite Broadcasting")}</h6>
                                    </Brand>
                                </Col>
                                <Col md={4}>
                                    <Brand>
                                        <img src={"/assets/img/partners/logo-zheta-removebg-preview.png"} />
                                        <h6>Zheta<br/>{applicationContext.translator("Web design")}</h6>
                                    </Brand>
                                </Col>
                                <Col md={4}>
                                    <Brand>
                                        <img src={"/assets/img/partners/logos2-removebg-preview.png"} />
                                        <h6>Curacao Gaming<br/>{applicationContext.translator("License Authority")}</h6>
                                    </Brand>
                                </Col>
                                <Col md={4}>
                                    <Brand>
                                        <img src={"/assets/img/partners/TCSJH-removebg-preview.png"} />
                                        <h6>TCS John Huxley<br/>{applicationContext.translator("Gaming Solutions")}</h6>
                                    </Brand>
                                </Col>
                                <Col md={4}>
                                    <Brand>
                                        <img src={"/assets/img/partners/em_copy-removebg-preview.png"} />
                                        <h6>eMagnus Curacao NV<br/>{applicationContext.translator("eGaming Business")}</h6>
                                    </Brand>
                                </Col>

                            </Row>
                        </Brands>
                        <CenterText>{applicationContext.translator("Myroulette.tv operates under the license of Swissco Gaming and regulated by (CEG) Curaçao eGaming Authority.")}</CenterText>
                        <CenterText>{applicationContext.translator("All tables are live broadcast with a direct uplink from London-based teleport for satellite T.V.")}</CenterText>
                    </SectionBody>

                </Container>
            </SectionContainer>

        );
    }
}

export default withApplicationContext(OurPartners);