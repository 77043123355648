import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {
    SectionContainer,
    SectionTitle,
    SectionBody, CenterCol,
    PrimaryButton,
    CenterText, SecondaryButton, FlexButtons, SecondaryLink, ColImage
} from "./style.jsx";
import {Container, Row, Col} from 'react-bootstrap';

class FeaturesAndInterface extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SectionContainer bg={"white"}  id={"section-features"}>
                <Container>
                    <SectionTitle><div className={"wrapper"}>{applicationContext.translator("Features & Interface")}</div></SectionTitle>
                    <SectionBody>
                        <Row>
                            <CenterCol md={6} className={"sm-order-2"}>
                                <CenterText>
                                    <p>در اینجا می توانید قابلیت ها و رابط های کاربری رولت زنده ما را که در یک فایل pdf و یا روی یوتیوب توضیح داده شده را ببینید. پیشنهاد می کنیم این راهنما را مطالعه کنید تا کاملاً با همه گزینه های اضافی میز بازی ما آشنا شوید. چند گزینه متنوع برای ذخیره کردن شرط بندی های مورد علاقه تان، مشاهده 5 شرط بندی برنده برتر قبل از افتادن شماره های برنده، نمایش با جزییات تمام بردهای شما، بازی مجاورها با کلیک یا راست کلیک روی سیلندر رولت، و بسیاری قابلیت های دیگر.
                                        این چند گام ساده را بردارید تا برای شروع بازی هیجان انگیزتان آماده شوید. قوانین اصلی را یاد بگیرید تا بتوانید برای کشف استراتژی های مختلف و سیستم هایی که امکان برد شما را افزایش می دهد، حرکت کنید.</p>

                                    <FlexButtons>
                                        <SecondaryLink target={"_blank"} href={"/assets/files/SwisscoGamingTableGuide.pdf"}>{applicationContext.translator("Read More")}</SecondaryLink>
                                        <SecondaryLink target={"_blank"} href={"https://youtu.be/FAZNS98bqTk"}>{applicationContext.translator("View Video")}</SecondaryLink>
                                    </FlexButtons>

                                </CenterText>
                            </CenterCol>
                            <Col md={6} className={"sm-order-1"}>
                                <ColImage><img src={"/assets/img/maxxtvgaming.jpg"} width={"90%"}/></ColImage>
                            </Col>

                        </Row>
                    </SectionBody>

                </Container>
            </SectionContainer>

        );
    }
}

export default withApplicationContext(FeaturesAndInterface);