import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import {withRouter} from "react-router-dom"
import AccountContainer from "../AccountContainer";
import AccountTable from "../../../components/ui/AccountTable";
import UserService from "../../../services/user.service";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import moment from "moment-timezone";
import {numberWithCommas, timezone, utcTime} from "../../../helpers/helpers";
import LoadingContainer from "../../../components/common/LoadingContainer";
import LeftSidePopup from "../../../components/ui/LeftSidePopup";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
class PlayedHistory extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dialog: false,
            history: null
        }
    }
    onClose = (e) => {
        this.setState({
            dialog: false,
            dialogTitle: "",
            history: null
        })
    }
    onLoadHistory = (e, history) => {
        e.preventDefault();
        this.setState({
            dialog: true,
            history: history.id
        })
    }
    onChangeHistoryRow = (history) => {
        this.setState({
            history: history
        })
    }
    onChangeRow = (id, action) => {
        console.log(id, action)
    }
    render() {
        const {accountContext, applicationContext} = this.props;
        return (
            <LoadingContainer loading={accountContext.loading}>
                <AccountContainer>
                    <div className={"form-center"}>
                        <AccountTable loadData={UserService.getPlayedHistory} header={(onSort) => {
                            return <TableRow>
                                <TableColHeader>#</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Date")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Time")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Round#")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Winning#")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("BALANCE BEFORE PLAY")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("TOTAL BET")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("REMAINING BALANCE")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("TOTAL WIN")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("NEW BALANCE")}</TableColHeader>
                            </TableRow>
                        }}
                          sort={"id"}
                          align={"desc"}
                          render={(row, index, number) =>
                              <TableRow key={index} onClick={(e) => this.onLoadHistory(e, row)} active={this.state.history === row.id}>
                                  <TableCol>{number}</TableCol>
                                  <TableCol>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableCol>
                                  <TableCol>{utcTime(row['created_at']).format('HH:mm:ss')}</TableCol>
                                  <TableCol yellow={true} >{row['round_number']}</TableCol>
                                  <TableCol>{row['number']}</TableCol>
                                  <TableCol>{numberWithCommas(row['balance_before_play'], true)}</TableCol>
                                  <TableCol yellow={true}>{numberWithCommas(row['table_bet'], true)}</TableCol>
                                  <TableCol>{numberWithCommas(row['remain_balance'], true)}</TableCol>
                                  <TableCol>{numberWithCommas(row['table_win'], true)}</TableCol>
                                  <TableCol>{numberWithCommas(row['new_balance'], true)}</TableCol>
                              </TableRow>
                          }
                          ref={(ref) => this.refTable = ref}
                          mobile={(row, index, number, total, onNext, onPrevious) =>
                              <TableMobileRow key={index} active={this.state.history === row.id}>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Number / Total Records")}</label>
                                      <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Date")}</label>
                                      <TableColValue>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Time")}</label>
                                      <TableColValue>{utcTime(row['created_at']).format('HH:mm:ss')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Round#")}</label>
                                      <TableColValue>{row['round_number']}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Winning#")}</label>
                                      <TableColValue>{row['number']}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("BALANCE BEFORE PLAY")}</label>
                                      <TableColValue>{numberWithCommas(row['balance_before_play'], true)}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("TOTAL BET")}</label>
                                      <TableColValue>{numberWithCommas(row['table_bet'], true)}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("REMAINING BALANCE")}</label>
                                      <TableColValue>{numberWithCommas(row['remain_balance'], true)}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("TOTAL WIN")}</label>
                                      <TableColValue>{numberWithCommas(row['table_win'], true)}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("NEW BALANCE")}</label>
                                      <TableColValue>{numberWithCommas(row['new_balance'], true)}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <TableColValue>
                                          <button onClick={(e) => this.onLoadHistory(e, row)}>{applicationContext.translator("View this game on table ...")}</button>
                                      </TableColValue>
                                  </TableMobileCol>
                              </TableMobileRow>}
                        />
                    </div>
                    {this.state.dialog && <LeftSidePopup
                        show={this.state.dialog}
                        onClose={this.onClose}
                        onSubmit={this.onClose}
                        SubmitCaption={"OK"}
                        onChangeHistoryRow={this.onChangeHistoryRow}
                        onChangeRow={this.onChangeRow}
                        large={true}
                        dark={true}
                        previous={true}
                        history={this.state.history}
                    />}
                </AccountContainer>
            </LoadingContainer>
        )
    }
}

export default withRouter(withApplicationContext(withAccountContext((PlayedHistory))));