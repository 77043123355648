import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import classNames from "classnames";
import {RED_NUMBERS_OF_TABLE} from "../../../helpers/constants";
class TopCells extends Component {
    render() {
        const {gameTableContext} = this.props;
        return (
            <div className={styles.TopCellWrapper}>
                <table border={0} width="100%" className={styles.TopCellTable}>
                    {gameTableContext.state.numberHistory.map((r, index) => {
                        if(index > 0)
                            return (
                                <tr>
                                    <td className={classNames((RED_NUMBERS_OF_TABLE.includes(r) && styles.CellRed), r === 0 && styles.CellGreen)} key={index}>{r}</td>
                                </tr>
                            )
                        else
                            return null;
                    })}


                </table>
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext(TopCells));