import React from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";

var BaseIcon = function BaseIcon(_ref) {
    var color = _ref.color,
        _ref$pushRight = _ref.pushRight,
        pushRight = _ref$pushRight === undefined ? true : _ref$pushRight,
        children = _ref.children;
    return React.createElement(
        'svg',
        {
            xmlns: 'http://www.w3.org/2000/svg',
            width: '24',
            height: '24',
            viewBox: '0 0 24 24',
            fill: 'none',
            stroke: color,
            strokeWidth: '2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            style: pushRight?{ marginLeft: '20px', minWidth: 24 }:{ marginRight: '20px', minWidth: 24 }
        },
        children
    );
};

var InfoIcon = function InfoIcon(_ref) {
    return React.createElement(
        BaseIcon,
        { color: '#2E9AFE', pushRight: _ref.right },
        React.createElement('circle', { cx: '12', cy: '12', r: '10' }),
        React.createElement('line', { x1: '12', y1: '16', x2: '12', y2: '12' }),
        React.createElement('line', { x1: '12', y1: '8', x2: '12', y2: '8' })
    );
};

var SuccessIcon = function SuccessIcon(_ref) {
    return React.createElement(
        BaseIcon,
        { color: '#31B404', pushRight: _ref.right },
        React.createElement('path', { d: 'M22 11.08V12a10 10 0 1 1-5.93-9.14' }),
        React.createElement('polyline', { points: '22 4 12 14.01 9 11.01' })
    );
};

var ErrorIcon = function ErrorIcon(_ref) {
    return React.createElement(
        BaseIcon,
        { color: '#FF0040', pushRight: _ref.right },
        React.createElement('circle', { cx: '12', cy: '12', r: '10' }),
        React.createElement('line', { x1: '12', y1: '8', x2: '12', y2: '12' }),
        React.createElement('line', { x1: '12', y1: '16', x2: '12', y2: '16' })
    );
};

var CloseIcon = function CloseIcon(_ref) {
    return React.createElement(
        BaseIcon,
        { color: '#FFFFFF', pushRight: _ref.right},
        React.createElement('line', { x1: '18', y1: '6', x2: '6', y2: '18' }),
        React.createElement('line', { x1: '6', y1: '6', x2: '18', y2: '18' })
    );
};

var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];

        for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
            }
        }
    }

    return target;
};

var alertStyle = {
    backgroundColor: 'rgba(0,0,0, 0.9)',
    color: 'white',
    padding: '10px',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.05)',
    fontFamily: 'Arial',
    width: '400px',
    boxSizing: 'border-box',
    fontSize: '12px'

};

var buttonStyle = {
    marginLeft: '20px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: '#FFFFFF'
};
var rightButtonStyle = {
    marginLeft: '0px',
    marginRight: '0px',
}

var rightToLeft = {
    direction: "rtl"
};
let iranFont = {
    fontFamily: 'IranSANS !important'
}
var CustomAlertTemplate = function CustomAlertTemplate(_ref) {
    const {applicationContext} = _ref;
    var message = _ref.message,
        options = _ref.options,
        style = _ref.style,
        close = _ref.close;
    return React.createElement(
        'div',
        { style: _extends({}, alertStyle, style, applicationContext.translator("direction") === "rtl" && rightToLeft, applicationContext.translator("direction") === "rtl" && iranFont) },
        options.type === 'info' && React.createElement(InfoIcon, { right: applicationContext.translator("direction") === "rtl" }),
        options.type === 'success' && React.createElement(SuccessIcon, { right: applicationContext.translator("direction") === "rtl" }),
        options.type === 'error' && React.createElement(ErrorIcon, { right: applicationContext.translator("direction") === "rtl" }),
        React.createElement(
            'span',
            { style: { flex: 2 } },
            message
        ),
        React.createElement(
            'button',
            { onClick: close, style: _extends({}, buttonStyle, applicationContext.translator("direction") === "rtl" && rightButtonStyle) },
            React.createElement(CloseIcon, { right: applicationContext.translator("direction") !== "rtl" })
        )
    );
};

export default withApplicationContext(CustomAlertTemplate);
