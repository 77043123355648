import React, {Component, Suspense} from 'react';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/App.module.css';
import './theme.css';
import './assets/css/main.css';
import 'react-calendar/dist/Calendar.css';
import { Translation } from 'react-i18next'
import {ApplicationProvider} from "./contexts/ApplicationContext";
import i18n from 'i18next'
import {Route, Switch, BrowserRouter} from "react-router-dom";
import {library} from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import WebsiteRouter from "./routers/WebsiteRouter";
import AccountRouter from "./routers/AccountRouter";
import {positions, Provider} from "react-alert";
import CustomAlertTemplate from "./components/ui/Alert";
import UserService from "./services/user.service";
import AuthService from "./services/auth.service";
import AdminRouter from "./routers/AdminRouter";
import Popup from "./components/ui/Popup";
import TokenService from "./services/token.service";
import {HOME_WEBSITE} from "./helpers/constants";
import {uuid} from "./helpers/helpers";


const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

class App extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userInfo: null,
      logoutConfirm: false,
      mobileVersion: true,
      isMobileVersion: false,
      theme: (localStorage.getItem('theme_setting'))?localStorage.getItem('theme_setting'):'dark',
      lang: process.env.REACT_APP_DEFAULT_LANGUAGE
    };
  }
  syncLanguage = async () => {
      if(TokenService.getLocalAccessToken()) {
        let common = await UserService.getCommonData();
        localStorage.setItem('lang', common['user']['locale']);
        return localStorage.getItem('lang');
      }
      const urlSearchParams = new URLSearchParams(window.location.search);
      if(urlSearchParams.has('lang') && ['fa', 'en'].includes(urlSearchParams.get('lang'))){
          localStorage.setItem('lang', urlSearchParams.get('lang'))
      }
      if(localStorage.getItem('lang')){
          return localStorage.getItem('lang');
      }
      return process.env.REACT_APP_DEFAULT_LANGUAGE;
  }
  componentDidMount = async () => {
    document.body.classList.add(this.state.theme)
    this.checkMobileVersion();
    window.addEventListener('resize', this.checkMobileVersion);

    let lang = await this.syncLanguage()
    this.setState({
      lang
    }, async () => {
      if (localStorage.getItem("token")){
        await this.fetchUserInfo();
      }else{
        this.setState({
          loading: false
        })
      }
    })



  }
  checkMobileVersion = () => {
    if(!this.state.mobileVersion) {
      this.setState({
        isMobileVersion: false
      })
      return false;
    }
    if (window.innerWidth < 1023) {
      this.setState({
        isMobileVersion: true
      })
      return true;
    }
    this.setState({
      isMobileVersion: false
    })
    return false;
  }
  handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }
  handleChangeTheme = (theme = null) => {
    document.body.classList.remove(this.state.theme)
    if(!theme){
       theme = (this.state.theme === "dark")?"light":"dark";
    }
    this.setState({
      theme
    }, () => {
        localStorage.setItem('theme_setting', this.state.theme)
        document.body.classList.add(this.state.theme)
    })
  }
  handleLoading = (loading) => {
    this.setState({
      loading
    })
  }
  fetchUserInfo = async () => {
    try{
      const userInfo = await UserService.getUserBoard();
      this.setState({
        loading: false,
        userInfo: userInfo
      })
    }catch (e) {

    }
    return false;
  }
  updateUserInfo = (data) => {
    this.setState({
      userInfo: data
    })
  }
  onLogout = (e) => {
    e.preventDefault();
    this.setState({
      logoutConfirm: true
    })

  }
  handleLogout = async () => {
    let logout = await UserService.action({ action: "LOGOUT", description: "User logout account by self" });
    TokenService.removeUser();
    window.location.href = HOME_WEBSITE;
  }
  render() {
    return (
        <Suspense fallback={<div className={"loading-container"}></div>}>
          <Translation>
            {(t, { i18n }) => (
                <ApplicationProvider value={{
                  state: this.state,
                  handleChangeLanguage: this.handleChangeLanguage,
                  handleLoading: this.handleLoading,
                  updateUserInfo: this.updateUserInfo,
                  handleChangeTheme: this.handleChangeTheme,
                  onLogout: this.onLogout,
                  handleLogout: this.handleLogout,
                  syncLanguage: this.syncLanguage,
                  translator: t,
                  i18n: i18n
                }}>
                  <Provider template={CustomAlertTemplate} {...{
                    timeout: 20000,
                    position: positions.TOP_CENTER
                  }}>
                  <BrowserRouter>
                    <Switch>
                      <Route path={"/admin/account"} render={(props) => (<AdminRouter />)} />
                      <Route path={"/account"} render={(props) => (<AccountRouter />)} />
                      <Route path={"/"} render={(props) => (<WebsiteRouter />)} />
                    </Switch>
                  </BrowserRouter>

                  <Popup
                      show={this.state.logoutConfirm}
                      onClose={() => this.setState({ logoutConfirm: false })}
                      onSubmit={this.handleLogout}
                      SubmitCaption={t("Yes, I'm sure")}
                      SubmitButtonClass={"btn-success"}
                      CancelCaption={t("No")}
                      SubmittingLoading={this.state.submitting}
                      title={t("Logout")}
                      large={false}
                      description={t("Are you sure to logout your account?")}
                  >

                  </Popup>
                  </Provider>

                </ApplicationProvider>
            )}
          </Translation>
        </Suspense>

    );
  }

}

export default App;
