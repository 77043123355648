import React, {Component} from 'react';
import styles from "./LoadingData.module.css";
import {Spinner} from "react-bootstrap";

class LoadingData extends Component {

    render() {
        const {loading, children} = this.props;
        return (loading)?(
            <div className={styles.AppLogoContainer}>
                <Spinner animation="grow" variant="light" />
            </div>
        ):(
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}

export default LoadingData;