import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import {withAlert} from "react-alert";
import PanelContainer from "../PanelContainer";
import AdminService from "../../../services/admin.service";
import PageTitleContainer from "../Search/PageTitleContainer";
import {convertChipsToGroup, convertChipsToTopChips} from "../../../helpers/helpers";
import styles from "../../GameTable/GameTable.module.css";
import moduleStyles from "./Top37Payouts.module.css";
import classNames from "classnames";
class Top37Payouts extends Component {
    timer = null;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: []
        }
    }
    componentDidMount = async () => {
        await this.fetchTop37Data();
        this.timer = setInterval(async () => {
            await this.fetchTop37Data();
        }, 5000)
    }
    componentWillUnmount() {
        clearInterval(this.timer)
    }

    fetchTop37Data = async () => {
        this.setState({
            loading: true
        })
        let payouts = await AdminService.getTop37Payout();
        let top37 = convertChipsToTopChips(payouts)
        this.setState({
            loading: false,
            data: top37
        })
    }

    render() {
        return (
            <PanelContainer>
                <PageTitleContainer center={true} fullTitleWidth={500}/>
                <div className={"form-center"}>
                    <table border={0} width="100%" className={moduleStyles.WonBoard}>
                        <tr className={styles.WonBoardHeader}>
                            <th width={"30"}></th>
                            <th>BET ON</th>
                            <th className={classNames(styles.TextGreen, "text-center")} width={"20%"}>TOTAL BET</th>
                            <th className={classNames(styles.TextGreen, "text-center")} width={"20%"}>TOTAL WIN</th>
                        </tr>
                        {this.state.data && this.state.data.length > 0 &&
                        <React.Fragment>
                            {this.state.data.map((formula, index) => {
                                return (formula.number.startsWith("#"))?(
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{formula.number}</td>
                                        <td className={"text-center"}><span className={classNames(styles.TextGreen)}>{formula.value}</span></td>
                                        <td className={classNames(styles.TextGreen, "text-center")}>{formula.win}</td>
                                    </tr>
                                ):null;
                            })}
                        </React.Fragment>
                        }
                    </table>
                </div>
            </PanelContainer>
        )
    }
}

export default withAlert()(withRouter(withAdminAccountContext(Top37Payouts)));