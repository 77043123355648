import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import {NavLink, withRouter} from "react-router-dom"
import AccountContainer from "../AccountContainer";
import {Formik} from "formik";
import * as Yup from "yup";
import {Col, Form, Row, Spinner, Dropdown} from "react-bootstrap";
import {withAlert} from "react-alert";
import styled from "./Profile.module.css";
import LoadingContainer from "../../../components/common/LoadingContainer";
import UserService from "../../../services/user.service";
import TokenService from "../../../services/token.service";
import {ROUTE_ADMIN_DASHBOARD} from "../../../routers/routes";
import CommonService from "../../../services/common.service";
import CustomMenu from "../../../components/ui/CustomMenu";
import {withProfileContext} from "../../../contexts/ProfileContext";
import {accountCodeParse, age} from "../../../helpers/helpers";
import AuthService from "../../../services/auth.service";
import moment from "moment";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            password_confirmation: "",
            successfully: false,
            changeEmailLoading: false,
            changePasswordLoading: false,
            loading: false,
            countries: [],
            cities: [],
            country_code: null
        }
    }
    componentDidMount = async () => {
        const {accountContext, profileContext} = this.props;
        let context = (this.props.userId)?profileContext:accountContext;
        const countries = await CommonService.getCountries();
        const cities = await CommonService.getCities(context.userInfo['country']);
        let country_code = context.userInfo['country_code'];
        if(!country_code){
            let country = countries.filter((country) => country.name === context.userInfo['country']);
            if(country.length > 0)
                country_code = country[0].phonecode;
        }
        let message = {
            action: "authenticated",
            user: TokenService.getUser(),
            token: TokenService.getToken()
        };
        window.parent.postMessage(message, "*");
        this.setState({
            loading: false,
            countries,
            cities,
            country_code
        })
    }

    handleChangeEmail = async (e) => {
        e.preventDefault();
        if(this.state.changeEmailLoading) return;
        this.setState({changeEmailLoading : true});
        const {alert, applicationContext} = this.props;
        try{
            const result = await UserService.doChangeEmail({
                email: this.refForm.values.email
            });
            this.setState({
                successfully: true,
                changeEmailLoading: false
            })

            alert.success(applicationContext.translator(result.message));
        }catch (e){
            this.setState({changeEmailLoading : false})
        }

    }
    handleChangePassword = async (e) => {
        e.preventDefault();
        if(this.state.changePasswordLoading) return;
        this.setState({changePasswordLoading : true});
        const {alert, applicationContext} = this.props;
        try{
            const result = await UserService.doChangePassword();
            this.setState({
                successfully: true,
                changePasswordLoading: false
            })
            alert.success(applicationContext.translator(result.message));
        }catch (e){
            this.setState({
                changePasswordLoading: false
            })
        }
    }
    handleSubmit = async (values, actions) => {
        const {alert, applicationContext, accountContext} = this.props;
        try{
            let result = await UserService.updateUserProfile(values);
            alert.success(result.message);
            TokenService.setUser(result.data)
            accountContext.updateUserInfo(result.data);
        }catch (e){
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
            })

        }


    }
    renderCountryCode = (code) => {
        let country_code = this.state.countries.filter((r) => r.phonecode.toString() === code.toString())
        if(country_code.length > 0)
            return country_code[0].code.toLowerCase()
    }
    render() {
        const {accountContext, applicationContext} = this.props;
        let validation = null;
        if(process.env.REACT_APP_NAME === "swissco.gaming"){
            validation = {
                first_name: Yup.string().required(applicationContext.translator("You must enter your firstname")),
                last_name: Yup.string().required(applicationContext.translator("You must enter your lastname")),
                address: Yup.string().required(applicationContext.translator("You must enter your address")),
                zip_code: Yup.string().required(applicationContext.translator("You must enter your zipcode")),
                country: Yup.string().required(applicationContext.translator("You must select your country")),
                city: Yup.string().required(applicationContext.translator("You must select your city")),
                country_code: Yup.string().required(applicationContext.translator("You must select your country code")),
                phone_number: Yup.string().required(applicationContext.translator("You must select your mobile number")),
            };
        }
        let context = accountContext;
        return (
            <LoadingContainer loading={accountContext.loading}>
                <AccountContainer profile={this.props.userId}>
                    <div className={"form-center"}>
                        {(!accountContext.loading && !this.state.loading) && <Formik
                            innerRef={(ref) => this.refForm = ref}
                            enableReinitialize={true}
                            initialValues={{
                                first_name: (context.userInfo['first_name'])?context.userInfo['first_name']:"",
                                last_name: (context.userInfo['last_name'])?context.userInfo['last_name']:"",
                                email: context.userInfo['email'],
                                birthdate: (context.userInfo['birth_date'])?moment(new Date(context.userInfo['birth_date'])).format('MMMM DD, YYYY')+ " ("+age(context.userInfo['birth_date'])+" "+applicationContext.translator("years old")+")":"",
                                address: (context.userInfo['address'])?context.userInfo['address']:"",
                                zip_code: (context.userInfo['zip_code'])?context.userInfo['zip_code']:"",
                                country: (context.userInfo['country'])?context.userInfo['country']:"",
                                city: (context.userInfo['city'])?context.userInfo['city']:"",
                                country_code: (this.state.country_code)?this.state.country_code:"",
                                phone_number: (context.userInfo['phone_number'])?context.userInfo['phone_number']:"",
                                application: process.env.REACT_APP_NAME
                            }}
                            validationSchema={Yup.object().shape(validation)}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit} style={{ maxWidth: "70%"}}>
                                    <Form.Control type="hidden"
                                                  name={"application"}
                                                  onChange={handleChange}
                                                  value={values.application}
                                    />

                                    {process.env.REACT_APP_NAME === "swissco.gaming" && <React.Fragment>
                                        <Form.Group as={Row} className={"mb-3"}>
                                            <Col md={2} className={"start-center-value"}>
                                                <Form.Label>{applicationContext.translator("Date of birth")}</Form.Label>
                                            </Col>
                                            <Col className={"start-center-value"}>
                                                <Form.Control type="text"
                                                              name={"birthdate"}
                                                              onChange={handleChange}
                                                              disabled={true}
                                                              value={values.birthdate}
                                                              isValid={touched.birthdate && !errors.birthdate}
                                                              isInvalid={touched.birthdate && errors.birthdate}
                                                              autoComplete="off"
                                                />
                                            </Col>
                                            <Col md={4}>

                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className={!touched.first_name && "mb-3"}>
                                            <Col md={2} className={"start-center-value"}>
                                                <Form.Label>{applicationContext.translator("First Name")}</Form.Label>
                                            </Col>
                                            <Col className={"start-center-value"}>
                                                <Form.Control type="text"
                                                              name={"first_name"}
                                                              onChange={handleChange}
                                                              disabled={true}
                                                              value={values.first_name}
                                                              isValid={touched.first_name && !errors.first_name}
                                                              isInvalid={touched.first_name && errors.first_name}
                                                              autoComplete="off"
                                                />
                                            </Col>
                                            <Col md={4}>

                                            </Col>
                                        </Form.Group>
                                        {touched.first_name && <Form.Group as={Row} className={"mb-3"}>
                                            <Col md={2}></Col>
                                            <Col>
                                                <Form.Text className="text-danger mt-2">
                                                    {errors.first_name}
                                                </Form.Text>
                                            </Col>
                                        </Form.Group>}
                                        <Form.Group as={Row} className={!touched.last_name && "mb-3"}>
                                            <Col md={2} className={"start-center-value"}>
                                                <Form.Label>{applicationContext.translator("Last Name")}</Form.Label>
                                            </Col>
                                            <Col className={"start-center-value"}>
                                                <Form.Control type="text"
                                                              name={"last_name"}
                                                              onChange={handleChange}
                                                              disabled={true}
                                                              value={values.last_name}
                                                              isValid={touched.last_name && !errors.last_name}
                                                              isInvalid={touched.last_name && errors.last_name}
                                                              autoComplete="off"
                                                />
                                            </Col>
                                            <Col md={4}>

                                            </Col>
                                        </Form.Group>
                                        {touched.last_name && <Form.Group as={Row} className={"mb-3"}>
                                            <Col md={2}></Col>
                                            <Col>
                                                <Form.Text className="text-danger mt-2">
                                                    {errors.last_name}
                                                </Form.Text>
                                            </Col>
                                        </Form.Group>}
                                        <Form.Group as={Row} className={!touched.address && "mb-3"}>
                                            <Col md={2} className={"start-center-value"}>
                                                <Form.Label>{applicationContext.translator("Address")}</Form.Label>
                                            </Col>
                                            <Col className={"start-center-value"}>
                                                <Form.Control type="text"
                                                              name={"address"}
                                                              onChange={handleChange}
                                                              value={values.address}
                                                              isValid={touched.address && !errors.address}
                                                              isInvalid={touched.address && errors.address}
                                                              autoComplete="off"
                                                />
                                            </Col>
                                            <Col md={4}>

                                            </Col>
                                        </Form.Group>
                                        {touched.address && <Form.Group as={Row} className={"mb-3"}>
                                            <Col md={2}></Col>
                                            <Col>
                                                <Form.Text className="text-danger mt-2">
                                                    {errors.address}
                                                </Form.Text>
                                            </Col>
                                        </Form.Group>}
                                        <Form.Group as={Row} className={!touched.zip_code && "mb-3"}>
                                            <Col md={2} className={"start-center-value"}>
                                                <Form.Label>{applicationContext.translator("Zip Code")}</Form.Label>
                                            </Col>
                                            <Col className={"start-center-value"}>
                                                <Form.Control type="text"
                                                              name={"zip_code"}
                                                              onChange={handleChange}
                                                              value={values.zip_code}
                                                              isValid={touched.zip_code && !errors.zip_code}
                                                              isInvalid={touched.zip_code && errors.zip_code}
                                                              autoComplete="off"
                                                />
                                            </Col>
                                            <Col md={4}>

                                            </Col>
                                        </Form.Group>
                                        {touched.zip_code && <Form.Group as={Row} className={"mb-3"}>
                                            <Col md={2}></Col>
                                            <Col>
                                                <Form.Text className="text-danger mt-2">
                                                    {errors.zip_code}
                                                </Form.Text>
                                            </Col>
                                        </Form.Group>}
                                        <Form.Group as={Row} className={!touched.city && "mb-3"}>
                                            <Col md={2} className={"start-center-value"}>
                                                <Form.Label>{applicationContext.translator("City")}</Form.Label>
                                            </Col>
                                            <Col className={"start-center-value"}>
                                                <Form.Control type="text"
                                                              name={"city"}
                                                              onChange={handleChange}
                                                              value={values.city}
                                                              isValid={touched.city && !errors.city}
                                                              isInvalid={touched.city && errors.city}
                                                              autoComplete="off"
                                                />
                                            </Col>
                                            <Col md={4}>

                                            </Col>
                                        </Form.Group>
                                        {touched.city && <Form.Group as={Row} className={"mb-3"}>
                                            <Col md={2}></Col>
                                            <Col>
                                                <Form.Text className="text-danger mt-2">
                                                    {errors.city}
                                                </Form.Text>
                                            </Col>
                                        </Form.Group>}
                                        <Form.Group as={Row} className={!touched.country && "mb-3"}>
                                            <Col md={2} className={"start-center-value"}>
                                                <Form.Label>{applicationContext.translator("Country")}</Form.Label>
                                            </Col>
                                            <Col className={"start-center-value"}>
                                                <Form.Control
                                                    as={"select"}
                                                    name={"country"}
                                                    onChange={async (e) => {
                                                        handleChange(e);
                                                        const cities = await CommonService.getCities(e.target.value);
                                                        let country = this.state.countries.filter((country) => country.name === e.target.value);
                                                        if(country.length > 0)
                                                            setFieldValue('country_code', country[0].phonecode);

                                                        this.setState({
                                                            cities
                                                        })
                                                    }}
                                                    value={values.country}
                                                    isValid={touched.country && !errors.country}
                                                    isInvalid={touched.country && errors.country}
                                                >
                                                    <option>Please select a country</option>
                                                    {this.state.countries.map((country, index) =>
                                                        <option key={index} value={country.name} >{country.name}</option>
                                                    )}
                                                </Form.Control>
                                            </Col>
                                            <Col md={4}>

                                            </Col>
                                        </Form.Group>
                                        {touched.country && <Form.Group as={Row} className={"mb-3"}>
                                            <Col md={2}></Col>
                                            <Col>
                                                <Form.Text className="text-danger mt-2">
                                                    {errors.country}
                                                </Form.Text>
                                            </Col>
                                        </Form.Group>}

                                        <Form.Group as={Row} className={!touched.phone_number && "mb-3"}>
                                            <Col md={2} className={"start-center-value"}>
                                                <Form.Label>{applicationContext.translator("Mobile Number")}</Form.Label>
                                            </Col>
                                            <Col className={"start-center-value"}>
                                                <Dropdown className={styled.CountryCodeDropDown}>
                                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                                        {values.country_code?
                                                            <React.Fragment>
                                                                <div className={`flag flag-${this.renderCountryCode(values.country_code)}`}></div> +{values.country_code}
                                                            </React.Fragment>
                                                            :<React.Fragment>
                                                                <div className={`flag flag-xs`} style={{ width: "1px" }}></div> + Code
                                                            </React.Fragment>}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu variant="dark" >
                                                        {this.state.countries.map((country, index) =>
                                                            <Dropdown.Item href="javascript:void(0)"
                                                                           onClick={(e) => setFieldValue('country_code', country.phonecode)}
                                                                           active={values.country_code.toString() === country.phonecode.toString()}>
                                                                <div className={`flag flag-${country.code.toLowerCase()}`}></div>+{country.phonecode}
                                                            </Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Form.Control type="text"
                                                              name={"phone_number"}
                                                              className={styled.CountryPhoneNumber}
                                                              onChange={handleChange}
                                                              value={values.phone_number}
                                                              isValid={touched.phone_number && !errors.phone_number}
                                                              isInvalid={touched.phone_number && errors.phone_number}
                                                              autoComplete="off"
                                                />
                                            </Col>
                                            <Col md={4}>

                                            </Col>
                                        </Form.Group>
                                        {touched.phone_number && <Form.Group as={Row} className={"mb-3"}>
                                            <Col md={2}></Col>
                                            <Col>
                                                <Form.Text className="text-danger mt-2">
                                                    {errors.phone_number}
                                                </Form.Text>
                                            </Col>
                                        </Form.Group>}
                                        <hr/>
                                    </React.Fragment>}

                                    <Form.Group as={Row} className={!touched.email && "mb-3"}>
                                        <Col md={2} className={"start-center-value"}>
                                            <Form.Label>{applicationContext.translator("E-Mail")}</Form.Label>
                                        </Col>
                                        <Col className={"start-center-value"}>
                                            <Form.Control type="text"
                                                          name={"email"}
                                                          onChange={handleChange}
                                                          value={values.email}
                                                          isValid={touched.email && !errors.email}
                                                          isInvalid={touched.email && errors.email}
                                                          autoComplete="off"
                                                          disabled={true}
                                            />
                                        </Col>
                                        <Col md={4} className={"start-center-value"}>
                                            {this.state.changeEmailLoading?<Spinner animation="grow" variant="light" size={"sm"} />:<NavLink to={"/"} onClick={this.handleChangeEmail} className={"normal-link uppercase"}>{applicationContext.translator("Request to change E-Mail")}</NavLink>}
                                        </Col>
                                    </Form.Group>
                                    {touched.email && <Form.Group as={Row} className={"mb-3"}>
                                        <Col md={2}></Col>
                                        <Col>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.email}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>
                                    }
                                    <Form.Group as={Row} className={!touched.password && "mb-3"}>
                                        <Col md={2} className={"start-center-value"}>
                                            <Form.Label>{applicationContext.translator("Password")}</Form.Label>
                                        </Col>
                                        <Col className={"start-center-value"}>
                                            <Form.Control type="password"
                                                          name={"password"}
                                                          onChange={handleChange}
                                                          value={"1234567"}
                                                          autoComplete="off"
                                                          disabled={true}
                                            />
                                        </Col>
                                        <Col md={4} className={"start-center-value"}>
                                            {this.state.changePasswordLoading?<Spinner animation="grow" variant="light" size={"sm"} />:<NavLink to={"/"} onClick={this.handleChangePassword} className={"normal-link uppercase"}>{applicationContext.translator("Request to change password")}</NavLink>}
                                        </Col>
                                    </Form.Group>

                                    {this.state.successfully && <Form.Group as={Row} className={"text-center mt-3"}>
                                        <Col md={2}></Col>
                                        <Col className={"text-center mb-3"}>
                                            <Form.Text className="text-success mt-2">
                                                {applicationContext.translator("PLEASE CHECK YOUR EMAIL")}
                                            </Form.Text>
                                        </Col>
                                        <Col md={4}></Col>
                                    </Form.Group>}
                                    {process.env.REACT_APP_NAME === "swissco.gaming" && <Form.Group as={Row} className={"d-flex justify-content-center"}>
                                        <Col md={2}></Col>
                                        <Col className={"start-center-value"}>
                                            <button className={"btn btn-account full-width"} type={"submit"}>{applicationContext.translator("Update Profile")}</button>
                                        </Col>
                                        <Col md={4}></Col>

                                    </Form.Group>}
                                </Form>
                            )}
                        </Formik>}
                    </div>
                </AccountContainer>
            </LoadingContainer>

        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(withProfileContext(Profile)))));