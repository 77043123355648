import React, {Component} from 'react';
import {HeaderBar,
    HeaderBarWrapper,
    HeaderLogo,
    HeaderLeft,
    HeaderRight,
    HeaderCenter,
    HeaderMenu,
    MenuBar,
    MenuBarItem,
    FloatMenu,
    FloatMenuContainer,
    FloatMenuItems,
    FloatMenuItem,
    CloseMenu,
    FloatMenuIcon,
    HeaderBalance
} from "./style.jsx";
import {Container, Row, Col} from 'react-bootstrap';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {NavLink, withRouter} from "react-router-dom";
import {withAccountContext} from "../../../contexts/AccountContext";
import {ROUTE_TABLE_LIVE_PLAY} from "../../../routers/routes";
import TokenService from "../../../services/token.service";
class AppHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false
        }
    }
    componentDidMount() {
        if(this.props.history.location.hash){
            let section = this.props.history.location.hash.replaceAll("#","");
            document.getElementById("section-" + section).scrollIntoView();
        }
    }

    onClickMenuIcon = (e) => {
        this.setState({
            menu: !this.state.menu
        })
    }
    onMoveToSection = (e, section) => {
        if(this.props.history.location.pathname === "/")
            document.getElementById("section-" + section).scrollIntoView();
    }
    render() {
        const {applicationContext, accountContext, history} = this.props;
        return (
            <HeaderBar>
                <Container style={{ height: "100%" }}>
                    <HeaderBarWrapper>
                        <FloatMenuIcon icon={"bars"} onClick={this.onClickMenuIcon}/>
                        <FloatMenu display={this.state.menu}>
                            <FloatMenuContainer>
                                <CloseMenu onClick={this.onClickMenuIcon}><FontAwesomeIcon icon={"times"} /></CloseMenu>
                                <FloatMenuItems>
                                    <FloatMenuItem><NavLink to={ROUTE_TABLE_LIVE_PLAY.concat("?lang="+applicationContext.state.lang)}>{applicationContext.translator("Play on live table")}</NavLink></FloatMenuItem>
                                    <FloatMenuItem><NavLink to={"/#practice"} onClick={(e) => this.onMoveToSection(e, "practice")}>{applicationContext.translator("Practice Table")}</NavLink></FloatMenuItem>
                                    <FloatMenuItem><NavLink to={"/#about"} onClick={(e) => this.onMoveToSection(e, "about")}>{applicationContext.translator("About")}</NavLink></FloatMenuItem>
                                    <FloatMenuItem><NavLink to={"/#contact"} onClick={(e) => this.onMoveToSection(e, "contact")}>{applicationContext.translator("Contact")}</NavLink></FloatMenuItem>
                                    <FloatMenuItem><NavLink to={"/#features"} onClick={(e) => this.onMoveToSection(e, "features")}>{applicationContext.translator("Features & Interface")}</NavLink></FloatMenuItem>
                                    {/*<FloatMenuItem><NavLink to={"/#partners"} onClick={(e) => this.onMoveToSection(e, "partners")}>{applicationContext.translator("Our partners")}</NavLink></FloatMenuItem>*/}
                                    {applicationContext.state.userInfo && <FloatMenuItem><a href={process.env.REACT_APP_LIVE_URL.replaceAll("/live/play", "").concat("/account/profile?lang="+applicationContext.state.lang)}>{applicationContext.translator("My Account").replaceAll("<br/>", " ")}</a></FloatMenuItem>}
                                    {applicationContext.state.userInfo && <FloatMenuItem><NavLink to={"#"}>{applicationContext.translator("Logout")}</NavLink></FloatMenuItem>}
                                    {!applicationContext.state.userInfo && <FloatMenuItem><a href={process.env.REACT_APP_LIVE_URL.replaceAll("/live/play", "").concat("/account/register?lang="+applicationContext.state.lang)}>{applicationContext.translator("Register")}</a></FloatMenuItem>}
                                    {!applicationContext.state.userInfo && <FloatMenuItem><a href={process.env.REACT_APP_LIVE_URL.replaceAll("/live/play", "").concat("/account/login?lang="+applicationContext.state.lang)}>{applicationContext.translator("Log In")}</a></FloatMenuItem>}
                                </FloatMenuItems>
                            </FloatMenuContainer>

                        </FloatMenu>
                        <HeaderLeft>
                            <HeaderMenu>
                                <MenuBar>
                                    {/*<MenuBarItem active={false} first={true}><NavLink to={ROUTE_TABLE_LIVE_PLAY.concat("?lang="+applicationContext.state.lang)}>{applicationContext.translator("Play on live table")}</NavLink></MenuBarItem>*/}
                                    {!applicationContext.state.userInfo && <MenuBarItem active={false}><a href={process.env.REACT_APP_LIVE_URL.replaceAll("/live/play", "").concat("/account/register?lang="+applicationContext.state.lang)}>{applicationContext.translator("Register")}</a></MenuBarItem>}
                                    {!applicationContext.state.userInfo && <MenuBarItem active={false} ><a href={process.env.REACT_APP_LIVE_URL.replaceAll("/live/play", "").concat("/account/login?lang="+applicationContext.state.lang)}>{applicationContext.translator("Log In")}</a></MenuBarItem>}
                                    <MenuBarItem active={false}><NavLink to={"/#features"} onClick={(e) => this.onMoveToSection(e, "features")}>{applicationContext.translator("Features & Interface")}</NavLink></MenuBarItem>


                                </MenuBar>
                            </HeaderMenu>
                        </HeaderLeft>
                        <HeaderCenter>
                            <NavLink to={"/"}>
                                {process.env.REACT_APP_NAME === "my.roulette"?
                                    <HeaderLogo src={"/assets/img/my-roulette-header-logo.png"} />:
                                    <HeaderLogo src={"/assets/img/logo.png"} />}
                            </NavLink>
                        </HeaderCenter>
                        <HeaderRight>
                            <HeaderMenu>
                                <MenuBar>
                                    <MenuBarItem active={false} ><NavLink to={"/#practice"} onClick={(e) => this.onMoveToSection(e, "practice")}>{applicationContext.translator("Practice Table")}</NavLink></MenuBarItem>
                                    <MenuBarItem><NavLink to={"/#about"} onClick={(e) => this.onMoveToSection(e, "about")}>{applicationContext.translator("About")}</NavLink></MenuBarItem>
                                    <MenuBarItem><NavLink to={"/#contact"} onClick={(e) => this.onMoveToSection(e, "contact")}>{applicationContext.translator("Contact")}</NavLink></MenuBarItem>
                                    {/*<MenuBarItem><NavLink to={"/#partners"} onClick={(e) => this.onMoveToSection(e, "partners")}>{applicationContext.translator("Our partners")}</NavLink></MenuBarItem>*/}
                                    {applicationContext.state.userInfo && <MenuBarItem active={false}><a href={process.env.REACT_APP_LIVE_URL.replaceAll("/live/play", "").concat("/account/profile?lang="+applicationContext.state.lang)}>{applicationContext.translator("My Account").replaceAll("<br/>", " ")}</a></MenuBarItem>}
                                    {applicationContext.state.userInfo && <MenuBarItem><NavLink to={"#"} onClick={applicationContext.onLogout}>{applicationContext.translator("Logout")}</NavLink></MenuBarItem>}

                                </MenuBar>
                            </HeaderMenu>

                        </HeaderRight>
                    </HeaderBarWrapper>
                </Container>
            </HeaderBar>
        );
    }
}

export default withApplicationContext((withAccountContext(withRouter(AppHeader))));