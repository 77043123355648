import React, {Component} from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import {withApplicationContext} from "../contexts/ApplicationContext"
import {AppContainer} from "../components/styles/AppStyle";
import Loading from "../components/common/Loading";
import {
    ROUTE_ADMIN_CEG_PLAYED_ROUND_WITH_TOTAL_BETS_AND_RESULT,
    ROUTE_ADMIN_CEG_ROUND_AND_WINNING_NUMBERS,
    ROUTE_ADMIN_CEG_SEARCH_PLAYER_ACCOUNT,
    ROUTE_ADMIN_CEG_SELECTED_ROUND,
    ROUTE_ADMIN_DASHBOARD,
    ROUTE_ADMIN_LOGIN,
    ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS,
    ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS_SELECTED,
    ROUTE_ADMIN_MONTHLY_DAILY_WITHDRAWALS,
    ROUTE_ADMIN_MONTHLY_DAILY_WITHDRAWALS_SELECTED,
    ROUTE_ADMIN_PROFILE_DEPOSIT_HISTORY,
    ROUTE_ADMIN_PROFILE_WITHDRAWAL_HISTORY,
    ROUTE_ADMIN_RANKING_DEPOSIT,
    ROUTE_ADMIN_RANKING_WITHDRAWAL,
    ROUTE_ADMIN_ROUND_OVERVIEW,
    ROUTE_ADMIN_ROUND_SELECTED_OVERVIEW,
    ROUTE_ADMIN_USER_ACCOUNT_CASH_IN,
    ROUTE_ADMIN_USER_ACCOUNT_CASH_OUT,
    ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW,
    ROUTE_ADMIN_USER_ACCOUNT_PLAYED,
    ROUTE_ADMIN_USER_ACCOUNT_PROFILE,
    ROUTE_ADMIN_USERS_LIST,
    ROUTE_ADMIN_USERS_SEARCH,
    ROUTE_ADMIN_WAIT_FOR_APPROVAL_WITHDRAWALS,
    ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW,
    ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW_SELECTED,
    ROUTE_ADMIN_ACCOUNT_CHARGE_CREDIT,
    ROUTE_ADMIN_ENTER_MANUALLY_WINNING_NUMBER,
    ROUTE_ADMIN_ROUND_AND_WINNING_NUMBERS,
    ROUTE_ADMIN_TOP37_PAYOUTS,
    ROUTE_ADMIN_SEND_EMAIL,
    ROUTE_ADMIN_WAIT_FOR_APPROVAL_DEPOSITS,
    ROUTE_ADMIN_USER_ACCOUNT_LOGON, ROUTE_ADMIN_WAIT_FOR_ANSWER_TICKETS, ROUTE_ADMIN_TICKET_SELECTED
} from "./routes";
import Users from "../scenes/Admin/Users";
import Search from "../scenes/Admin/Search";
import CEGSearch from "../scenes/Admin/CEGSearch";
import Deposits from "../scenes/Admin/Deposits";
import Withdrawals from "../scenes/Admin/Withdrawals";
import TransferCreditToAccount from "../scenes/Admin/TransferCreditToAccount";
import CashInRanking from "../scenes/Admin/Ranking/CashInRanking";
import CashOutRanking from "../scenes/Admin/Ranking/CashOutRanking";
import AdminAuthRoute from "./AdminAuthRoute";
import Login from "../scenes/Admin/Login";
import Dashboard from "../scenes/Admin/Dashboard";
import UserOverview from "../scenes/Admin/Overview";
import PlayedHistory from "../scenes/Admin/PlayedHistory/PlayedHistory";
import Profile from "../scenes/Admin/Profile/Profile";
import {withAdminAccountContext} from "../contexts/AdminAccountContext";
import {RoundWinningNumber} from "../scenes/Admin/RoundWinningNumber";
import {SelectedRoundWinningNumber} from "../scenes/Admin/RoundWinningNumber";
import {RoundBetWinningResultNumber} from "../scenes/Admin/RoundWinningNumber";
import {
    MonthlyDailyDeposits,
    MonthlyDailyWithdrawals,
    SelectedDailyDeposits,
    SelectedDailyWithdrawals
} from "../scenes/Admin/Reports";
import YearlyMonthlyOverview from "../scenes/Admin/Reports/YearlyMonthlyOverview/YearlyMonthlyOverview";
import WaitForApprovalWithdrawals from "../scenes/Admin/Withdrawals/WaitForApprovalWithdrawals";
import EnterManuallyWinningNumber from "../scenes/Admin/EnterManuallyWinningNumber";
import Top37Payouts from "../scenes/Admin/Top37Payouts";
import SendEmail from "../scenes/Admin/SendEmail";
import WaitForApprovalDeposits from "../scenes/Admin/Deposits/WaitForApprovalDeposits";
import LogonHistory from "../scenes/Admin/LogonHistory";
import UsersTickets from "../scenes/Admin/Ticketing/UsersTickets";
import SelectedTicket from "../scenes/Admin/Ticketing/SelectedTicket";
class AdminRouter extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {applicationContext, accountContext} = this.props;
        return (
            <React.Fragment>
                <AppContainer direction={applicationContext.translator("direction")}>
                    {(applicationContext.state.loading)?(<Loading />):(
                        <Switch>
                            <Route path={ROUTE_ADMIN_LOGIN} render={(props) => (<Login />)} />
                            <AdminAuthRoute path={`${ROUTE_ADMIN_PROFILE_DEPOSIT_HISTORY}`} render={(props) => (<Deposits />)} />
                            <AdminAuthRoute path={`${ROUTE_ADMIN_PROFILE_WITHDRAWAL_HISTORY}`} render={(props) => (<Withdrawals />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_DASHBOARD} render={(props) => (<Dashboard />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_USERS_LIST} render={(props) => (<Users />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_USERS_SEARCH} render={(props) => (<Search />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_RANKING_DEPOSIT} render={(props) => (<CashInRanking />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_RANKING_WITHDRAWAL} render={(props) => (<CashOutRanking />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW} render={(props) => (<UserOverview />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_USER_ACCOUNT_CASH_IN} render={(props) => (<Deposits />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_USER_ACCOUNT_CASH_OUT} render={(props) => (<Withdrawals />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_USER_ACCOUNT_PLAYED} render={(props) => (<PlayedHistory />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_USER_ACCOUNT_LOGON} render={(props) => (<LogonHistory />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_USER_ACCOUNT_PROFILE} render={(props) => (<Profile />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_CEG_SEARCH_PLAYER_ACCOUNT} render={(props) => (<CEGSearch />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_CEG_ROUND_AND_WINNING_NUMBERS} render={(props) => (<RoundWinningNumber />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_ROUND_AND_WINNING_NUMBERS} render={(props) => (<RoundWinningNumber />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_CEG_PLAYED_ROUND_WITH_TOTAL_BETS_AND_RESULT} render={(props) => (<RoundBetWinningResultNumber />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_CEG_SELECTED_ROUND} render={(props) => (<SelectedRoundWinningNumber />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_ROUND_SELECTED_OVERVIEW} render={(props) => (<SelectedRoundWinningNumber />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_ROUND_OVERVIEW} render={(props) => (<RoundBetWinningResultNumber />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS_SELECTED} render={(props) => (<SelectedDailyDeposits />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS} render={(props) => (<MonthlyDailyDeposits />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_MONTHLY_DAILY_WITHDRAWALS_SELECTED} render={(props) => (<SelectedDailyWithdrawals />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_MONTHLY_DAILY_WITHDRAWALS} render={(props) => (<MonthlyDailyWithdrawals />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW_SELECTED} render={(props) => (<YearlyMonthlyOverview />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW} render={(props) => (<YearlyMonthlyOverview />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_WAIT_FOR_APPROVAL_WITHDRAWALS} render={(props) => (<WaitForApprovalWithdrawals />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_WAIT_FOR_APPROVAL_DEPOSITS} render={(props) => (<WaitForApprovalDeposits />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_ACCOUNT_CHARGE_CREDIT} render={(props) => (<TransferCreditToAccount />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_ENTER_MANUALLY_WINNING_NUMBER} render={(props) => (<EnterManuallyWinningNumber />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_TOP37_PAYOUTS} render={(props) => (<Top37Payouts />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_SEND_EMAIL} render={(props) => (<SendEmail />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_WAIT_FOR_ANSWER_TICKETS} render={(props) => (<UsersTickets />)} />
                            <AdminAuthRoute path={ROUTE_ADMIN_TICKET_SELECTED} render={(props) => (<SelectedTicket />)} />




                        </Switch>
                    )}

                </AppContainer>
            </React.Fragment>
        );
    }
}

export default withApplicationContext(withAdminAccountContext(withRouter(AdminRouter)));