import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import PanelContainer from "../PanelContainer";
import AccountTable from "../../../components/ui/AccountTable/AccountTable";
import AdminService from "../../../services/admin.service";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import {
    ROUTE_ADMIN_CEG_SELECTED_ROUND,
    ROUTE_ADMIN_ROUND_SELECTED_OVERVIEW,
    ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW
} from "../../../routers/routes";
import {numberWithCommas, timezone, utcTime} from "../../../helpers/helpers";
import moment from "moment-timezone";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import PageTitleContainer from "../Search/PageTitleContainer";
import RoundBetWinningResultNumberSearch from "./RoundBetWinningResultNumberSearch";
import MonthlyDailyWithdrawalsSearch from "../Reports/MonthlyDailyWithdrawals/MonthlyDailyWithdrawalsSearch";
class RoundBetWinningResultNumber extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            searchComponent: null,
            search: {
                round_number: "",
                account_code: "",
                start_date: "",
                end_date: "",
                time_hour: "",
                time_min: "",
            }
        }
    }
    componentDidMount() {
        if(localStorage.getItem('backed_from_account_state')){
            let state = JSON.parse(localStorage.getItem('backed_from_account_state'));
            localStorage.removeItem('backed_from_account_state');
            this.setState(state)
        }
    }

    onLoadSelectedRound = (e, row) => {
        const {accountContext, history} = this.props;
        e.preventDefault();
        if(accountContext.path.includes("/ceg/"))
            accountContext.doGoto(ROUTE_ADMIN_CEG_SELECTED_ROUND, ":roundNumber", row['round_number'], this.state, this.refTable.getState());
        else
            accountContext.doGoto(ROUTE_ADMIN_ROUND_SELECTED_OVERVIEW, ":roundNumber", row['round_number'], this.state, this.refTable.getState());
    }
    doUpdateSearchData = (data) => {
        this.setState({
            ...this.state,
            search: data
        })
    }
    render() {
        return (
            <PanelContainer>
                <PageTitleContainer searchBar={this.state.searchComponent}/>
                <div className={"form-center"}>
                    <AccountTable ref={(ref) => this.refTable = ref} full={true} loadData={AdminService.getRoundAndWinningHistory} sort={"id"} align={"desc"} header={(onSort, sort, align) => {
                        return <TableRow>
                            <TableColHeader>Round#</TableColHeader>
                            <TableColHeader>Date</TableColHeader>
                            <TableColHeader id={"table-cell-05"}>Time</TableColHeader>
                            <TableColHeader id={"table-cell-04"}>Winning#</TableColHeader>
                            <TableColHeader width={160} id={"table-cell-03"}>Total Bet</TableColHeader>
                            <TableColHeader id={"table-cell-02"}>Total Pay-out</TableColHeader>
                            <TableColHeader id={"table-cell-01"}>House Balance</TableColHeader>
                        </TableRow>
                    }}
                       render={(row, index, number) =>
                           <TableRow key={index} onClick={(e) => this.onLoadSelectedRound(e, row)}>
                               <TableCol yellow={true}>{row['round_number']}</TableCol>
                               <TableCol>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableCol>
                               <TableCol>{row['round_time']}</TableCol>
                               <TableCol yellow={true}>{row['number']}</TableCol>
                               <TableCol><span>{numberWithCommas(row['total_bet'], true)}</span></TableCol>
                               <TableCol><span>{numberWithCommas(row['total_pay_out'], true)}</span></TableCol>
                               <TableCol><span className={(row['total_bet'] - row['total_pay_out'] >= 0)?"text-success":"text-danger"}>{numberWithCommas(row['total_bet'] - row['total_pay_out'], true)}</span></TableCol>
                           </TableRow>
                       }
                       mobile={(row, index, number, total, onNext, onPrevious) =>
                           <TableMobileRow key={index}>
                               <TableMobileCol>
                                   <label>Round#</label>
                                   <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{row['round_number']}</TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Date</label>
                                   <TableColValue>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Time</label>
                                   <TableColValue>{utcTime(row['created_at']).format('HH:mm:ss')}</TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Winning#</label>
                                   <TableColValue>{row['number']}</TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Total Bet</label>
                                   <TableColValue><span className={"yellow-text"}>{numberWithCommas(row['total_bet'], true)}</span></TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Total Pay-out</label>
                                   <TableColValue><span className={"yellow-text"}>{numberWithCommas(row['total_pay_out'], true)}</span></TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>House Balance</label>
                                   <TableColValue><span className={(row['total_bet'] - row['total_pay_out'] >= 0)?"text-success":"text-danger"}>{numberWithCommas(row['total_bet'] - row['total_pay_out'], true)}</span></TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <TableColValue>
                                       <button onClick={(e) => this.onLoadSelectedRound(e, row)}>View user profile ...</button>
                                   </TableColValue>
                               </TableMobileCol>
                           </TableMobileRow>}
                       search={this.state.search}
                       loaded={() => {
                           this.setState({
                               searchComponent: <RoundBetWinningResultNumberSearch searchData={this.state.search} doUpdateSearchData={this.doUpdateSearchData} refTable={this.refTable} />
                           })
                       }}
                    />
                </div>
            </PanelContainer>
        )
    }
}

export default withRouter(withAdminAccountContext(RoundBetWinningResultNumber));