import React, {Component} from 'react';
import {withApplicationContext} from "../../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../../contexts/AccountContext";
import {withRouter} from "react-router-dom"
import {Formik} from "formik";
import * as Yup from "yup";
import {Alert, Col, Form, FormText, InputGroup, Row} from "react-bootstrap";
import AccountContainer from "../../AccountContainer";
import LoadingContainer from "../../../../components/common/LoadingContainer";
import {withAlert} from "react-alert";
import styles from "./DeleteAccount.module.css";
import Popup from "../../../../components/ui/Popup";
import UserService from "../../../../services/user.service";
class DeleteAccount extends Component {
    timer = null;
    constructor(props) {
        super(props);
        this.state = {
            reason: "",
            period: "",
            successfully: false,
            disabled: true,
            timer: ""
        }
    }
    handleSubmit = async (values, actions) => {
        const {alert, applicationContext, accountContext} = this.props;
        try{
            this.setState({
                disabled: true
            })
            const result = await UserService.doUpdateSettings({
                action: "exclusion",
                period: values.period,
                reason: values.reason
            });
            accountContext.updateUserInfo(result.data)
            this.handleUpdateEnable(result.data.settings)
            alert.success(applicationContext.translator("Your account exclusion has been updated successfully"));

            actions.resetForm()
        }catch (e){
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, e.response.data.errors[field][0])
            })

        }


    }
    componentDidMount() {
        const {accountContext} = this.props;
        this.handleUpdateEnable(accountContext.userInfo.settings)
    }
    handleUpdateEnable = (data) => {
        if(!data){
            return this.setState({
                disabled: false
            })
        }
        if(data['exclusion_end_at']){
            if(new Date(data['exclusion_end_at']).getTime() < new Date().getTime()){
                return this.setState({
                    disabled: false,
                    period: data['exclusion_period']
                })
            }else{
                let compareDate = new Date(data['exclusion_end_at']);
                compareDate.setDate(compareDate.getDate()); //just for this demo today + 7 days
                let self = this;
                this.timer = setInterval(function() {
                    self.timeBetweenDates(compareDate);
                }, 1000);
                return this.setState({
                    disabled: true,
                    period: data['exclusion_period']
                })
            }
        }
        return this.setState({
            disabled: false,
            period: data['exclusion_period']
        })
    }
    timeBetweenDates = (toDate) => {
        const {applicationContext} = this.props;
        let dateEntered = toDate;
        let now = new Date();
        let difference = dateEntered.getTime() - now.getTime();

        if (difference <= 0) {
            clearInterval(this.timer);
        } else {
            let seconds = Math.floor(difference / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);

            hours %= 24;
            minutes %= 60;
            seconds %= 60;
            let out = "";
            if(days === 1)
                out += "1 "+applicationContext.translator("day")+" ";
            else if(days > 1)
                out += days+" "+applicationContext.translator("days")+" ";
            if(hours < 10) hours = "0"+hours;
            if(minutes < 10) minutes = "0"+minutes;
            if(seconds < 10) seconds = "0"+seconds;
            out += hours+":"+minutes+":"+seconds;
            this.setState({
                timer: out
            })
        }
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        const {accountContext, applicationContext} = this.props;
        return (
            <LoadingContainer loading={accountContext.loading}>
                <AccountContainer>
                    <div className={"form-center"}>

                        {!accountContext.loading && <Formik
                            innerRef={(ref) => this.refForm = ref}
                            enableReinitialize={true}
                            initialValues={this.state}
                            validationSchema={Yup.object().shape({
                                period: Yup.string().required(applicationContext.translator("You must select your time period")),
                                reason: Yup.string().required(applicationContext.translator("You must enter your reason")).min(2, "Your reason must be minimum 2 characters")
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit} className={styles.FullWidth}>
                                    <Form.Group as={Row} >
                                        <Col md={12} className={"text-center"}>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <div className={"text-left mb-3"} style={{fontSize: "15px", textAlign: "justify"}}>
                                                    {applicationContext.translator("If you feel at risk of developing a gambling problem, we advise you to self-exclusion for period of time.")}
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group as={Row} className={!touched.period && "mb-3"}>
                                                <Col className={"text-left start-center-value"}>
                                                    <Form.Control
                                                        as={"select"}
                                                        name={"period"}
                                                        disabled={this.state.disabled}
                                                        value={values.period}
                                                        onChange={handleChange}
                                                        className={errors.period && "is-invalid"}
                                                    >
                                                        <option value={""}>{applicationContext.translator("Please select time period")}</option>
                                                        <option value={"6"}>{applicationContext.translator("6 Months")}</option>
                                                        <option value={"12"}>{applicationContext.translator("1 Year")}</option>
                                                        <option value={"24"}>{applicationContext.translator("2 Years")}</option>
                                                        <option value={"36"}>{applicationContext.translator("3 Years")}</option>
                                                        <option value={"48"}>{applicationContext.translator("4 Years")}</option>
                                                        <option value={"60"}>{applicationContext.translator("5 Years")}</option>

                                                    </Form.Control>

                                                </Col>
                                            </Form.Group>
                                            {touched.period && <Form.Group as={Row} className={"mb-3"}>
                                                <Col className={"text-left"}>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.period}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}
                                            {values.period && <Form.Group as={Row} className={!touched.reason && "mb-3"}>
                                                <Col className={"text-left"}>
                                                    <Form.Control as="textarea" rows={5}
                                                                  onChange={handleChange}
                                                                  disabled={this.state.disabled}
                                                                  name={"reason"}
                                                                  placeholder={applicationContext.translator("Please enter your self exclusion reason")}
                                                                  isValid={touched.reason && !errors.reason}
                                                                  isInvalid={touched.reason && errors.reason}
                                                                  value={values.message}
                                                    />
                                                </Col>
                                            </Form.Group>}
                                            {values.period && touched.reason && <Form.Group as={Row} className={"mb-3"}>
                                                <Col className={"text-left"}>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.reason}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}

                                            <Form.Group className={"d-flex justify-content-center"}>
                                                <button disabled={this.state.disabled} className={"btn btn-account"} type={"submit"}>{applicationContext.translator("UPDATE")}</button>
                                            </Form.Group>
                                            {this.state.disabled && <div className={styles.Timer}>
                                                <div className={"no-letter-space"}>{applicationContext.translator("You can change your settings in next")}</div>
                                                {this.state.timer}
                                            </div>}

                                        </Col>



                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>}
                    </div>
                </AccountContainer>
            </LoadingContainer>
        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(DeleteAccount))));