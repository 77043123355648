import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import PanelContainer from "../PanelContainer";
import {Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AccountTable from "../../../components/ui/AccountTable/AccountTable";
import AdminService from "../../../services/admin.service";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import {ROUTE_ADMIN_CEG_SELECTED_ROUND} from "../../../routers/routes";
import {numberWithCommas, timezone, utcTime} from "../../../helpers/helpers";
import moment from "moment-timezone";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import Calendar from "react-calendar";
import styles from "../Journal/Journal.module.css";
import PageTitleContainer from "../Search/PageTitleContainer";
import RoundWinningNumberSearch from "./RoundWinningNumberSearch";
class RoundWinningNumber extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            searchComponent: null
        }
    }
    render() {
        return (
            <PanelContainer>
                <PageTitleContainer center={true}/>
                {this.state.searchComponent}
                <div className={"form-center"}>
                    <AccountTable width={"50%"} ref={(ref) => this.refTable = ref} loadData={AdminService.getRoundAndWinningHistory} sort={"id"} align={"desc"} header={(onSort, sort, align) => {
                        return <TableRow>
                            <TableColHeader id={"table-cell-01"}>Round#</TableColHeader>
                            <TableColHeader id={"table-cell-02"}>Date</TableColHeader>
                            <TableColHeader id={"table-cell-03"}>Time</TableColHeader>
                            <TableColHeader id={"table-cell-04"}>Winning#</TableColHeader>
                        </TableRow>
                    }}
                       render={(row, index, number) =>
                           <TableRow key={index}>
                               <TableCol yellow={true}>{row['round_number']}</TableCol>
                               <TableCol>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableCol>
                               <TableCol>{row['round_time']}</TableCol>
                               <TableCol yellow={true}>{row['number']}</TableCol>
                           </TableRow>
                       }
                       mobile={(row, index, number, total, onNext, onPrevious) =>
                           <TableMobileRow key={index}>
                               <TableMobileCol>
                                   <label>Round#</label>
                                   <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{row['round_number']}</TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Date</label>
                                   <TableColValue>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Time</label>
                                   <TableColValue>{utcTime(row['created_at']).format('HH:mm:ss')}</TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Winning#</label>
                                   <TableColValue>{row['number']}</TableColValue>
                               </TableMobileCol>
                           </TableMobileRow>}
                       search={this.state.search}
                       params={{
                           has_empty: 1
                       }}
                       loaded={() => {
                           this.setState({
                               searchComponent: <RoundWinningNumberSearch refTable={this.refTable}/>
                           })
                       }}
                    />
                </div>
            </PanelContainer>
        )
    }
}

export default withRouter(withAdminAccountContext(RoundWinningNumber));