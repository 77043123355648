import React, {Component} from 'react';
import styles from "./Login.module.css";
import baseStyles from "../../../assets/css/App.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withGameTableContext} from "../../../contexts/GameTableContext";
import * as Yup from "yup";
import {Col, Dropdown, Form, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {NavLink, withRouter} from "react-router-dom"
import {
    ROUTE_ADMIN_DASHBOARD,
    ROUTE_FORGET_PASSWORD, ROUTE_LOGIN,
    ROUTE_REGISTER,
    ROUTE_USER_PROFILE
} from "../../../routers/routes";
import classNames from "classnames";
import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import {age, parseErrorMessage} from "../../../helpers/helpers";
import {withAlert} from "react-alert";
import TokenService from "../../../services/token.service";
import Footer from "../../../components/ui/Footer";
import MaxFooter from "../Login/MaxFooter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CommonService from "../../../services/common.service";
import {HOME_WEBSITE} from "../../../helpers/constants";
import moment from "moment";

class RegisterComplete extends Component {
    refForm = React.createRef();
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            successfully: false,
            close: false,
            loading: true,
            submitting: false,
            userInfo: null,
            countries: [],
            cities: [],
            country_code: null,
            token: null
        }

    }
    componentDidMount = async () =>{
        const {history, level, location, applicationContext, alert} = this.props;
        const urlSearchParams = new URLSearchParams(location.search);
        if(urlSearchParams.has('token')){
            TokenService.removeUser();
            let token = JSON.parse(atob(urlSearchParams.get('token')));
            if(token.hasOwnProperty('access_token')){
                const userInfo = await UserService.getUserInfoByToken(token['access_token']);
                if(!userInfo) history.push(ROUTE_REGISTER);
                if(userInfo.password) history.push(ROUTE_REGISTER);

                const countries = await CommonService.getCountries();
                const cities = await CommonService.getCities(userInfo['country']);
                let country_code = userInfo['country_code'];
                if(!country_code){
                    let country = countries.filter((country) => country.name === userInfo['country']);
                    if(country.length > 0)
                        country_code = country[0].phonecode;
                }
                this.setState({
                    userInfo,
                    loading: false,
                    countries,
                    cities,
                    country_code,
                    token
                })
            }else{
                history.push(ROUTE_REGISTER);
            }
        }
    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, alert, history, level} = this.props;
        if(this.state.submitting) return;
        this.setState({submitting: true});
        try {
            const user = await AuthService.registerComplete(this.state.token['access_token'], values);
            TokenService.updateLocalAccessToken(this.state.token);
            const userInfo = await UserService.getUserBoard();
            if(userInfo){
                applicationContext.updateUserInfo(userInfo);
                history.push(ROUTE_USER_PROFILE)
            }

        } catch (e) {
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, e.response.data.errors[field][0])
            })
        }
        this.setState({submitting: false})
    }
    renderCountryCode = (code) => {
        let country_code = this.state.countries.filter((r) => r.phonecode.toString() === code.toString())
        if(country_code.length > 0)
            return country_code[0].code.toLowerCase()
    }
    render() {
        return (
            <div className={styles.FullHeight}>
                {this.state.loading?
                    <div className={styles.LoadingContainer}>
                        {process.env.REACT_APP_NAME === "swissco.gaming" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} width={150}/>}
                        {process.env.REACT_APP_NAME === "my.roulette" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo-my.roulette.png`} />}
                    </div>:
                    <Formik
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={{
                        email: this.state.userInfo['email'],
                        first_name: (this.state.userInfo['first_name'])?this.state.userInfo['first_name']:"",
                        last_name: (this.state.userInfo['last_name'])?this.state.userInfo['last_name']:"",
                        address: (this.state.userInfo['address'])?this.state.userInfo['address']:"",
                        zip_code: (this.state.userInfo['zip_code'])?this.state.userInfo['zip_code']:"",
                        country: (this.state.userInfo['country'])?this.state.userInfo['country']:"",
                        city: (this.state.userInfo['city'])?this.state.userInfo['city']:"",
                        country_code: (this.state.country_code)?this.state.country_code:"",
                        phone_number: (this.state.userInfo['phone_number'])?this.state.userInfo['phone_number']:"",
                        password: this.state.password,
                        password_confirmation: this.state.password_confirmation,
                        is_agree_rules: false
                    }}
                    validationSchema={Yup.object().shape({
                        first_name: Yup.string().required("You must enter your ${path}").label("FirstName"),
                        last_name: Yup.string().required("You must enter your ${path}").label("LastName"),
                        address: Yup.string().required("You must enter your ${path}").label("Address"),
                        zip_code: Yup.string().required("You must enter your ${path}").label("ZipCode"),
                        country: Yup.string().required("You must select your ${path}").label("Country"),
                        city: Yup.string().required("You must enter your ${path}").label("City"),
                        country_code: Yup.string().required("You must select your ${path}").label("Country Code"),
                        phone_number: Yup.string()
                            .required("You must enter your ${path}")
                            .label("Phone Number")
                            .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, '${path} is not valid'),
                        password: Yup.string().required("You must enter your ${path}").label("Password").min(6),
                        password_confirmation: Yup.string().required("You must enter your ${path}").label("Repeat Password").oneOf([Yup.ref('password'), null], 'Passwords must match'),
                        is_agree_rules: Yup.bool().oneOf([true], "If you want complete your registration must read and agree terms & conditions and privacy policy.")
                    })}
                    onSubmit={this.handleSubmit}
                >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                  }) => (
                     <React.Fragment>
                         <div className={classNames(styles.WebsiteLoginContainer, styles.RegisterComplete)}>

                                 <div className={styles.LoginWrapper}>
                                     <div className={"text-center mt-2"}>
                                         {process.env.REACT_APP_NAME === "swissco.gaming" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} width={150}/>}
                                         {process.env.REACT_APP_NAME === "my.roulette" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo-my.roulette.png`} />}
                                     </div>
                                     <div className={styles.LoginBody}>
                                         <h5>Please complete the registration form</h5>

                                         <Form onSubmit={handleSubmit}>
                                             <Form.Group as={Row} className={"mb-3"}>
                                                 <Col className={styles.StartCenterValue}>
                                                     <Form.Control type="text"
                                                                   name={"email"}
                                                                   placeholder={"E-Mail Address"}
                                                                   value={values.email}
                                                                   disabled={true}
                                                                   autoComplete="off"
                                                     />
                                                 </Col>
                                             </Form.Group>
                                             <Form.Group as={Row} className={"mb-3"}>
                                                 <Col className={styles.StartCenterValue}>
                                                     <Form.Control type="text"
                                                                   name={"birthdate"}
                                                                   value={moment(new Date(this.state.userInfo['birth_date'])).format('MMMM DD, YYYY')+ " ("+age(this.state.userInfo['birth_date'])+" years old)"}
                                                                   disabled={true}
                                                                   autoComplete="off"
                                                     />
                                                 </Col>
                                             </Form.Group>
                                             <React.Fragment>
                                                 <Form.Group as={Row} className={!touched.first_name && "mb-3"}>
                                                     <Col className={"start-center-value"}>
                                                         <Form.Control type="text"
                                                                       name={"first_name"}
                                                                       onChange={handleChange}
                                                                       placeholder={"First Name"}
                                                                       value={values.first_name}
                                                                       isValid={touched.first_name && !errors.first_name}
                                                                       isInvalid={touched.first_name && errors.first_name}
                                                                       autoComplete="off"
                                                         />
                                                     </Col>
                                                 </Form.Group>
                                                 {touched.first_name && <Form.Group as={Row} className={"mb-3"}>
                                                     <Col>
                                                         <Form.Text className="text-danger mt-2">
                                                             {errors.first_name}
                                                         </Form.Text>
                                                     </Col>
                                                 </Form.Group>}
                                                 <Form.Group as={Row} className={!touched.last_name && "mb-3"}>
                                                     <Col className={"start-center-value"}>
                                                         <Form.Control type="text"
                                                                       name={"last_name"}
                                                                       onChange={handleChange}
                                                                       placeholder={"Last Name"}
                                                                       value={values.last_name}
                                                                       isValid={touched.last_name && !errors.last_name}
                                                                       isInvalid={touched.last_name && errors.last_name}
                                                                       autoComplete="off"
                                                         />
                                                     </Col>
                                                 </Form.Group>
                                                 {touched.last_name && <Form.Group as={Row} className={"mb-3"}>
                                                     <Col>
                                                         <Form.Text className="text-danger mt-2">
                                                             {errors.last_name}
                                                         </Form.Text>
                                                     </Col>
                                                 </Form.Group>}
                                                 <Form.Group as={Row} className={!touched.address && "mb-3"}>
                                                     <Col className={"start-center-value"}>
                                                         <Form.Control type="text"
                                                                       name={"address"}
                                                                       onChange={handleChange}
                                                                       value={values.address}
                                                                       placeholder={"Address"}
                                                                       isValid={touched.address && !errors.address}
                                                                       isInvalid={touched.address && errors.address}
                                                                       autoComplete="off"
                                                         />
                                                     </Col>
                                                 </Form.Group>
                                                 {touched.address && <Form.Group as={Row} className={"mb-3"}>
                                                     <Col>
                                                         <Form.Text className="text-danger mt-2">
                                                             {errors.address}
                                                         </Form.Text>
                                                     </Col>
                                                 </Form.Group>}
                                                 <Form.Group as={Row} className={!touched.zip_code && "mb-3"}>
                                                     <Col className={"start-center-value"}>
                                                         <Form.Control type="text"
                                                                       name={"zip_code"}
                                                                       onChange={handleChange}
                                                                       value={values.zip_code}
                                                                       placeholder={"Zip Code"}
                                                                       isValid={touched.zip_code && !errors.zip_code}
                                                                       isInvalid={touched.zip_code && errors.zip_code}
                                                                       autoComplete="off"
                                                         />
                                                     </Col>
                                                 </Form.Group>
                                                 {touched.zip_code && <Form.Group as={Row} className={"mb-3"}>
                                                     <Col>
                                                         <Form.Text className="text-danger mt-2">
                                                             {errors.zip_code}
                                                         </Form.Text>
                                                     </Col>
                                                 </Form.Group>}
                                                 <Form.Group as={Row} className={!touched.city && "mb-3"}>
                                                     <Col className={"start-center-value"}>
                                                         <Form.Control type="text"
                                                                       name={"city"}
                                                                       onChange={handleChange}
                                                                       value={values.city}
                                                                       placeholder={"City"}
                                                                       isValid={touched.city && !errors.city}
                                                                       isInvalid={touched.city && errors.city}
                                                                       autoComplete="off"
                                                         />
                                                     </Col>
                                                 </Form.Group>
                                                 {touched.city && <Form.Group as={Row} className={"mb-3"}>
                                                     <Col>
                                                         <Form.Text className="text-danger mt-2">
                                                             {errors.city}
                                                         </Form.Text>
                                                     </Col>
                                                 </Form.Group>}
                                                 <Form.Group as={Row} className={!touched.country && "mb-3"}>
                                                     <Col className={"start-center-value"}>
                                                         <Form.Control
                                                             as={"select"}
                                                             name={"country"}
                                                             onChange={async (e) => {
                                                                 handleChange(e);
                                                                 const cities = await CommonService.getCities(e.target.value);
                                                                 let country = this.state.countries.filter((country) => country.name === e.target.value);
                                                                 if(country.length > 0)
                                                                     setFieldValue('country_code', country[0].phonecode);

                                                                 this.setState({
                                                                     cities
                                                                 })
                                                             }}
                                                             value={values.country}
                                                             isValid={touched.country && !errors.country}
                                                             isInvalid={touched.country && errors.country}
                                                         >
                                                             <option>Please select a country</option>
                                                             {this.state.countries.map((country, index) =>
                                                                 <option key={index} value={country.name} >{country.name}</option>
                                                             )}
                                                         </Form.Control>
                                                     </Col>
                                                 </Form.Group>
                                                 {touched.country && <Form.Group as={Row} className={"mb-3"}>
                                                     <Col>
                                                         <Form.Text className="text-danger mt-2">
                                                             {errors.country}
                                                         </Form.Text>
                                                     </Col>
                                                 </Form.Group>}
                                                 {/*<Form.Group as={Row} className={!touched.city && "mb-3"}>*/}
                                                 {/*    <Col className={"start-center-value"}>*/}
                                                 {/*        <Form.Control*/}
                                                 {/*            as={"select"}*/}
                                                 {/*            name={"city"}*/}
                                                 {/*            onChange={handleChange}*/}
                                                 {/*            value={values.city}*/}
                                                 {/*            isValid={touched.city && !errors.city}*/}
                                                 {/*            isInvalid={touched.city && errors.city}*/}
                                                 {/*        >*/}
                                                 {/*            <option>Please select a city</option>*/}
                                                 {/*            {this.state.cities.map((city, index) =>*/}
                                                 {/*                <option key={index} value={city.name} >{city.name}</option>*/}
                                                 {/*            )}*/}
                                                 {/*        </Form.Control>*/}
                                                 {/*    </Col>*/}
                                                 {/*</Form.Group>*/}
                                                 {/*{touched.city && <Form.Group as={Row} className={"mb-3"}>*/}
                                                 {/*    <Col>*/}
                                                 {/*        <Form.Text className="text-danger mt-2">*/}
                                                 {/*            {errors.city}*/}
                                                 {/*        </Form.Text>*/}
                                                 {/*    </Col>*/}
                                                 {/*</Form.Group>}*/}
                                                 <Form.Group as={Row} className={!touched.phone_number && "mb-3"}>
                                                     <Col className={"start-center-value"}>
                                                         <Dropdown className={styles.CountryCodeDropDown}>
                                                             <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                                                 {values.country_code?
                                                                     <React.Fragment>
                                                                         <div className={`flag flag-${this.renderCountryCode(values.country_code)}`}></div> +{values.country_code}
                                                                     </React.Fragment>
                                                                     :<React.Fragment>
                                                                         <div className={`flag flag-xs`} style={{ width: "1px" }}></div> + Code
                                                                     </React.Fragment>}
                                                             </Dropdown.Toggle>
                                                             <Dropdown.Menu variant="dark" >
                                                                 {this.state.countries.map((country, index) =>
                                                                     <Dropdown.Item href="javascript:void(0)"
                                                                                    onClick={(e) => setFieldValue('country_code', country.phonecode)}
                                                                                    active={values.country_code.toString() === country.phonecode.toString()}>
                                                                         <div className={`flag flag-${country.code.toLowerCase()}`}></div>+{country.phonecode}
                                                                     </Dropdown.Item>
                                                                 )}
                                                             </Dropdown.Menu>
                                                         </Dropdown>
                                                         <Form.Control type="text"
                                                                       name={"phone_number"}
                                                                       className={styles.CountryPhoneNumber}
                                                                       onChange={handleChange}
                                                                       placeholder={"Mobile Number"}
                                                                       value={values.phone_number}
                                                                       isValid={touched.phone_number && !errors.phone_number}
                                                                       isInvalid={touched.phone_number && errors.phone_number}
                                                                       autoComplete="off"
                                                         />
                                                     </Col>
                                                 </Form.Group>
                                                 {touched.phone_number && <Form.Group as={Row} className={"mb-3"}>
                                                     <Col>
                                                         <Form.Text className="text-danger mt-2">
                                                             {errors.phone_number}
                                                         </Form.Text>
                                                     </Col>
                                                 </Form.Group>}
                                                 <hr/>
                                                 <Form.Group as={Row} className={!touched.password && "mb-3"}>
                                                     <Col className={styles.StartCenterValue}>
                                                         <Form.Control type="password"
                                                                       name={"password"}
                                                                       onChange={handleChange}
                                                                       placeholder={"Password"}
                                                                       value={values.password}
                                                                       isValid={touched.password && !errors.password}
                                                                       isInvalid={touched.password && errors.password}
                                                                       onFocus={(e) => {
                                                                           e.target.select()
                                                                       }}
                                                                       autoComplete="off"
                                                         />
                                                     </Col>
                                                 </Form.Group>
                                                 {touched.password && <Form.Group as={Row} className={"mb-3"}>
                                                     <Col>
                                                         <Form.Text className="text-danger mt-2">
                                                             {errors.password}
                                                         </Form.Text>
                                                     </Col>
                                                 </Form.Group>}
                                                 <Form.Group as={Row} className={!touched.password_confirmation && "mb-3"}>
                                                     <Col className={styles.StartCenterValue}>
                                                         <Form.Control type="password"
                                                                       name={"password_confirmation"}
                                                                       onChange={handleChange}
                                                                       placeholder={"Repeat Password"}
                                                                       value={values.password_confirmation}
                                                                       isValid={touched.password_confirmation && !errors.password_confirmation}
                                                                       isInvalid={touched.password_confirmation && errors.password_confirmation}
                                                                       onFocus={(e) => {
                                                                           e.target.select()
                                                                       }}
                                                                       autoComplete="off"
                                                         />
                                                     </Col>
                                                 </Form.Group>
                                                 {touched.password_confirmation && <Form.Group as={Row} className={"mb-3"}>
                                                     <Col>
                                                         <Form.Text className="text-danger mt-2">
                                                             {errors.password_confirmation}
                                                         </Form.Text>
                                                     </Col>
                                                 </Form.Group>}
                                                 <Form.Group as={Row} className={!touched.password_confirmation && "mb-3"}>
                                                     <Col className={styles.StartCenterValue}>
                                                         <Form.Check
                                                             type={"checkbox"}
                                                             id={`default-checkbox`}
                                                             name={"is_agree_rules"}
                                                             onChange={handleChange}
                                                             isValid={touched.is_agree_rules && !errors.is_agree_rules}
                                                             isInvalid={touched.is_agree_rules && errors.is_agree_rules}
                                                             className={styles.RegisterCheckBox}
                                                             label={<React.Fragment>I understand and agree with the <a href={HOME_WEBSITE + "/terms-and-conditions"} target={"_blank"}>Terms & Conditions</a> and <a href={HOME_WEBSITE + "/kyc-policies,-privacy-and-management-of-personal-data"} target={"_blank"}>Privacy Policy.</a></React.Fragment>}
                                                         />

                                                     </Col>
                                                 </Form.Group>
                                                 {touched.is_agree_rules && <Form.Group as={Row} className={"mb-3"}>
                                                     <Col>
                                                         <Form.Text className="text-danger mt-2">
                                                             {errors.is_agree_rules}
                                                         </Form.Text>
                                                     </Col>
                                                 </Form.Group>}
                                             </React.Fragment>


                                             {this.state.successfully &&
                                             <div className={"text-success mt-3 mb-3 text-center"}>
                                                 <FontAwesomeIcon icon={"check-circle"} className={styles.SuccessLogo}/><br/>
                                                 Your Email has been subscribed successfully.<br/>An email has been sent to your inbox
                                             </div>}

                                             <br/>
                                             {!this.state.successfully && <Form.Group as={Row} className={"mb-3"}>
                                                 <Col className={"text-center"}>
                                                     <Form.Text className={styles.SubLoginText}>
                                                         Do you have an account? <NavLink to={ROUTE_LOGIN}>Click here to login</NavLink>
                                                     </Form.Text>
                                                 </Col>
                                             </Form.Group>}

                                             <Form.Group className={"text-center mb-5"}>
                                                 <button disabled={this.state.submitting} className={"btn btn-account"} type={"submit"}>{this.state.submitting?<Spinner animation="border" variant="light" size={"sm"} />:"Register"}</button>
                                             </Form.Group>
                                         </Form>


                                     </div>

                                 </div>
                         </div>
                         {process.env.REACT_APP_NAME !== "swissco.gaming"?<Footer />:<MaxFooter register={(Object.keys(errors).length == 0 && Object.keys(touched).length == 0)} float={(Object.keys(errors).length > 0 || Object.keys(touched).length > 0)}/>}

                     </React.Fragment>
                )}
                </Formik>}

            </div>

        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withGameTableContext(RegisterComplete))));