import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import PanelContainer from "../../PanelContainer";
import AccountTable from "../../../../components/ui/AccountTable/AccountTable";
import AdminService from "../../../../services/admin.service";
import TableRow from "../../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../../components/ui/AccountTable/TableCol";
import {
    ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS_SELECTED,
    ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW_SELECTED
} from "../../../../routers/routes";
import {numberWithCommas} from "../../../../helpers/helpers";
import moment from "moment";
import TableMobileRow from "../../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../../components/ui/AccountTable/TableColValue";
import PageTitleContainer from "../../Search/PageTitleContainer";
import YearlyMonthlyOverviewSearch from "./YearlyMonthlyOverviewSearch";
import MonthlyDailyDepositsSearch from "../MonthlyDailyDeposits/MonthlyDailyDepositsSearch";
class YearlyMonthlyOverview extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            searchComponent: null,
            search: {
                type: props.match.params.hasOwnProperty('date')?"monthly":"yearly",
                start_date: "",
                end_date: "",
                month: new Date().getMonth(),
                year: new Date().getFullYear(),
                date: props.match.params.hasOwnProperty('date')?props.match.params.date:null,
            }
        }
    }
    componentDidMount() {
        if(localStorage.getItem('backed_from_account_state')){
            let state = JSON.parse(localStorage.getItem('backed_from_account_state'));
            localStorage.removeItem('backed_from_account_state');
            this.setState(state)
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params !== this.props.match.params){
            this.setState({
                ...this.state,
                search: {
                    type: this.props.match.params.hasOwnProperty('date')?"monthly":"yearly",
                    year: new Date().getFullYear(),
                    date: this.props.match.params.hasOwnProperty('date')?this.props.match.params.date:null,
                }
            })
        }
    }

    onLoadSelectedRound = (e, row) => {
        const {accountContext} = this.props;
        e.preventDefault();
        accountContext.doGoto(ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW_SELECTED, ":date", row['Date'], this.state, this.refTable.getState());
    }
    doUpdateSearchData = (data) => {
        this.setState({
            ...this.state,
            search: data
        })
    }
    render() {
        let sum = {
            "DepositAmount": 0,
            "WithdrawalAmount": 0,
            "MembersCount": 0,
            "ActiveMembersCount": 0,
            "WinningAmount": 0,
            "LosingAmount": 0,
            "PlayersBalance": 0,
            "HouseBalance": 0,
            "WHouseBalance": 0,
            "TotalBets": 0,
            "PayoutAmount": 0
        }
        return (
            <PanelContainer>
                <PageTitleContainer back={this.state.search.date && true} center={this.state.search.date && true} searchBar={!this.state.search.date && this.state.searchComponent}/>
                <div className={"form-center"}>
                    <AccountTable ref={(ref) => this.refTable = ref} full={true} loadData={AdminService.getYearlyMonthlyOverviewReports} sort={"id"} align={"desc"} header={(onSort, sort, align) => {
                        return <TableRow>
                            <TableColHeader>#</TableColHeader>
                            <TableColHeader>Date</TableColHeader>
                            <TableColHeader id={"table-cell-08"}>Total Bets</TableColHeader>
                            <TableColHeader id={"table-cell-07"}>Total Pay-Out</TableColHeader>
                            <TableColHeader id={"table-cell-06"}>House balance</TableColHeader>
                            <TableColHeader id={"table-cell-05"}>Total Winning</TableColHeader>
                            <TableColHeader id={"table-cell-04"}>Total Loosing</TableColHeader>
                            <TableColHeader id={"table-cell-03"}>House balance</TableColHeader>
                            <TableColHeader id={"table-cell-02"}>Total Deposit</TableColHeader>
                            <TableColHeader id={"table-cell-01"}>Total Withdrawal</TableColHeader>


                        </TableRow>
                    }}
                       render={(row, index, number) => {
                           sum['DepositAmount'] += parseFloat(row['DepositAmount']);
                           sum['WithdrawalAmount'] += parseFloat(row['WithdrawalAmount']);
                           sum['WinningAmount'] += parseFloat(row['WinningAmount']);
                           sum['LosingAmount'] += parseFloat(row['LosingAmount']);
                           sum['PlayersBalance'] += parseFloat(row['PlayersBalance']);
                           sum['TotalBets'] += parseFloat(row['TotalBets']);
                           sum['PayoutAmount'] += parseFloat(row['PayoutAmount']);
                           sum['HouseBalance'] += parseFloat(row['HouseBalance']);
                           sum['WHouseBalance'] += parseFloat(row['WHouseBalance']);
                           return <TableRow key={index} onClick={(e) => this.onLoadSelectedRound(e, row)}>
                               <TableCol>{index + 1}</TableCol>
                               <TableCol>{moment(new Date(row['Date'])).format((row['Date'].split("-").length === 3)?'ddd, MMMM DD, YYYY':'MMMM, YYYY')}</TableCol>
                               <TableCol><span>{numberWithCommas(row['TotalBets'], true)}</span></TableCol>
                               <TableCol><span>{numberWithCommas(row['PayoutAmount'], true)}</span></TableCol>
                               <TableCol classes={row['HouseBalance'] <= 0?"red-text":"green-text"}><span>{numberWithCommas(row['HouseBalance'], true)}</span></TableCol>
                               <TableCol><span>{numberWithCommas(row['WinningAmount'], true)}</span></TableCol>
                               <TableCol><span>{numberWithCommas(row['LosingAmount'], true)}</span></TableCol>
                               <TableCol classes={row['WHouseBalance'] <= 0?"red-text":"green-text"}><span>{numberWithCommas(row['WHouseBalance'], true)}</span></TableCol>
                               <TableCol><span>{numberWithCommas(row['DepositAmount'], true)}</span></TableCol>
                               <TableCol><span>{numberWithCommas(row['WithdrawalAmount'], true)}</span></TableCol>

                           </TableRow>
                       }}
                      mobile={(row, index, number, total, onNext, onPrevious) =>
                          <TableMobileRow key={index}>
                              <TableMobileCol>
                                  <label>#</label>
                                  <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{index + 1}</TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Date</label>
                                  <TableColValue>{moment(new Date(row['Date'])).format((row['Date'].split("-").length === 3)?'ddd, MMMM DD, YYYY':'MMMM, YYYY')}</TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Total Bets</label>
                                  <TableColValue><span>{numberWithCommas(row['TotalBets'], true)}</span></TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Total Pay-Outs</label>
                                  <TableColValue><span>{numberWithCommas(row['PayoutAmount'], true)}</span></TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>House balance</label>
                                  <TableColValue classes={row['HouseBalance'] <= 0?"red-text":"green-text"}><span>{numberWithCommas(row['HouseBalance'], true)}</span></TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Total Winning</label>
                                  <TableColValue><span>{numberWithCommas(row['WinningAmount'], true)}</span></TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Total Loosing</label>
                                  <TableColValue><span>{numberWithCommas(row['LosingAmount'], true)}</span></TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>House Balance</label>
                                  <TableColValue><span>{numberWithCommas(row['WHouseBalance'], true)}</span></TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Total Deposit</label>
                                  <TableColValue><span>{numberWithCommas(row['DepositAmount'], true)}</span></TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Total Withdrawal</label>
                                  <TableColValue><span>{numberWithCommas(row['WithdrawalAmount'], true)}</span></TableColValue>
                              </TableMobileCol>

                              <TableMobileCol>
                                  <TableColValue>
                                      <button onClick={(e) => this.onLoadSelectedRound(e, row)}>View details ...</button>
                                  </TableColValue>
                              </TableMobileCol>
                          </TableMobileRow>}
                      last_line={() => {
                          return <TableRow>
                              <TableCol colSpan={2}></TableCol>

                              <TableCol classes={"yellow-text"}><span>{numberWithCommas(sum['TotalBets'], true)}</span></TableCol>
                              <TableCol classes={"yellow-text"}><span>{numberWithCommas(sum['PayoutAmount'], true)}</span></TableCol>
                              <TableCol classes={"yellow-text"}><span>{numberWithCommas(sum['HouseBalance'], true)}</span></TableCol>
                              <TableCol classes={"yellow-text"}><span>{numberWithCommas(sum['WinningAmount'], true)}</span></TableCol>
                              <TableCol classes={"yellow-text"}><span>{numberWithCommas(sum['LosingAmount'], true)}</span></TableCol>
                              <TableCol classes={"yellow-text"}><span>{numberWithCommas(sum['WHouseBalance'], true)}</span></TableCol>
                              <TableCol classes={"yellow-text"}><span>{numberWithCommas(sum['DepositAmount'], true)}</span></TableCol>
                              <TableCol classes={"yellow-text"}><span>{numberWithCommas(sum['WithdrawalAmount'], true)}</span></TableCol>
                          </TableRow>
                      }}
                       search={this.state.search}
                       loaded={() => {
                           this.setState({
                               searchComponent: <YearlyMonthlyOverviewSearch searchData={this.state.search} doUpdateSearchData={this.doUpdateSearchData}  refTable={this.refTable} />
                           })
                       }}
                    />
                </div>
            </PanelContainer>
        )
    }
}

export default withRouter(withAdminAccountContext(YearlyMonthlyOverview));