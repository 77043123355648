import api from "./api";
import {queryStringSerialize} from "../helpers/helpers"
const doWithdrawal = (data) => {
    return api
        .post("/api/account/withdrawal/create", data)
        .then((response) => {
            return response.data;
        });
};

const getList = (data) => {
    return api
        .get("/api/account/withdrawal/list?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const WithdrawalService = {
    doWithdrawal,
    getList
};

export default WithdrawalService;