import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import classNames from "classnames";
import {totalWin, convertChipsToGroup, sumChipsByFormula} from "../../../helpers/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class TopChips extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    onDeleteChips = (number) => {
        const {gameTableContext} = this.props;
        let chips = gameTableContext.state.chips;
        let values = sumChipsByFormula(chips, number);
        chips.delete(number);
        gameTableContext.onUpdateState({
            chips,
            balance: gameTableContext.state.balance + values
        })
    }
    render() {
        const {applicationContext, gameTableContext} = this.props;
        let chips = convertChipsToGroup(gameTableContext.state.chips)
        return (
            <div className={classNames(styles.WonBoardWrapper, styles.Top5BoardWrapper, gameTableContext.state.windows.top5 && styles.OpenTop5BoardWrapper)}>
                <table border={0} width="100%">
                    <tr className={styles.WonBoardHeader}>
                        <th className={applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font"} width={"30"}></th>
                        <th className={applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font"}>{applicationContext.translator("BET ON")}</th>
                        <th className={classNames(styles.TextGreen, "text-center", applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font")} width={"20%"}>{applicationContext.translator("TOTAL BET")}</th>
                        <th className={classNames(styles.TextGreen, "text-center", applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold rtl-font")} width={"20%"}>{applicationContext.translator("TOTAL WIN")}</th>
                    </tr>
                    {chips && chips.length > 0 &&
                    <React.Fragment>
                        {chips.map((formula, index) => {
                            return (
                                <tr key={index}>
                                    <td >{index + 1}</td>
                                    <td >{formula.number}</td>
                                    <td className={classNames("text-center")}><span className={classNames(styles.TextGreen)}>{formula.value}</span></td>
                                    <td className={classNames(styles.TextGreen, "text-center")}>{formula.win}</td>
                                </tr>
                            );
                        })}
                    </React.Fragment>
                    }
                </table>
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext(TopChips));