import React from "react";
import FavoriteIcon from "../components/icons/FavoriteIcon.png";
import ChipIcon from "../components/icons/ChipIcon.png";
import {
    ROUTE_ADMIN_ACCOUNT_CHARGE_CREDIT,
    ROUTE_ADMIN_CEG_PLAYED_ROUND_WITH_TOTAL_BETS_AND_RESULT,
    ROUTE_ADMIN_CEG_ROUND_AND_WINNING_NUMBERS,
    ROUTE_ADMIN_CEG_SEARCH_PLAYER_ACCOUNT,
    ROUTE_ADMIN_CEG_SELECTED_ROUND,
    ROUTE_ADMIN_DASHBOARD, ROUTE_ADMIN_ENTER_MANUALLY_WINNING_NUMBER,
    ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS,
    ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS_SELECTED,
    ROUTE_ADMIN_MONTHLY_DAILY_WITHDRAWALS,
    ROUTE_ADMIN_MONTHLY_DAILY_WITHDRAWALS_SELECTED,
    ROUTE_ADMIN_RANKING_DEPOSIT,
    ROUTE_ADMIN_RANKING_WITHDRAWAL, ROUTE_ADMIN_ROUND_AND_WINNING_NUMBERS,
    ROUTE_ADMIN_ROUND_OVERVIEW,
    ROUTE_ADMIN_ROUND_SELECTED_OVERVIEW,
    ROUTE_ADMIN_SEARCH_PLAYER_ACCOUNT, ROUTE_ADMIN_SEND_EMAIL, ROUTE_ADMIN_TICKET_SELECTED, ROUTE_ADMIN_TOP37_PAYOUTS,
    ROUTE_ADMIN_USER_ACCOUNT_CASH_IN,
    ROUTE_ADMIN_USER_ACCOUNT_CASH_OUT, ROUTE_ADMIN_USER_ACCOUNT_LOGON,
    ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW,
    ROUTE_ADMIN_USER_ACCOUNT_PLAYED,
    ROUTE_ADMIN_USER_ACCOUNT_PROFILE,
    ROUTE_ADMIN_USERS_LIST, ROUTE_ADMIN_WAIT_FOR_ANSWER_TICKETS, ROUTE_ADMIN_WAIT_FOR_APPROVAL_DEPOSITS,
    ROUTE_ADMIN_WAIT_FOR_APPROVAL_WITHDRAWALS,
    ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW,
    ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW_SELECTED, ROUTE_LOGON_HISTORY,
    ROUTE_LOGOUT,
    ROUTE_PROFILE_WITHDRAWAL_HISTORY
} from "../routers/routes";
import MenuLabel from "../components/ui/MenuLabel";
export const MAX_CHIP_AMOUNT = 500;
export const MAX_CHIP_COUNT = 25;
export const TIME_DURATION = 60;
export const LOCK_TIME = 3
export const NUMBERS = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36];
export const NUMBERS_OF_LEFT_TABLE = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34];
export const RED_NUMBERS_OF_TABLE = [1,3,5,7,9,12,14,16,18,19,21,23,25,27,30,32,34,36];
export const BLACK_NUMBERS_OF_TABLE = [2,4,6,8,10,11,13,15,17,20,22,24,26,28,29,31,33,35];
export const WHEEL_NUMBERS = [0,32,15,19,4,21,2,25,17,34,6,27,13,36,11,30,8,23,10,5,24,16,33,1,20,14,31,9,22,18,29,7,28,12,35,3,26];
export const CUSTOM_FORMULA = ["1st-12", "2nd-12", "3rd-12", "1-18", "19-36", "EVEN",  "ODD", "BLACK", "RED", "2-to-1-1", "2-to-1-2", "2-to-1-3"];
export const VOLUME_LEVEL_1 = 0.1;
export const VOLUME_LEVEL_2 = 0.4;
export const VOLUME_LEVEL_3 = 1;
export const HOME_WEBSITE = (process.env.NODE_ENV === "development")?"http://localhost:3000":((process.env.REACT_APP_NAME === "swissco.gaming")?"https://maxx.tv/ceg":"https://myroulette.tv");
export const ROLE_SUPER_ADMIN = "super-admin";
export const ROLE_CEG_ADMIN = "ceg-admin";
export const ROLE_ADMIN = "admin";
export const ROLE_AGENT = "agent";
export const ROLE_FINANCIAL = "financial";
export const FORMULA_BET_DATA = {
    "straight": {
        "label": "Straight",
        "description": "Straight up bet",
        "position" : "#",
        "value": 35
    },
    "trio": {
        "label": "Trio",
        "description": "Trio",
        "position" : "#",
        "value": 11
    },
    "corner": {
        "label": "Corner",
        "description": "Corner bet",
        "position" : "#",
        "value": 8
    },
    "split": {
        "label": "Split",
        "description": "Split bet",
        "position" : "#",
        "value": 17
    },
    "street": {
        "label": "Street",
        "description": "Street bet",
        "position" : "#",
        "value": 11
    },
    "first-four": {
        "label": "First Four",
        "description": "First Four bet",
        "position" : "#",
        "value": 8
    },
    "line": {
        "label": "Line",
        "description": "Line bet",
        "position" : "#",
        "value": 5
    },
    "1st-12": {
        "label": "1st 12",
        "description": "Dozen bet",
        "position" : "1st Dozen",
        "value": 2
    },
    "2nd-12": {
        "label": "2nd 12",
        "description": "Dozen bet",
        "position" : "2nd Dozen",
        "value": 2
    },
    "3rd-12": {
        "label": "3rd 12",
        "description": "Dozen bet",
        "position" : "3rd Dozen",
        "value": 2
    },
    "1-18": {
        "label": "1 to 18",
        "description": "Low/High Numbers bet",
        "position": "1/18",
        "value": 1
    },
    "19-36": {
        "label": "19 to 36",
        "description": "Low/High Numbers bet",
        "position": "19/36",
        "value": 1
    },
    "EVEN": {
        "label": "Even",
        "description": "Even/Odd bet",
        "position": "Even",
        "value": 1
    },
    "ODD": {
        "label": "Odd",
        "description": "Even/Odd bet",
        "position": "Odd",
        "value": 1
    },
    "BLACK": {
        "label": "Black",
        "description": "Colour bet",
        "position": "Black",
        "value": 1
    },
    "RED": {
        "label": "Red",
        "description": "Colour bet",
        "position": "Red",
        "value": 1
    },
    "2-to-1-1": {
        "label": "2 to 1",
        "description": "Column bet",
        "position": "Left Column",
        "value": 2
    },
    "2-to-1-2": {
        "label": "2 to 1",
        "description": "Column bet",
        "position": "Middle Column",
        "value": 2
    },
    "2-to-1-3": {
        "label": "2 to 1",
        "description": "Column bet",
        "position": "Right Column",
        "value": 2
    },
    "neighbours": {
        "label": "Neighbours",
        "description": "Neighbours",
        "position": "Neighbours",
        "value": 2
    },

};
export const CIRCLE_POSITION_ON_WHEEL = {
    "0": ["225", "72"],
    "32": ["251", "76"],
    "15": ["274", "83"],
    "19": ["300", "92"],
    "4": ["320", "106"],
    "21": ["337", "124"],
    "2": ["353", "145"],
    "25": ["365", "168"],
    "17": ["373", "192"],
    "34": ["376", "218"],
    "6": ["376", "242"],
    "27": ["370", "268"],
    "13": ["359", "292"],
    "36": ["347", "313"],
    "11": ["329", "333"],
    "30": ["310", "350"],
    "8": ["288", "361"],
    "23": ["263", "370"],
    "10": ["238", "372"],
    "5": ["214", "372"],
    "24": ["187", "370"],
    "16": ["163", "360"],
    "33": ["141", "350"],
    "1": ["121", "333"],
    "20": ["105", "315"],
    "14": ["90", "294"],
    "31": ["80", "270"],
    "9": ["74", "245"],
    "22": ["74", "219"],
    "18": ["76", "194"],
    "29": ["82", "168"],
    "7": ["94", "145"],
    "28": ["110", "123"],
    "12": ["127", "105"],
    "35": ["152", "94"],
    "3": ["174", "84"],
    "26": ["200", "77"]
}
export const FORMULA_NUMBERS = {
    "1st-12": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    "2nd-12": [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
    "3rd-12": [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
    "1-18": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
    "19-36": [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
    "EVEN": [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36],
    "ODD": [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35],
    "BLACK": [2,4,6,8,10,11,13,15,17,20,22,24,26,28,29,31,33,35],
    "RED": [1,3,5,7,9,12,14,16,18,19,21,23,25,27,30,32,34,36],
    '2-to-1-1': [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34],
    "2-to-1-2": [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
    "2-to-1-3": [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36]
}
export const DEFAULT_FAVORITES = [
    {
        id: 1,
        name: "Zero Game",
        chip: null,
        numbers: [0, 3, 12, 15, 26, 32, 35],
        actions: ["tsplit-3", "tsplit-15", "tsplit-35", 26]
    },
    {
        id: 2,
        name: "Voisins du zero",
        chip: null,
        numbers: [0, 2, 3,4,7,12,15,18,21,19,22,25,28,26,29,32,35],
        actions: ["corner-3", "tsplit-7", "tsplit-15", "tsplit-21", "tsplit-22", "corner-29", "tsplit-35"]
    },
    {
        id: 3,
        name: "Orphelins",
        chip: null,
        numbers: [1,6,9,14,17,20,31,34],
        actions: [1, "tsplit-9", "tsplit-17", "tsplit-20", "tsplit-34"]
    },
    {
        id: 4,
        name: "Tiers du culindre",
        chip: null,
        numbers: [1,5,8,10,11,13,16,23,24,27,30,33,36],
        actions: [1, "tsplit-8", "split-10", "tsplit-16", "split-23", "tsplit-30", "tsplit-36"]
    },
    {
        id: 5,
        name: "Favorite 1",
        chip: null,
        numbers: [],
        actions: []
    },
    {
        id: 6,
        name: "Favorite 2",
        chip: null,
        numbers: [],
        actions: []
    },
    {
        id: 7,
        name: "Favorite 3",
        chip: null,
        numbers: [],
        actions: []
    },
    {
        id: 8,
        name: "Favorite 4",
        chip: null,
        numbers: [],
        actions: []
    }
];
export const WHEEL_SVG_ARROW = {
    0: "rotate(0 1000 225)",
    1: "rotate(223.78378378378378 225 225)",
    2: "rotate(58.37837837837838 225 225)",
    3: "rotate(340.5405405405405 225 225)",
    4: "rotate(38.91891891891892 225 225)",
    5: "rotate(184.86486486486487 225 225)",
    6: "rotate(97.29729729729729 225 225)",
    7: "rotate(301.6216216216216 225 225)",
    8: "rotate(155.67567567567568 225 225)",
    9: "rotate(262.7027027027027 225 225)",
    10: "rotate(175.13513513513513 225 225)",
    11: "rotate(136.21621621621622 225 225)",
    12: "rotate(321.0810810810811 225 225)",
    13: "rotate(116.75675675675676 225 225)",
    14: "rotate(243.24324324324326 225 225)",
    15: "rotate(19.45945945945946 225 225)",
    16: "rotate(204.32432432432432 225 225)",
    17: "rotate(77.83783783783784 225 225)",
    18: "rotate(282.1621621621622 225 225)",
    19: "rotate(29.18918918918919 225 225)",
    20: "rotate(233.51351351351352 225 225)",
    21: "rotate(48.648648648648646 225 225)",
    22: "rotate(268.43243243243245 213 225)",
    23: "rotate(165.40540540540542 225 225)",
    24: "rotate(194.59459459459458 225 225)",
    25: "rotate(68.10810810810811 225 225)",
    26: "rotate(350.27027027027026 225 225)",
    27: "rotate(107.02702702702703 225 225)",
    28: "rotate(311.35135135135135 225 225)",
    29: "rotate(291.8918918918919 225 225)",
    30: "rotate(145.94594594594594 225 225)",
    31: "rotate(252.97297297297297 225 225)",
    32: "rotate(9.72972972972973 225 225)",
    33: "rotate(214.05405405405406 225 225)",
    34: "rotate(87.56756756756756 225 225)",
    35: "rotate(330.81081081081084 225 225)",
    36: "rotate(126.48648648648648 225 225)"
}

export const WHEEL_SVG_PATHS = [
    {
        path1: {fill: "#008938", transform: "translate(225 225)"},
        path2: {transform: "rotate(9.73 -1209.28 1434.28)"},
        text: {transform: "translate(225 225)", value: 0},
        pointer: {transform: "translate(225 225)"},
        rect: {transform: "rotate(0,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(9.73 -1209.28 1434.28)"},
        path2: {transform: "rotate(9.73 -1209.28 1434.28)"},
        text: {transform: "rotate(9.73 -1209.28 1434.28)", value: 32},
        pointer: {transform: "rotate(9.73 -1209.28 1434.28)"},
        rect: {transform: "rotate(9.72972972972973,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(19.459 -543.602 768.602)"},
        path2: {transform: "rotate(19.459 -543.602 768.602)"},
        text: {transform: "rotate(19.459 -543.602 768.602)", value: 15},
        pointer: {transform: "rotate(19.459 -543.602 768.602)"},
        rect: {transform: "rotate(19.45945945945946,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(29.189 -319.561 544.561)"},
        path2: {transform: "rotate(29.189 -319.561 544.561)"},
        text: {transform: "rotate(29.189 -319.561 544.561)", value: 19},
        pointer: {transform: "rotate(29.189 -319.561 544.561)"},
        rect: {transform: "rotate(29.18918918918919,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(38.919 -205.906 430.906)"},
        path2: {transform: "rotate(38.919 -205.906 430.906)"},
        text: {transform: "rotate(38.919 -205.906 430.906)", value: 4},
        pointer: {transform: "rotate(38.919 -205.906 430.906)"},
        rect: {transform: "rotate(38.91891891891892,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(48.649 -136.378 361.378)"},
        path2: {transform: "rotate(48.649 -136.378 361.378)"},
        text: {transform: "rotate(48.649 -136.378 361.378)", value: 21},
        pointer: {transform: "rotate(48.649 -136.378 361.378)"},
        rect: {transform: "rotate(48.648648648648646,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(58.378 -88.884 313.884)"},
        path2: {transform: "rotate(58.378 -88.884 313.884)"},
        text: {transform: "rotate(58.378 -88.884 313.884)", value: 2},
        pointer: {transform: "rotate(58.378 -88.884 313.884)"},
        rect: {transform: "rotate(58.37837837837838,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(68.108 -53.95 278.95)"},
        path2: {transform: "rotate(68.108 -53.95 278.95)"},
        text: {transform: "rotate(68.108 -53.95 278.95)", value: 25},
        pointer: {transform: "rotate(68.108 -53.95 278.95)"},
        rect: {transform: "rotate(68.10810810810811,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(77.838 -26.829 251.829)"},
        path2: {transform: "rotate(77.838 -26.829 251.829)"},
        text: {transform: "rotate(77.838 -26.829 251.829)", value: 17},
        pointer: {transform: "rotate(77.838 -26.829 251.829)"},
        rect: {transform: "rotate(77.83783783783784,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(87.568 -4.88 229.88)"},
        path2: {transform: "rotate(87.568 -4.88 229.88)"},
        text: {transform: "rotate(87.568 -4.88 229.88)", value: 34},
        pointer: {transform: "rotate(87.568 -4.88 229.88)"},
        rect: {transform: "rotate(87.56756756756756,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(97.297 13.488 211.512)"},
        path2: {transform: "rotate(97.297 13.488 211.512)"},
        text: {transform: "rotate(97.297 13.488 211.512)", value: 6},
        pointer: {transform: "rotate(97.297 13.488 211.512)"},
        rect: {transform: "rotate(97.29729729729729,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(107.027 29.295 195.705)"},
        path2: {transform: "rotate(107.027 29.295 195.705)"},
        text: {transform: "rotate(107.027 29.295 195.705)", value: 27},
        pointer: {transform: "rotate(107.027 29.295 195.705)"},
        rect: {transform: "rotate(107.02702702702703,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(116.757 43.231 181.769)"},
        path2: {transform: "rotate(116.757 43.231 181.769)"},
        text: {transform: "rotate(116.757 43.231 181.769)", value: 13},
        pointer: {transform: "rotate(116.757 43.231 181.769)"},
        rect: {transform: "rotate(116.75675675675676,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(126.486 55.779 169.221)"},
        path2: {transform: "rotate(126.486 55.779 169.221)"},
        text: {transform: "rotate(126.486 55.779 169.221)", value: 36},
        pointer: {transform: "rotate(126.486 55.779 169.221)"},
        rect: {transform: "rotate(126.48648648648648,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(136.216 67.294 157.706)"},
        path2: {transform: "rotate(136.216 67.294 157.706)"},
        text: {transform: "rotate(136.216 67.294 157.706)", value: 11},
        pointer: {transform: "rotate(136.216 67.294 157.706)"},
        rect: {transform: "rotate(136.21621621621622,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(145.946 78.047 146.953)"},
        path2: {transform: "rotate(145.946 78.047 146.953)"},
        text: {transform: "rotate(145.946 78.047 146.953)", value: 30},
        pointer: {transform: "rotate(145.946 78.047 146.953)"},
        rect: {transform: "rotate(145.94594594594594,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(155.676 88.254 136.746)"},
        path2: {transform: "rotate(155.676 88.254 136.746)"},
        text: {transform: "rotate(155.676 88.254 136.746)", value: 8},
        pointer: {transform: "rotate(155.676 88.254 136.746)"},
        rect: {transform: "rotate(155.67567567567568,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(165.405 98.094 126.906)"},
        path2: {transform: "rotate(165.405 98.094 126.906)"},
        text: {transform: "rotate(165.405 98.094 126.906)", value: 23},
        pointer: {transform: "rotate(165.405 98.094 126.906)"},
        rect: {transform: "rotate(165.40540540540542,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(175.135 107.721 117.279)"},
        path2: {transform: "rotate(175.135 107.721 117.279)"},
        text: {transform: "rotate(175.135 107.721 117.279)", value: 10},
        pointer: {transform: "rotate(175.135 107.721 117.279)"},
        rect: {transform: "rotate(175.13513513513513,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(-175.135 117.279 107.721)"},
        path2: {transform: "rotate(-175.135 117.279 107.721)"},
        text: {transform: "rotate(-175.135 117.279 107.721)", value: 5},
        pointer: {transform: "rotate(-175.135 117.279 107.721)"},
        rect: {transform: "rotate(184.86486486486487,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(-165.405 126.906 98.094)"},
        path2: {transform: "rotate(-165.405 126.906 98.094)"},
        text: {transform: "rotate(-165.405 126.906 98.094)", value: 24},
        pointer: {transform: "rotate(-165.405 126.906 98.094)"},
        rect: {transform: "rotate(194.59459459459458,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(-155.676 136.746 88.254)"},
        path2: {transform: "rotate(-155.676 136.746 88.254)"},
        text: {transform: "rotate(-155.676 136.746 88.254)", value: 16},
        pointer: {transform: "rotate(-155.676 136.746 88.254)"},
        rect: {transform: "rotate(204.32432432432432,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(-145.946 146.953 78.047)"},
        path2: {transform: "rotate(-145.946 146.953 78.047)"},
        text: {transform: "rotate(-145.946 146.953 78.047)", value: 33},
        pointer: {transform: "rotate(-145.946 146.953 78.047)"},
        rect: {transform: "rotate(214.05405405405406,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(-136.216 157.706 67.294)"},
        path2: {transform: "rotate(-136.216 157.706 67.294)"},
        text: {transform: "rotate(-136.216 157.706 67.294)", value: 1},
        pointer: {transform: "rotate(-136.216 157.706 67.294)"},
        rect: {transform: "rotate(223.78378378378378,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(-126.486 169.221 55.779)"},
        path2: {transform: "rotate(-126.486 169.221 55.779)"},
        text: {transform: "rotate(-126.486 169.221 55.779)", value: 20},
        pointer: {transform: "rotate(-126.486 169.221 55.779)"},
        rect: {transform: "rotate(233.51351351351352,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(-116.757 181.769 43.231)"},
        path2: {transform: "rotate(-116.757 181.769 43.231)"},
        text: {transform: "rotate(-116.757 181.769 43.231)", value: 14},
        pointer: {transform: "rotate(-116.757 181.769 43.231)"},
        rect: {transform: "rotate(243.24324324324326,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(-107.027 195.705 29.295)"},
        path2: {transform: "rotate(-107.027 195.705 29.295)"},
        text: {transform: "rotate(-107.027 195.705 29.295)", value: 31},
        pointer: {transform: "rotate(-107.027 195.705 29.295)"},
        rect: {transform: "rotate(252.97297297297297,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(-97.297 211.512 13.488)"},
        path2: {transform: "rotate(-97.297 211.512 13.488)"},
        text: {transform: "rotate(-97.297 211.512 13.488)", value: 9},
        pointer: {transform: "rotate(-97.297 211.512 13.488)"},
        rect: {transform: "rotate(262.7027027027027,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(-87.568 229.88 -4.88)"},
        path2: {transform: "rotate(-87.568 229.88 -4.88)"},
        text: {transform: "rotate(-87.568 229.88 -4.88)", value: 22},
        pointer: {transform: "rotate(-87.568 229.88 -4.88)"},
        rect: {transform: "rotate(272.43243243243245,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(-77.838 251.829 -26.829)"},
        path2: {transform: "rotate(-77.838 251.829 -26.829)"},
        text: {transform: "rotate(-77.838 251.829 -26.829)", value: 18},
        pointer: {transform: "rotate(-77.838 251.829 -26.829)"},
        rect: {transform: "rotate(282.1621621621622,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(-68.108 278.95 -53.95)"},
        path2: {transform: "rotate(-68.108 278.95 -53.95)"},
        text: {transform: "rotate(-68.108 278.95 -53.95)", value: 29},
        pointer: {transform: "rotate(-68.108 278.95 -53.95)"},
        rect: {transform: "rotate(291.8918918918919,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(-58.378 313.884 -88.884)"},
        path2: {transform: "rotate(-58.378 313.884 -88.884)"},
        text: {transform: "rotate(-58.378 313.884 -88.884)", value: 7},
        pointer: {transform: "rotate(-58.378 313.884 -88.884)"},
        rect: {transform: "rotate(301.6216216216216,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(-48.649 361.378 -136.378)"},
        path2: {transform: "rotate(-48.649 361.378 -136.378)"},
        text: {transform: "rotate(-48.649 361.378 -136.378)", value: 28},
        pointer: {transform: "rotate(-48.649 361.378 -136.378)"},
        rect: {transform: "rotate(311.35135135135135,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(-38.919 430.906 -205.906)"},
        path2: {transform: "rotate(-38.919 430.906 -205.906)"},
        text: {transform: "rotate(-38.919 430.906 -205.906)", value: 12},
        pointer: {transform: "rotate(-38.919 430.906 -205.906)"},
        rect: {transform: "rotate(321.0810810810811,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(-29.189 544.561 -319.561)"},
        path2: {transform: "rotate(-29.189 544.561 -319.561)"},
        text: {transform: "rotate(-29.189 544.561 -319.561)", value: 35},
        pointer: {transform: "rotate(-29.189 544.561 -319.561)"},
        rect: {transform: "rotate(330.81081081081084,0,0)"}
    },
    {
        path1: {fill: "#b9242a", transform: "rotate(-19.459 768.602 -543.602)"},
        path2: {transform: "rotate(-19.459 768.602 -543.602)"},
        text: {transform: "rotate(-19.459 768.602 -543.602)", value: 3},
        pointer: {transform: "rotate(-19.459 768.602 -543.602)"},
        rect: {transform: "rotate(340.5405405405405,0,0)"}
    },
    {
        path1: {fill: "#000", transform: "rotate(-9.73 1434.28 -1209.28)"},
        path2: {transform: "rotate(-9.73 1434.28 -1209.28)"},
        text: {transform: "rotate(-9.73 1434.28 -1209.28)", value: 26},
        pointer: {transform: "rotate(-9.73 1434.28 -1209.28)"},
        rect: {transform: "rotate(350.27027027027026,0,0)"}
    }
]
export const TABLE_NUMBERS = [
    [
        {number: 0, label: "0", green: true, colSpan: 3},
        {number: "icon", label: FavoriteIcon, func: "favorite"},
        {number: "icon", label: ChipIcon, func: "chips"}
    ],
    [
        {number: 1, label: "1"},
        {number: 2, label: "2"},
        {number: 3, label: "3"},
        {number: "1st-12", label: "1st 12", green: true, rowSpan: 4, vertical: true},
        {number: "1-18", label: "1 - 18", green: true, rowSpan: 2, vertical: true}
    ],
    [
        {number: 4, label: "4"},
        {number: 5, label: "5"},
        {number: 6, label: "6"}
    ],
    [
        {number: 7, label: "7"},
        {number: 8, label: "8"},
        {number: 9, label: "9"},
        {number: "EVEN", label: "EVEN", green: true, rowSpan: 2, vertical: true}
    ],
    [
        {number: 10, label: "10"},
        {number: 11, label: "11"},
        {number: 12, label: "12"}
    ],
    [
        {number: 13, label: "13"},
        {number: 14, label: "14"},
        {number: 15, label: "15"},
        {number: "2nd-12", label: "2nd 12", green: true, rowSpan: 4, vertical: true},
        {number: "RED", label: "RED", rowSpan: 2, vertical: true}
    ],
    [
        {number: 16, label: "16"},
        {number: 17, label: "17"},
        {number: 18, label: "18"}
    ],
    [
        {number: 19, label: "19"},
        {number: 20, label: "20"},
        {number: 21, label: "21"},
        {number: "BLACK", label: "BLACK", rowSpan: 2, vertical: true}
    ],
    [
        {number: 22, label: "22"},
        {number: 23, label: "23"},
        {number: 24, label: "24"}
    ],
    [
        {number: 25, label: "25"},
        {number: 26, label: "26"},
        {number: 27, label: "27"},
        {number: "3rd-12", label: "3rd 12", green: true, rowSpan: 4, vertical: true},
        {number: "ODD", label: "ODD", rowSpan: 2, green: true, vertical: true}
    ],
    [
        {number: 28, label: "28"},
        {number: 29, label: "29"},
        {number: 30, label: "30"}
    ],
    [
        {number: 31, label: "31"},
        {number: 32, label: "32"},
        {number: 33, label: "33"},
        {number: "19-36", label: "19 - 36", green: true, rowSpan: 2, vertical: true}
    ],
    [
        {number: 34, label: "34"},
        {number: 35, label: "35"},
        {number: 36, label: "36"}
    ],
    [
        {number: "2-to-1-1", label: "2 TO 1", green: true, small: true},
        {number: "2-to-1-2", label: "2 TO 1", green: true, small: true},
        {number: "2-to-1-3", label: "2 TO 1", green: true, small: true},
        {number: "timer", label: "", colSpan: 2}
    ],
]


export const ADMIN_MENU_ITEMS = [
    {label: "Search", path: ROUTE_ADMIN_SEARCH_PLAYER_ACCOUNT, roles: [ROLE_SUPER_ADMIN]},
    {label: "Search", path: ROUTE_ADMIN_CEG_SEARCH_PLAYER_ACCOUNT, roles: [ROLE_CEG_ADMIN]},
    {label: "Complete Overview", path: ROUTE_ADMIN_DASHBOARD, roles: [ROLE_SUPER_ADMIN, ROLE_FINANCIAL]},
    {label: "Complete Overview", path: ROUTE_ADMIN_DASHBOARD, hidden:true, roles: [ROLE_SUPER_ADMIN]},
    {label: "Round & Winning Numbers", path: ROUTE_ADMIN_CEG_ROUND_AND_WINNING_NUMBERS, roles: [ROLE_CEG_ADMIN]},
    {label: "Played Rounds With Total Bets And Results", path: ROUTE_ADMIN_CEG_PLAYED_ROUND_WITH_TOTAL_BETS_AND_RESULT, roles: [ROLE_CEG_ADMIN]},
    {label: "Selected Round", hidden:true, path: ROUTE_ADMIN_CEG_SELECTED_ROUND, roles: [ROLE_CEG_ADMIN], backButton: ROUTE_ADMIN_CEG_PLAYED_ROUND_WITH_TOTAL_BETS_AND_RESULT},
    {label: "Round Overview", path: ROUTE_ADMIN_ROUND_OVERVIEW, roles: [ROLE_SUPER_ADMIN]},
    {label: "Selected Round Overview", hidden:true, path: ROUTE_ADMIN_ROUND_SELECTED_OVERVIEW, roles: [ROLE_SUPER_ADMIN], backButton: ROUTE_ADMIN_ROUND_OVERVIEW},
    {label: "Monthly and daily deposits", path: ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS, roles: [ROLE_SUPER_ADMIN]},
    {label: "Daily deposits", hidden:true, path: ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS_SELECTED, roles: [ROLE_SUPER_ADMIN], backButton: ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS},
    {label: "Monthly and daily withdrawals", path: ROUTE_ADMIN_MONTHLY_DAILY_WITHDRAWALS, roles: [ROLE_SUPER_ADMIN]},
    {label: "Daily withdrawals", hidden:true, path: ROUTE_ADMIN_MONTHLY_DAILY_WITHDRAWALS_SELECTED, roles: [ROLE_SUPER_ADMIN], backButton: ROUTE_ADMIN_MONTHLY_DAILY_WITHDRAWALS},
    {label: "Yearly and monthly overview", path: ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW, bottom: "mb-4", roles: [ROLE_SUPER_ADMIN]},
    {label: "Monthly overview", hidden:true, path: ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW_SELECTED, roles: [ROLE_SUPER_ADMIN], backButton: ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW},
    {label: "Waiting for approvals withdrawals", count_holder: "withdrawals", path: ROUTE_ADMIN_WAIT_FOR_APPROVAL_WITHDRAWALS, roles: [ROLE_SUPER_ADMIN, ROLE_FINANCIAL]},
    process.env.REACT_APP_NAME !== "swissco.gaming" && {label: "Waiting for approvals deposits", count_holder: "deposits", path: ROUTE_ADMIN_WAIT_FOR_APPROVAL_DEPOSITS, roles: [ROLE_SUPER_ADMIN, ROLE_FINANCIAL]},
    {label: "Waiting for answers tickets", count_holder: "tickets", path: ROUTE_ADMIN_WAIT_FOR_ANSWER_TICKETS, bottom: "mb-4", roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_AGENT]},
    {label: "Ticket Details", hidden:true, path: ROUTE_ADMIN_TICKET_SELECTED, roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_AGENT], backButton: ROUTE_ADMIN_WAIT_FOR_ANSWER_TICKETS},
    {label: "Ranking On Deposit", path: ROUTE_ADMIN_RANKING_DEPOSIT, roles: [ROLE_SUPER_ADMIN, ROLE_FINANCIAL]},
    {label: "Ranking On Withdrawal", path: ROUTE_ADMIN_RANKING_WITHDRAWAL, roles: [ROLE_SUPER_ADMIN, ROLE_FINANCIAL]},
    {label: "All Players List A/Z", path: ROUTE_ADMIN_USERS_LIST, roles: [ROLE_SUPER_ADMIN]},
    {label: "Round & Winning Numbers", path: ROUTE_ADMIN_ROUND_AND_WINNING_NUMBERS, roles: [ROLE_SUPER_ADMIN]},
    {label: "Top37 Payout - Upcoming Round", path: ROUTE_ADMIN_TOP37_PAYOUTS, roles: [ROLE_SUPER_ADMIN]},
    {label: "Switch Backup Studio", path: ROUTE_ADMIN_ENTER_MANUALLY_WINNING_NUMBER, roles: [ROLE_SUPER_ADMIN]},
    {label: "Send An Email To Players", path: ROUTE_ADMIN_SEND_EMAIL, roles: [ROLE_SUPER_ADMIN]},
    {label: "Charge players account", path: ROUTE_ADMIN_ACCOUNT_CHARGE_CREDIT, roles: [ROLE_SUPER_ADMIN]},
    {label: "Logout", path: ROUTE_LOGOUT, logout: true, roles: [ROLE_SUPER_ADMIN, ROLE_CEG_ADMIN, ROLE_ADMIN, ROLE_AGENT]},

];

export const ADMIN_USER_PROFILE_MENU_ITEMS = [
    {label: "COMPLETE OVERVIEW", path: ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW, roles: [ROLE_SUPER_ADMIN, ROLE_CEG_ADMIN, ROLE_FINANCIAL]},
    {label: "ALL DEPOSIT", path: ROUTE_ADMIN_USER_ACCOUNT_CASH_IN, roles: [ROLE_SUPER_ADMIN, ROLE_CEG_ADMIN, ROLE_FINANCIAL]},
    {label: "ALL WITHDRAWAL", path: ROUTE_ADMIN_USER_ACCOUNT_CASH_OUT, roles: [ROLE_SUPER_ADMIN, ROLE_CEG_ADMIN, ROLE_FINANCIAL]},
    {label: "PLAYED HISTORY", path: ROUTE_ADMIN_USER_ACCOUNT_PLAYED, roles: [ROLE_SUPER_ADMIN, ROLE_CEG_ADMIN]},
    {label: "Logon History", path: ROUTE_ADMIN_USER_ACCOUNT_LOGON, roles: [ROLE_SUPER_ADMIN, ROLE_CEG_ADMIN]},
    {label: "PROFILE INFO", path: ROUTE_ADMIN_USER_ACCOUNT_PROFILE, roles: [ROLE_SUPER_ADMIN, ROLE_CEG_ADMIN]},

];