import axios from "axios";
import AdminTokenService from "./admin.token.service";

const https = require('https');
const agent = new https.Agent({
    rejectUnauthorized: false
});
const instance = axios.create({
    baseURL: process.env.REACT_APP_BACK_END_WEBSERVICE_API,
    httpsAgent: agent,
    headers: {
        "Content-Type": "application/json",
    },
});


instance.interceptors.request.use(
    (config) => {
        const token = AdminTokenService.getAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        config.httpsAgent = agent;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/oauth/token" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    return instance(originalConfig);
                } catch (_error) {

                    return Promise.reject(_error);
                }
            }

        }else if (originalConfig.url === "/oauth/token"){
            if(err.response.status === 401){
                AdminTokenService.logout();
                window.location.reload();
            }

        }

        return Promise.reject(err);
    }
);

export default instance;