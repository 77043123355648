import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
class WithdrawalStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const {status, applicationContext} = this.props;
        const description = {
            0: {
                label: applicationContext.translator("Waiting for approval"),
                color: "text-warning"
            },
            1: {
                label: applicationContext.translator("Approved"),
                color: "text-success"
            },
            2: {
                label: applicationContext.translator("Declined"),
                color: "text-danger"
            }
        }
        return (
            description.hasOwnProperty(status)?<Form.Text className={description[status].color}>
                {description[status].label}
            </Form.Text>:null
        );
    }
}

export default withApplicationContext(WithdrawalStatus);