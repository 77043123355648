import React, {Component} from 'react';
import {withApplicationContext} from "../../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../../contexts/AccountContext";
import {withRouter} from "react-router-dom"
import {Formik} from "formik";
import * as Yup from "yup";
import {Alert, Col, Form, FormText, InputGroup, Row} from "react-bootstrap";
import AccountContainer from "../../AccountContainer";
import {accountCodeParse, numberWithCommas} from "../../../../helpers/helpers";
import LoadingContainer from "../../../../components/common/LoadingContainer";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {withAlert} from "react-alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from "./Limits.module.css";
import DepositService from "../../../../services/deposit.service";
import {parse} from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import UserService from "../../../../services/user.service";
class DepositLimit extends Component {
    timer = null;
    constructor(props) {
        super(props);
        this.state = {
            amount: 0,
            period: "monthly",
            successfully: false,
            disabled: true,
            timer: ""
        }
    }
    handleSubmit = async (values, actions) => {
        const {alert, applicationContext, accountContext} = this.props;
        try{
            this.setState({
                disabled: true
            })
            const result = await UserService.doUpdateSettings({
                action: "deposit_limit",
                period: values.period,
                amount: values.amount
            });
            accountContext.updateUserInfo(result.data)
            this.handleUpdateEnable(result.data.settings)
            alert.success(applicationContext.translator("Your account settings has been updated successfully"));
            actions.resetForm()
        }catch (e){
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, e.response.data.errors[field][0])
            })

        }


    }
    componentDidMount() {
        const {accountContext} = this.props;
        this.handleUpdateEnable(accountContext.userInfo.settings)
    }
    handleUpdateEnable = (data) => {
        if(!data){
            return this.setState({
                disabled: false
            })
        }
        if(data['deposit_limitation_start_at']){
            let changeTime = new Date(data['deposit_limitation_start_at']).setDate(new Date(data['deposit_limitation_start_at']).getDate() + 30);
            if(new Date(changeTime).getTime() <= new Date().getTime()){
                return this.setState({
                    disabled: false,
                    period: (data['deposit_limitation_period'])?data['deposit_limitation_period']:"monthly",
                    amount: data['deposit_limitation_amount'],
                })
            }else{
                new Date(changeTime).setDate(new Date(changeTime).getDate()); //just for this demo today + 7 days
                let self = this;
                this.timer = setInterval(function() {
                    self.timeBetweenDates(new Date(changeTime));
                }, 1000);
                return this.setState({
                    disabled: true,
                    period: (data['deposit_limitation_period'])?data['deposit_limitation_period']:"monthly",
                    amount: data['deposit_limitation_amount'],
                })
            }
        }
        return this.setState({
            disabled: false,
            period: (data['deposit_limitation_period'])?data['deposit_limitation_period']:"monthly",
            amount: data['deposit_limitation_amount'],
        })

    }
    timeBetweenDates = (toDate) => {
        const {applicationContext} = this.props;
        let dateEntered = toDate;
        let now = new Date();
        let difference = dateEntered.getTime() - now.getTime();

        if (difference <= 0) {
            clearInterval(this.timer);
        } else {
            let seconds = Math.floor(difference / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);

            hours %= 24;
            minutes %= 60;
            seconds %= 60;
            let out = "";
            if(days === 1)
                out += "1 "+applicationContext.translator("day")+" ";
            else if(days > 1)
                out += days+" "+applicationContext.translator("days")+" ";
            if(hours < 10) hours = "0"+hours;
            if(minutes < 10) minutes = "0"+minutes;
            if(seconds < 10) seconds = "0"+seconds;
            out += hours+":"+minutes+":"+seconds;
            this.setState({
                timer: out
            })
        }
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <div className={"form-center"}>
                <Formik
                    innerRef={(ref) => this.refForm = ref}
                    enableReinitialize={true}
                    initialValues={this.state}
                    validationSchema={Yup.object().shape({
                        period: Yup.string().required(applicationContext.translator("You must select your period")),
                        amount: Yup.number().required(applicationContext.translator("You must enter amount")).min(1, applicationContext.translator("Amount must be greater than or equal to 1")),
                    })}
                    onSubmit={this.handleSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) => (
                        <Form onSubmit={handleSubmit} className={styles.FullWidth}>
                            <Form.Group as={Row} >
                                <Col md={12} className={"text-center"}>
                                    <Form.Group as={Row} className={"mb-3"}>
                                        <div className={"text-left mb-3"} style={{fontSize: "15px", textAlign: "justify"}}>
                                            {applicationContext.translator("Because of responsible gaming, we advise setting a deposit limit for yourself.")}
                                        </div>

                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group as={Row} className={"mb-3"} style={{ display: 'none' }}>
                                        <Col className={"text-left start-center-value"}>
                                            <Form.Check
                                                type={"radio"}
                                                id={`deposit-radio-daily`}
                                                label={applicationContext.translator(`Daily`)}
                                                name={"period"}
                                                disabled={this.state.disabled}
                                                value={"daily"}
                                                checked={values.period === "daily"}
                                                onChange={handleChange}
                                            />
                                            <Form.Check
                                                type={"radio"}
                                                id={`deposit-radio-weekly`}
                                                name={"period"}
                                                disabled={this.state.disabled}
                                                label={applicationContext.translator(`Weekly`)}
                                                value={"weekly"}
                                                checked={values.period === "weekly"}
                                                onChange={handleChange}
                                                className={"ml-5"}
                                            />
                                            <Form.Check
                                                type={"radio"}
                                                id={`deposit-radio-monthly`}
                                                name={"period"}
                                                label={applicationContext.translator(`Monthly`)}
                                                disabled={this.state.disabled}
                                                value={"monthly"}
                                                checked={values.period === "monthly"}
                                                onChange={handleChange}
                                                className={"ml-5"}
                                            />

                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className={!touched.amount && "mb-3"}>
                                        <Col>
                                            <Form.Label className={classNames(errors.amount && "text-danger")}>{applicationContext.translator("Amount (Monthly)")}</Form.Label>
                                            <InputGroup className={"mt-1"}>
                                                <Form.Control
                                                    aria-describedby="basic-addon2"
                                                    name={"amount"}
                                                    disabled={this.state.disabled}
                                                    onChange={(e) => {
                                                        if(e.nativeEvent.inputType === "insertFromPaste"){
                                                            let v = e.target.value;
                                                            v = e.target.value.replace(".", "");
                                                            if(e.target.value.includes(",") && parseFloat(e.target.value) !== NaN){
                                                                v = v.replace(",", ".");
                                                                v = Math.round(v).toString();
                                                            }
                                                            setFieldValue('amount', v)
                                                        }else{
                                                            if(!e.target.value.includes(",") && !e.target.value.includes("."))
                                                                handleChange(e);
                                                        }

                                                    }}

                                                    onBlur={(e) => {
                                                        if(!isNaN(e.target.value) && e.target.value !== "")
                                                            e.target.value = numberWithCommas(parseFloat(e.target.value));
                                                        else
                                                            e.target.value = 0;

                                                    }}
                                                    value={values.amount}
                                                    className={"yellow-text"}
                                                    isValid={touched.amount && !errors.amount}
                                                    isInvalid={touched.amount && errors.amount}
                                                    autoComplete="off"
                                                />
                                                <InputGroup.Append>
                                                    <InputGroup.Text>USD</InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    {touched.amount && <Form.Group as={Row} className={"mb-3"}>
                                        <Col className={"text-left"}>
                                            <Form.Text className="text-danger mt-2">
                                                {errors.amount}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>}

                                    <Form.Group className={"d-flex justify-content-center"}>
                                        <button className={"btn btn-account"} disabled={this.state.disabled} type={"submit"}>{applicationContext.translator("UPDATE")}</button>
                                    </Form.Group>

                                    {this.state.disabled && <div className={styles.Timer}>
                                        <div className={"no-letter-space"}>{applicationContext.translator("You can change your deposit limitation settings in next")}</div>
                                        <span>{this.state.timer}</span>
                                    </div>}

                                </Col>



                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(DepositLimit))));