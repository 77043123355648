import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withGameTableContext} from "../../../contexts/GameTableContext";
import styles from "./VideoPlayer.module.css";
import ContextMenu from "../ContextMenu/ContextMenu";
import classNames from "classnames";
class VideoPlayer extends Component {
    player = null;
    constructor(props) {
        super(props);
        this.state = {
            quality: 2,
            entries: []
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {gameTableContext} = this.props;
        if(gameTableContext.state.volume !== prevProps.gameTableContext.state.volume && this.player){
            this.player.setVolume(gameTableContext.state.volume);
        }
    }
    componentDidMount() {
        const {gameTableContext} = this.props;

        if(!gameTableContext.state.practice){
            // eslint-disable-next-line no-undef
            this.player = new NanoPlayer("playerDiv");
            let self = this;
            this.player.setup(this.handleLoadConfig()).then(function (config) {
                self.player.setVolume(gameTableContext.state.volume);
                self.player.play();
                console.log(self.player)
                console.log("video streaming loaded");
            }, function (error) {
                alert(error.message);
            });
        }


    }
    handleLoadConfig = (update = false) => {
        const {gameTableContext} = this.props;
        let entries = [];
        let qualities = ["High", "Medium", "Low"];
        gameTableContext.state.feedStreamName.map((feed, index) => {
            entries.push({
                "index": index,
                "label": qualities[index],
                "h5live": {
                    "rtmp": {
                        "streamname": feed
                    }
                }
            })
        })
        let quality = this.state.quality;
        if(this.state.quality > entries.length - 1)
            quality = entries.length - 1;
        let config = {
            source: {
                "defaults": {
                    "service": "bintu"
                },
                "entries": entries,
                "options": {
                    "adaption": {
                        "rule": "deviationOfMean2",
                        "downStep": 1
                    },
                    "switch": {
                        'method': 'server',
                        'pauseOnError': false,
                        'forcePlay': true,
                        'fastStart': true,
                        'timeout': 20
                    }
                },
                "startIndex": quality
            },
            "playback": {
                "autoplay": true,
                "automute": false,
                "muted": false
            },
            "style": {
                "controls": false,
                "width": '1280px',
                "height": '620px'
            },
            "events": {
                "onPlay": () => {}
            }
        };
        config.events.onPlay = (event) => {
            console.log('Playing');
            console.log('play stats: ' + JSON.stringify(event.data.stats));
        };
        this.setState({
            entries,
            quality
        })
        return update?config.source:config;
    }
    onChangeQuality = (quality) => {
        const {gameTableContext} = this.props;
        if(quality === this.state.quality) return;
        let self = this;
        this.setState({
            quality
        }, () => {
            self.player.updateSource(this.handleLoadConfig(true)).then(function (config) {
                console.log("change quality successfully");
            }, function (error) {
                alert(error.message);
            });
        })
    }
    onClickFullscreen = (e) => {
        const {gameTableContext} = this.props;
        window.open(gameTableContext.state.wheelId?`${process.env.PUBLIC_URL}/studio1`:`${process.env.PUBLIC_URL}/studio9`,'','directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=4000,height=4000,fullscreen=yes');
        this.setState({
            context: false
        })
    }
    onClickPlay = (e) => {
        this.player.play();
        this.setState({
            context: false
        })
    }
    onClickPause = (e) => {
        this.player.pause();
        this.setState({
            context: false
        })
    }
    render() {
        const {gameTableContext, mobile, applicationContext} = this.props;
        return (
            !gameTableContext.state.practice?
            <React.Fragment>
                <div className={classNames(styles.MonitorScreenWrapper, mobile && styles.MobileScreenWrapper)} onContextMenu={(e) => {
                    e.preventDefault();
                    this.setState({ context: true })
                }}>
                    <div id="playerDiv" className={mobile?styles.VideoPlayer:styles.MonitorVideo}></div>
                    {this.state.entries.length > 0 && <ul className={classNames(styles.QualityWrapper, gameTableContext.state.isMobileVersion && styles.MobileQualityWrapper)} style={{ direction: applicationContext.translator("direction") === "rtl"?"ltr":"rtl"}}>
                        {this.state.entries.map((entry, index) => {
                            return (<li onClick={(e) => this.onChangeQuality(entry.index)} className={this.state.quality === entry.index && "active"}>{applicationContext.translator(entry.label)}</li>)
                        })}

                    </ul>}
                    {this.state.context &&
                    <ContextMenu show={this.state.context}
                                 onClose={(e) => {
                                     this.setState({ context: false })
                                 }}
                    >
                        <li onClick={this.onClickPlay}>
                            <div>{applicationContext.translator("Play")}</div>
                        </li>
                        <li onClick={this.onClickPause}>
                            <div>{applicationContext.translator("Pause")}</div>
                        </li>
                        <li onClick={this.onClickFullscreen}>
                            <div>{applicationContext.translator("Fullscreen")}</div>
                        </li>

                    </ContextMenu>}
                </div>

            </React.Fragment>:
            <React.Fragment>
                <div className={styles.MonitorBackground} style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/assets/img/max.jpg')`}}>

                </div>
            </React.Fragment>
        );
    }
}

export default withApplicationContext(withGameTableContext(VideoPlayer));