import React, {Component} from 'react';
import styles from "./Login.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withGameTableContext} from "../../../contexts/GameTableContext";
import * as Yup from "yup";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {NavLink, withRouter} from "react-router-dom"
import {
    ROUTE_ADMIN_DASHBOARD,
    ROUTE_FORGET_PASSWORD,
    ROUTE_REGISTER, ROUTE_TABLE_LIVE_PLAY
} from "../../../routers/routes";
import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import {parseErrorMessage} from "../../../helpers/helpers";
import {withAlert} from "react-alert";
import TokenService from "../../../services/token.service";
import Footer from "../../../components/ui/Footer";
class Login extends Component {
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            neighbours: false,
            close: false,
            loading: false,
            submitting: false,
            redirect: (urlSearchParams.has('r'))?((props.level == "user")?ROUTE_TABLE_LIVE_PLAY:decodeURIComponent(urlSearchParams.get('r'))):((props.level == "user")?ROUTE_TABLE_LIVE_PLAY:ROUTE_ADMIN_DASHBOARD)
        }

    }
    componentDidMount = async () => {
        const {history, level, location, applicationContext, alert} = this.props;
        const urlSearchParams = new URLSearchParams(location.search);
        this.setState({loading: true});
        if(urlSearchParams.has('token')){
            try {
                TokenService.removeUser();

                let token = JSON.parse(atob(urlSearchParams.get('token')));
                TokenService.updateLocalAccessToken(token);
                const userInfo = await UserService.getUserBoard();
                if(userInfo){
                    applicationContext.updateUserInfo(userInfo);
                    history.push(this.state.redirect)
                }

            } catch (err) {

            }


        }else{
            if(level && localStorage.getItem("token")){
                try {
                    const userInfo = await UserService.getUserBoard();
                    if(userInfo){
                        applicationContext.updateUserInfo(userInfo);
                        history.push(this.state.redirect)
                    }
                } catch (err) {
                    TokenService.removeUser();
                }
            }
        }
        this.refInput.focus();
        this.setState({loading: false});
    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, alert, history, level} = this.props;
        if(this.state.loading) return;
        this.setState({submitting: true});
        try {
            const token  = await AuthService.login(values.email, values.password, level);
            TokenService.updateLocalAccessToken(token);

            const userInfo = await UserService.getUserBoard();
            applicationContext.updateUserInfo(userInfo);
            history.push(this.state.redirect)

        } catch (err) {
            alert.error(applicationContext.translator(parseErrorMessage(err)));
        }
        this.setState({submitting: false})
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <div className={styles.FullHeight}>
                <div className={styles.WebsiteLoginContainer}>
                    {this.state.loading?
                        <div className={styles.LoadingContainer}>
                            {process.env.REACT_APP_NAME === "swissco.gaming" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} width={150}/>}
                            {process.env.REACT_APP_NAME === "my.roulette" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo-my.roulette.png`} />}
                        </div>:
                        <div className={styles.LoginWrapper}>
                            <div className={"text-center"}>
                                {process.env.REACT_APP_NAME === "swissco.gaming" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} width={150}/>}
                                {process.env.REACT_APP_NAME === "my.roulette" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo-my.roulette.png`} />}
                            </div>
                            <div className={styles.LoginBody}>
                                <h5>{applicationContext.translator("Enter username and password.")}</h5>
                                <Formik
                                    innerRef={(ref) => this.refForm = ref}
                                    initialValues={{
                                        email: this.state.email,
                                        password: this.state.password,
                                    }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string().required(applicationContext.translator("You must enter your ${path}")).email(applicationContext.translator("E-Mail Address format is invalid.")).label(applicationContext.translator("E-Mail Address")),
                                        password: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Password")).min(8, applicationContext.translator("Password must be minimum 8 characters"))
                                    })}
                                    onSubmit={this.handleSubmit}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleSubmit,
                                          isSubmitting,
                                          setFieldValue
                                      }) => (
                                        <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                            <Form.Group as={Row} className={!touched.email && "mb-3"}>
                                                <Col className={styles.StartCenterValue}>
                                                    <Form.Control type="text"
                                                                  name={"email"}
                                                                  onChange={handleChange}
                                                                  placeholder={applicationContext.translator("E-Mail Address")}
                                                                  value={values.username}
                                                                  isValid={touched.email && !errors.email}
                                                                  isInvalid={touched.email && errors.email}
                                                                  readOnly={true}
                                                                  onFocus={(e) => {
                                                                      e.target.select()
                                                                      this.refInput.removeAttribute('readonly');
                                                                  }}
                                                                  autoComplete="off"
                                                                  ref={(ref) => this.refInput = ref}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            {touched.email && <Form.Group as={Row} className={"mb-3"}>
                                                <Col>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.email}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>
                                            }
                                            <Form.Group as={Row} className={!touched.password && "mb-3"}>
                                                <Col className={styles.StartCenterValue}>
                                                    <Form.Control type="password"
                                                                  name={"password"}
                                                                  onChange={handleChange}
                                                                  placeholder={applicationContext.translator("Password")}
                                                                  value={values.password}
                                                                  isValid={touched.password && !errors.password}
                                                                  isInvalid={touched.password && errors.password}
                                                                  readOnly={true}
                                                                  onFocus={(e) => {
                                                                      this.refPasswordInput.removeAttribute('readonly');
                                                                  }}
                                                                  autoComplete="off"
                                                                  ref={(ref) => this.refPasswordInput = ref}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            {touched.password && <Form.Group as={Row} className={"mb-3"}>
                                                <Col>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.password}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}
                                            <br/>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col className={"text-center"}>
                                                    <Form.Text className={styles.SubLoginText}>
                                                        {applicationContext.translator("Don't have an account yet?")} <NavLink to={ROUTE_REGISTER}>{applicationContext.translator("Click here to register")}</NavLink>
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={"mb-5"}>
                                                <Col className={"text-center"}>
                                                    <Form.Text className={styles.SubLoginText}>
                                                        {applicationContext.translator("Forgot your Password?")} <NavLink to={ROUTE_FORGET_PASSWORD}>{applicationContext.translator("Click here to reset")}</NavLink>
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group className={"text-center"}>
                                                <button disabled={this.state.submitting} className={"btn btn-account"} type={"submit"}>{this.state.submitting?<Spinner animation="border" variant="light" size={"sm"} />:applicationContext.translator("Login")}</button>
                                            </Form.Group>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                        </div>}

                </div>
                <Footer />
            </div>

        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withGameTableContext(Login))));