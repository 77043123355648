import React, {Component} from 'react';
import AppHeader from "../../components/ui/AppHeader/AppHeader";
import Footer from "../../components/ui/Footer/Footer";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {BodyContainer} from "./style";
class BaseMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        const {applicationContext, children} = this.props;
        return (
            <BodyContainer dir={applicationContext.translator("direction")}>
                <AppHeader />
                {children}
                <Footer />
            </BodyContainer>
        );
    }
}

export default withApplicationContext(BaseMaster);