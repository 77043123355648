import React, {Component} from "react";
import styles from "./AccountTable.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
class TableColHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: props.sort
        }
    }
    onSort = async (sort, align) => {
        const { onSort } = this.props;
        this.setState({
            sort
        }, async () => {
            await onSort(sort, align);
        })
    }
    render() {
        const { applicationContext, children, onSort, align, sorted, sort, width, mobile, id, ref } = this.props
        return (
            !applicationContext.state.isMobileVersion?(
                <th className={classNames(styles.TableHeader, mobile && styles.ShowMobileVersion)} width={width} id={id} ref={ref}>
                    <div className={styles.Cell}>
                        {children} {onSort &&
                    <React.Fragment>
                        <FontAwesomeIcon icon={"chevron-down"} className={classNames(align === "asc" && sorted === sort && styles.CellActive)} onClick={(e) => this.onSort(this.state.sort, "asc")} />
                        <FontAwesomeIcon icon={"chevron-up"} className={classNames(align === "desc" && sorted === sort && styles.CellActive)} onClick={(e) => this.onSort(this.state.sort, "desc")} />
                    </React.Fragment>
                    }
                    </div>

                </th>
            ):(
                <React.Fragment>
                    <div className={classNames(styles.TableHeader, mobile && styles.ShowMobileVersion)} width={width} id={id} ref={ref}>
                        <div className={styles.Cell}>
                            {children} {onSort &&
                                <React.Fragment>
                                    <FontAwesomeIcon icon={"chevron-down"} className={classNames(align === "asc" && sorted === sort && styles.CellActive)} onClick={(e) => this.onSort(this.state.sort, "asc")} />
                                    <FontAwesomeIcon icon={"chevron-up"} className={classNames(align === "desc" && sorted === sort && styles.CellActive)} onClick={(e) => this.onSort(this.state.sort, "desc")} />
                                </React.Fragment>
                             }
                        </div>
                    </div>
                </React.Fragment>
            )
        );
    }
}
export default withApplicationContext(TableColHeader);