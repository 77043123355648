import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import PanelContainer from "../PanelContainer";
import PageTitleContainer from "../Search/PageTitleContainer";
import * as Yup from "yup";
import {Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import styles from "./SendEmail.module.css";
import {numberWithCommas} from "../../../helpers/helpers";
import classNames from "classnames";
import {Formik} from "formik";
import AdminService from "../../../services/admin.service";
class SendEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    handleSubmit = async (values, actions) => {
        this.setState({submitting: true, successfully: false, error: false})
        try{
            await AdminService.doSendBroadcastEmail({
                message: values.message,
                subject: values.subject
            });
            this.setState({
                successfully: true,
                submitting: false
            })
            actions.resetForm();

        }catch (e){
            if(e.response.data.error === "AUTHENTICATION_FAILED"){
                this.setState({
                    error: true,
                    successfully: false,
                    submitting: false
                })
            }else{
                Object.keys(e.response.data.errors).map((field) => {
                    actions.setFieldError(field, e.response.data.errors[field][0])
                })
                this.setState({
                    error: false,
                    successfully: false,
                    submitting: false
                })
            }


        }

    }
    render() {
        return (
            <PanelContainer>
                <PageTitleContainer center={true} fullTitleWidth={500}/>
                <div className={"form-center"}>
                    <Formik
                        innerRef={(ref) => this.refForm = ref}
                        enableReinitialize={true}
                        initialValues={{
                            message: "",
                            subject: ""
                        }}
                        validationSchema={Yup.object().shape({
                            message: Yup.string().required("You must enter ${path}").min(9).label("Message"),
                            subject: Yup.string().required("You must enter ${path}").min(3).label("Subject"),
                        })}
                        onSubmit={this.handleSubmit}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <Form onSubmit={handleSubmit} className={styles.FullWidth}>
                                <Form.Group as={Row} >
                                    <Col md={12} className={"text-center"}>
                                        <Form.Group className={(!errors.subject || !touched.subject) && "mb-3"}>
                                            <Col>
                                                <Form.Label className={styles.FormLabel}>Subject :</Form.Label>
                                                <Form.Control type="text"
                                                              onChange={handleChange}
                                                              name={"subject"}
                                                              isValid={touched.subject && !errors.subject}
                                                              isInvalid={touched.subject && errors.subject}
                                                              value={values.subject}
                                                />
                                            </Col>
                                        </Form.Group>
                                        {touched.subject && <Form.Group className={"mb-2"}>
                                            <Col className={"text-left"}>
                                                <Form.Text className="text-danger">
                                                    {errors.subject}
                                                </Form.Text>
                                            </Col>
                                        </Form.Group>}
                                        <Form.Group className={(!errors.message || !touched.message) && "mb-3"}>
                                            <Col>
                                                <Form.Label className={styles.FormLabel}>Message :</Form.Label>
                                                <Form.Control as="textarea" rows={20}
                                                              onChange={handleChange}
                                                              name={"message"}
                                                              isValid={touched.message && !errors.message}
                                                              isInvalid={touched.message && errors.message}
                                                              value={values.message}
                                                />
                                            </Col>
                                        </Form.Group>
                                        {touched.message && <Form.Group className={"mb-2"}>
                                            <Col className={"text-left"}>
                                                <Form.Text className="text-danger">
                                                    {errors.message}
                                                </Form.Text>
                                            </Col>
                                        </Form.Group>}
                                        <Form.Group>
                                            <Col>
                                                <Form.Group className={"d-flex justify-content-center"}>
                                                    <button className={"btn btn-account full-width"} disabled={this.state.submitting} type={"submit"}>{this.state.submitting?<Spinner animation="grow" size={"sm"} variant="light" />:"Send"}</button>
                                                </Form.Group>

                                                {this.state.successfully && <Form.Group as={Row} className={classNames(styles.TextAlert, "green-text")}>
                                                    <Form.Text className={classNames(styles.TextAlert, "green-text")}>
                                                        Emails has been sent to all users
                                                    </Form.Text>

                                                </Form.Group>}

                                            </Col>
                                        </Form.Group>

                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                </div>
            </PanelContainer>
        )
    }
}

export default withRouter(withAdminAccountContext(SendEmail));