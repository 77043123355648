import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import PanelContainer from "../PanelContainer";
import AccountTable from "../../../components/ui/AccountTable";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import moment from "moment-timezone";
import {accountCodeParse, numberWithCommas, timezone, utcTime} from "../../../helpers/helpers";
import LeftSidePopup from "../../../components/ui/LeftSidePopup";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import AdminService from "../../../services/admin.service";
import PageTitleContainer from "../Search/PageTitleContainer";
class LogonHistory extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dialog: false,
            history: null,
            position: null
        }
    }
    render() {
        const {accountContext} = this.props;
        return (
            <PanelContainer>
                {!this.state.loading && <React.Fragment>
                    <PageTitleContainer center={true} fullTitleWidth={500}/>
                    <div className={"form-center"}>
                        <AccountTable loadData={AdminService.getLogonHistory} header={(onSort) => {
                            return <TableRow>
                                <TableColHeader>#</TableColHeader>
                                <TableColHeader>Account ID</TableColHeader>
                                <TableColHeader>Date</TableColHeader>
                                <TableColHeader>Time</TableColHeader>
                                <TableColHeader>Action</TableColHeader>
                                <TableColHeader>IP</TableColHeader>
                            </TableRow>
                        }}
                          sort={"id"}
                          align={"desc"}
                          render={(row, index, number) =>
                              <TableRow key={index} >
                                  <TableCol>{number}</TableCol>
                                  <TableCol yellow={true}>
                                      {accountCodeParse(row.issuer['account_code'])}
                                  </TableCol>
                                  <TableCol>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableCol>
                                  <TableCol>{utcTime(row['created_at']).format('HH:mm:ss')}</TableCol>
                                  <TableCol>{row['action']}</TableCol>
                                  <TableCol>{row['ip']}</TableCol>
                              </TableRow>
                          }
                          params={{...(accountContext.profileInfo && { user_id: accountContext.profileInfo['id'] }) }}
                          ref={(ref) => this.refTable = ref}
                          mobile={(row, index, number, total, onNext, onPrevious) =>
                              <TableMobileRow key={index} >
                                  <TableMobileCol>
                                      <label>Number / Total Records</label>
                                      <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Account ID</label>
                                      <TableColValue>{accountCodeParse(row.issuer['account_code'])}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Date</label>
                                      <TableColValue>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Time</label>
                                      <TableColValue>{utcTime(row['created_at']).format('hh:mm:ss')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Action</label>
                                      <TableColValue>{row['action']}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>IP</label>
                                      <TableColValue>{row['ip']}</TableColValue>
                                  </TableMobileCol>
                              </TableMobileRow>}
                        />
                    </div>
                </React.Fragment>}
            </PanelContainer>
        )
    }
}

export default withAdminAccountContext(withRouter(LogonHistory));