import api from "./api";
import {queryStringSerialize} from "../helpers/helpers"
const doDeposit = (data) => {
    return api
        .post("/api/account/deposit/create", data)
        .then((response) => {
            return response.data;
        });
};


const getList = (data) => {
    return api
        .get("/api/account/deposit/list?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};


const DepositService = {
    doDeposit,
    getList
};

export default DepositService;