import React, {Component} from "react";
import styles from "./AccountTable.module.css";
import classNames from "classnames";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
class TableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }
    render() {
        const { applicationContext, children, onClick, active, selected, bg } = this.props
        return (
            <React.Fragment>
                {!applicationContext.state.isMobileVersion?(
                    <tr className={classNames(onClick && styles.RowLink, active && styles.ActiveCell, selected && styles.ActiveCell)} onClick={onClick} style={bg?{backgroundColor: bg}:null}>
                        {children}
                    </tr>
                ):(
                    <React.Fragment>
                        <div className={classNames(styles.TableRow, onClick && styles.RowLink, active && styles.ActiveCell)}>
                            {children}
                        </div>
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}
export default withApplicationContext(TableRow);