import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {NavLink, withRouter} from "react-router-dom"
import AccountTable from "../../../components/ui/AccountTable";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import moment from 'moment-timezone';
import {accountCodeParse, numberWithCommas, parseErrorMessage, timezone, utcTime} from "../../../helpers/helpers";
import {Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik} from "formik";
import * as Yup from "yup";
import Popup from "../../../components/ui/Popup";
import {withAlert} from "react-alert";
import {ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW} from "../../../routers/routes";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import PanelContainer from "../PanelContainer";
import AdminService from "../../../services/admin.service";
import PageTitleContainer from "../Search/PageTitleContainer";
import WaitForApprovalDepositsSearch from "./WaitForApprovalDepositsSearch";
import WithdrawalStatus from "../../../components/ui/WithdrawalStatus";
import UsersSearch from "../Users/UsersSearch";
import {ROLE_SUPER_ADMIN} from "../../../helpers/constants";
class WaitForApprovalDeposits extends Component {
    refForm = React.createRef();
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dialog: false,
            detailsDialog: false,
            detailsDialogRow: null,
            action: "accept",
            row: null,
            index: -1,
            submitting: false,
            date: null,
            datepicker: false,
            keyword: "",
            typingTimeout: null,
            status: "",
            search: {
                keyword: "",
                status: "0"
            },
        }
    }
    componentDidMount() {
        if(localStorage.getItem('backed_from_account_state')){
            let state = JSON.parse(localStorage.getItem('backed_from_account_state'));
            localStorage.removeItem('backed_from_account_state');
            this.setState(state)
        }
    }
    onCloseDetailsPopup = (e = null) => {
        this.setState({
            detailsDialog: false,
            detailsDialogRow: null
        })
    }
    onOpenDetailsPopup = (e, row) => {
        e.preventDefault();
        this.setState({
            detailsDialog: true,
            detailsDialogRow: row,
        })
    }
    onClosePopup = (e = null) => {
        if(this.state.submitting) return;
        this.setState({
            dialog: false,
            action: "accept",
            index: -1,
            row: null,
            status: "",
            searchComponent: null
        })
    }
    onOpenPopup = (e, action, row, index) => {
        e.preventDefault();
        this.setState({
            dialog: true,
            action,
            index,
            row,
        }, () => {
            this.refInput.select()
        })
    }
    onSubmitPopup = (e) => {
        this.refForm.submitForm();
    }
    handleSubmit = async (values, actions) => {
        const {applicationContext, alert, history} = this.props;
        if(this.state.submitting) return;
        this.setState({submitting: true});
        try {
            const response  = await AdminService.doUpdateDeposit({
                id: this.state.row.id,
                status: (this.state.action == "accept")?1:2,
                description: values.description,
                hash_code: values.hash_code
            });
            this.setState({
                dialog: false,
                action: "accept",
                row: null
            });
            this.refTable.doUpdateRow(this.state.index, response.data);
            alert.success(response.message);
            this.refTable.search(this.state.search);
        } catch (err) {
            alert.error(parseErrorMessage(err));
        }
        this.setState({submitting: false})
    }
    onChangeDate = (date) => {
        this.setState({
            date,
            datepicker: false
        }, this.handleSearch)
    }
    handleSearch = () => {
        this.refTable.search({
            keyword: this.state.keyword,
            date: (this.state.date)?moment(new Date(this.state.date)).format('YYYY-MM-DD'):null
        })
    }
    onSearchKeyword = (e) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        self.setState({
            keyword: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.handleSearchKeyword(self.state.keyword);
            }, 1000)
        });
    }
    handleSearchKeyword = (value) => {
        this.refTable.search({
            keyword: value,
            date: this.state.date
        }, this.handleSearch)
    }
    doUpdateSearchData = (data) => {
        this.setState({
            ...this.state,
            search: data
        })
    }
    render() {
        const {accountContext} = this.props;
        return (
            <PanelContainer>
                <PageTitleContainer searchBar={this.state.searchComponent}/>

                <div className={"form-center"}>
                    <AccountTable loadData={AdminService.getDepositList} sort={"created_at"} align={"desc"} full={true}
                      header={(onSort, sort, align) => {
                            return <TableRow>
                                <TableColHeader sort={"id"} align={align} sorted={sort} onSort={onSort} width={"100"}>#</TableColHeader>
                                <TableColHeader>Date</TableColHeader>
                                <TableColHeader>Time</TableColHeader>
                                <TableColHeader width={"150"}>Account ID</TableColHeader>
                                <TableColHeader id={"table-cell-03"}>Deposit Amount</TableColHeader>
                                <TableColHeader id={"table-cell-02"} width={200}>Approved</TableColHeader>
                                <TableColHeader id={"table-cell-01"} width={200}>Declined</TableColHeader>
                            </TableRow>
                      }}
                      render={(row, index, number) =>
                          <TableRow key={index} bg={row['status'] === 0 && "#1b1e22"}>
                              <TableCol>{number}</TableCol>
                              <TableCol>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableCol>
                              <TableCol>{utcTime(row['created_at']).format('HH:mm:ss')}</TableCol>
                              <TableCol width={"100"} yellow={true}>
                                  <a href={"javascript:void(0)"} className={"underline-link yellow-text"} onClick={(e) => accountContext.doGotoProfile(ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW, ":profileId", row['user']['account_code'], this.state, this.refTable.getState())}>
                                      {accountCodeParse(row.user['account_code'])}
                                  </a>
                              </TableCol>
                              <TableCol>
                                  <a href={"javascript:void(0)"} className={"underline-link yellow-text"} onClick={(e) => this.onOpenDetailsPopup(e, row)}>
                                    {numberWithCommas(row['amount'], true)}
                                  </a>
                              </TableCol>
                              <TableCol>
                                  <React.Fragment>
                                      {row['status'] === 0 && <span className={"green-text hover-green"} onClick={(e) => this.onOpenPopup(e, "accept", row, index)} >APPROVED</span>}
                                      {row['status'] === 1 && <span className={"green-text"}>APPROVED</span>}
                                      {row['status'] === 2 && <span className={"gray-text hover-green"} onClick={(e) => this.onOpenPopup(e, "accept", row, index)} >APPROVED</span>}
                                  </React.Fragment>
                              </TableCol>
                              <TableCol>
                                  <React.Fragment>
                                      {row['status'] === 0 && <span className={"red-text hover-red"} onClick={(e) => this.onOpenPopup(e, "reject", row, index)} >DECLINED</span>}
                                      {row['status'] === 1 && <span className={"gray-text hover-red"}  onClick={(e) => this.onOpenPopup(e, "reject", row, index)}>DECLINED</span>}
                                      {row['status'] === 2 && <span className={"red-text"}>DECLINED</span>}
                                  </React.Fragment>
                              </TableCol>

                          </TableRow>
                      } ref={(ref) => this.refTable = ref}
                      params={{
                            ...(accountContext.profileInfo && { user_id: accountContext.profileInfo['id'] })
                      }}
                      loaded={() => {
                          this.setState({
                              searchComponent: <WaitForApprovalDepositsSearch refTable={this.refTable} searchData={this.state.search} doUpdateSearchData={this.doUpdateSearchData}  />
                          })
                      }}
                      search={this.state.search}
                      mobile={(row, index, number, total, onNext, onPrevious) =>
                          <TableMobileRow key={index}>
                              <TableMobileCol>
                                  <label>Number</label>
                                  <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>ID NUMBER</label>
                                  <TableColValue>
                                      <a href={"javascript:void(0)"} className={"underline-link yellow-text"} onClick={(e) => accountContext.doGotoProfile(ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW, ":profileId", row['user']['account_code'], this.state, this.refTable.getState())}>
                                          {accountCodeParse(row.user['account_code'])}
                                      </a>
                                  </TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Date</label>
                                  <TableColValue>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Time</label>
                                  <TableColValue>{utcTime(row['created_at']).format('HH:mm:ss')}</TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Amount</label>
                                  <TableColValue>
                                      <a href={"javascript:void(0)"} className={"underline-link yellow-text"} onClick={(e) => this.onOpenDetailsPopup(e, row)}>
                                          {numberWithCommas(row['amount'], true)}
                                      </a>
                                  </TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Approved</label>
                                  <TableColValue>
                                      <button className={"btn btn-success ml-1"} onClick={(e) => this.onOpenPopup(e, "accept", row, index)} ><FontAwesomeIcon icon={"check"} /> Approved</button>
                                  </TableColValue>
                              </TableMobileCol>
                              <TableMobileCol>
                                  <label>Declined</label>
                                  <TableColValue>
                                      <button className={"btn btn-danger mr-1"} onClick={(e) => this.onOpenPopup(e, "reject", row, index)} ><FontAwesomeIcon icon={"times"} /> Declined</button>
                                  </TableColValue>
                              </TableMobileCol>
                          </TableMobileRow>}
                    />
                </div>
                <Popup
                    show={this.state.dialog}
                    onClose={this.onClosePopup}
                    onSubmit={this.onSubmitPopup}
                    SubmitCaption={"Yes, I'm sure"}
                    SubmitButtonClass={"btn-success"}
                    CancelCaption={"Cancel"}
                    SubmittingLoading={this.state.submitting}
                    title={"Confirm"}
                    description={this.state.action == "accept"?"Are you sure to approval this transaction and charge amount to user account?":"Are you sure to decline this transaction?"}
                    force={true}
                >
                    <Formik
                        innerRef={(ref) => this.refForm = ref}
                        initialValues={{
                            description: ""
                        }}
                        validationSchema={this.state.action == "reject" && Yup.object().shape({
                            ...(this.state.action !== "accept" && {description: Yup.string().required("You must enter a reason for decline transaction")})
                        })}
                        onSubmit={this.handleSubmit}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea"
                                                  rows={5}
                                                  name={"description"}
                                                  onChange={handleChange}
                                                  value={values.description}
                                                  isValid={touched.description && !errors.description}
                                                  isInvalid={touched.description && errors.description}
                                                  onFocus={(e) => {
                                                      e.target.select()
                                                  }}
                                                  autoComplete="off"
                                                  ref={(ref) => this.refInput = ref}
                                    />
                                    {touched.description && <Form.Text className="text-danger mt-2">
                                        {errors.description}
                                    </Form.Text>}
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>

                </Popup>
                <Popup
                    show={this.state.detailsDialog}
                    onClose={this.onCloseDetailsPopup}
                    CancelCaption={"Close"}
                    title={"Deposit Details"}
                >
                    {this.state.detailsDialogRow && <table className={"details-table"}>
                        <tr>
                            <td width={150}>NUMBER# :</td>
                            <td>{this.state.detailsDialogRow['id']}</td>
                        </tr>
                        <tr>
                            <td>DATE :</td>
                            <td>{utcTime(this.state.detailsDialogRow['created_at']).format('ddd, MMMM DD, YYYY')}</td>
                        </tr>
                        <tr>
                            <td>TIME :</td>
                            <td>{utcTime(this.state.detailsDialogRow['created_at']).format('hh:mm:ss')}</td>
                        </tr>
                        <tr>
                            <td>Account ID :</td>
                            <td>
                                <NavLink className={"underline-link yellow-text"} to={ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW.replaceAll(":profileId", this.state.detailsDialogRow['user']['account_code'])}>
                                    {accountCodeParse(this.state.detailsDialogRow.user['account_code'])}
                                </NavLink>
                            </td>
                        </tr>
                        <tr>
                            <td>Amount :</td>
                            <td>{numberWithCommas(this.state.detailsDialogRow['amount'], true)}</td>
                        </tr>
                        <tr>
                            <td>Balance :</td>
                            <td>{this.state.detailsDialogRow.transaction?numberWithCommas(this.state.detailsDialogRow['transaction']['balance'], true):numberWithCommas(this.state.detailsDialogRow['user']['balance'], true)}</td>
                        </tr>
                        <tr>
                            <td>New Balance :</td>
                            <td>{this.state.detailsDialogRow.transaction?numberWithCommas(this.state.detailsDialogRow['transaction']['new_balance'], true):numberWithCommas(parseFloat(this.state.detailsDialogRow['user']['balance']) + parseFloat(this.state.detailsDialogRow['amount']) , true)}</td>
                        </tr>
                        {process.env.REACT_APP_NAME !== "swissco.gaming" && <tr>
                            <td>Wallet Address :</td>
                            <td>{this.state.detailsDialogRow['dest_wallet_code']}</td>
                        </tr>}
                        {process.env.REACT_APP_NAME !== "swissco.gaming" && <tr>
                            <td>Hash Code :</td>
                            <td><a className={"underline-link yellow-text"} href={"https://tronscan.org/#/transaction/" + this.state.detailsDialogRow['hash_code']} target={"_blank"}>{this.state.detailsDialogRow['hash_code']}</a></td>
                        </tr>}
                        <tr>
                            <td>Status :</td>
                            <td><WithdrawalStatus status={this.state.detailsDialogRow['status']}/></td>
                        </tr>
                        <tr>
                            <td>Description :</td>
                            <td>{this.state.detailsDialogRow['description']}</td>
                        </tr>
                        <tr>
                            <td>Actions :</td>
                            <td>
                                <div className={"start-center-value"}>
                                    {this.state.detailsDialogRow['status'] === 0 && <span className={"btn btn-outline btn-outline-green"} onClick={(e) => this.onOpenPopup(e, "accept", this.state.detailsDialogRow, 0)} >APPROVED</span>}
                                    {this.state.detailsDialogRow['status'] === 2 && <span className={"btn btn-outline btn-outline-green"} onClick={(e) => this.onOpenPopup(e, "accept", this.state.detailsDialogRow, 0)} >APPROVED</span>}
                                    {this.state.detailsDialogRow['status'] === 0 && <span className={"btn btn-outline btn-outline-red"} onClick={(e) => this.onOpenPopup(e, "reject", this.state.detailsDialogRow, 0)} >DECLINED</span>}
                                    {this.state.detailsDialogRow['status'] === 1 && <span className={"btn btn-outline btn-outline-red"}  onClick={(e) => this.onOpenPopup(e, "reject", this.state.detailsDialogRow, 0)}>DECLINED</span>}
                                </div>
                            </td>
                        </tr>
                    </table>}
                </Popup>
            </PanelContainer>
        )
    }
}

export default withAlert()(withRouter(withAdminAccountContext(WaitForApprovalDeposits)));