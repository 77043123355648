import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import {Col, Form, Row, Spinner} from "react-bootstrap";
import styles from "./MasterPasswordAuthentication.module.css";
import AdminService from "../../../services/admin.service";
import {Formik} from "formik";
import * as Yup from "yup";
class MasterPasswordAuthentication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            submitting: false
        }
    }
    handleSubmit = async (values, actions) => {
        this.setState({submitting: true})
        try{
            let data = await AdminService.doCheckMasterPassword({
                password: values.password
            });
            console.log(data)
            this.setState({submitting: false}, () => {
                this.props.handleAuthenticated(values.password, data);
            })
        }catch (e){
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, e.response.data.errors[field][0])
            })
            this.setState({submitting: false})
        }


    }
    render() {
        return (
            <Formik
                innerRef={(ref) => this.refForm = ref}
                enableReinitialize={true}
                initialValues={{
                    password: this.state.password
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string().required("You must enter master password")
                })}
                onSubmit={this.handleSubmit}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit} className={styles.FullWidth}>
                        <Form.Group as={Row} >
                            <Col md={12} className={"text-center"}>
                                <Form.Group as={Row}>
                                    <Col md={2} className={"start-center-value"}>
                                        <Form.Label>Password</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value position-relative"}>
                                        <Form.Control type="password"
                                                      onChange={handleChange}
                                                      name={"password"}
                                                      isValid={touched.password && !errors.password}
                                                      isInvalid={touched.password && errors.password}
                                                      value={values.password}
                                        />
                                    </Col>
                                    <Col md={3} className={"start-center-value"}>
                                        <button className={"btn btn-account full-width"} disabled={this.state.submitting} type={"submit"}>{this.state.submitting?<Spinner animation="grow" size={"sm"} variant="light" />:"OK"}</button>
                                    </Col>
                                </Form.Group>
                                {touched.password && <Form.Group as={Row}>
                                    <Col md={2}></Col>
                                    <Col>
                                        <Form.Text className="text-danger mt-2">
                                            {errors.password}
                                        </Form.Text>
                                    </Col>
                                    <Col md={3}></Col>
                                </Form.Group>}

                            </Col>
                        </Form.Group>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default withRouter(withAdminAccountContext(MasterPasswordAuthentication));