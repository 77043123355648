import {ADMIN_MENU_ITEMS, ADMIN_USER_PROFILE_MENU_ITEMS} from "../../../helpers/constants";
import {getBackButton} from "../../../helpers/helpers"
import {pathHasPermission} from "../../../helpers/helpers";
import {NavLink} from "react-router-dom";
import styles from "./PageTitle.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const renderPageTitle = (props) => {
    const {accountContext, match} = props;
    if(accountContext.pageTitle) return accountContext.pageTitle;
    let menu = ADMIN_MENU_ITEMS;
    if(match.params.hasOwnProperty('profileId')){
        menu = ADMIN_USER_PROFILE_MENU_ITEMS;
    }
    let page = menu.filter((menu) => match.path === menu.path)
    if(page.length > 0){
        return pathHasPermission(accountContext.path, accountContext.role, menu)?page[0].label:"Permission Denied";
    }
}
const renderBackButton = (props) => {
    const {accountContext, match} = props;
    let menu = ADMIN_MENU_ITEMS;
    if(match.params.hasOwnProperty('profileId')){
        menu = ADMIN_USER_PROFILE_MENU_ITEMS;
    }
    return getBackButton(accountContext.path, menu)?
        <NavLink to={getBackButton(accountContext.path, menu)} className={styles.BackNavButton}>
            <FontAwesomeIcon icon={"chevron-left"} /> Back
        </NavLink>:null
}
export {renderPageTitle, renderBackButton};