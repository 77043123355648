import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import classNames from "classnames";
import {RED_NUMBERS_OF_TABLE} from "../../../helpers/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class MobileTopCells extends Component {
    render() {
        const {gameTableContext} = this.props;
        return (
            <div className={classNames(styles.TopCellWrapper, gameTableContext.state.windows.topCells && styles.OpenTopCellWrapper)}>

                <div className={styles.TopCellTable}>
                    {gameTableContext.state.numberHistory.map((r, index) => {
                        if(index > 0)
                            return (
                                <div className={classNames((RED_NUMBERS_OF_TABLE.includes(r) && styles.CellRed), r === 0 && styles.CellGreen)} key={index}>{r}</div>
                            )
                        else
                            return (
                                <React.Fragment>
                                    <div className={classNames(styles.LastNumber, (RED_NUMBERS_OF_TABLE.includes(r) && styles.CellRed), r === 0 && styles.CellGreen)} key={index}>{r}</div>
                                </React.Fragment>
                            );
                    })}


                </div>
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext(MobileTopCells));