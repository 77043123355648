import React from "react";

const ProfileContext = React.createContext({});
const ProfileProvider = ProfileContext.Provider;
const ProfileConsumer = ProfileContext.Consumer;
const withProfileContext = Component => React.forwardRef((props, ref) => (
    <ProfileConsumer>
        {(response) => {
            return <Component {...props} profileContext={response} ref={ref} />;
        }}
    </ProfileConsumer>
));
export { ProfileProvider, ProfileConsumer, withProfileContext };
