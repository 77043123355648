import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
class TableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {
        const {children} = this.props;
        return (
            <tr>
                {children}
            </tr>
        );
    }
}

export default withApplicationContext(withGameTableContext(TableRow));