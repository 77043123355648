import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import {NavLink, withRouter} from "react-router-dom"
import AccountContainer from "../AccountContainer";
import LoadingContainer from "../../../components/common/LoadingContainer";
import AccountTable from "../../../components/ui/AccountTable";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import DepositService from "../../../services/deposit.service";
import moment from 'moment-timezone';
import {accountCodeParse, numberWithCommas, timezone, utcTime} from "../../../helpers/helpers";
import DepositStatus from "../../../components/ui/DepositStatus";
import {withAlert} from "react-alert";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import {ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW} from "../../../routers/routes";
import UserService from "../../../services/user.service";
class LogonHistory extends Component {
    refForm = React.createRef();
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dialog: false,
            action: "accept",
            row: null,
            index: -1,
            submitting: false
        }
    }

    render() {
        const {accountContext, applicationContext} = this.props;
        return (
            <LoadingContainer loading={accountContext.loading}>
                <AccountContainer>
                    <div className={"form-center"}>
                        <AccountTable loadData={UserService.getLogonHistory} header={(onSort) => {
                            return <TableRow>
                                <TableColHeader>#</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Date")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Time")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Action")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("IP")}</TableColHeader>
                            </TableRow>
                        }}
                        render={(row, index, number) =>
                            <TableRow key={index}>
                                <TableCol>{number}</TableCol>
                                <TableCol>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableCol>
                                <TableCol>{utcTime(row['created_at']).format('HH:mm:ss')}</TableCol>
                                <TableCol>{row['action']}</TableCol>
                                <TableCol>{row['ip']}</TableCol>
                            </TableRow>
                        }
                          sort={"id"}
                          align={"desc"}
                          ref={(ref) => this.refTable = ref}
                          mobile={(row, index, number, total, onNext, onPrevious) =>
                              <TableMobileRow key={index}>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Number / Total Records")}</label>
                                      <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Date")}</label>
                                      <TableColValue>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Time")}</label>
                                      <TableColValue>{utcTime(row['created_at']).format('hh:mm:ss')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Action")}</label>
                                      <TableColValue>{row['action']}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("IP")}</label>
                                      <TableColValue>{row['ip']}</TableColValue>
                                  </TableMobileCol>

                              </TableMobileRow>}
                        />
                    </div>

                </AccountContainer>
            </LoadingContainer>
        )
    }
}

export default withAlert()((withApplicationContext(withAccountContext(withRouter(LogonHistory)))));