import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import {Formik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import {withAlert} from "react-alert";
import {ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW, ROUTE_ADMIN_USERS_LIST} from "../../../routers/routes";
import styles from "./Search.module.css";
import PanelContainer from "../PanelContainer";
import SwisscoSearch from "./SwisscoSearch";
import RouletteSearch from "./RouletteSearch";
import PageTitleContainer from "./PageTitleContainer";
import AccountTable from "../../../components/ui/AccountTable/AccountTable";
import AdminService from "../../../services/admin.service";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import {accountCodeParse, numberWithCommas} from "../../../helpers/helpers";
import moment from "moment";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import classNames from "classnames";
class Search extends Component {
    refForm = React.createRef();
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            search: true,
            params: null,
            error: false,
            searched: false,
            searching: false,
            searchFields: {
                account_code: "",
                email: "",
                name: "",
                mobile_number: "",
                wallet_code: "",
                hash_code: "",
                receipt_number: ""
            },
            infoWrapperWidth: null
        }
    }
    componentDidMount() {
        if(localStorage.getItem('backed_from_account_state')){
            let state = JSON.parse(localStorage.getItem('backed_from_account_state'));
            localStorage.removeItem('backed_from_account_state');
            this.setState(state)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.refForm.current && this.state.infoWrapperWidth !== this.refForm.current.clientWidth){
            this.setState({
                infoWrapperWidth: this.refForm.current.clientWidth
            })
        }

    }
    handleSubmit = async (values, actions) => {
        const {history} = this.props;
        this.setState({ error: false, searching:true })
        if(values.account_code || values.email || values.name || values.mobile_number){
            this.setState({
                searchFields: {
                    account_code: values.account_code.replaceAll(" ", ""),
                    email: values.email,
                    name: values.name,
                    mobile_number: values.mobile_number,
                },
                searching: false,
                searched: true
            })
        }else{
            this.setState({ error: true, searching: false })
        }
    }
    onClickRow = (e, row) => {
        const {accountContext} = this.props;
        e.preventDefault();
        accountContext.doGotoProfile(ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW, ":profileId", row['account_code'], this.state, this.refTable.getState())
    }
    render() {
        const {accountContext} = this.props;
        return (
            <PanelContainer>

                <div className={"form-center"}>
                    <Formik
                        innerRef={(ref) => this.refForm = ref}
                        enableReinitialize={true}
                        initialValues={this.state.searchFields}
                        onSubmit={this.handleSubmit}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <Form onSubmit={handleSubmit} className={classNames(styles.FullWidth)}>

                                <Form.Group as={Row} className={"mb-3"}>
                                    <Col className={"start-center-value"}>
                                        <Form.Label className={styles.FormLabel}></Form.Label>
                                        <PageTitleContainer center={true} full={true}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group className={"mb-3"}>
                                    <Col className={"start-center-value"}>
                                        <Form.Label className={styles.FormLabel}>ACCOUNT ID :</Form.Label>
                                        <Form.Control type="text"
                                                      name={"account_code"}
                                                      onChange={handleChange}
                                                      value={values.account_code}
                                                      isValid={touched.account_code && !errors.account_code}
                                                      isInvalid={touched.account_code && errors.account_code}
                                                      autoComplete="off"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group className={"mb-3"}>
                                    <Col className={"start-center-value"}>
                                        <Form.Label className={styles.FormLabel}>E-MAIL :</Form.Label>
                                        <Form.Control type="text"
                                                      name={"email"}
                                                      onChange={handleChange}
                                                      value={values.email}
                                                      isValid={touched.email && !errors.email}
                                                      isInvalid={touched.email && errors.email}
                                                      autoComplete="off"
                                        />
                                    </Col>
                                </Form.Group>
                                <SwisscoSearch errors={errors} touched={touched} values={values} handleChange={handleChange}/>
                                <RouletteSearch errors={errors} touched={touched} values={values} handleChange={handleChange}/>

                                <Form.Group>
                                    <Col className={"start-center-value"}>
                                        <Form.Label className={styles.FormLabel}></Form.Label>
                                        <button className={"btn btn-account full-width"} type={"submit"}>Search</button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className={"form-center"}>
                    {this.state.searched &&
                    <AccountTable  full={true} loadData={AdminService.getUsersList} sort={"id"} align={"asc"}
                                   header={(onSort, sort, align) => {
                                       return <TableRow>
                                           <TableColHeader sort={"id"}>#</TableColHeader>
                                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableColHeader sort={"first_name"} >FIRST NAME</TableColHeader>}
                                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableColHeader sort={"last_name"}  >LAST NAME</TableColHeader>}
                                           {process.env.REACT_APP_NAME !== "swissco.gaming" && <TableColHeader sort={"email"} >EMAIL</TableColHeader>}
                                           <TableColHeader>ID NUMBER</TableColHeader>
                                           <TableColHeader>MEMBER SINCE</TableColHeader>
                                           <TableColHeader>ACCOUNT BALANCE</TableColHeader>
                                       </TableRow>
                                   }}
                                   render={(row, index, number) =>
                                       <TableRow key={index} onClick={(e) => this.onClickRow(e, row)} >
                                           <TableCol>{number}</TableCol>
                                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableCol>{row['first_name']}</TableCol>}
                                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableCol>{row['last_name']}</TableCol>}
                                           {process.env.REACT_APP_NAME !== "swissco.gaming" && <TableCol>{row['email']}</TableCol>}
                                           <TableCol><span>{accountCodeParse(row['account_code'])}</span></TableCol>
                                           <TableCol>{moment(new Date(row['created_at'])).format('ddd, MMMM DD, YYYY')}</TableCol>
                                           <TableCol><span className={"yellow-text"}>{numberWithCommas(row['balance'], true)}</span></TableCol>
                                       </TableRow>
                                   }
                                   mobile={(row, index, number, total, onNext, onPrevious) =>
                                       <TableMobileRow key={index}>
                                           <TableMobileCol>
                                               <label>Number</label>
                                               <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                                           </TableMobileCol>
                                           <TableMobileCol>
                                               <label>ID NUMBER</label>
                                               <TableColValue><span>{accountCodeParse(row['account_code'])}</span></TableColValue>
                                           </TableMobileCol>
                                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableMobileCol>
                                               <label>FIRST NAME</label>
                                               <TableColValue>{row['first_name']}</TableColValue>
                                           </TableMobileCol>}
                                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableMobileCol>
                                               <label>LAST NAME</label>
                                               <TableColValue>{row['last_name']}</TableColValue>
                                           </TableMobileCol>}
                                           {process.env.REACT_APP_NAME === "swissco.gaming" && <TableMobileCol>
                                               <label>E-MAIL ADDRESS</label>
                                               <TableColValue>{row['email']}</TableColValue>
                                           </TableMobileCol>}
                                           <TableMobileCol>
                                               <label>MEMBER SINCE</label>
                                               <TableColValue>{moment(new Date(row['created_at'])).format('ddd, MMMM DD, YYYY')}</TableColValue>
                                           </TableMobileCol>
                                           <TableMobileCol>
                                               <label>ACCOUNT BALANCE</label>
                                               <TableColValue><span className={"yellow-text"}>{numberWithCommas(row['balance'], true)}</span></TableColValue>
                                           </TableMobileCol>
                                           <TableMobileCol>
                                               <TableColValue>
                                                   <button onClick={(e) => this.onClickRow(e, row)}>View user profile ...</button>
                                               </TableColValue>
                                           </TableMobileCol>
                                       </TableMobileRow>}
                                   search={this.state.searchFields}
                                   ref={(ref) => this.refTable = ref}
                    />}
                </div>
            </PanelContainer>

        )
    }
}

export default withAlert()(withRouter(withAdminAccountContext(Search)));