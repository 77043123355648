import React, {Component} from 'react';
import {withApplicationContext} from "../../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../../contexts/AccountContext";
import {withRouter} from "react-router-dom"
import AccountContainer from "../../AccountContainer";
import LoadingContainer from "../../../../components/common/LoadingContainer";
import {withAlert} from "react-alert";
import {Tab, Tabs} from "react-bootstrap";
import DepositLimit from "./DepositLimit";
import BetLimit from "./BetLimit";
import classNames from "classnames";
import styles from "./Limits.module.css";

class Limits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: "",
            period: "daily",
            successfully: false
        }
    }
    render() {
        const {accountContext, applicationContext} = this.props;
        return (
            <LoadingContainer loading={accountContext.loading}>
                <AccountContainer>
                    <Tabs
                        defaultActiveKey="deposit"
                        id="limit-tabs"
                        className={classNames("mb-3", styles.TabsContainer)}
                    >
                        <Tab eventKey="deposit" title={applicationContext.translator("Deposit Limit")}>
                            <DepositLimit />
                        </Tab>
                        <Tab eventKey="bet" title={applicationContext.translator("Bet Limit")}>
                            <BetLimit />
                        </Tab>
                    </Tabs>
                </AccountContainer>
            </LoadingContainer>
        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(Limits))));