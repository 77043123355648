import React, {Component} from "react";
import styles from "./AccountTable.module.css";
import classNames from "classnames";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
class TableMobileRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }
    render() {
        const { applicationContext, children, onClick, active } = this.props
        return (
            <React.Fragment>
                <div className={classNames(styles.TableMobileRow)}>
                    {children}
                </div>

            </React.Fragment>
        );
    }
}
export default withApplicationContext(TableMobileRow);