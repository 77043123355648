import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import PanelContainer from "../PanelContainer";
import {Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AccountTable from "../../../components/ui/AccountTable/AccountTable";
import AdminService from "../../../services/admin.service";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import {ROUTE_ADMIN_CEG_SELECTED_ROUND} from "../../../routers/routes";
import {numberWithCommas} from "../../../helpers/helpers";
import moment from "moment";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import Calendar from "react-calendar";
import styles from "../../../components/ui/PageTitle/PageTitle.module.css";
class RoundWinningNumberSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            round_number: "",
            date: null,
            datepicker: false,
            search: {
                round_number: "",
                date: "",
                start_date: "",
                end_date: "",
                time_hour: "",
                time_min: ""
            }
        }
    }

    onSearchRoundNumber = (e) => {
        this.setState({
            search: {
                ...this.state.search,
                round_number: e.target.value
            }
        });
    }
    onChangeDate = (date) => {
        this.setState({
            date,
            search: {
                ...this.state.search,
                start_date: (date)?moment(new Date(date[0])).format('YYYY-MM-DD'):null,
                end_date: (date)?moment(new Date(date[1])).format('YYYY-MM-DD'):null
            },
            datepicker: false
        })
    }
    handleSearch = () => {
        this.props.refTable.search(this.state.search)
    }

    render() {
        return (
            <div className={"form-center"}>
                <div className={styles.SearchBox}>
                    <Form.Control
                        aria-describedby="basic-addon2"
                        className={"search-item"}
                        name={"round_number"}
                        onChange={this.onSearchRoundNumber}
                        style={document.getElementById('table-cell-01')?{ width: document.getElementById('table-cell-01').offsetWidth }:null}
                        placeholder={"ROUND NUMBER"}
                    />
                    <div className={"search-item"} style={document.getElementById('table-cell-02')?{ width: document.getElementById('table-cell-02').offsetWidth }:null}>
                        <a href={"javascript:void(0)"} onClick={(e) => this.setState({ datepicker: !this.state.datepicker })}>
                            <React.Fragment>
                                {this.state.search.start_date?<span style={{ fontSize: '11px' }}>{moment(new Date(this.state.search.start_date)).format('MMM D, YY')}</span>:"DATE"}
                                {this.state.search.end_date && this.state.search.end_date !== this.state.search.start_date?<span style={{ fontSize: '11px' }}>-{moment(new Date(this.state.search.end_date)).format('MMM D, YY')}</span>:""}
                            </React.Fragment>
                        </a>
                        {this.state.datepicker && <div className={"float-calendar"}><Calendar value={this.state.date} onChange={this.onChangeDate} selectRange={true}/></div>}
                    </div>

                    <Form.Control
                        as={"select"}
                        className={"search-item"}
                        name={"status"}
                        value={this.state.search.time_hour}
                        style={document.getElementById('table-cell-03')?{ width: document.getElementById('table-cell-03').offsetWidth }:null}
                        onChange={(e) => {
                            this.setState({
                                search: {
                                    ...this.state.search,
                                    time_hour: e.target.value
                                }
                            })
                        }}

                    >
                        <option value={""}>TIME</option>
                        {[...Array(24).keys()].map((h) => {
                            return <option value={(h < 10)?`0${h}`:h}>{(h < 10)?`0${h}`:h}:00</option>
                        })}

                    </Form.Control>
                    <button className={"btn btn-account search-item"} type={"submit"} onClick={this.handleSearch}
                        style={document.getElementById('table-cell-04')?{ width: document.getElementById('table-cell-04').offsetWidth }:null}
                    >Search</button>

                </div>

            </div>
        )
    }
}

export default withRouter(withAdminAccountContext(RoundWinningNumberSearch));