import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import classNames from "classnames";
import styled from "./Ticket.module.css";
import TicketPriority from "../TicketPriority";
import moment from "moment-timezone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TicketStatus from "../TicketStatus";
import {timezone} from "../../../helpers/helpers";
class Ticket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attachments: false
        }
    }
    render() {
        const {ticket} = this.props;
        return (
            <div className={classNames(styled.Ticket, (!ticket.hasOwnProperty('subject'))?(ticket.agent?styled.AgentTicket:styled.OwnerTicket):(styled.FirstTicket))}>
                <div className={styled.TicketHeader}>
                    <div className={styled.TicketHeaderAvatar}>
                        <div>{ticket.agent?ticket.agent.full_name.substring(0, 1):ticket.user.full_name.substring(0, 1)}</div>
                    </div>
                    <div className={classNames(styled.TicketHeaderBase, !ticket.hasOwnProperty('subject') && styled.TicketHeaderBaseCenter)}>
                        <h1>{ticket.agent?ticket.agent.full_name:ticket.user.full_name}</h1>
                        {ticket.hasOwnProperty('subject') && <h3>Ticket ID: #{ticket.id} <TicketPriority priority={`(${ticket.priority})`} /> <TicketStatus status={ticket.status} /></h3>}
                    </div>
                    <div className={styled.TicketHeaderDate}>
                        <div>{moment(new Date(ticket['created_at'])).tz(timezone()).format('ddd, MMM DD, YYYY')}<br/>{moment(new Date(ticket['created_at'])).tz(timezone()).format('HH:mm:ss')}</div>
                    </div>
                </div>
                <div className={styled.TicketBody}>
                    <h6>{ticket.subject}</h6>
                    <div dangerouslySetInnerHTML={{__html: ticket.message.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></div>
                    {ticket.attachments &&
                        <React.Fragment>
                            <button className={"btn btn-secondary mt-2"} type={"button"}  onClick={(e) => this.setState({ attachments: !this.state.attachments }) }>
                                <FontAwesomeIcon icon={"paperclip"} className={"mr-1"}/> Attachments
                            </button>
                            {this.state.attachments && <div className={styled.UploadedFiles}>
                                {ticket.attachments.map((attach, index) => {
                                    return <div className={styled.AttachItem} key={index}>
                                        <a href={attach} target={"_blank"}><img src={attach} /></a>
                                    </div>;
                                })}
                            </div>}
                        </React.Fragment>
                    }


                </div>
            </div>
        );
    }
}

export default Ticket;