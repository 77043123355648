import api from "./api";
import TokenService from "./token.service";
import UserService from "./user.service";

const register = (email, birthdate) => {
    let config = {
        auth: {
            username: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_ID,
            password: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_SECRET
        }
    };
    return api.post("/api/auth/register", {
        email,
        birthdate,
        app: process.env.REACT_APP_NAME
    }, config).then((response) => {
        return response.data;
    });
};

const registerWithPassword = (email, password, password_confirmation) => {
    let config = {
        auth: {
            username: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_ID,
            password: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_SECRET
        }
    };
    return api.post("/api/auth/register", {
        email,
        password,
        password_confirmation,
        app: process.env.REACT_APP_NAME
    }, config).then((response) => {
        return response.data;
    });
};

const login = (username, password, level) => {
    let config = {
        auth: {
            username: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_ID,
            password: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_SECRET
        }
    };
    return api
        .post("/api/auth/login", {
            email: username,
            password,
            level
        }, config)
        .then((response) => {

            return response.data;
        });
};

const logout = () => {
    TokenService.removeUser();
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const getCurrentAdmin = () => {
    return JSON.parse(localStorage.getItem("admin"));
};

const registerComplete = (token, data) => {
    let config = {
        headers: {
            Authorization: 'Bearer ' + token
        }
    };
    return api.post("/api/auth/register-complete", data, config).then((response) => {
        return response.data;
    });
};

const doChangePassword = (email) => {
    let config = {
        auth: {
            username: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_ID,
            password: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_SECRET
        }
    };
    return api
        .post("/api/auth/reset-password", {
            email
        }, config)
        .then((response) => {
            return response.data;
        });
};

const doUpdatePassword = (url, password, password_confirmation) => {
    let config = {
        auth: {
            username: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_ID,
            password: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_SECRET
        }
    };
    return api
        .post(url, {
            password,
            password_confirmation
        }, config)
        .then((response) => {
            return response.data;
        });
};

const AuthService = {
    register,
    registerWithPassword,
    registerComplete,
    login,
    logout,
    getCurrentUser,
    getCurrentAdmin,
    doChangePassword,
    doUpdatePassword
};

export default AuthService;