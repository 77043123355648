import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export const HeaderBar = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  background: linear-gradient(#070505, #070505 50%, #fff 50%);
  @media screen and (max-width: 700px) {
    height: 90px;
    background: #070505;
  }
`;
export const HeaderBarWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  
`;
export const HeaderLogo = styled.img`
  width: 160px;
  height: 160px;
  margin-top: 20px;
  @media screen and (max-width: 700px) {
    width: 60px;
    height: 60px;
    margin-top: 0;
  }
`;
export const HeaderCenter = styled.div`
  @media screen and (max-width: 700px) {
    position: absolute;
    left: 15px;
    top: 15px;
  }
`;
export const HeaderLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 992px) {
    display: none !important;
  }
`;
export const HeaderRight = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 992px) {
    display: none !important;
  }
`;
export const HeaderBalance = styled.div`
  position: absolute;
  color: #f1cc01;
  left: 0;
  top: 2em;
  padding: 1em 0;
  border-radius: 10px;
  font-size: 1.1em;
  font-weight: 600;
  & span{
    direction: ltr !important;
    font-weight: 700;
    background: #f1cc01;
    color: #333;
    padding: 0.5em 2em;
    border-radius: 10px;
    font-size: 1.2em;
  }
`
export const HeaderMenu = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 15px;
  display: flex;
  align-items: flex-end;
`;
export const HeaderTextMenu = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  ${(props) => props.flex && `
    display: flex;
  `};
`;

export const MenuBar = styled.ul`
  margin: 0;
  padding: 0;
  color: var(--color-header-text);
  list-style: none;
  display: flex;
  flex: 1;
  text-align: center;
`;
export const MenuBarItem = styled.li`
    width: 100%;
    ${(props) => props.first && `
      text-align: right;
    `}
    ${(props) => props.last && `
      text-align: left;
      width: calc(100% - 15em);
    `}
    & a{
      font-size: 1.2em;
      font-weight: bold;
      padding: 15px 5px;
      color: #333;
      display: block;
      &:hover{
        color: var(--color-primary);
        transition:all ease-in-out 0.3s;
      }
      ${(props) => props.active && `
           color: var(--color-primary);
           transition:all ease-in-out 0.3s;
        `}
      
    }
`;
export const TextChanceWeekly = styled.p`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.8em;
  margin: 0;
  
`;
export const TextDrawWeeklyTime = styled.p`
  width: 40%;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5em;
  margin: 0;
`;
export const VerticalLine = styled.div`
  width: 2px;
  height: 50px;
  background: var(--color-header-background);
  margin: 0 45px;
`;
export const FloatMenuIcon = styled(FontAwesomeIcon)`
  color: #fff;
  position: absolute;
  right: 29px;
  top: 29px;
  font-size: 2em;
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
`

export const FloatMenu = styled.div`
  display: block;
  position: fixed;
  right: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 99;
  ${(props) => props.display?`
    right: 0px;
    transition: all linear .2s;
  `:`
    right: -100%;
    transition: all linear .2s;
  `};
  
`;
export const FloatMenuContainer = styled.div`
  width: 90%;
  height: 100%;
  background: #212121;
`
export const FloatMenuItems = styled.ul`
  list-style: none;
  margin: 0;
  padding: 1em;
`
export const FloatMenuItem = styled.li`
  color: #333;
  & a {
    font-size: 1.2em;
    font-weight: 700;
    padding: 1em;
    display: block;
    color: #fff;
    &:hover, &:active{
      color: var(--color-primary);
      transition: all ease-in-out 0.3s;
    }
    
  }
`
export const CloseMenu = styled.div`
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1em 1em;
  font-size: 2em;
`


export const PopupText = styled.div`
  font-size: 1em;
  font-weight: 600;
  color: var(--color-text);
  padding: 1em;
`