import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
class TicketStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const {status, applicationContext} = this.props;
        const description = {
            0: {
                color: "ticket-new",
                icon: "certificate"
            },
            1: {
                color: "ticket-answered",
                icon: "reply"
            },
            2: {
                color: "ticket-open",
                icon: "envelope-open-o"
            },
            3: {
                color: "ticket-resolved",
                icon: "check"
            }
        }
        return (
            description.hasOwnProperty(status['code'])?<Form.Text className={classNames("ticket-label", description[status['code']].color)}>
                <FontAwesomeIcon icon={description[status['code']].icon} className={"mr-1"}/>{applicationContext.translator(status['label']).toUpperCase()}
            </Form.Text>:null
        );
    }
}

export default withApplicationContext(TicketStatus);