import axios from "axios";
import TokenService from "./token.service";

const https = require('https');
const agent = new https.Agent({
    rejectUnauthorized: false
});
const instance = axios.create({
    baseURL: process.env.REACT_APP_BACK_END_WEBSERVICE_API,
    httpsAgent: agent,
    headers: {
        "Content-Type": "application/json",
    },
});
instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        config.httpsAgent = agent;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/oauth/token" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    if(!TokenService.getLocalRefreshToken()){
                        TokenService.removeUser();
                        window.location.reload();
                        return Promise.reject("Authorization Failed.");
                    }
                    const rs = await instance.post("/oauth/token", {
                        refresh_token: TokenService.getLocalRefreshToken(),
                        grant_type: "refresh_token"
                    }, {
                        auth: {
                            username: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_ID,
                            password: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_SECRET
                        }
                    });

                    TokenService.updateLocalAccessToken(rs.data);


                } catch (_error) {
                    console.log(_error.response)
                }
                return instance(originalConfig);
            }


        }
        if (originalConfig.url === "/oauth/token" && err.response) {
            if (err.response.status === 401){
                TokenService.removeUser();
                window.location.href = process.env.REACT_APP_LIVE_URL;
            }
        }

        return Promise.reject(err);
    }
);

export default instance;