import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import Loading from "../../../components/common/Loading";
import {Container} from "react-bootstrap";
import {Content, NotFound, Title, TitleValue} from "./style";
import BaseMaster from "../BaseMaster";
import {withRouter} from "react-router-dom";
import SearchService from "../../../services/SearchService";
class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: null,
            notFound: false
        }


    }
    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if(prevProps.match.params['slug'] !== this.props.match.params['slug']){
            await this.fetchData();
        }
    }


    componentDidMount = async () => {
        document.body.classList.add("white-background");
        await this.fetchData();
    }

    fetchData = async () => {
        const {applicationContext, match, history} = this.props;
        this.setState({ loading: true })
        SearchService.page(applicationContext.state.lang, match.params['slug']).then((data) => {
            this.setState({
                page: data
            })
        }).catch((e) => {
            history.push("/");
            //this.setState({ notFound: true })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {applicationContext} = this.props;
        return (
            <BaseMaster>
                {this.state.loading?(
                    <Loading />
                ):(
                    <React.Fragment>
                        <Title dir={applicationContext.translator("direction")}>
                            <TitleValue>
                                {!this.state.notFound && <Container>
                                    {this.state.page.title}
                                </Container>}
                            </TitleValue>
                        </Title>
                        <Container>
                            {this.state.notFound?(<NotFound><img src={"/assets/img/404.png"}/></NotFound>):(
                                <Content dir={applicationContext.translator("direction")} dangerouslySetInnerHTML={{__html: this.state.page.content}} />
                            )}
                        </Container>
                    </React.Fragment>

                )}

            </BaseMaster>

        );
    }
}

export default withApplicationContext(withRouter(Page));