import React from "react";

const GameTableContext = React.createContext({});
const GameTableProvider = GameTableContext.Provider;
const GameTableConsumer = GameTableContext.Consumer;
const withGameTableContext = Component => React.forwardRef((props, ref) => (
    <GameTableConsumer>
        {(response) => {
            return <Component {...props} gameTableContext={response} ref={ref} />;
        }}
    </GameTableConsumer>
));
export { GameTableProvider, GameTableConsumer, withGameTableContext };
