import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import classNames from "classnames";
import {withApplicationContext} from "../../../contexts/ApplicationContext";

class TicketPriority extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const {priority, applicationContext} = this.props;
        const description = {
            "LOW": {
                color: "btn-white",
                label: "Low"
            },
            "MEDIUM": {
                color: "yellow-text",
                label: "Medium"
            },
            "HIGH": {
                color: "orange-text",
                label: "High"
            },
            "URGENT": {
                color: "red-text",
                label: "Urgent"
            }
        }
        return (
            <Form.Text className={classNames(description[priority.replace(/\(|\)/g, '')].color)}>
                {applicationContext.translator(description[priority.replace(/\(|\)/g, '')].label)}
            </Form.Text>
        );
    }
}

export default withApplicationContext(TicketPriority);