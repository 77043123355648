import React, {Component} from 'react';
import styles from "./Popup.module.css";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
class Popup extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {applicationContext, show, onClose, children, onSubmit, title, description, SubmitCaption, CancelCaption, SubmitButtonClass, SubmittingLoading, textLeft, disableClose, large, dark, force} = this.props;
        return (
            show?(<div className={classNames(styles.PopupOverlay, force && styles.ForcePopup)} style={{ direction: applicationContext.translator("direction") }}>
                <div className={classNames(styles.PopupContainer, !large?styles.SmallPopup:styles.LargePopup, dark && styles.Dark)}>
                    <div className={styles.PopupHeader}>
                        <p className={(applicationContext.translator("direction") === "rtl"?"text-right":"text-left")}>{title}</p>
                        {!disableClose && <button onClick={onClose}><FontAwesomeIcon icon={"times"} /></button>}
                    </div>
                    <div className={styles.PopupBody}>
                        <div className={classNames(styles.PopupBodyWrapper, applicationContext.translator("direction") === "rtl" && "rtl-section")}>
                            {description && <p className={classNames(styles.PopupDescription, textLeft && "text-left")}>{description}</p>}
                            {children}
                        </div>

                    </div>
                    <div className={styles.PopupFooter}>
                        {CancelCaption && <button disabled={SubmittingLoading} className={"btn btn-danger " + (applicationContext.translator("direction") === "rtl"?"ml-1":"mr-1")} onClick={onClose}>{CancelCaption?CancelCaption:"Cancel"}</button>}
                        {onSubmit && <button disabled={SubmittingLoading} className={"btn " + ((SubmitButtonClass)?SubmitButtonClass:"btn-primary")} onClick={onSubmit}>{SubmitCaption?SubmitCaption:"Save"}</button>}
                    </div>

                </div>
            </div>):(null)
        );
    }
}

export default withApplicationContext(Popup);