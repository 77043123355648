import React, {Component} from 'react';
import {Formik} from "formik";
import * as Yup from "yup";
import {Alert, Col, Form, FormText, InputGroup, Row} from "react-bootstrap";
import AccountContainer from "../AccountContainer";
import LoadingContainer from "../../../components/common/LoadingContainer";
import {withAlert} from "react-alert";
import {withRouter} from "react-router-dom";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import WithdrawalService from "../../../services/withdrawal.service";
import {accountCodeParse, numberWithCommas} from "../../../helpers/helpers";
import styles from "./Withdrawal.module.css";
class Withdrawal extends Component {
    refFile = React.createRef();
    refImageBox = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            amount: "0",
            dest_wallet_code: "",
            new_balance: 0,
            submitting: false,
            balance: props.accountContext.userInfo['balance']
        }
    }
    handleSubmit = async (values, actions) => {
        const {alert, applicationContext, accountContext} = this.props;
        this.setState({ submitting: true })
        try{
            let result = await WithdrawalService.doWithdrawal(values);
            alert.success(applicationContext.translator("Your withdrawal will be verified and as soon as possible and will be transfer into your account."));
            actions.resetForm();
            accountContext.updateUserInfo(result.data.user)
            this.setState({
                successfully: true,
                new_balance: result.data.user.balance,
                submitting: false
            })
            this.refImageBox.style.backgroundImage = ``;
        }catch (e){
            this.setState({ submitting: false })
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, e.response.data.errors[field][0])
            })

        }


    }
    toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    onSelectFile = async (e, setFieldValue) => {
        let file = e.target.files[0];
        let image = (await this.toBase64(file));
        this.refImageBox.style.backgroundImage = `url(${image})`;
        setFieldValue("image", file);
    }
    render() {
        const {accountContext, applicationContext} = this.props;
        let self = this;
        return (
            <LoadingContainer loading={accountContext.loading}>
                <AccountContainer>
                    <div className={"form-center"}>

                        {!accountContext.loading && <Formik
                            innerRef={(ref) => this.refForm = ref}
                            initialValues={this.state}
                            validationSchema={Yup.object().shape({
                                amount: Yup.string().required(applicationContext.translator("You must enter amount")).min(1).test('match', applicationContext.translator('Amount must be smaller than available balance'), function (amount) {
                                    return parseFloat(amount) <= parseFloat(self.state.balance)
                                }),
                                dest_wallet_code: Yup.string().required(applicationContext.translator("You must enter your wallet address"))
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit} className={styles.FullWidth}>
                                    <Form.Group as={Row} >
                                        <Col md={8} className={"text-center"}>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4} className={applicationContext.translator("direction") === "rtl"?"start-center-value":"end-center-value"}>
                                                    <Form.Label>{applicationContext.translator("AVAILABLE BALANCE")} </Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="text"
                                                                      disabled={true}
                                                                      name={"current_balance"}
                                                                      className={"yellow-text"}
                                                                      value={numberWithCommas(this.state.balance)}
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>USD</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>

                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={!touched.amount && "mb-3"}>
                                                <Col md={4} className={applicationContext.translator("direction") === "rtl"?"start-center-value":"end-center-value"}>
                                                    <Form.Label>{applicationContext.translator("TOTAL WITHDRAWAL")} </Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <InputGroup>
                                                        <Form.Control
                                                            aria-describedby="basic-addon2"
                                                            name={"amount"}
                                                            value={values.amount}
                                                            onChange={(e) => {
                                                                if(e.nativeEvent.inputType === "insertFromPaste"){
                                                                    let v = e.target.value;
                                                                    v = e.target.value.replace(".", "");
                                                                    if(e.target.value.includes(",") && parseFloat(e.target.value) !== NaN){
                                                                        v = v.replace(",", ".");
                                                                        v = Math.round(v).toString();
                                                                    }
                                                                    setFieldValue('amount', v)
                                                                }else{
                                                                    if(!e.target.value.includes(",") && !e.target.value.includes("."))
                                                                        handleChange(e);
                                                                }

                                                            }}

                                                            onBlur={(e) => {
                                                                if(!isNaN(e.target.value) && e.target.value !== "")
                                                                    e.target.value = numberWithCommas(parseFloat(e.target.value));
                                                                else
                                                                    e.target.value = 0;
                                                            }}
                                                            className={"yellow-text"}
                                                            isValid={touched.amount && !errors.amount}
                                                            isInvalid={touched.amount && errors.amount}
                                                            autoComplete="off"
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>USD</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>

                                                </Col>
                                            </Form.Group>
                                            {touched.amount && <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4}></Col>
                                                <Col className={applicationContext.translator("direction") === "rtl"?"text-right":"text-left"}>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.amount}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}
                                            <Form.Group as={Row} className={"mb-4 mt-4"}>
                                                <Col md={4} className={applicationContext.translator("direction") === "rtl"?"start-center-value":"end-center-value"}>
                                                    <Form.Label>{applicationContext.translator("ACCOUNT-ID")}</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  disabled={true}
                                                                  name={"account_code"}
                                                                  value={accountCodeParse(accountContext.userInfo['account_code'], applicationContext.translator("direction") === "rtl")}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={!touched.dest_wallet_code && "mb-3"}>
                                                <Col md={4} className={applicationContext.translator("direction") === "rtl"?"start-center-value":"end-center-value"}>
                                                    <Form.Label>{applicationContext.translator("YOUR WALLET ADDRESS(TRC20)")}</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  name={"dest_wallet_code"}
                                                                  onChange={handleChange}
                                                                  value={values.dest_wallet_code}
                                                                  isValid={touched.dest_wallet_code && !errors.dest_wallet_code}
                                                                  isInvalid={touched.dest_wallet_code && errors.dest_wallet_code}
                                                                  autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            {touched.dest_wallet_code && <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4}></Col>
                                                <Col className={applicationContext.translator("direction") === "rtl"?"text-right":"text-left"}>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.dest_wallet_code}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={4} className={applicationContext.translator("direction") === "rtl"?"start-center-value":"end-center-value"}>
                                                    <Form.Label>{applicationContext.translator("UPLOAD YOUR TETHER QR CODE")}</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <button className={"btn btn-dark full-width"} type={"button"} onClick={(e) => this.refFile.click()}>{applicationContext.translator("Upload")}</button>

                                                </Col>
                                            </Form.Group>

                                            <Form.Group className={"d-flex justify-content-center"}>
                                                <Col md={4} className={applicationContext.translator("direction") === "rtl"?"start-center-value":"end-center-value"}>
                                                    <Form.Label></Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <button className={"btn btn-account full-width"} disabled={this.state.submitting} type={"submit"}>{applicationContext.translator("Save And Send")}</button>
                                                </Col>

                                            </Form.Group>
                                            {this.state.successfully &&
                                            <React.Fragment>
                                                <Form.Group as={Row} className={"text-center mt-3"}>
                                                    <Col md={4} className={applicationContext.translator("direction") === "rtl"?"start-center-value":"end-center-value"}>
                                                        <Form.Label></Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Text className="text-success mt-2">
                                                            {applicationContext.translator("Your withdrawal will be verified and as soon as possible and will be transfer into your account.")}
                                                        </Form.Text>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className={"mb-3 mt-3"}>
                                                    <Col md={4} className={applicationContext.translator("direction") === "rtl"?"start-center-value":"end-center-value"}>
                                                        <Form.Label className={"yellow-text"}>{applicationContext.translator("NEW BALANCE")}</Form.Label>
                                                    </Col>
                                                    <Col className={"start-center-value"}>
                                                        <Form.Control type="text"
                                                                      value={numberWithCommas(this.state.new_balance, true)}
                                                                      className={"yellow-text"}
                                                                      autoComplete="off"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </React.Fragment>}
                                        </Col>
                                        <Col>
                                            <div className={styles.UploadBox} ref={(ref) => this.refImageBox = ref}>

                                            </div>
                                        </Col>
                                    </Form.Group>
                                    <input type={"file"} name={"image"} onChange={(e) => this.onSelectFile(e, setFieldValue)}
                                           accept=".png, .jpg, .jpeg"
                                           style={{ visibility: "hidden" }}
                                           ref={(ref) => this.refFile = ref}/>
                                </Form>
                            )}
                        </Formik>}
                    </div>
                </AccountContainer>
            </LoadingContainer>
        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(Withdrawal))));