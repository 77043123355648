import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Popup from "../Popup";
class ActionButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }
    onClick = async (e) => {
        e.preventDefault();
        if(this.state.loading) return;
        if(this.props.confirm){
            this.setState({ confirm: true });
        }else{
            await this.handleClick();
        }

    }
    handleClick = async () => {
        const {onClick} = this.props;
        this.setState({
            loading: true
        });
        await onClick();
        this.setState({
            loading: false
        });
    }
    onClose = (e) => {
        this.setState({ confirm: false });
    }
    onConfirm = async (e) => {
        this.setState({ confirm: false });
        await this.handleClick();
    }
    render() {
        const {label, confirmTitle} = this.props;
        return (
            <React.Fragment>
                <NavLink to={this.props.to}
                         className={this.props.className} onClick={(e) => this.onClick(e)}
                         disabled={this.props.loading}>{this.state.loading?"Loading...":label}</NavLink>
                <Popup
                    show={this.state.confirm}
                    onClose={this.onClose}
                    disableClose={true}
                    onSubmit={this.onConfirm}
                    SubmitCaption={"Yes, Sure"}
                    CancelCaption={"No"}
                    title={confirmTitle}
                    textLeft={true}
                >
                    <div className={"text-left"}> Are you sure to do this action?</div>
                </Popup>
            </React.Fragment>

        );
    }
}

export default ActionButton;