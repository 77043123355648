import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import PanelContainer from "../PanelContainer";
import AccountTable from "../../../components/ui/AccountTable";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import moment from "moment-timezone";
import {numberWithCommas, timezone, utcTime} from "../../../helpers/helpers";
import LeftSidePopup from "../../../components/ui/LeftSidePopup";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import AdminService from "../../../services/admin.service";
import PageTitleContainer from "../Search/PageTitleContainer";
import {Form, InputGroup} from "react-bootstrap";
import styles from "../Journal/Journal.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Calendar from "react-calendar";
class PlayedHistory extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        this.state = {
            loading: false,
            dialog: false,
            history: null,
            selectedRoundId: params.get('rId'),
            position: null,
            date: null,
            datepicker: false,
            keyword: "",
            typingTimeout: null,
            status: "",
            start_date: null,
            end_date: null,
        }
    }
    componentDidMount = async () => {
        const {accountContext} = this.props;
        if(this.state.selectedRoundId){
            this.setState({ loading: true })
            const playedGame = await AdminService.getPlayedHistory({
                round_number: this.state.selectedRoundId,
                ...(accountContext.profileInfo && {user_id: accountContext.profileInfo['id']})
            })
            this.setState({
                loading: false,
                position: playedGame.position
            })
        }
    }

    onClose = (e) => {
        this.setState({
            dialog: false,
            dialogTitle: "",
            history: null
        })
    }
    onLoadHistory = (e, history) => {
        e.preventDefault();
        this.setState({
            dialog: true,
            history: history.id
        })
    }
    onChangeHistoryRow = (history) => {
        this.setState({
            history: history
        })
    }
    onChangeRow = (id, action) => {
        console.log(id, action)
    }
    onChangeDate = (date) => {
        this.setState({
            date,
            start_date: date[0],
            end_date: date[1],
            datepicker: false
        }, this.handleSearch)
    }
    handleSearch = () => {
        this.refTable.search({
            keyword: this.state.keyword,
            start_date: (this.state.start_date)?moment(new Date(this.state.start_date)).format('YYYY-MM-DD'):null,
            end_date: (this.state.end_date)?moment(new Date(this.state.end_date)).format('YYYY-MM-DD'):null,
        })
    }
    onSearchKeyword = (e) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        self.setState({
            keyword: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.handleSearchKeyword(self.state.keyword);
            }, 1000)
        });
    }
    handleSearchKeyword = (value) => {
        this.refTable.search({
            keyword: value,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
        }, this.handleSearch)
    }
    render() {
        const {accountContext} = this.props;
        return (
            <PanelContainer>
                {!this.state.loading && <React.Fragment>
                    <PageTitleContainer center={true} fullTitleWidth={500}/>
                    <div className={"form-center form-search"}>
                        <InputGroup className="mb-3">

                            <InputGroup.Text className={"calendar-container"} id="basic-addon1">
                                <a href={"javascript:void(0)"} onClick={(e) => this.setState({ datepicker: !this.state.datepicker })} className={"btn btn-white full-width"} >
                                    <React.Fragment>
                                        <FontAwesomeIcon icon={"calendar"} /> {this.state.start_date?
                                        <span className={"ml-1"}>
                                        {moment(new Date(this.state.start_date)).format('MMMM DD, YY')}
                                            {this.state.end_date && moment(new Date(this.state.start_date)).format('MMMM DD, YY') !== moment(new Date(this.state.end_date)).format('MMMM DD, YY')?("-" + moment(new Date(this.state.end_date)).format('MMMM DD, YY')):""}
                                    </span>:"DATE"}
                                    </React.Fragment>
                                </a>
                                {this.state.datepicker && <div className={"float-calendar"}><Calendar value={this.state.date} selectRange={true} onChange={this.onChangeDate}/></div>}
                            </InputGroup.Text>
                            <Form.Control
                                aria-describedby="basic-addon2"
                                name={"keyword"}
                                onChange={this.onSearchKeyword}
                                placeholder={"SEARCH BY ROUND#"}
                            />

                        </InputGroup>
                    </div>
                    <div className={"form-center"}>

                        <AccountTable loadData={AdminService.getPlayedHistory} full={true} position={this.state.position} header={(onSort) => {
                            return <TableRow>
                                <TableColHeader>#</TableColHeader>
                                <TableColHeader>GameID</TableColHeader>
                                <TableColHeader>Date</TableColHeader>
                                <TableColHeader>Time</TableColHeader>
                                <TableColHeader>Round#</TableColHeader>
                                <TableColHeader>Winning#</TableColHeader>
                                <TableColHeader>BALANCE BEFORE PLAY</TableColHeader>
                                <TableColHeader>TOTAL BET</TableColHeader>
                                <TableColHeader>REMAINING BALANCE</TableColHeader>
                                <TableColHeader>TOTAL WIN</TableColHeader>
                                <TableColHeader>NEW BALANCE</TableColHeader>
                            </TableRow>
                        }}
                                      sort={"id"}
                                      align={"desc"}
                                      render={(row, index, number) =>
                                          <TableRow key={index} onClick={(e) => this.onLoadHistory(e, row)} active={this.state.history === row.id} selected={this.state.selectedRoundId && this.state.selectedRoundId.toString() === row['round_number'].toString()}>
                                              <TableCol>{number}</TableCol>
                                              <TableCol>{row['id']}</TableCol>
                                              <TableCol>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableCol>
                                              <TableCol>{utcTime(row['created_at']).format('HH:mm:ss')}</TableCol>
                                              <TableCol yellow={true} >{row['round_number']}</TableCol>
                                              <TableCol>{row['number']}</TableCol>
                                              <TableCol>{numberWithCommas(row['balance_before_play'], true)}</TableCol>
                                              <TableCol yellow={true}>{numberWithCommas(row['table_bet'], true)}</TableCol>
                                              <TableCol>{numberWithCommas(row['remain_balance'], true)}</TableCol>
                                              <TableCol>{numberWithCommas(row['table_win'], true)}</TableCol>
                                              <TableCol>{numberWithCommas(row['new_balance'], true)}</TableCol>
                                          </TableRow>
                                      }
                                      params={{...(accountContext.profileInfo && { user_id: accountContext.profileInfo['id'] }) }}
                                      ref={(ref) => this.refTable = ref}
                                      mobile={(row, index, number, total, onNext, onPrevious) =>
                                          <TableMobileRow key={index} active={this.state.history === row.id}>
                                              <TableMobileCol>
                                                  <label>Number / Total Records</label>
                                                  <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                                              </TableMobileCol>
                                              <TableMobileCol>
                                                  <label>DATE</label>
                                                  <TableColValue>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableColValue>
                                              </TableMobileCol>
                                              <TableMobileCol>
                                                  <label>Time</label>
                                                  <TableColValue>{utcTime(row['created_at']).format('HH:mm:ss')}</TableColValue>
                                              </TableMobileCol>
                                              <TableMobileCol>
                                                  <label>Round#</label>
                                                  <TableColValue>{row['round_number']}</TableColValue>
                                              </TableMobileCol>
                                              <TableMobileCol>
                                                  <label>Winning#</label>
                                                  <TableColValue>{row['number']}</TableColValue>
                                              </TableMobileCol>
                                              <TableMobileCol>
                                                  <label>BALANCE BEFORE PLAY</label>
                                                  <TableColValue>{numberWithCommas(row['balance_before_play'], true)}</TableColValue>
                                              </TableMobileCol>
                                              <TableMobileCol>
                                                  <label>TOTAL BET</label>
                                                  <TableColValue>{numberWithCommas(row['table_bet'], true)}</TableColValue>
                                              </TableMobileCol>
                                              <TableMobileCol>
                                                  <label>REMAINING BALANCE</label>
                                                  <TableColValue>{numberWithCommas(row['remain_balance'], true)}</TableColValue>
                                              </TableMobileCol>
                                              <TableMobileCol>
                                                  <label>TOTAL WIN</label>
                                                  <TableColValue>{numberWithCommas(row['table_win'], true)}</TableColValue>
                                              </TableMobileCol>
                                              <TableMobileCol>
                                                  <label>NEW BALANCE</label>
                                                  <TableColValue>{numberWithCommas(row['new_balance'], true)}</TableColValue>
                                              </TableMobileCol>
                                              <TableMobileCol>
                                                  <TableColValue>
                                                      <button onClick={(e) => this.onLoadHistory(e, row)}>View this game on table ...</button>
                                                  </TableColValue>
                                              </TableMobileCol>
                                          </TableMobileRow>}
                        />
                    </div>
                </React.Fragment>}
                {this.state.dialog && <LeftSidePopup
                    show={this.state.dialog}
                    onClose={this.onClose}
                    onSubmit={this.onClose}
                    SubmitCaption={"OK"}
                    onChangeHistoryRow={this.onChangeHistoryRow}
                    onChangeRow={this.onChangeRow}
                    large={true}
                    dark={true}
                    previous={true}
                    history={this.state.history}
                />}
            </PanelContainer>
        )
    }
}

export default withAdminAccountContext(withRouter(PlayedHistory));