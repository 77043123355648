import api from "./api";
import {queryStringSerialize} from "../helpers/helpers"
const doCreateTicket = (data, files) => {
    let formData = new FormData();
    Object.keys(data).map(( key) => {
        formData.append(key, data[key]);
    })
    files.map(( file) => {
        formData.append("attachments[]", file);
    })
    return api
        .post("/api/account/tickets/create", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            return response.data;
        });
};
const getList = (data) => {
    return api
        .get("/api/account/tickets/list?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const show = (id) => {
    return api
        .get("/api/account/tickets/show/"+ id)
        .then((response) => {
            return response.data;
        });
};

const resolved = (id) => {
    return api
        .get("/api/account/tickets/resolved/"+ id)
        .then((response) => {
            return response.data;
        });
};

const TicketsService = {
    doCreateTicket,
    getList,
    show,
    resolved
};

export default TicketsService;