import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {NavLink, withRouter} from "react-router-dom"
import {withAlert} from "react-alert";
import moment from "moment-timezone";
import AccountTable from "../../../components/ui/AccountTable/AccountTable";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import {accountCodeParse, timezone, utcTime} from "../../../helpers/helpers";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import TicketStatus from "../../../components/ui/TicketStatus";
import {
    ROUTE_ADMIN_CEG_SELECTED_ROUND,
    ROUTE_ADMIN_ROUND_SELECTED_OVERVIEW, ROUTE_ADMIN_TICKET_SELECTED, ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW,
    ROUTE_MY_TICKETS, ROUTE_TICKET_SELECTED
} from "../../../routers/routes";
import TicketPriority from "../../../components/ui/TicketPriority";
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import PageTitleContainer from "../Search/PageTitleContainer";
import PanelContainer from "../PanelContainer/PanelContainer";
import AdminService from "../../../services/admin.service";
import UsersSearch from "../Users/UsersSearch";
import UsersTicketsSearch from "./UsersTicketsSearch";

class UsersTickets extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            search: {
                keyword: "",
                status: "0"
            }
        }
    }
    onLoadSelected = (e, row) => {
        const {accountContext, history} = this.props;
        e.preventDefault();
        accountContext.doGoto(ROUTE_ADMIN_TICKET_SELECTED, ":ticketId", row['id']);
    }
    doUpdateSearchData = (data) => {
        this.setState({
            ...this.state,
            search: data
        })
    }
    render() {
        const {accountContext} = this.props;
        return (
            <PanelContainer>
                <PageTitleContainer searchBar={this.state.searchComponent}/>

                    <div className={"form-center"}>
                        <AccountTable loadData={AdminService.getTicketsList} full={true} header={(onSort) => {
                            return <TableRow>
                                <TableColHeader>#</TableColHeader>
                                <TableColHeader>Account ID</TableColHeader>
                                <TableColHeader>Ticket ID</TableColHeader>
                                <TableColHeader>Date</TableColHeader>
                                <TableColHeader id={"table-cell-06"}>Time</TableColHeader>
                                <TableColHeader id={'table-cell-05'}>Subject</TableColHeader>
                                <TableColHeader id={'table-cell-04'}>Priority</TableColHeader>
                                <TableColHeader id={'table-cell-03'}>Team</TableColHeader>
                                <TableColHeader id={'table-cell-02'}>Status</TableColHeader>
                                <TableColHeader id={'table-cell-01'}></TableColHeader>
                            </TableRow>
                        }}
                          render={(row, index, number) =>
                              <TableRow key={index}>
                                  <TableCol>{number}</TableCol>
                                  <TableCol>#{row['id']}</TableCol>
                                  <TableCol>
                                      <a href={"javascript:void(0)"} className={"underline-link yellow-text"}
                                         onClick={(e) => accountContext.doGotoProfile(ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW, ":profileId", row['user']['account_code'], this.state, this.refTable.getState())}>
                                          {accountCodeParse(row['user']['account_code'])}
                                      </a>
                                  </TableCol>
                                  <TableCol>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableCol>
                                  <TableCol>{utcTime(row['created_at']).format('HH:mm:ss')}</TableCol>
                                  <TableCol>{row['subject']}</TableCol>
                                  <TableCol><TicketPriority priority={row['priority']} /></TableCol>
                                  <TableCol>{row['team']['name']}</TableCol>
                                  <TableCol><TicketStatus status={row['status']} /></TableCol>
                                  <TableCol>
                                      <a href={"javascript:void(0)"} className={"underline-link yellow-text"}
                                         onClick={(e) => this.onLoadSelected(e, row)}>
                                          OPEN
                                      </a>
                                  </TableCol>
                              </TableRow>
                          }
                          sort={"id"}
                          align={"desc"}
                          ref={(ref) => this.refTable = ref}
                          loaded={() => {
                              this.setState({
                                  searchComponent: <UsersTicketsSearch refTable={this.refTable} searchData={this.state.search} doUpdateSearchData={this.doUpdateSearchData}  />
                              })
                          }}
                          params={{
                              ...(accountContext.profileInfo && { user_id: accountContext.profileInfo['id'] })
                          }}
                          search={this.state.search}
                          mobile={(row, index, number, total, onNext, onPrevious) =>
                              <TableMobileRow key={index}>
                                  <TableMobileCol>
                                      <label>Number / Total Records</label>
                                      <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Ticket ID</label>
                                      <TableColValue>#{row['id']}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>ID NUMBER</label>
                                      <TableColValue>
                                          <NavLink className={"underline-link yellow-text"} to={ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW.replaceAll(":profileId", row['user']['account_code'])}>
                                              {accountCodeParse(row['user']['account_code'])}
                                          </NavLink>
                                      </TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Date</label>
                                      <TableColValue>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Time</label>
                                      <TableColValue>{utcTime(row['created_at']).format('hh:mm:ss')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Subject</label>
                                      <TableColValue>{row['subject']}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Priority</label>
                                      <TableColValue><TicketPriority priority={row['priority']} /></TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Team</label>
                                      <TableColValue>{row['team']['name']}</TableColValue>
                                  </TableMobileCol>

                                  <TableMobileCol>
                                      <label>Status</label>
                                      <TableColValue>{row['status']['label']}</TableColValue>
                                  </TableMobileCol>
                              </TableMobileRow>}
                        />
                    </div>
            </PanelContainer>

        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withAdminAccountContext(UsersTickets))));