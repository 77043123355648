import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import classNames from "classnames";
import {
    countChips,
    totalChips,
    uuid,
    getRightNumbers,
    getLeftNumbers,
    getTopNumbers,
    getCornerNumbers,
    sumChipsByFormula,
    neonBorder,
    specialCells,
    onHoverBorder,
    sumChips,
    convertArrayKeyToMap,
    calculateWinInFormula
} from "../../../helpers/helpers";
import RedLozenge from "../../../components/icons/RedLozenge.png";
import BlackLozenge from "../../../components/icons/BlackLozenge.png";
import RedLozengeGlow from "../../../components/icons/RedLozengeGlow.png";
import BlackLozengeGlow from "../../../components/icons/BlackLozengeGlow.png";
import {
    NUMBERS_OF_LEFT_TABLE,
    FORMULA_BET_DATA,
    CUSTOM_FORMULA,
    MAX_CHIP_AMOUNT,
    MAX_CHIP_COUNT,
    FORMULA_NUMBERS, TABLE_NUMBERS, RED_NUMBERS_OF_TABLE
} from "../../../helpers/constants";
import ContextMenu from "../../../components/ui/ContextMenu";
import TableRow from "./TableRow";
import NumberCounter from "../../../components/ui/NumberCounter";

class SmallTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contextMenu: false,
            contextMenuNumber: null,
            lock: false,
            lockWheel: false,
            chips: new Map(),
            selectedHover: [],
            selectedHoverFormula: null,
            selectedHoverActions: null,
            wonCells: [],
            height: "100%"
        }

    }
    getTableHeight = (e) => {
        const {history} = this.props;
        let h = 160;
        if (window.innerWidth > 720 && window.innerWidth <= 1023){
            h = 176;
        }
        this.setState({
            height: `${window.innerHeight - h}px`,
            chips: convertArrayKeyToMap(history['chips']),
            wonCells: calculateWinInFormula(history['number'])
        })
        window.addEventListener('resize', this.getTableHeight);
    }
    componentDidMount() {

        this.getTableHeight();

    }

    onClearSelected = () => {
        this.setState({
            selectedHover: [],
            selectedHoverFormula: null,
            displayText: null
        })
    }
    renderBorders = (number) => {
        return (
            <React.Fragment>
                {!number.toString().endsWith("-12") && <div className={classNames(styles.TableRightBorder,
                    neonBorder(this.state.wonCells, "right", number) && styles.HighLightWonBorder,
                )}
                     id={`cell-f-split-${number}`}
                >
                    <div className={classNames(styles.VerticalLine)}></div>
                    {this.renderChips(`split-${number}`)}
                </div>}

                {(NUMBERS_OF_LEFT_TABLE.includes(number)
                    || number.toString().endsWith("1-18")
                    || number.toString().endsWith("19-36")
                    || number.toString().endsWith("EVEN")
                    || number.toString().endsWith("ODD")
                    || number.toString().endsWith("BLACK")
                    || number.toString().endsWith("RED")
                ) &&
                <div className={classNames(styles.TableLeftBorder,
                    neonBorder(this.state.wonCells, "left", number) && styles.HighLightWonBorder,
                )}
                     id={`cell-f-street-${number}`}
                >
                    <div className={classNames(styles.VerticalLine)}></div>
                    {this.renderChips(`street-${number}`)}

                </div>}
                {(number === 0 || number === "2-to-1-1") &&
                <div className={classNames(styles.TableLeftBorder,
                    neonBorder(this.state.wonCells, "left", number) && styles.HighLightWonBorder,
                )}>
                    <div className={classNames(styles.VerticalLine)}></div>
                </div>}

                <div className={classNames(styles.TableTopBorder,
                    neonBorder(this.state.wonCells, "top", number) && styles.HighLightWonBorder,
                )}
                     id={`cell-f-tsplit-${number}`}
                >
                    <div className={classNames(styles.HorizontalLine)}></div>
                    {this.renderChips(`tsplit-${number}`)}
                </div>

                {(number === "2-to-1-1" ||
                    number === "2-to-1-2" ||
                    number === "2-to-1-3" ||
                    number === "2-to-1-1" ||
                    number === "3rd-12" || number === "19-36"
                ) &&
                <div className={classNames(styles.TableBottomBorder,
                    neonBorder(this.state.wonCells, "bottom", number) && styles.HighLightWonBorder,
                )}>
                    <div className={classNames(styles.HorizontalLine)}></div>
                </div>}

                <div className={styles.TableTopLeftCorner}
                     id={`cell-f-corner-${number}`}
                >
                    {this.renderChips(`corner-${number}`)}
                </div>
            </React.Fragment>
        )
    }


    renderChips = (number) => {
        let chips = this.state.chips;
        let html = [];
        if(chips.has(number)){
            chips.get(number).map((c, index) =>
                html.push(
                    <img src={`${process.env.PUBLIC_URL}/assets/img/chips-${c.chipSelected}.png`}
                         className={classNames(styles.CellChipButton)}
                         style={{left: `calc(50% - ${16 + c.x}px)`, top: `calc(50% - ${16 + c.y}px)`}}
                         key={index}
                         alt={`chips-${c.chipSelected}`}
                         id={c.id}
                    />
                )
            )
        }
        return html;
    }
    render() {
        const {applicationContext, history} = this.props;
        return (
            history?<div className={classNames(styles.Table, styles.FullTable)}>
                <div className={styles.LeftGreenDisplayWrapper} >
                    <div className={classNames(styles.NumberCounterWrapper, styles.MobileNumberCounterWrapper)}>
                        <NumberCounter fixedSize title={applicationContext.translator("CHIPS")} number={parseInt(history['chip_count'])} count={MAX_CHIP_COUNT.toString().length}/>
                        <NumberCounter fixedSize title={applicationContext.translator("ON TABLE")} number={parseInt(history['table_bet'])} count={MAX_CHIP_AMOUNT.toString().length}/>
                        <NumberCounter fixedSize title={applicationContext.translator("WIN")} number={parseInt(history['table_win'])} count={5}/>
                        <NumberCounter fixedSize title={applicationContext.translator("BALANCE")} number={parseInt(history['new_balance'])} count={6}/>
                    </div>

                </div>
                <table width={"100%"}
                       ref={(el) => {
                           if (el && applicationContext.state.isMobileVersion) {
                               el.style.setProperty('height', this.state.height, 'important');
                           }
                       }}
                >
                    {TABLE_NUMBERS.map((row, index) => {
                        return (
                            <TableRow key={index}>
                                {row.map((cell, i) => {
                                    if(cell.number !== "icon" && cell.number !== "timer"){
                                        return (
                                            typeof cell.number === "number"?(
                                                <React.Fragment>
                                                    <td key={i} colSpan={cell.colSpan} rowSpan={cell.rowSpan}>
                                                        <div className={classNames(
                                                            styles.CellNumber,
                                                            cell.small && styles.TextSmall,
                                                            cell.vertical && styles.Vertical,
                                                            cell.green && styles.TextGreen,
                                                            RED_NUMBERS_OF_TABLE.includes(cell.number) && styles.TextRed)}
                                                             id={`cell-n-${cell.number}`}
                                                        >
                                                            {cell.label}
                                                            {this.renderChips(cell.number)}
                                                        </div>
                                                        {this.renderBorders(cell.number)}
                                                    </td>
                                                </React.Fragment>
                                            ):(
                                                <React.Fragment>
                                                    <td key={i} colSpan={cell.colSpan} rowSpan={cell.rowSpan}
                                                        className={classNames(
                                                            styles.CellNumber,
                                                            cell.small && styles.TextSmall,
                                                            cell.vertical && styles.Vertical,
                                                            cell.green && styles.TextGreen,
                                                            RED_NUMBERS_OF_TABLE.includes(cell.number) && styles.TextRed)}
                                                        id={`cell-f-${cell.number}`}
                                                    >
                                                        {(cell.label === "RED" || cell.label === "BLACK")?(
                                                            (cell.label === "RED")?(
                                                                <img src={(this.state.wonCells.includes("RED"))?RedLozengeGlow:RedLozenge} className={styles.Diamond} />
                                                            ):(
                                                                <img src={(this.state.wonCells.includes("BLACK"))?BlackLozengeGlow:BlackLozenge} className={styles.Diamond}/>
                                                            )
                                                        ):(cell.label)}

                                                        {this.renderChips(cell.number)}
                                                        {this.renderBorders(cell.number)}
                                                    </td>
                                                </React.Fragment>
                                            )

                                        )
                                    }
                                })}

                            </TableRow>
                        )
                    })}
                </table>
            </div>:null
        );
    }
}

export default withApplicationContext((SmallTable));