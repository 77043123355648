import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import {Formik} from "formik";
import * as Yup from "yup";
import {Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import {accountCodeParse, numberWithCommas} from "../../../helpers/helpers";
import {withAlert} from "react-alert";
import styles from "./TransferCreditToAccount.module.css";
import classNames from "classnames";
import PanelContainer from "../PanelContainer";
import AdminService from "../../../services/admin.service";
import PageTitleContainer from "../Search/PageTitleContainer";
import MasterPasswordAuthentication from "../MasterPasswordAuthentication";
class TransferCreditToAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password_authenticated: false,
            password: "",
            amount: "",
            account_code: "",
            successfully: false,
            error: false,
            submitting: false
        }
    }

    handleSubmit = async (values, actions) => {
        this.setState({submitting: true, successfully: false, error: false})
        try{
            let amount = values.amount.replaceAll(".", "").replaceAll("$", "").replace(/\D/g, "");
            let account_code = values.account_code.replaceAll(" ", "")
             await AdminService.doTransfer({
                 account_code: account_code,
                 amount: amount,
                 password: this.state.password
            });
            this.setState({
                successfully: true,
                submitting: false,
                error: false,
                amount: "",
                account_code: ""
            })
            actions.resetForm();

        }catch (e){
            if(e.response.data.error === "AUTHENTICATION_FAILED"){
                this.setState({
                    error: true,
                    successfully: false,
                    submitting: false
                })
            }else{
                Object.keys(e.response.data.errors).map((field) => {
                    actions.setFieldError(field, e.response.data.errors[field][0])
                })
                this.setState({
                    error: false,
                    successfully: false,
                    submitting: false
                })
            }


        }


    }
    handleAuthenticated = (password, data = null) => {
        this.setState({
            password_authenticated: true,
            password
        })
    }
    render() {
        return (
            <PanelContainer>
                <PageTitleContainer center={true} fullTitleWidth={500}/>
                <div className={"form-center"}>
                    {this.state.password_authenticated?
                        <Formik
                            innerRef={(ref) => this.refForm = ref}
                            enableReinitialize={true}
                            initialValues={{
                                account_code: this.state.account_code,
                                amount: this.state.amount
                            }}
                            validationSchema={Yup.object().shape({
                                account_code: Yup.string().required("You must enter user ${path}").min(9).max(11).label("Account ID"),
                                amount: Yup.string().required("You must enter ${path}").test('is-valid-amount', '${path} must be bigger than $1', value => {
                                    if(value){
                                        let filtered = value.replaceAll(".", "").replaceAll("$", "").replace(/\D/g, "");
                                        if(parseFloat(filtered) !== NaN && parseFloat(filtered) >= 1)
                                            return true;

                                    }
                                    return false;
                                }).label("Amount"),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit} className={styles.FullWidth}>
                                    <Form.Group as={Row} >
                                        <Col md={12} className={"text-center"}>
                                            <Form.Group className={(!errors.account_code || !touched.account_code) && "mb-3"}>
                                                <Col className={"start-center-value position-relative"}>
                                                    <Form.Label className={styles.FormLabel}>Account ID :</Form.Label>
                                                    <Form.Control type="text"
                                                                  onChange={handleChange}
                                                                  name={"account_code"}
                                                                  isValid={touched.account_code && !errors.account_code}
                                                                  isInvalid={touched.account_code && errors.account_code}
                                                                  value={values.account_code}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            {touched.account_code && <Form.Group className={"mb-2"}>
                                                <Col className={"text-left"}>
                                                    <Form.Label className={styles.FormLabel}></Form.Label>
                                                    <Form.Text className="text-danger">
                                                        {errors.account_code}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}
                                            <Form.Group className={(!errors.amount || !touched.amount) && "mb-3"}>
                                                <Col className={"start-center-value position-relative"}>
                                                    <Form.Label className={styles.FormLabel}>Amount :</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            aria-describedby="basic-addon2"
                                                            name={"amount"}
                                                            onChange={(e) => {
                                                                let filtered = e.target.value.replaceAll(".", "").replaceAll("$", "").replace(/\D/g, "");
                                                                setFieldValue('amount', filtered)
                                                            }}
                                                            onFocus={(e) => {
                                                                let filtered = e.target.value.replaceAll(".", "").replaceAll("$", "").replace(/\D/g, "");
                                                                this.setState({
                                                                    amount: filtered
                                                                }, () => {
                                                                    setTimeout(() => {
                                                                        e.target.select()
                                                                    }, 100)
                                                                })

                                                            }}
                                                            onKeyUp={(e) => {

                                                            }}
                                                            onBlur={(e) => {
                                                                let filtered = numberWithCommas(parseFloat(e.target.value), true);
                                                                setFieldValue('amount', filtered)
                                                            }}
                                                            value={values.amount}
                                                            isValid={touched.amount && !errors.amount}
                                                            isInvalid={touched.amount && errors.amount}
                                                            autoComplete="off"
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>USD</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            {touched.amount && <Form.Group className={"mb-2"}>
                                                <Col className={"text-left"}>
                                                    <Form.Label className={styles.FormLabel}></Form.Label>
                                                    <Form.Text className="text-danger">
                                                        {errors.amount}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}
                                            <Form.Group>
                                                <Col>
                                                    <Form.Group className={"d-flex justify-content-center"}>
                                                        <Form.Label className={styles.FormLabel}></Form.Label>
                                                        <button className={"btn btn-account full-width"} disabled={this.state.submitting} type={"submit"}>{this.state.submitting?<Spinner animation="grow" size={"sm"} variant="light" />:"Charge"}</button>
                                                    </Form.Group>

                                                    {this.state.successfully && <Form.Group as={Row} className={classNames(styles.TextAlert, "green-text")}>
                                                        <Form.Text className={classNames(styles.TextAlert, "green-text")}>
                                                            The user account has been charged successfully.
                                                        </Form.Text>

                                                    </Form.Group>}
                                                    {this.state.error && <Form.Group as={Row} className={classNames(styles.TextAlert, "red-text")}>
                                                        <Form.Text className={classNames(styles.TextAlert, "red-text")}>
                                                            You need master password for charge user's accounts
                                                        </Form.Text>

                                                    </Form.Group>}
                                                </Col>
                                            </Form.Group>

                                        </Col>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                        :
                        <MasterPasswordAuthentication handleAuthenticated={this.handleAuthenticated}/>
                    }
                </div>
            </PanelContainer>
        )
    }
}

export default withAlert()(withRouter(withAdminAccountContext(TransferCreditToAccount)));