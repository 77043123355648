import React, {Component} from 'react';
import styles from "./GameTable.module.css";

class AccountBlock extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }
    render() {
        return (
            <div className={styles.BlockAccount}>
                <div>
                    <img src={"/assets/img/block-icon.png"} width={"60%"} /><br/>
                    Your account has been blocked by administrator.<br/>
                    if you don't know reason you can call to support team.
                </div>
            </div>
        );
    }
}

export default AccountBlock;