import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import {withRouter} from "react-router-dom"
import AccountContainer from "../AccountContainer";
import {withAlert} from "react-alert";
import LoadingContainer from "../../../components/common/LoadingContainer";
import {withProfileContext} from "../../../contexts/ProfileContext";
import SubmitTicket from "./SubmitTicket";

class NewTicket extends Component {
    refFileSelector = React.createRef();
    refUploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount = async () => {

    }

    render() {
        const {accountContext} = this.props;

        return (
            <LoadingContainer loading={accountContext.loading}>
                <AccountContainer profile={this.props.userId}>
                    <div className={"form-center"}>
                        {!accountContext.loading && <SubmitTicket />}
                    </div>
                </AccountContainer>
            </LoadingContainer>

        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(withProfileContext(NewTicket)))));