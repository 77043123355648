import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {
    SectionContainer,
    SectionTitle,
    SectionBody, CenterCol,
    Brand
} from "./style.jsx";
import {Container, Row, Col} from 'react-bootstrap';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SectionContainer bg={"white"} id={"section-about"}>
                <Container>
                    <SectionTitle><div className={"wrapper"}>{applicationContext.translator("About")}</div></SectionTitle>
                    <SectionBody>
                        <Row>
                            <Col md={8}>
                                <p><b>"My Roulette"</b> <span>یک پلتفرم بازی خصوصی است که در آن فضای شخصی کاملی دارید به این معنی که هیچکونه اطلاعات شخصی اسکن پاسپورت، کارت اعتباری، آدرس محل سکونت یا مشخصات حساب بانکی از شما خواسته نمی شود.</span></p>
                                <p><span>در بخش ثبت نام سایت، فقط یک آدرس ایمیل و پسورد انتخابی خواسته می شود. پس از وارد کردن آن امکان ورود به حساب کاربری و شروع بازی را خواهید داشت.</span></p>
                                <p><span>تنهای چیزی که نیاز دارید یک کیف پول دیجیتال تتر&nbsp; (Tether USD (USDT) TRC20)برای پرداخت و برداشت مبالغ بردتان است. یک فضای بازی کاملاً خصوصی!</span></p>
                                <p><span>My Roulette </span><span>یک کانال تلویزیونی به شدت در حال رشد و مطمئن در فضای بازی های مجازی است. هدف ما ایجاد بهترین تجربه بازی های سرگرم کننده برای شماست تا در فضای راحت منزلتان یا هر جای دیگری، فرصت و امکان بازی را داشته باشید.</span></p>
                                <p><span>علاوه بر پخش زنده بازی ها بصورت آنلاین، همزمان پخش زنده از کانال های ماهواره ای منتخب را هم فراهم می کنیم تا بهترین تجربه را در مشاهده جریان بازی را داشته باشید. هدف ما ارائه سرگرمی باکیفیت برای علاقمندان به کازینوهای آنلابن در سراسر دنیاست. علاوه بر آن، ما قصد داریم اطمینان پیدا کنیم که محتواهای در حال پخش ما بالاترین کیفیت را داشته و اطلاعات کافی را برای شما فراهم کنند. در همین راستا از دوربین های تلویزیونی بسیار با کیفیت استفاده می کنیم تا به کاربران خود بهترین نمای میز بازی خود را نمایش دهیم. ما لیستی کامل و متنوع از قابلیت هایی را در بازی ارائه می کنیم که به وضوح ما را از سایر رقبا متمایز می کند. همه بازی های ما کاملا بصورت زنده، منصفانه و مطمئن (هم برای شما و هم برای ما) انجام می شوند.</span></p>
                                <p><span>مسئولانه بازی کنید و لذت ببرید!</span></p>
                            </Col>
                            <CenterCol>
                                <div>
                                    <Brand>
                                        <img src={"/assets/img/trust-wallet.jpeg"} />
                                        <h6>{applicationContext.translator("The most trusted & secure crypto wallet")}</h6>
                                    </Brand>
                                    {/*<Brand>*/}
                                    {/*    <img src={"/assets/img/logo.png"} />*/}
                                    {/*    <h6>{applicationContext.translator("Licensed Operator")}</h6>*/}
                                    {/*</Brand>*/}
                                </div>
                            </CenterCol>
                        </Row>
                    </SectionBody>

                </Container>
            </SectionContainer>

        );
    }
}

export default withApplicationContext(AboutUs);