import React, {Component} from 'react';
import styles from "./Login.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withGameTableContext} from "../../../contexts/GameTableContext";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import {withRouter} from "react-router-dom"
import {withAlert} from "react-alert";
import classNames from "classnames";
import {HOME_WEBSITE} from "../../../helpers/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
class MaxFooter extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <footer className={classNames(this.props.float && styles.NoFloat, this.props.register && styles.RegisterFooter)} style={{ direction: applicationContext.translator("direction")}}>
                <div className={"container"}>
                    <Row className={classNames(styles.FooterMenu)}>
                        <Col md={12} className={"text-center"}>
                            <ul className="">
                                <li>
                                    <a href={HOME_WEBSITE + "/responsible-gaming"} target={"_blank"} style={applicationContext.translator("direction") === "rtl"?{fontSize: "12px", lineHeight: '1.7em', fontWeight: "bold"}:null}>{applicationContext.translator("Responsible Gaming")}</a>
                                </li>
                                <li>
                                    <a href={HOME_WEBSITE + "/terms-and-conditions"} target={"_blank"} style={applicationContext.translator("direction") === "rtl"?{fontSize: "12px", lineHeight: '1.7em', fontWeight: "bold"}:null}>{applicationContext.translator("Terms & Conditions")}</a>
                                </li>
                                <li>
                                    <a href={HOME_WEBSITE + "/self-exclusion"} target={"_blank"} style={applicationContext.translator("direction") === "rtl"?{fontSize: "12px", lineHeight: '1.7em', fontWeight: "bold"}:null}>{applicationContext.translator("Self-Exclusion")}</a>
                                </li>
                                <li>
                                    <a href={HOME_WEBSITE + "/dispute-resolution"} target={"_blank"} style={applicationContext.translator("direction") === "rtl"?{fontSize: "12px", lineHeight: '1.7em', fontWeight: "bold"}:null}>{applicationContext.translator("Dispute Resolution")}</a>
                                </li>
                                <li>
                                    <a href={HOME_WEBSITE + "/anti-money-laundering"} target={"_blank"} style={applicationContext.translator("direction") === "rtl"?{fontSize: "12px", lineHeight: '1.7em', fontWeight: "bold"}:null}>{applicationContext.translator("Anti-Money Laundering")}</a>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <a href={HOME_WEBSITE + "/fairness-and-rng-testing-methods"} target={"_blank"} style={applicationContext.translator("direction") === "rtl"?{fontSize: "12px", lineHeight: '1.7em', fontWeight: "bold"}:null}>{applicationContext.translator("Fairness & RNG Testing Methods")}</a>
                                </li>
                                <li>
                                    <a href={HOME_WEBSITE + "/kyc-policies,-privacy-and-management-of-personal-data"} target={"_blank"} style={applicationContext.translator("direction") === "rtl"?{fontSize: "12px", lineHeight: '1.7em', fontWeight: "bold"}:null}>{applicationContext.translator("KYC Policies, Privacy & Management of Personal Data")}</a>
                                </li>
                                <li>
                                    <a href={HOME_WEBSITE + "/accounts,-payouts-and-bonuses"} target={"_blank"} style={applicationContext.translator("direction") === "rtl"?{fontSize: "12px", lineHeight: '1.7em', fontWeight: "bold"}:null}>{applicationContext.translator("Accounts, Payouts & Bonuses")}</a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row style={{ direction: "ltr" }}>
                        <Col md={4} className={"text-left"}>
                            <a className={styles.SocialIcon} href="https://www.youtube.com/channel/UC6xKfAX7nkBYM1lKlsEbidg" target="_blank"><FontAwesomeIcon icon={faYoutube} /></a>
                        </Col>
                        <Col md={4} >
                            <div className={styles.LogoCopyright}>
                                <a href={HOME_WEBSITE}><img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={150}/></a>
                            </div>
                        </Col>
                        <Col md={4} >

                        </Col>
                    </Row>
                    <p className={styles.Copyright} style={{ direction: applicationContext.translator("direction") }} >
                        <small style={applicationContext.translator("direction") === "rtl"?{fontSize: "12px", lineHeight: '1.7em'}:null}>
                            {applicationContext.translator("In order to register for this website, the user is required to accept the General Terms and Conditions.")}<br/>
                            {applicationContext.translator("In the event the General Terms and Conditions are updated, existing users may choose to discontinue using the products and services before the said update shall become effective,")}<br/>
                            {applicationContext.translator("which is a minimum of two weeks after it has been announced.")}
                        </small>
                        <hr style={{ backgroundColor:"#919191" }}/>
                        {applicationContext.translator("SWISSCO GAMING IS REGISTERED UNDER NO. 155110 AT SCHOUT BIJ NACHT DOORMANWEG 40, WILLEMSTAD, CURAÇAO.")}<br/>
                        {applicationContext.translator("THIS WEBSITE IS LICENSED AND REGULATED BY CURAÇAO EGAMING (CURAÇAO LICENSE NO. 1668 JAZ ISSUED BY CURAÇAO EGAMING).")}
                    </p>
                </div>
            </footer>

        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withGameTableContext(MaxFooter))));