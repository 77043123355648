import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import NumberCounter from "../../../components/ui/NumberCounter";
import {totalChips, countChips, sumChips} from "../../../helpers/helpers"
import classNames from "classnames";
import {MAX_CHIP_AMOUNT, MAX_CHIP_COUNT, RED_NUMBERS_OF_TABLE} from "../../../helpers/constants";
class Display extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const {applicationContext, gameTableContext, mobile} = this.props;
        return (
            <div className={classNames(styles.LeftGreenDisplayContainer, mobile && styles.MobileGreenDisplayContainer)}>
                <div className={styles.LeftGreenDisplayLastNumber}>
                    <div className={classNames(styles.CircleButton, styles.GrayCircleButton)}>
                        <div className={classNames(styles.CircleButtonWrapper, (gameTableContext.state.numberHistory[0] === 0)?styles.GreenCircleButtonWrapper:(RED_NUMBERS_OF_TABLE.includes(gameTableContext.state.numberHistory[0]) && styles.RedCircleButtonWrapper))}>{gameTableContext.state.numberHistory[0]}</div>
                    </div>
                </div>
                <div className={styles.LeftGreenDisplayWrapper}>
                    <div className={styles.NumberCounterWrapper}>
                        <NumberCounter title={applicationContext.translator("Chips")} dir={applicationContext.translator("direction")} number={parseInt(countChips(gameTableContext.state.chips) - gameTableContext.state.lose.length)} count={MAX_CHIP_COUNT.toString().length}/>
                        <NumberCounter title={applicationContext.translator("On Table")} dir={applicationContext.translator("direction")} number={parseInt(totalChips(gameTableContext.state.chips) - sumChips(gameTableContext.state.lose))} count={MAX_CHIP_AMOUNT.toString().length}/>
                        <NumberCounter title={applicationContext.translator("Win")} dir={applicationContext.translator("direction")} number={parseInt(gameTableContext.state.win)} count={5}/>
                        <NumberCounter title={applicationContext.translator("Balance")} dir={applicationContext.translator("direction")} number={(parseInt(gameTableContext.state.balance))} count={6}/>
                    </div>

                </div>
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext(Display));