import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import {NavLink, withRouter} from "react-router-dom"
import AccountContainer from "../AccountContainer";
import {withAlert} from "react-alert";
import styled from "./Ticketing.module.css";
import LoadingContainer from "../../../components/common/LoadingContainer";
import {withProfileContext} from "../../../contexts/ProfileContext";
import {Formik} from "formik";
import * as Yup from "yup";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import styles from "../Withdrawal/Withdrawal.module.css";
import {numberWithCommas, uuid} from "../../../helpers/helpers";
import Attachments from "../../../components/ui/Attachments";
import WithdrawalService from "../../../services/withdrawal.service";
import TicketsService from "../../../services/tickets.service";
import AdminService from "../../../services/admin.service";

class SubmitTicket extends Component {
    refFileSelector = React.createRef();
    refUploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            teams: [],
            attachments: [],
            files: [],
            deleted_attachments: [],
            submitting: false,
            subject: "",
            message: "",
            team: "",
            priority: "LOW"
        }
    }
    componentDidMount = async () => {
        const {accountContext, profileContext} = this.props;
        let context = (this.props.userId)?profileContext:accountContext;
        if(context.hasOwnProperty('userInfo'))
            this.setState({
                teams: context.userInfo.teams
            })
    }

    onCallbackAttachment = (attachments, deleted = null) => {
        let deleted_attachments = this.state.deleted_attachments;
        if(deleted)
            deleted_attachments.push(deleted);
        let files = attachments.map(a => a.file);
        this.setState({
            attachments,
            files,
            deleted_attachments
        })
    }
    handleSubmit = async (values, actions) => {
        const {alert, reply, submitted, accountContext, profileContext, applicationContext} = this.props;
        let context = (this.props.userId)?profileContext:accountContext;
        this.setState({ submitting: true })
        try{
            let service = (context.hasOwnProperty('userInfo'))?TicketsService:AdminService;
            let result = await service.doCreateTicket({
                subject: values.subject,
                message: values.message,
                priority: values.priority,
                team: values.team,
                ...(reply && {ticket_id: reply})
            }, this.state.files);
            alert.success((reply)?applicationContext.translator("Success! Your reply has been submitted."):applicationContext.translator("Success! Your request has been submitted."));
            actions.resetForm();
            this.setState({
                successfully: true,
                attachments: [],
                files: [],
                deleted_attachments: [],
                submitting: false,
                subject: "",
                message: "",
                team: "",
                priority: "LOW"
            })
            this.refUploader.reset();
            if(submitted)
                submitted();


        }catch (e){
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, e.response.data.errors[field][0])
            })

        }
        this.setState({ submitting: false })
    }
    render() {
        const {reply, applicationContext} = this.props;

        return (
            <Formik
                innerRef={(ref) => this.refForm = ref}
                initialValues={this.state}
                validationSchema={Yup.object().shape({
                    ...(!reply && {subject: Yup.string().required(applicationContext.translator("You must enter your ${path}")).min(3).label(applicationContext.translator("Subject"))}),
                    message: Yup.string().required(applicationContext.translator("You must enter your ${path}")).min(3).label(applicationContext.translator("Message")),
                    ...(!reply && {team: Yup.string().required(applicationContext.translator("You must select your ${path}")).label(applicationContext.translator("Team"))}),
                })}
                onSubmit={this.handleSubmit}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched,
                      setFieldError,
                      setErrors,
                      setTouched
                  }) => (
                    <Form onSubmit={handleSubmit} className={styles.FullWidth}>
                        <Form.Control type="hidden"
                                      name={"application"}
                                      onChange={handleChange}
                                      value={values.application}
                        />
                        <Form.Group as={Row} >
                            <Col md={9} className={"text-center"}>
                                {!reply && <Form.Group as={Row} className={!touched.subject && "mb-3"}>
                                    <Col md={4} className={"end-center-value"}>
                                        <Form.Label>{applicationContext.translator("Subject")} </Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <Form.Control type="text"
                                                      name={"subject"}
                                                      value={values.subject}
                                                      onChange={handleChange}
                                                      isValid={touched.subject && !errors.subject}
                                                      isInvalid={touched.subject && errors.subject}
                                                      autoComplete="off"
                                        />

                                    </Col>
                                </Form.Group>}
                                {!reply && touched.subject && <Form.Group as={Row} className={"mb-3"}>
                                    <Col md={4}></Col>
                                    <Col className={applicationContext.translator("direction") === "rtl"?"text-right":"text-left"}>
                                        <Form.Text className="text-danger mt-2">
                                            {errors.subject}
                                        </Form.Text>
                                    </Col>
                                </Form.Group>}

                                {!reply && <Form.Group as={Row} className={!touched.priority && "mb-3"}>
                                    <Col md={4} className={"end-center-value"}>
                                        <Form.Label >{applicationContext.translator("Priority")}</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <Form.Control
                                            as={"select"}
                                            name={"priority"}
                                            value={values.priority}
                                            onChange={handleChange}
                                        >
                                            <option value={"LOW"}>{applicationContext.translator("Low")}</option>
                                            <option value={"MEDIUM"}>{applicationContext.translator("Medium")}</option>
                                            <option value={"HIGH"}>{applicationContext.translator("High")}</option>
                                            <option value={"URGENT"}>{applicationContext.translator("Urgent")}</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>}
                                {!reply && touched.priority && <Form.Group as={Row} className={"mb-3"}>
                                    <Col md={4}></Col>
                                    <Col className={applicationContext.translator("direction") === "rtl"?"text-right":"text-left"}>
                                        <Form.Text className="text-danger mt-2">
                                            {errors.priority}
                                        </Form.Text>
                                    </Col>
                                </Form.Group>}
                                {!reply && <Form.Group as={Row} className={!touched.team && "mb-3"}>
                                    <Col md={4} className={"end-center-value"}>
                                        <Form.Label >{applicationContext.translator("Team")}</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <Form.Control
                                            as={"select"}
                                            name={"team"}
                                            value={values.team}
                                            onChange={handleChange}
                                        >
                                            <option value={""}>......</option>
                                            {this.state.teams.map((team, index) => {
                                                return <option key={index} value={team.id}>{applicationContext.translator(team.name)}</option>
                                            })}

                                        </Form.Control>
                                    </Col>
                                </Form.Group>}
                                {!reply && touched.team && <Form.Group as={Row} className={"mb-3"}>
                                    <Col md={4}></Col>
                                    <Col className={applicationContext.translator("direction") === "rtl"?"text-right":"text-left"}>
                                        <Form.Text className="text-danger mt-2">
                                            {errors.team}
                                        </Form.Text>
                                    </Col>
                                </Form.Group>}

                                <Form.Group as={Row} className={!touched.message && "mb-3"}>
                                    <Col md={4} className={"end-center-value start-align-items"}>
                                        <Form.Label >{applicationContext.translator("Message")}</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <Form.Control as="textarea"
                                                      rows={15}
                                                      name={"message"}
                                                      onChange={handleChange}
                                                      value={values.message}
                                                      isValid={touched.message && !errors.message}
                                                      isInvalid={touched.message && errors.message}
                                                      autoComplete="off"
                                        />
                                    </Col>
                                </Form.Group>
                                {touched.message && <Form.Group as={Row} className={"mb-3"}>
                                    <Col md={4}></Col>
                                    <Col className={applicationContext.translator("direction") === "rtl"?"text-right":"text-left"}>
                                        <Form.Text className="text-danger mt-2">
                                            {errors.message}
                                        </Form.Text>
                                    </Col>
                                </Form.Group>}
                                <Form.Group as={Row} className={!touched.message && "mb-3"}>
                                    <Col md={4} className={"end-center-value start-align-items"}>
                                        <Form.Label >{applicationContext.translator("Attachments")}</Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <Attachments callback={this.onCallbackAttachment} ref={(ref) => this.refUploader = ref} />
                                    </Col>
                                </Form.Group>



                                <Form.Group className={"d-flex justify-content-center"}>
                                    <Col md={4} className={"end-center-value"}>
                                        <Form.Label></Form.Label>
                                    </Col>
                                    <Col className={"start-center-value"}>
                                        <button className={"btn btn-account full-width"} disabled={this.state.submitting} type={"submit"}>{(this.state.submitting)?applicationContext.translator("Submitting..."):applicationContext.translator("Submit Ticket")}</button>
                                    </Col>

                                </Form.Group>
                                {this.state.successfully &&
                                <React.Fragment>
                                    <Form.Group as={Row} className={"text-center mt-3"}>
                                        <Col md={4} className={"end-center-value"}>
                                            <Form.Label></Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Text className="text-success mt-2">
                                                {reply?applicationContext.translator("Success! Your reply has been submitted."):applicationContext.translator("Success! Your request has been submitted.")}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>

                                </React.Fragment>}
                            </Col>
                        </Form.Group>

                    </Form>
                )}
            </Formik>

        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(withProfileContext(SubmitTicket)))));