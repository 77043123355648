import React, {Component} from 'react';
import styles from "../Login/Login.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withGameTableContext} from "../../../contexts/GameTableContext";
import * as Yup from "yup";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {NavLink, withRouter} from "react-router-dom"
import Footer from "../../../components/ui/Footer";
import MaxFooter from "../Login/MaxFooter";
import AuthService from "../../../services/auth.service";
import {parseErrorMessage} from "../../../helpers/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {ROUTE_LOGIN} from "../../../routers/routes";
class ForgetPassword extends Component {
    refForm = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            success: null,
            update_url: null,
            showPassword: false
        }
    }
    componentDidMount() {
        let params = new URLSearchParams(this.props.history.location.search);
        if(params.has('u')){
            this.setState({ loading: false, update_url: params.get('u') })
        }else{
            this.setState({ loading: false })
        }
    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, alert, history} = this.props;
        if(this.state.loading || this.state.submitting) return;
        this.setState({submitting: true, success: null, error: null});
        try {
            if(this.state.update_url){
                let u = (process.env.NODE_ENV === "development")?atob(this.state.update_url):atob(this.state.update_url).replaceAll("http:", "https:");
                const result  = await AuthService.doUpdatePassword(u, values.password, values.password_confirmation);
                this.setState({
                    success: true
                })
            }else{
                const result  = await AuthService.doChangePassword(values.email);
                this.setState({
                    success: applicationContext.translator(result.message),
                    email: ""
                })
            }

        } catch (err) {
            console.log(parseErrorMessage(err))
            this.setState({
                error: applicationContext.translator(parseErrorMessage(err)),
                email: ""
            })
        }
        this.setState({submitting: false})
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <div className={styles.FullHeight}>
                <div className={styles.WebsiteLoginContainer}>
                    {this.state.loading?
                        <div className={styles.LoadingContainer}>
                            {process.env.REACT_APP_NAME === "swissco.gaming" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} width={150}/>}
                            {process.env.REACT_APP_NAME === "my.roulette" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo-my.roulette.png`} />}
                        </div>:
                        <div className={styles.LoginWrapper}>
                            <div className={"text-center"}>
                                {process.env.REACT_APP_NAME === "swissco.gaming" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} width={150}/>}
                                {process.env.REACT_APP_NAME === "my.roulette" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo-my.roulette.png`} />}
                            </div>
                            {!this.state.update_url?
                            (<div className={styles.LoginBody}>
                                <h5>{applicationContext.translator("Lost your password? Please enter your email address. You will receive a link to create new password via email.")}</h5>
                                <Formik
                                    innerRef={(ref) => this.refForm = ref}
                                    initialValues={{
                                        email: this.state.email
                                    }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string().required(applicationContext.translator("You must enter your ${path}")).email(applicationContext.translator("E-Mail Address format is invalid")).label(applicationContext.translator("E-Mail Address")),
                                    })}
                                    onSubmit={this.handleSubmit}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleSubmit,
                                          isSubmitting,
                                          setFieldValue
                                      }) => (
                                        <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                            <Form.Group as={Row} className={!touched.email && "mb-3"}>
                                                <Col className={styles.StartCenterValue}>
                                                    <Form.Control type="text"
                                                                  name={"email"}
                                                                  onChange={handleChange}
                                                                  placeholder={applicationContext.translator("E-Mail Address")}
                                                                  value={values.username}
                                                                  isValid={touched.email && !errors.email}
                                                                  isInvalid={touched.email && errors.email}
                                                                  disabled={this.state.submitting}
                                                                  onFocus={(e) => {
                                                                      e.target.select()
                                                                  }}
                                                                  autoComplete="off"
                                                                  ref={(ref) => this.refInput = ref}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            {touched.email && <Form.Group as={Row} className={"mb-3"}>
                                                <Col>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.email}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>
                                            }

                                            <Form.Group className={"text-center"}>
                                                <button disabled={this.state.submitting} className={"btn btn-account"} type={"submit"}>{this.state.submitting?<Spinner animation="border" variant="light" size={"sm"} />:applicationContext.translator("Reset Password")}</button>
                                            </Form.Group>

                                            <Form.Group className={"text-center mt-3"}>
                                                {this.state.error && <Form.Text className="text-danger">
                                                    {this.state.error}
                                                </Form.Text>}
                                                {this.state.success && <Form.Text className="text-success">
                                                    {this.state.success}
                                                </Form.Text>}
                                            </Form.Group>

                                        </Form>
                                    )}
                                </Formik>
                            </div>):(
                                <div className={styles.LoginBody}>
                                    {this.state.success?(
                                        <div className={" mt-3 mb-3 text-center"}>
                                            <FontAwesomeIcon icon={"check-circle"} className={classNames("text-success", styles.SuccessLogo)}/><br/>
                                            <h5 className={"m-0 text-success"}>{applicationContext.translator("Password changed successfully.")}</h5>
                                            <small className={"text-muted"}>{applicationContext.translator("Please login to your account again.")}</small>
                                            <Form.Group className={"text-center mt-3"}>
                                                <NavLink to={ROUTE_LOGIN} className={"btn btn-account text-white full-width"}>{applicationContext.translator("Login now")}</NavLink>
                                            </Form.Group>
                                        </div>
                                    ):(
                                        <React.Fragment>
                                            <h5>{applicationContext.translator("You can reset your password using this form.")}</h5>
                                            <Formik
                                                innerRef={(ref) => this.refForm = ref}
                                                initialValues={{
                                                    password: this.state.password,
                                                    password_confirmation: this.state.password_confirmation,
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    password: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Password")).min(8, applicationContext.translator("Password must be minimum 8 characters")),
                                                    password_confirmation: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Repeat Password")).oneOf([Yup.ref('password'), null], applicationContext.translator('Passwords must match')),
                                                })}
                                                onSubmit={this.handleSubmit}
                                            >
                                                {({
                                                      values,
                                                      errors,
                                                      touched,
                                                      handleChange,
                                                      handleSubmit,
                                                      isSubmitting,
                                                      setFieldValue
                                                  }) => (
                                                    <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                                        <Form.Group as={Row} className={!touched.password && "mb-3"}>
                                                            <Col className={styles.StartCenterValue}>
                                                                <Form.Control type={this.state.showPassword?"text":"password"}
                                                                              name={"password"}
                                                                              onChange={handleChange}
                                                                              placeholder={applicationContext.translator("Password")}
                                                                              value={values.password}
                                                                              isValid={touched.password && !errors.password}
                                                                              isInvalid={touched.password && errors.password}
                                                                              onFocus={(e) => {
                                                                                  e.target.select()
                                                                              }}
                                                                              autoComplete="off"
                                                                              style={process.env.REACT_APP_NAME !== "swissco.gaming"?{ direction: "ltr", textAlign: "center" }:null}
                                                                />
                                                                <div className={styles.EyePassword} onClick={(e) => this.setState({ showPassword: !this.state.showPassword })}>
                                                                    <FontAwesomeIcon icon={this.state.showPassword?"eye-slash":"eye"} />
                                                                </div>
                                                            </Col>

                                                        </Form.Group>
                                                        {touched.password && <Form.Group as={Row} className={"mb-3"}>
                                                            <Col>
                                                                <Form.Text className="text-danger mt-2">
                                                                    {errors.password}
                                                                </Form.Text>
                                                            </Col>
                                                        </Form.Group>}
                                                        <Form.Group as={Row} className={!touched.password_confirmation && "mb-3"}>
                                                            <Col className={styles.StartCenterValue}>
                                                                <Form.Control type={this.state.showPassword?"text":"password"}
                                                                              name={"password_confirmation"}
                                                                              onChange={handleChange}
                                                                              placeholder={applicationContext.translator("Repeat Password")}
                                                                              value={values.password_confirmation}
                                                                              isValid={touched.password_confirmation && !errors.password_confirmation}
                                                                              isInvalid={touched.password_confirmation && errors.password_confirmation}
                                                                              onFocus={(e) => {
                                                                                  e.target.select()
                                                                              }}
                                                                              autoComplete="off"
                                                                              style={process.env.REACT_APP_NAME !== "swissco.gaming"?{ direction: "ltr", textAlign: "center" }:null}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        {touched.password_confirmation && <Form.Group as={Row} className={"mb-3"}>
                                                            <Col>
                                                                <Form.Text className="text-danger mt-2">
                                                                    {errors.password_confirmation}
                                                                </Form.Text>
                                                            </Col>
                                                        </Form.Group>}

                                                        <Form.Group as={Row}>
                                                            <Col>
                                                                <p style={{ color: '#818181', fontSize: "11px", textAlign: "center" }}>{applicationContext.translator("Your password must be minimum 8 characters and contains lowercase and uppercase characters, numbers and symbols (Ex. R123r@ap#)")}</p>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group className={"text-center"}>
                                                            <button disabled={this.state.submitting} className={"btn btn-account"} type={"submit"}>{this.state.submitting?<Spinner animation="border" variant="light" size={"sm"} />:applicationContext.translator("Reset Password")}</button>
                                                        </Form.Group>

                                                        <Form.Group className={"text-center mt-3"}>
                                                            {this.state.error && <Form.Text className="text-danger">
                                                                {this.state.error}
                                                            </Form.Text>}
                                                            {this.state.success && <Form.Text className="text-success">
                                                                {this.state.success}
                                                            </Form.Text>}
                                                        </Form.Group>

                                                    </Form>
                                                )}
                                            </Formik>
                                        </React.Fragment>
                                    )}
                                </div>
                            )}

                        </div>}

                </div>
                {process.env.REACT_APP_NAME !== "swissco.gaming"?<Footer />:<MaxFooter/>}
            </div>
        )
    }
}

export default withRouter(withApplicationContext(withGameTableContext(ForgetPassword)));