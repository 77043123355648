const getAccessToken = () => {
    return localStorage.getItem("admin_access_token")?JSON.parse(localStorage.getItem("admin_access_token")):false;
};

const setAccessToken = (token) => {
    localStorage.setItem("admin_access_token", JSON.stringify(token));
};

const logout = () => {
    localStorage.removeItem("admin_access_token");
};

const AdminTokenService = {
    setAccessToken,
    getAccessToken,
    logout
};

export default AdminTokenService;