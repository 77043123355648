import React, {Component} from 'react';
import styles from "./PanelContainer.module.css";
import {NavLink, withRouter} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {
    ROUTE_ADMIN_DASHBOARD,
    ROUTE_ADMIN_LOGIN,
    ROUTE_ADMIN_CEG_SEARCH_PLAYER_ACCOUNT,
    ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS_SELECTED, ROUTE_ADMIN_USERS_LIST
} from "../../../routers/routes";
import {
    accountCodeParse,
    back,
    numberWithCommas,
    pathHasPermission, queryStringSerialize,
    returnToAdmin, rolesCheck,
    url
} from "../../../helpers/helpers";
import LoadingContainer from "../../../components/common/LoadingContainer";
import UserService from "../../../services/user.service";
import {Form} from "react-bootstrap";
import classNames from "classnames";
import Popup from "../../../components/ui/Popup";
import AdminService from "../../../services/admin.service";
import {
    ADMIN_MENU_ITEMS,
    ADMIN_USER_PROFILE_MENU_ITEMS, ROLE_ADMIN, ROLE_AGENT,
    ROLE_CEG_ADMIN,
    ROLE_SUPER_ADMIN
} from "../../../helpers/constants";
import AdminTokenService from "../../../services/admin.token.service";
import MenuLabel from "../../../components/ui/MenuLabel";
import {withAlert} from "react-alert";

class PanelContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingProfile: true,
            memberReportLoading: true,
            memberReport: null,
            menu: [],
            userInfo: null,
            ranking: null,
            blacklisted: false,
            openSidebar: false,
            logoutConfirm: false
        }

    }
    componentDidMount = async() => {
        const {accountContext, match} = this.props;
        this.onClickCloseSidebar();
        if(match.params.hasOwnProperty('profileId')){
            accountContext.doStartLoading();
            const {profileId} = match.params;
            try{
                const userInfo = await AdminService.getUsersList({
                    account_code: profileId
                });
                const overview = await AdminService.getOverview({
                    id: userInfo.id
                });
                userInfo.overview = overview;
                const ranking = await AdminService.getUserRanking(userInfo.id);
                this.setState({
                    loadingProfile: false,
                    blacklisted: (userInfo['blacklisted_at'])?"yes":"no",
                    userInfo,
                    ranking,
                    menu: ADMIN_USER_PROFILE_MENU_ITEMS
                }, () => {
                    accountContext.doUpdateProfileInfo(userInfo);
                });

            }catch (e){

            }
        }else{
            this.setState({
                menu: ADMIN_MENU_ITEMS
            })
            try{
                const reports = await AdminService.getMembersStats();
                this.setState({
                    loadingProfile: false,
                    memberReport: reports,
                    memberReportLoading: false
                }, () => {
                    accountContext.doUpdateProfileInfo(null);
                });
            }catch (e){

            }
        }

    }
    onChangeUserStatus = async (e) => {
        const {accountContext, alert} = this.props;
        try{
            this.setState({
                blacklisted: e.target.value
            }, async () => {
                if(e.target.value === "yes"){
                    let response = await AdminService.doDisableUser(accountContext.profileInfo.id);
                    alert.success("User has been added to blacklist successfully.");
                }else{
                    let response = await AdminService.doActivateUser(accountContext.profileInfo.id);
                    alert.success("User has been removed from blacklist successfully.");
                }
            })
        }catch (e){

        }
    }

    onClickCloseSidebar = (e) => {
        this.setState({
            openSidebar: false
        }, () => document.getElementById('root').style['overflow-y'] = "scroll")
    }
    onClickOpenSidebar = (e) => {
        this.setState({
            openSidebar: true
        }, () => document.getElementById('root').style['overflow-y'] = "hidden")
    }
    onLogout = (e) => {
        e.preventDefault();
        this.setState({
            logoutConfirm: true
        })

    }
    handleLogout = () => {
        AdminTokenService.logout();
        window.location.href = url(ROUTE_ADMIN_LOGIN);
    }
    handleClick = (e) => {
        if(e.target.hash && e.target.hash !== ""){
            e.preventDefault();
            window.location.reload();
        }
    }
    renderLogo = () => {
        if(process.env.REACT_APP_NAME !== "swissco.gaming")
            return <img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={150} />;

        return <img src={`${process.env.PUBLIC_URL}/assets/img/logo-light.png`} width={150}/>;
    }
    returnToAdmin = (url) => {
        const {history} = this.props;
        if(localStorage.getItem('back_admin')){
            let back_url = JSON.parse(localStorage.getItem('back_admin'));
            localStorage.removeItem('back_admin');
            let u = back_url.url;
            if(back_url.state)
                localStorage.setItem('backed_from_account_state', JSON.stringify(back_url.state))
            if(back_url.tableState)
                localStorage.setItem('backed_from_account_table_state', JSON.stringify(back_url.tableState))
            window.location.href = u;
            return;
        }
        window.location.href = url;
    }
    renderMenuLabels = (menu) => {
        if(menu.count_holder === "withdrawals"){
            return <MenuLabel label={menu.label} count={numberWithCommas(this.state.memberReport['waiting_for_approvals_withdrawals'])}/>
        }
        if(menu.count_holder === "deposits"){
            return <MenuLabel label={menu.label} count={numberWithCommas(this.state.memberReport['waiting_for_approvals_deposits'])}/>
        }
        if(menu.count_holder === "tickets"){
            return <MenuLabel label={menu.label} count={numberWithCommas(this.state.memberReport['waiting_for_answers_tickets'])}/>
        }
        return <MenuLabel label={menu.label} />
    }
    onShowOnlineUsers = (e) => {
        e.preventDefault();
        const {history} = this.props;
        let filter = ROUTE_ADMIN_USERS_LIST+"?search="+encodeURIComponent(JSON.stringify({online: 1}));
        if(history.location.pathname + history.location.search === filter)
            window.location.reload()
        history.push(filter);


    }
    render() {
        const {accountContext, children, history, path} = this.props;
        return (
            (accountContext.loading || this.state.loadingProfile || accountContext.loadingProfile)?(
                <div className={styles.LoadingContainer}>{this.renderLogo()}</div>
            ):(
                <div className={styles.Container}>
                    {this.state.openSidebar && <div className={styles.OverlayLock} onClick={this.onClickCloseSidebar}></div>}
                    <div className={classNames(styles.AdminSidebar, this.state.openSidebar && styles.OpenedSidebarMenu)}>
                        <div className={styles.SidebarWrapper}>
                            <br/>
                            <div className={"text-center"}>
                                {rolesCheck(accountContext.role, [ROLE_SUPER_ADMIN, ROLE_AGENT]) && <a href={process.env.REACT_APP_LIVE_URL}>{this.renderLogo()}</a>}
                            </div>
                            {this.state.userInfo?(
                                <ul className={classNames(styles.SidebarMenu)}>
                                    {rolesCheck(accountContext.role, [ROLE_CEG_ADMIN])  &&
                                    <React.Fragment>
                                        <li className={"mb-2"}>
                                            <span className={"font-size-2x white-text"}>CEG Panel</span>
                                        </li>
                                        <li className={"text-center"}>
                                            <img src={`${process.env.PUBLIC_URL}/assets/img/ceg-logo.png`} className={styles.SidebarLogo} />
                                        </li>
                                    </React.Fragment>
                                    }
                                    <li className={styles.LineAccountInfo}>
                                        <h5>PLAYER'S ACCOUNT INFO</h5>
                                    </li>
                                    <li className={classNames(styles.LineAccountInfo, process.env.REACT_APP_NAME !== "swissco.gaming" && "mb-5", "mt-1")}>
                                        <h5>Account ID: </h5><span>{accountCodeParse(this.state.userInfo['account_code'])}</span>
                                    </li>
                                    {process.env.REACT_APP_NAME === "swissco.gaming" && <li className={classNames(styles.LineAccountInfo, "mb-5", "mt-1")}>
                                        <span>{this.state.userInfo['full_name']}</span>
                                    </li>}
                                    {this.state.menu.map((menu, index) => {
                                        return(
                                            !menu.hidden && (menu.hasOwnProperty('roles') && rolesCheck(accountContext.role, menu.roles)) && <li key={index} className={menu.hasOwnProperty('bottom') && menu.bottom}>
                                                <NavLink to={url(menu.path.replaceAll(":profileId", this.state.userInfo['account_code']))}
                                                         className={classNames(accountContext.path === menu.path && styles.LinkActive)}>
                                                    {menu.label}
                                                </NavLink>
                                            </li>
                                        )
                                    })}
                                    {rolesCheck(accountContext.role, [ROLE_SUPER_ADMIN])  && <React.Fragment>
                                        <li className={classNames("mt-3", styles.LineMenuItem)}>
                                            <h5>BLACKLISTED: </h5>
                                            <div className={styles.MenuRadioItem}>
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="group1"
                                                    value={"no"}
                                                    checked={this.state.blacklisted === "no"}
                                                    onChange={this.onChangeUserStatus}
                                                    type={"radio"}
                                                    id={`inline-radio-1`}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="group1"
                                                    value={"yes"}
                                                    checked={this.state.blacklisted === "yes"}
                                                    onChange={this.onChangeUserStatus}
                                                    type={"radio"}
                                                    id={`inline-radio-2`}
                                                />
                                            </div>
                                        </li>

                                        <li className={classNames("mt-5", styles.LineMenuItem)}>
                                            <h5>RANKING ON CASH-IN: </h5>
                                            <span className={"yellow-text"}>{this.state.ranking['cash_in']}</span>
                                        </li>
                                        <li className={classNames(styles.LineMenuItem)}>
                                            <h5>RANKING ON CASH-OUT: </h5>
                                            <span className={"yellow-text"}>{this.state.ranking['cash_out']}</span>
                                        </li>
                                        <li className={classNames(styles.LineMenuItem)}>
                                            <h5>RANKING ON WINNING: </h5>
                                            <span className={"yellow-text"}>{this.state.ranking['winning']}</span>
                                        </li>
                                        <li className={classNames(styles.LineMenuItem)}>
                                            <h5>RANKING ON LOOSING: </h5>
                                            <span className={"yellow-text"}>{this.state.ranking['losing']}</span>
                                        </li>
                                    </React.Fragment>}

                                    <li className={classNames("mt-5 mb-5", styles.LineMenuItem)}>

                                        {rolesCheck(accountContext.role, [ROLE_CEG_ADMIN]) ?(
                                                <a href={"javascript:void(0)"} onClick={(e) => this.returnToAdmin(ROUTE_ADMIN_CEG_SEARCH_PLAYER_ACCOUNT)}><FontAwesomeIcon icon={"arrow-left"} className={"mr-1"}/> RETURN TO CEG PANEL</a>
                                        ):(
                                            <a href={"javascript:void(0)"} onClick={(e) => this.returnToAdmin(ROUTE_ADMIN_DASHBOARD)}><FontAwesomeIcon icon={"arrow-left"} className={"mr-1"}/> RETURN TO ADMINISTRATOR PANEL</a>
                                        )}
                                    </li>
                                </ul>
                            ):(
                                <React.Fragment>
                                    <ul className={styles.SidebarMenu}>
                                        {rolesCheck(accountContext.role, [ROLE_SUPER_ADMIN])  && <li className={"mb-2"}>
                                            <span style={{color: '#4caf50'}}>KIA - MAIN ADMIN</span>
                                        </li>}
                                        {rolesCheck(accountContext.role, [ROLE_AGENT]) && <li className={"mb-2"}>
                                            <span style={{color: '#4caf50'}}>{accountContext.accountInfo.full_name} - AGENT</span>
                                        </li>}

                                        {rolesCheck(accountContext.role, [ROLE_CEG_ADMIN])  &&
                                        <React.Fragment>
                                            <li className={"mb-2"}>
                                                <span className={"font-size-2x white-text"}>CEG Panel</span>
                                            </li>
                                            <li className={"text-center"}>
                                                <img src={`${process.env.PUBLIC_URL}/assets/img/ceg-logo.png`} className={styles.SidebarLogo} />
                                            </li>
                                        </React.Fragment>
                                        }
                                        {!this.state.memberReportLoading && this.state.menu.map((menu, index) => {
                                            return(
                                                !menu.hidden && (menu.hasOwnProperty('roles') && rolesCheck(accountContext.role, menu.roles)) &&
                                                <li key={index} className={(menu.hasOwnProperty('bottom') && rolesCheck(accountContext.role, [ROLE_SUPER_ADMIN])) && menu.bottom}>
                                                    <NavLink to={!menu.logout?(accountContext.path === menu.path?"#reload":url(menu.path)):"/"}
                                                             onClick={menu.logout?this.onLogout:this.handleClick}
                                                             className={classNames(history.location.pathname.includes(menu.path) && styles.LinkActive)}>
                                                        {this.renderMenuLabels(menu)}
                                                    </NavLink>
                                                </li>
                                            )
                                        })}
                                        <React.Fragment>
                                            <li className={classNames(styles.LineMenuItem)}>
                                                <h5>Total Registered Members: </h5>
                                                <span className={"yellow-text"}>{this.state.memberReportLoading?null:numberWithCommas(this.state.memberReport['total_members'])}</span>
                                            </li>
                                            <li className={classNames(styles.LineMenuItem)}>
                                                <h5>Total Players On Live table: </h5>
                                                <span className={"text-danger"}>{this.state.memberReportLoading?null:numberWithCommas(this.state.memberReport['online_members'])}</span>
                                            </li>
                                            <li>
                                                <NavLink onClick={this.onShowOnlineUsers} to={"#"} className={styles.OnlineUsersLink}>Click to show online users</NavLink>
                                            </li>
                                        </React.Fragment>

                                    </ul>
                                </React.Fragment>
                            )}

                        </div>
                    </div>
                    <div className={styles.MainContainer}>
                        {/*<div className={styles.MapDashboard}>*/}
                        {/*    {!this.state.openSidebar && <button onClick={this.onClickOpenSidebar} className={styles.MobileNavButton}><FontAwesomeIcon icon={"bars"} /></button>}*/}
                        {/*    {this.state.openSidebar && <button onClick={this.onClickCloseSidebar} className={styles.MobileNavButton}><FontAwesomeIcon icon={"times"} /></button>}*/}
                        {/*    {getBackButton(accountContext.path, this.state.menu)?<NavLink to={getBackButton(accountContext.path, this.state.menu)} className={styles.BackNavButton}><FontAwesomeIcon icon={"chevron-left"} /> Back</NavLink>:null}*/}
                        {/*    {pathHasPermission(accountContext.path, accountContext.role, this.state.menu)?this.renderPageTitle():"Permission Denied"}*/}
                        {/*</div>*/}
                        <div className={styles.AdminMainWrapper}>
                            <LoadingContainer loading={accountContext.loading}>
                                {pathHasPermission(accountContext.path, accountContext.role, this.state.menu)?children:<p className={"text-center"}>You don't have permission for access to this section</p>}
                            </LoadingContainer>
                        </div>

                    </div>
                    <Popup
                        show={this.state.logoutConfirm}
                        onClose={() => this.setState({ logoutConfirm: false })}
                        onSubmit={this.handleLogout}
                        SubmitCaption={"Yes, I'm sure"}
                        SubmitButtonClass={"btn-success"}
                        CancelCaption={"No"}
                        SubmittingLoading={this.state.submitting}
                        title={"Logout"}
                        large={false}
                        description={"Are you sure to logout your account?"}
                    >

                    </Popup>
                </div>
            )

        )
    }
}

export default withAlert()(withRouter(withAdminAccountContext(PanelContainer)));