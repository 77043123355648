import React, {Component} from 'react';
import { StrictMode } from 'react';
import {withGameTableContext} from "../../contexts/GameTableContext";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import classNames from "classnames";
import styles from "./GameTable.module.css";
import VideoPlayer from "../../components/ui/VideoPlayer";

class MobileVideoStream extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            opened: false
        }

    }


    render() {
        const {gameTableContext} = this.props;
        return (
            <StrictMode>
                {gameTableContext.state.windows.video && <div className={styles.OverlayWindow} onClick={(e) => gameTableContext.handleCloseWindow("video")}></div>}
                <div className={classNames(styles.VideoStreamWrapper, gameTableContext.state.windows.video && styles.OpenVideoStreamWrapper)}>
                    <VideoPlayer mobile={true} />
                </div>
            </StrictMode>
        );
    }
}

export default withApplicationContext(withGameTableContext(MobileVideoStream));