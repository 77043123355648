import api from "./admin.api";
import {queryStringSerialize} from "../helpers/helpers";

const getAdminBoard = () => {
    return api.get("/api/account/profile").then((response) => {
        return response.data;
    });
};
const getInfoByToken = (token) => {
    let config = {
        headers: {
            Authorization: 'Bearer ' + token
        }
    };
    return api.get("/api/account/profile", config).then((response) => {
        return response.data;
    });
};
const login = (username, password) => {
    let config = {
        auth: {
            username: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_ID,
            password: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_SECRET
        }
    };
    return api
        .post("/api/auth/admin/login", {
            email: username,
            password
        }, config)
        .then((response) => {
            return response.data;
        });
};
const getMembersStats = () => {
    return api
        .get("/api/account/reports/members")
        .then((response) => {
            return response.data;
        });
};
const getOverview = (data = null) => {
    return api
        .get("/api/account/reports/complete-overview?" + queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const getDepositList = (data) => {
    return api
        .get("/api/account/deposit/list?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const doUpdateDeposit = (data) => {
    return api
        .post("/api/account/deposit/update", data)
        .then((response) => {
            return response.data;
        });
};

const doTransfer = (data) => {
    return api
        .post("/api/account/user/transfer", data)
        .then((response) => {
            return response.data;
        });
};
const getWithdrawalList = (data) => {
    return api
        .get("/api/account/withdrawal/list?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const doUpdateWithdrawal = (data) => {
    return api
        .post("/api/account/withdrawal/update", data)
        .then((response) => {
            return response.data;
        });
};

const getUsersList = (data) => {
    return api
        .get("/api/account/user/list?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const doActivateUser = (id) => {
    return api
        .get("/api/account/user/activate?id="+ id)
        .then((response) => {
            return response.data;
        });
};
const doDisableUser = (id) => {
    return api
        .get("/api/account/user/disable?id="+ id)
        .then((response) => {
            return response.data;
        });
};

const getRanking = (data) => {
    return api
        .get("/api/account/reports/ranking?" + queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const getMonthlyJournal = (data) => {
    return api
        .get("/api/account/reports/monthly?" + queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const getYearlyJournal = (data) => {
    return api
        .get("/api/account/reports/yearly?" + queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const getUserRanking = (id) => {
    return api
        .get("/api/account/reports/user-ranking?user_id=" + id)
        .then((response) => {
            return response.data;
        });
};

const getRoundAndWinningHistory = (data) => {
    return api
        .get("/api/account/reports/round-winning-history?" + queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const getRoundPlayedHistory = (data) => {
    return api
        .get("/api/account/reports/round-played-history?" + queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const getRoundDetails = (id) => {
    return api
        .get("/api/account/reports/round/" + id)
        .then((response) => {
            return response.data;
        });
};

const getPlayedHistory = (data) => {
    return api
        .get("/api/account/user/played_history?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const getLogonHistory = (data) => {
    return api
        .get("/api/account/user/logon_history?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const getMonthlyYearlyDepositsReports = (data) => {
    return api
        .get("/api/account/reports/deposits/md?" + queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const getMonthlyYearlyWithdrawalsReports = (data) => {
    return api
        .get("/api/account/reports/withdrawals/md?" + queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const getYearlyMonthlyOverviewReports = (data) => {
    return api
        .get("/api/account/reports/overview/total?" + queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const doCheckMasterPassword = (data) => {
    return api
        .get("/api/account/user/check_master_password?" + queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const doUpdateWheelSettings = (data) => {
    return api
        .post("/api/account/user/wheel/config/update", data)
        .then((response) => {
            return response.data;
        });
};
const getTop37Payout = (data) => {
    return api
        .get("/api/account/reports/top37" + queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const doSendBroadcastEmail = (data) => {
    return api
        .post("/api/account/email/send", data)
        .then((response) => {
            return response.data;
        });
};
const getTicketsList = (data) => {
    return api
        .get("/api/account/tickets/list?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const showTicket = (id) => {
    return api
        .get("/api/account/tickets/show/"+ id)
        .then((response) => {
            return response.data;
        });
};

const resolveTicket = (id) => {
    return api
        .get("/api/account/tickets/resolved/"+ id)
        .then((response) => {
            return response.data;
        });
};
const doCreateTicket = (data, files) => {
    let formData = new FormData();
    Object.keys(data).map(( key) => {
        formData.append(key, data[key]);
    })
    files.map(( file) => {
        formData.append("attachments[]", file);
    })
    return api
        .post("/api/account/tickets/create", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            return response.data;
        });
};
const AdminService = {
    getInfoByToken,
    getAdminBoard,
    getMembersStats,
    getOverview,
    getDepositList,
    doTransfer,
    doUpdateDeposit,
    getWithdrawalList,
    doUpdateWithdrawal,
    getUsersList,
    getLogonHistory,
    doActivateUser,
    doDisableUser,
    getRanking,
    getMonthlyJournal,
    getYearlyJournal,
    getUserRanking,
    getRoundAndWinningHistory,
    getRoundPlayedHistory,
    getRoundDetails,
    getPlayedHistory,
    getMonthlyYearlyDepositsReports,
    getMonthlyYearlyWithdrawalsReports,
    getYearlyMonthlyOverviewReports,
    doCheckMasterPassword,
    doUpdateWheelSettings,
    getTop37Payout,
    doSendBroadcastEmail,
    getTicketsList,
    showTicket,
    resolveTicket,
    doCreateTicket,
    login
};

export default AdminService;