import React, {Component} from 'react';
import styles from "./Login.module.css";
import baseStyles from "../../../assets/css/App.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withGameTableContext} from "../../../contexts/GameTableContext";
import * as Yup from "yup";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {NavLink, withRouter} from "react-router-dom"
import {
    ROUTE_ADMIN_DASHBOARD,
    ROUTE_FORGET_PASSWORD, ROUTE_LOGIN,
    ROUTE_REGISTER, ROUTE_TABLE_LIVE_PLAY,
    ROUTE_USER_PROFILE
} from "../../../routers/routes";
import classNames from "classnames";
import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import {parseErrorMessage} from "../../../helpers/helpers";
import {withAlert} from "react-alert";
import TokenService from "../../../services/token.service";
import Footer from "../../../components/ui/Footer";
import MaxFooter from "../Login/MaxFooter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CommonService from "../../../services/common.service";
class Register extends Component {
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            successfully: false,
            close: false,
            loading: false,
            submitting: false,
            showPassword: false,
            // birthdate_month: new Date().getMonth() + 1,
            // birthdate_day: new Date().getDay(),
            redirect: (urlSearchParams.has('r'))?decodeURIComponent(urlSearchParams.get('r')):((props.level == "user")?ROUTE_USER_PROFILE:ROUTE_ADMIN_DASHBOARD)
        }

    }
    componentDidMount = async () =>{
        const {history, applicationContext} = this.props;
        if(localStorage.getItem("token")){
            try {
                const userInfo = await UserService.getUserBoard();
                if(userInfo){
                    applicationContext.updateUserInfo(userInfo);
                    history.push(ROUTE_TABLE_LIVE_PLAY)
                }
            } catch (err) {
                TokenService.removeUser();
            }
        }
    }

    handleSubmit = async (values, actions) => {

        const {applicationContext, alert, history, level} = this.props;
        if(this.state.submitting) return;
        this.setState({submitting: true});
        try {
            if(process.env.REACT_APP_NAME === "swissco.gaming") {
                let birthdate = values.birthdate_year+"-"+values.birthdate_month+"-"+values.birthdate_day;
                await AuthService.register(values.email, birthdate);
            }else
                await AuthService.registerWithPassword(values.email, values.password, values.password_confirmation);
            this.setState({
                successfully: true
            })

        } catch (e) {
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
            })
        }
        this.setState({submitting: false})
    }
    yearRange = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, i) => (new Date().getFullYear() - 90) + i)
    }
    dayRange = (month) => {
        let days = {
            1: 31,
            2: 29,
            3: 31,
            4: 30,
            5: 31,
            6: 30,
            7: 31,
            8: 31,
            9: 30,
            10: 31,
            11: 30,
            12: 31
        }
        let dayCount = days[(month?month:1)];

        return Array.from({ length: dayCount - 0  }, (_, i) => 1 + i)
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <div className={styles.FullHeight}>
                <div className={styles.WebsiteLoginContainer}>
                    {this.state.loading?
                        <div className={styles.LoadingContainer}>
                            {process.env.REACT_APP_NAME === "swissco.gaming" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} width={150}/>}
                            {process.env.REACT_APP_NAME === "my.roulette" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo-my.roulette.png`} />}
                        </div>:
                        <div className={styles.LoginWrapper}>
                            <div className={"text-center"}>
                                {process.env.REACT_APP_NAME === "swissco.gaming" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} width={150}/>}
                                {process.env.REACT_APP_NAME === "my.roulette" && <img src={`${process.env.PUBLIC_URL}/assets/img/logo-my.roulette.png`} />}
                            </div>
                            <div className={styles.LoginBody}>
                                {!this.state.successfully &&<h5>{applicationContext.translator("Enter your email and follow the instructions sent to you by email.")}</h5>}
                                <Formik
                                    innerRef={(ref) => this.refForm = ref}
                                    initialValues={{
                                        ...(process.env.REACT_APP_NAME === "swissco.gaming" && {
                                            birthdate_year: this.state.birthdate_year,
                                            birthdate_month: this.state.birthdate_month,
                                            birthdate_day: this.state.birthdate_day,
                                        }),
                                        email: this.state.email,
                                        ...(process.env.REACT_APP_NAME !== "swissco.gaming" && {password: this.state.password}),
                                        ...(process.env.REACT_APP_NAME !== "swissco.gaming" && {password_confirmation: this.state.password_confirmation})
                                    }}
                                    validationSchema={Yup.object().shape({
                                        ...(process.env.REACT_APP_NAME === "swissco.gaming" && {
                                            birthdate_year: Yup.string().required(applicationContext.translator("You must select your birth date year")).label("Year"),
                                            birthdate_month: Yup.string().required(applicationContext.translator("You must select your birth date month")).label("Month"),
                                            birthdate_day: Yup.string().required(applicationContext.translator("You must select your birth date day")).label("Day"),
                                        }),
                                        email: Yup.string().required(applicationContext.translator("You must enter your ${path}")).email(applicationContext.translator("E-Mail Address format is invalid")).label(applicationContext.translator("E-Mail Address")),
                                        ...(process.env.REACT_APP_NAME !== "swissco.gaming" && {password: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Password")).min(8, applicationContext.translator("Password must be minimum 8 characters"))}),
                                        ...(process.env.REACT_APP_NAME !== "swissco.gaming" && {password_confirmation: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Repeat Password")).oneOf([Yup.ref('password'), null], applicationContext.translator('Passwords must match'))})
                                    })}
                                    onSubmit={this.handleSubmit}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleSubmit,
                                          isSubmitting,
                                          setFieldValue
                                      }) => (
                                        <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                            {!this.state.successfully &&
                                            <React.Fragment>
                                                {process.env.REACT_APP_NAME === "swissco.gaming" && <Form.Group as={Row} className={!touched.birthdate_year && "mb-3"}>
                                                    <Col className={styles.StartCenterValue}>
                                                        <Form.Control
                                                            as={"select"}
                                                            name={"birthdate_year"}
                                                            onChange={handleChange}
                                                            value={values.birthdate_year}
                                                            isValid={touched.birthdate_year && !errors.birthdate_year}
                                                            isInvalid={touched.birthdate_year && errors.birthdate_year}
                                                        >
                                                            <option>{applicationContext.translator("Year of birthdate")}</option>
                                                            {this.yearRange(new Date().getFullYear() - 90, new Date().getFullYear() - 18).map((year, index) => {
                                                                return <option key={index} value={year} >{year}</option>
                                                            })}
                                                        </Form.Control>
                                                        <Form.Control
                                                            as={"select"}
                                                            name={"birthdate_month"}
                                                            onChange={handleChange}
                                                            value={values.birthdate_month}
                                                            isValid={touched.birthdate_month && !errors.birthdate_month}
                                                            isInvalid={touched.birthdate_month && errors.birthdate_month}
                                                            style={{ margin: '0px 5px' }}
                                                        >
                                                            <option>{applicationContext.translator("Month of birthdate")}</option>
                                                            <option value={1} >January</option>
                                                            <option value={2} >February</option>
                                                            <option value={3} >March</option>
                                                            <option value={4} >April</option>
                                                            <option value={5} >May</option>
                                                            <option value={6} >June</option>
                                                            <option value={7} >July</option>
                                                            <option value={8} >August</option>
                                                            <option value={9} >September</option>
                                                            <option value={10} >October</option>
                                                            <option value={11} >November</option>
                                                            <option value={12} >December</option>

                                                        </Form.Control>
                                                        <Form.Control
                                                            as={"select"}
                                                            name={"birthdate_day"}
                                                            onChange={handleChange}
                                                            value={values.birthdate_day}
                                                            isValid={touched.birthdate_day && !errors.birthdate_day}
                                                            isInvalid={touched.birthdate_day && errors.birthdate_day}
                                                        >
                                                            <option>{applicationContext.translator("Day of birthdate")}</option>
                                                            {this.dayRange(values.birthdate_month).map((day, index) => {
                                                                return <option key={index} value={day} >{day}</option>
                                                            })}
                                                        </Form.Control>
                                                    </Col>
                                                </Form.Group>}
                                                {process.env.REACT_APP_NAME === "swissco.gaming" && (touched.birthdate_year || touched.birthdate_month || touched.birthdate_day) && <Form.Group as={Row} className={"mb-3"}>
                                                    <Col>
                                                        <Form.Text className="text-danger mt-2">
                                                            {errors.birthdate_year?(errors.birthdate_year):(errors.birthdate_month?errors.birthdate_month:(errors.birthdate_day && errors.birthdate_day))}
                                                        </Form.Text>
                                                    </Col>
                                                </Form.Group>}

                                                <Form.Group as={Row} className={!touched.email && "mb-3"}>
                                                    <Col className={styles.StartCenterValue}>
                                                        <Form.Control type="text"
                                                                      name={"email"}
                                                                      onChange={handleChange}
                                                                      placeholder={applicationContext.translator("E-Mail Address")}
                                                                      value={values.username}
                                                                      isValid={touched.email && !errors.email}
                                                                      isInvalid={touched.email && errors.email}
                                                                      onFocus={(e) => {
                                                                          e.target.select()
                                                                      }}
                                                                      autoComplete="off"
                                                                      ref={(ref) => this.refInput = ref}
                                                                      style={process.env.REACT_APP_NAME !== "swissco.gaming"?{ direction: "ltr", textAlign: "center" }:null}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                {touched.email && <Form.Group as={Row} className={"mb-3"}>
                                                    <Col>
                                                        <Form.Text className="text-danger mt-2">
                                                            {errors.email}
                                                        </Form.Text>
                                                    </Col>
                                                </Form.Group>}
                                                {process.env.REACT_APP_NAME !== "swissco.gaming" && <Form.Group as={Row} className={!touched.password && "mb-3"}>
                                                    <Col className={styles.StartCenterValue}>
                                                        <Form.Control type={this.state.showPassword?"text":"password"}
                                                                      name={"password"}
                                                                      onChange={handleChange}
                                                                      placeholder={applicationContext.translator("Password")}
                                                                      value={values.password}
                                                                      isValid={touched.password && !errors.password}
                                                                      isInvalid={touched.password && errors.password}
                                                                      onFocus={(e) => {
                                                                          e.target.select()
                                                                      }}
                                                                      autoComplete="off"
                                                                      style={{ direction: "ltr", textAlign: "center" }}
                                                        />
                                                        <div className={styles.EyePassword} onClick={(e) => this.setState({ showPassword: !this.state.showPassword })}>
                                                            <FontAwesomeIcon icon={this.state.showPassword?"eye-slash":"eye"} />
                                                        </div>
                                                    </Col>
                                                </Form.Group>}
                                                {process.env.REACT_APP_NAME !== "swissco.gaming" && touched.password && <Form.Group as={Row} className={"mb-3"}>
                                                    <Col>
                                                        <Form.Text className="text-danger mt-2">
                                                            {errors.password}
                                                        </Form.Text>
                                                    </Col>
                                                </Form.Group>}
                                                {process.env.REACT_APP_NAME !== "swissco.gaming" && <Form.Group as={Row} className={!touched.password_confirmation && "mb-3"}>
                                                    <Col className={styles.StartCenterValue}>
                                                        <Form.Control type={this.state.showPassword?"text":"password"}
                                                                      name={"password_confirmation"}
                                                                      onChange={handleChange}
                                                                      placeholder={applicationContext.translator("Repeat Password")}
                                                                      value={values.password_confirmation}
                                                                      isValid={touched.password_confirmation && !errors.password_confirmation}
                                                                      isInvalid={touched.password_confirmation && errors.password_confirmation}
                                                                      onFocus={(e) => {
                                                                          e.target.select()
                                                                      }}
                                                                      autoComplete="off"
                                                                      style={{ direction: "ltr", textAlign: "center" }}
                                                        />
                                                    </Col>
                                                </Form.Group>}
                                                {process.env.REACT_APP_NAME !== "swissco.gaming" && touched.password_confirmation && <Form.Group as={Row} className={"mb-3"}>
                                                    <Col>
                                                        <Form.Text className="text-danger mt-2">
                                                            {errors.password_confirmation}
                                                        </Form.Text>
                                                    </Col>
                                                </Form.Group>}
                                            </React.Fragment>
                                            }


                                            {this.state.successfully &&
                                            <div className={"text-success mt-3 mb-3 text-center"}>
                                                <FontAwesomeIcon icon={"check-circle"} className={styles.SuccessLogo}/><br/>
                                                {applicationContext.translator("Your Email has been subscribed successfully.")}<br/>{applicationContext.translator("An email has been sent to your inbox")}
                                            </div>}

                                            <br/>
                                            {!this.state.successfully && <Form.Group as={Row} className={"mb-3"}>
                                                <Col className={"text-center"}>
                                                    <Form.Text className={styles.SubLoginText}>
                                                        {applicationContext.translator("Do you have an account?")} <NavLink to={ROUTE_LOGIN}>{applicationContext.translator("Click here to login")}</NavLink>
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}

                                            {!this.state.successfully && <Form.Group className={"text-center"}>
                                                <button disabled={this.state.submitting} className={"btn btn-account"} type={"submit"}>{this.state.submitting?<Spinner animation="border" variant="light" size={"sm"} />:applicationContext.translator("Register")}</button>
                                            </Form.Group>}
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                        </div>}

                </div>
                {process.env.REACT_APP_NAME !== "swissco.gaming"?<Footer />:<MaxFooter/>}
            </div>

        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withGameTableContext(Register))));