import React, {Component} from "react";
import styles from "./AccountTable.module.css";
import classNames from "classnames";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
class TableCol extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        const { applicationContext, children, yellow, mobile, classes } = this.props
        return (
            !applicationContext.state.isMobileVersion?(
                <td className={classNames(!this.props.colSpan && styles.TableCell, yellow && "yellow-text", mobile && styles.ShowMobileVersion, classes)} {...this.props}>
                    {children}
                </td>
            ):(
                <React.Fragment>
                    <div className={classNames(styles.TableCell, yellow && "yellow-text", classes)} {...this.props}>
                        {children}
                    </div>
                </React.Fragment>
            )


        );
    }
}
export default withApplicationContext(TableCol);