
export const ROUTE_API_LOGIN = `/api/login`;

export const ROUTE_UPDATE_PASSWORD = `/account/password/update`;
export const ROUTE_UPDATE_EMAIL = `/account/email/update`;
export const ROUTE_LOGIN = `/account/login`;
export const ROUTE_LOGOUT = `/account/logout`;
export const ROUTE_FORGET_PASSWORD = `/account/forget-password`;
export const ROUTE_RESET_PASSWORD = `/account/reset-password`;
export const ROUTE_REGISTER = `/account/register`;
export const ROUTE_REGISTER_COMPLETE = `/account/register/complete`;
export const ROUTE_LOGON_HISTORY = `/account/history/logon`;
export const ROUTE_CREATE_NEW_TICKET = `/account/ticketing/create`;
export const ROUTE_MY_TICKETS = `/account/ticketing/tickets`;
export const ROUTE_TICKET_SELECTED = `/account/ticketing/ticket/:ticketId`;
export const ROUTE_USER_PROFILE = `/account/profile`;
export const ROUTE_PROFILE_DEPOSIT = `/account/deposit`;
export const ROUTE_PROFILE_WITHDRAWAL = `/account/withdrawal`;
export const ROUTE_PROFILE_OVERVIEW = `/account/overview`;
export const ROUTE_PROFILE_DEPOSIT_HISTORY = `/account/history/deposit`;
export const ROUTE_PROFILE_WITHDRAWAL_HISTORY = `/account/history/withdrawal`;
export const ROUTE_PROFILE_PLAYED_HISTORY = `/account/history/played`;
export const ROUTE_HOME = '/';
export const ROUTE_TABLE_LIVE_PLAY = `/live/play`;
export const ROUTE_TABLE_PRACTICE_PLAY = `/practice`;
export const ROUTE_ACCOUNT_DETAILS_DELETE = `/account/details/delete`;
export const ROUTE_ACCOUNT_DETAILS_CLOSURE = `/account/details/closure`;
export const ROUTE_ACCOUNT_DETAILS_SELF_EXCLUSION = `/account/details/self-exclusion`;
export const ROUTE_ACCOUNT_DETAILS_LIMITS = `/account/details/limits`;

export const ROUTE_ADMIN_TOTAL_OVERVIEW = `/admin/account/overview/total`;
export const ROUTE_ADMIN_LOGIN = `/admin/account/login`;
export const ROUTE_ADMIN_PROFILE_DEPOSIT_HISTORY = `/admin/account/history/deposit`;
export const ROUTE_ADMIN_PROFILE_WITHDRAWAL_HISTORY = `/admin/account/history/withdrawal`;
export const ROUTE_ADMIN_DASHBOARD = `/admin/account/dashboard`;
export const ROUTE_ADMIN_SEARCH_PLAYER_ACCOUNT = `/admin/account/users/search`;
export const ROUTE_ADMIN_USERS_LIST = `/admin/account/users/list`;
export const ROUTE_ADMIN_USERS_SEARCH = `/admin/account/users/search`;
export const ROUTE_ADMIN_RANKING_WITHDRAWAL = `/admin/account/ranking/withdrawal`;
export const ROUTE_ADMIN_RANKING_DEPOSIT = `/admin/account/ranking/deposit`;
export const ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW = `/admin/account/profile/:profileId/overview`;
export const ROUTE_ADMIN_USER_ACCOUNT_CASH_IN = `/admin/account/profile/:profileId/cash-in`;
export const ROUTE_ADMIN_USER_ACCOUNT_CASH_OUT = `/admin/account/profile/:profileId/cash-out`;
export const ROUTE_ADMIN_USER_ACCOUNT_PLAYED = `/admin/account/profile/:profileId/played`;
export const ROUTE_ADMIN_USER_ACCOUNT_PROFILE = `/admin/account/profile/:profileId/info`;
export const ROUTE_ADMIN_USER_ACCOUNT_LOGON = `/admin/account/profile/:profileId/logon`;
export const ROUTE_ADMIN_ROUND_SELECTED_OVERVIEW = `/admin/account/round/overview/:roundNumber`;
export const ROUTE_ADMIN_ROUND_OVERVIEW = `/admin/account/round/overview`;
export const ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS_SELECTED = `/admin/account/reports/deposits/:date`;
export const ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS = `/admin/account/reports/deposits`;
export const ROUTE_ADMIN_MONTHLY_DAILY_WITHDRAWALS_SELECTED = `/admin/account/reports/withdrawals/:date`;
export const ROUTE_ADMIN_MONTHLY_DAILY_WITHDRAWALS = `/admin/account/reports/withdrawals`;
export const ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW = `/admin/account/reports/overview`;
export const ROUTE_ADMIN_YEARLY_MONTHLY_OVERVIEW_SELECTED = `/admin/account/reports/overview/:date`;
export const ROUTE_ADMIN_WAIT_FOR_APPROVAL_WITHDRAWALS = `/admin/account/withdrawals/wait`;
export const ROUTE_ADMIN_WAIT_FOR_APPROVAL_DEPOSITS = `/admin/account/deposits/wait`;
export const ROUTE_ADMIN_ACCOUNT_CHARGE_CREDIT = `/admin/account/charge/credit`;
export const ROUTE_ADMIN_WAIT_FOR_ANSWER_TICKETS = `/admin/account/tickets/wait`;
export const ROUTE_ADMIN_TICKET_SELECTED = `/admin/account/tickets/:ticketId`;
export const ROUTE_ADMIN_ENTER_MANUALLY_WINNING_NUMBER = `/admin/account/studio/settings`;
export const ROUTE_ADMIN_ROUND_AND_WINNING_NUMBERS = `/admin/account/round-numbers`;
export const ROUTE_ADMIN_TOP37_PAYOUTS = `/admin/account/top37`;
export const ROUTE_ADMIN_SEND_EMAIL = `/admin/account/send-email`;


export const ROUTE_ADMIN_CEG_SEARCH_PLAYER_ACCOUNT = `/admin/account/ceg/users/search`;
export const ROUTE_ADMIN_CEG_PLAYED_ROUND_WITH_TOTAL_BETS_AND_RESULT = `/admin/account/ceg/played-round-with-total-bets`;
export const ROUTE_ADMIN_CEG_ROUND_AND_WINNING_NUMBERS = `/admin/account/ceg/round-numbers`;
export const ROUTE_ADMIN_CEG_SELECTED_ROUND = `/admin/account/ceg/round/:roundNumber`;
