import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import classNames from "classnames";
import {
    convertArrayKeyToMap,
    convertArrayToMap,
    convertMapToObject,
    filterFormula,
    parseFavorites,
    sumChips
} from "../../../helpers/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Popup from "../../../components/ui/Popup";
import * as Yup from "yup";
import {Formik} from "formik";
import {Button, Col, Form, Row} from "react-bootstrap";
import UserService from "../../../services/user.service";
import {HOME_WEBSITE} from "../../../helpers/constants";
import moment from "moment";

class Tools extends Component {
    refForm = React.createRef();
    refInput = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            menuId: null,
            changeNamePopup: false,
            favorite: null,
            action: null,
            favoriteName: "",
            processing: false
        }
    }

    getGameNumber = (id) => {
        const {gameTableContext} = this.props;
        let games = convertArrayToMap(gameTableContext.state.favorites, "id");
        let formula = games[id];
        return {
            actions: formula.actions,
            numbers: formula.numbers,
            chips: convertArrayKeyToMap(formula.chip, null)
        };
    }
    onBetHover = (game) => {
        const {gameTableContext, } = this.props;
        if(gameTableContext.state.lockTable) return;
        let formula = this.getGameNumber(game);
        gameTableContext.onUpdateState({
            selectedHoverFormula: "favorite",
            selectedHoverActions: formula.actions,
            selectedHover: formula.numbers
        })
    }
    onBet = (game) => {
        const {gameTableContext} = this.props;
        if(gameTableContext.state.lockTable || this.state.menuOpen || this.state.processing) return;
        this.setState({
            processing: true
        }, () => {
            let formula = this.getGameNumber(game);
            if(formula.chips && formula.chips.size > 0){
                gameTableContext.refTable.onBetActionsNumbers(formula.actions, formula.chips, `favorite-${game}`)
            }else{
                gameTableContext.refTable.onBetNumbers(formula.actions, `favorite-${game}`);
            }
            setTimeout(() => {
                this.setState({
                    processing: false
                })
            }, 1300)
        })
        if(gameTableContext.state.windows.favorite)
            gameTableContext.handleCloseAllWindows();



    }
    onClearSelected = () => {
        const {gameTableContext, } = this.props;
        if(gameTableContext.state.lockTable) return;
        gameTableContext.onUpdateState({
            selectedHover: [],
            selectedHoverActions: null,
            selectedHoverFormula: null
        })
    }
    handleOnContextMenu = (e, menuId = null) => {
        e.preventDefault();
        this.setState({
            menuOpen: !this.state.menuOpen,
            menuId
        })
    }
    onClosePopup = (e) => {
        this.setState({
            changeNamePopup: false,
            favorite: null,
            action: null,
            menuOpen: false,
            menuId: null,
            favoriteName: ""
        })
    }
    onOpenPopup = (e, favorite, action) => {
        this.setState({
            changeNamePopup: true,
            favorite,
            action,
            menuOpen: false,
            menuId: null,
            favoriteName: favorite.name
        }, () => {
            this.refInput.select()
        })
    }
    handleSubmit = async (values, actions) => {
        const {gameTableContext, applicationContext} = this.props;
        let live = (!gameTableContext.state.practice);
        const {favorites, chips} = gameTableContext.state;
        let action = [];
        let numbers = [];
        for (let [key, value] of chips) {
            action.push(key);
            if(typeof key === "number"){
                numbers = numbers.concat([key]);
            }else{
                let formula = filterFormula(key);
                numbers = numbers.concat(formula.numbers);

            }

        }

        if(live || applicationContext.state.userInfo){
            favorites.map((f) => {
                if(f.id === this.state.favorite.id){
                    f.name = values.favoriteName;
                    f.actions = action;
                    f.numbers = numbers;
                    f.chip = (this.state.action === "game")?convertMapToObject(chips):null
                }
            })
            const result = await UserService.doUpdateFavorites({
                favorites: JSON.stringify(favorites)
            });
            gameTableContext.onUpdateState({
                favorites: parseFavorites(JSON.parse(result.data.favorite)),
            })
        }else{
            favorites.map((f) => {
                if(f.id === this.state.favorite.id){
                    f.name = values.favoriteName;
                    f.actions = action;
                    f.numbers = numbers;
                    f.chip = (this.state.action === "game")?convertMapToObject(chips):null
                }
            })
            gameTableContext.onUpdateState({
                favorites,
            })
        }

        this.onClosePopup(null);
    }
    onSubmitPopup = (e) => {
        this.refForm.submitForm();
    }
    render() {
        const {applicationContext, gameTableContext} = this.props;
        return (
            <div className={classNames(styles.Tools , gameTableContext.state.windows.favorite && styles.OpenMobileTools)}>
                <div className={styles.ToolsWrapper}>
                    {gameTableContext.state.isMobileVersion && <a href={HOME_WEBSITE} className={styles.MobileFloatingMenuLogo}><img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={120}/></a>}

                    {gameTableContext.state.favorites.map((favorite, index) => {
                        return (
                            <button className={classNames(styles.ToolsButton)}
                                    disabled={this.state.processing}
                                    onContextMenu={(e) => this.handleOnContextMenu(e, favorite.id)}
                                    onClick={(e) => this.onBet(favorite.id)}
                                    onMouseMove={(e) => this.onBetHover(favorite.id)}
                                    onMouseOut={(e) => this.onClearSelected()}
                                    key={index}
                            >
                                <div className={classNames(styles.ToolsButtonWrapper, applicationContext.translator("direction") === "rtl" && "no-letter-space")}>{applicationContext.translator(favorite.name)}</div>
                                {!gameTableContext.state.lockTable && this.state.menuOpen && this.state.menuId === favorite.id &&
                                <React.Fragment>
                                    <div className={classNames(styles.ContextMenu, applicationContext.translator("direction") === "rtl" && "no-letter-space rtl", gameTableContext.state.isMobileVersion?styles.RightContextMenu:styles.LeftContextMenu)}>
                                        <ul>
                                            <li className={applicationContext.translator("direction") === "rtl" && "no-letter-space rtl"} onClick={(e) => this.onOpenPopup(e, favorite, "game")}>{applicationContext.translator("Save Game & Rename")} <b>"{favorite.name}"</b></li>
                                            <li className={applicationContext.translator("direction") === "rtl" && "no-letter-space rtl"} onClick={(e) => this.onOpenPopup(e, favorite, "position")}>{applicationContext.translator("Save Position & Rename")} <b>"{favorite.name}"</b></li>
                                        </ul>
                                    </div>
                                </React.Fragment>}
                            </button>
                        )
                    })}
                </div>
                {this.state.menuOpen && <div className={classNames(styles.ContextMenuOverlay)} onClick={this.handleOnContextMenu}></div>}
                <Popup
                    show={this.state.changeNamePopup}
                    onClose={this.onClosePopup}
                    onSubmit={this.onSubmitPopup}
                    title={(this.state.action && this.state.action === "game")?
                        "Save Game & Rename " + this.state.favoriteName
                        :"Save Position & Rename " + this.state.favoriteName}
                    description={(this.state.action && this.state.action === "game")?
                        "When saving Game, you are not able to change the value of the chips"
                        :"When saving Position, you are able to change the value of the chips"}
                >
                    <Formik
                        innerRef={(ref) => this.refForm = ref}
                        initialValues={{
                            favoriteName: this.state.favoriteName
                        }}
                        validationSchema={Yup.object().shape({
                            favoriteName: Yup.string().required("You must enter a name for your favorite game")
                        })}
                        onSubmit={this.handleSubmit}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Rename Favorite</Form.Label>
                                    <Form.Control type="text"
                                                  name={"favoriteName"}
                                                  onChange={handleChange}
                                                  value={values.favoriteName}
                                                  isValid={touched.favoriteName && !errors.favoriteName}
                                                  isInvalid={touched.favoriteName && errors.favoriteName}
                                                  onFocus={(e) => {
                                                      e.target.select()
                                                  }}
                                                  autoComplete="off"
                                                  ref={(ref) => this.refInput = ref}
                                    />
                                    {touched.favoriteName && <Form.Text className="text-danger mt-2">
                                        {errors.favoriteName}
                                    </Form.Text>}
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>

                </Popup>
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext(Tools));

