import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {RED_NUMBERS_OF_TABLE} from "../../../helpers/constants";
import {Spinner} from "react-bootstrap";
import UserService from "../../../services/user.service";
class Stats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        }
    }
    componentDidMount = async () => {
        const {gameTableContext} = this.props;
        if(gameTableContext.state.practice){
            setTimeout(() => {
                this.setState({
                    loading: false,
                    data: {
                        "cold": [2, 9, 14, 16, 19],
                        "fire": [5, 25, 17, 20, 33],
                        "1st-12": 33,
                        "2nd-12": 32,
                        "3rd-12": 34,
                        "1-18": 53,
                        "19-36": 47,
                        "odd": 48,
                        "even": 52,
                        "red": 56,
                        "black": 44
                    }
                })
            }, 1000)
        }else{
            const stats = await UserService.getStats();
            this.setState({
                loading: false,
                data: stats
            })
        }

    }

    render() {
        const {gameTableContext} = this.props;
        return (
            <div className={classNames(styles.StatsWrapper, gameTableContext.state.windows.stats && styles.OpenStatsWrapper)}>
                {this.state.loading?(
                    <div className={styles.Loading}>
                        <div className={"text-center"}>
                            <Spinner animation="grow" variant="light" />
                        </div>
                    </div>
                ):(
                <React.Fragment>
                    <div className={styles.StatsLeftBar}>
                        <ul className={styles.StatsBarItems}>
                            <li className={styles.StatsBarColdIcon}><FontAwesomeIcon icon={"snowflake"} /></li>
                            {(this.state.data.cold.map((number, index) => {
                                return <li className={RED_NUMBERS_OF_TABLE.includes(number) && styles.TextRed}>{number}</li>
                            }))}
                        </ul>

                    </div>
                    <div className={styles.StatsCenterBar}>
                        <div className={styles.StatsTableWrapper}>
                            <table border={0} className={styles.StatsTable}>
                                <tr>
                                    <th>1ST 12</th>
                                    <th>2ND 12</th>
                                    <th>3RD 12</th>
                                    <th className={styles.StatsCellNoBorder}></th>
                                </tr>
                                <tr>
                                    <td>{this.state.data['1st-12']}%</td>
                                    <td>{this.state.data['2nd-12']}%</td>
                                    <td>{this.state.data['3rd-12']}%</td>
                                    <td className={styles.StatsCellNoBorder}></td>
                                </tr>
                            </table>
                            <table border={0} className={styles.StatsTable}>
                                <tr>
                                    <th>1-18</th>
                                    <th>19-36</th>
                                    <th>EVEN</th>
                                    <th>ODD</th>
                                </tr>
                                <tr>
                                    <td>{this.state.data['1-18']}%</td>
                                    <td>{this.state.data['19-36']}%</td>
                                    <td>{this.state.data['even']}%</td>
                                    <td>{this.state.data['odd']}%</td>
                                </tr>
                            </table>
                            <table border={0} className={styles.StatsTable}>
                                <tr>
                                    <td className={classNames(styles.StatsColRed, styles.TextRed)}>RED</td>
                                    <td>{this.state.data['red']}%</td>
                                    <td className={styles.StatsColRed}>BLACK</td>
                                    <td>{this.state.data['black']}%</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className={styles.StatsRightBar}>
                        <ul className={styles.StatsBarItems}>
                            <li className={styles.StatsBarFireIcon}><FontAwesomeIcon icon={"fire"} /></li>
                            {(this.state.data.fire.map((number, index) => {
                                return <li className={RED_NUMBERS_OF_TABLE.includes(number) && styles.TextRed}>{number}</li>
                            }))}
                        </ul>
                    </div>
                </React.Fragment>)}
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext(Stats));