import React, {Component} from 'react';
import {NavLink, withRouter} from "react-router-dom"
import {withAccountContext} from "../../../contexts/AccountContext";
import TicketsService from "../../../services/tickets.service";
import {ROUTE_ADMIN_DASHBOARD, ROUTE_MY_TICKETS} from "../../../routers/routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "./Ticketing.module.css";
import Ticket from "../../../components/ui/Ticket";
import Popup from "../../../components/ui/Popup";
import UserService from "../../../services/user.service";
import PageTitleContainer from "../Search/PageTitleContainer";
import PanelContainer from "../PanelContainer/PanelContainer";
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import Error404 from "../../Account/Errors/Error404";
import AdminService from "../../../services/admin.service";
import SubmitTicket from "../../Account/Ticketing/SubmitTicket";
class SelectedTicket extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            ticketId: props.match.params.hasOwnProperty('ticketId')?props.match.params.ticketId:null,
            loading: true,
            ticket: null,
            reply: false,
            confirm: false,
            submitting: false
        }
    }

    componentDidMount = async () => {
        const {accountContext} = this.props;
        try{
            let ticket = await AdminService.showTicket(this.state.ticketId)
            accountContext.doUpdatePageTitle("Ticket #"+ticket.id);
            this.setState({
                ticket,
                loading: false
            })
        }catch (e){
            this.setState({
                loading: false
            })
        }
    }

    refreshTicketReplies = async () => {
        let ticket = await AdminService.showTicket(this.state.ticketId)
        this.setState({
            ticket,
            reply: false
        })
    }
    handleResolveTicket = async (e) => {
        const {applicationContext} = this.props;
        this.setState({
            submitting: true
        });
        try{
            let resolved = await AdminService.resolveTicket(this.state.ticket.id);
            this.setState({
                submitting: false,
                confirm: false,
            });
            await this.refreshTicketReplies();
        }catch (e){
            this.setState({
                submitting: false
            });
        }
    }
    render() {
        const {accountContext} = this.props;
        return (
            <PanelContainer>
                <PageTitleContainer back={true} center={true}/>
                {this.state.ticket?(
                <div className={"form-center d-block"}>
                    <Ticket ticket={this.state.ticket} />
                    {this.state.ticket.status.code !== 3 && <button className={"btn btn-secondary mr-2"} type={"button"}  onClick={(e) => this.setState({ reply: !this.state.reply }) }><FontAwesomeIcon icon={"reply"} className={"mr-1"}/> Reply</button>}
                    {this.state.ticket.status.code !== 3 && <button className={"btn btn-primary mr-2"} type={"button"}  onClick={(e) => this.setState({ confirm: true }) }><FontAwesomeIcon icon={"check"} className={"mr-1"}/> Resolved</button>}

                    {this.state.reply && <div className={styled.SubmitBox}>
                        <SubmitTicket reply={this.state.ticket.id} submitted={this.refreshTicketReplies} />
                    </div>}
                    {this.state.ticket.replies && this.state.ticket.replies.map((reply, index) => {
                        return <Ticket ticket={reply} key={index} />
                    })}

                    <Popup
                        show={this.state.confirm}
                        onClose={() => this.setState({ confirm: false })}
                        onSubmit={this.handleResolveTicket}
                        SubmitCaption={"Yes, I'm sure"}
                        SubmitButtonClass={"btn-success"}
                        CancelCaption={"No"}
                        SubmittingLoading={this.state.submitting}
                        title={"Resolve Ticket"}
                        large={false}
                        description={"Are you sure to get your response as correct?"}
                    >

                    </Popup>
                </div>
                ):(
                    <Error404 />
                )}
            </PanelContainer>
        )
    }
}

export default withRouter(withAdminAccountContext(SelectedTicket));