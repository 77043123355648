import React, {Component} from 'react';
import {Route, Redirect, withRouter} from "react-router-dom";
import {ROUTE_ADMIN_LOGIN} from "./routes";
import {AdminAccountProvider} from "../contexts/AdminAccountContext";
import {withApplicationContext} from "../contexts/ApplicationContext"
import AdminTokenService from "../services/admin.token.service";
import AdminService from "../services/admin.service";
import {isHiddenAdminPage, url} from "../helpers/helpers";
class AdminAuthRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingProfile: true,
            accountInfo: null,
            profileInfo: null,
            role: null,
            path: null,
            pageTitle: null,
            searchBar: null
        }
    }
    componentDidMount = async () => {
        await this.fetchProfileInfo();
    }
    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if(prevProps.path !== this.props.path){
            await this.fetchProfileInfo();
            this.setState({
                pageTitle: null
            })
        }



    }
    fetchProfileInfo = async () => {
        const {history} = this.props;
        this.setState({ loadingProfile: true });
        try{
            let profile = await AdminService.getAdminBoard();
            this.doUpdateAccountInfo(profile);
        }catch (e){
            if(e.response.status === 401){
                history.push(url(ROUTE_ADMIN_LOGIN, {r: encodeURIComponent(this.props.location.pathname)}));
            }
        }

        this.setState({ loadingProfile: false });
    }

    doUpdateAccountInfo = (data, cb = null) => {
        const {path} = this.props;
        console.log(data.role)
        this.setState({
            accountInfo: data,
            role: data.role,
            path
        }, cb)
    }
    doStartLoading = (cb = null) => {
        this.setState({
            loading: true
        }, cb)
    }
    doFinishLoading = (cb = null) => {
        this.setState({
            loading: false
        }, cb)
    }
    doUpdatePageTitle = (title, cb = null) => {
        this.setState({
            pageTitle: title
        }, cb)
    }
    doUpdateProfileInfo = (profile, cb = null) => {
        this.setState({
            profileInfo: profile,
            loading: false
        }, cb)
    }
    handlePutSearchBarToHeader = (component, cb = null) => {
        this.setState({
            searchBar: component
        }, cb)
    }
    doGoto = (url, key, value, state = null, tableState = null) => {
        const {history} = this.props;
        if(state){
            Object.keys(state).forEach((key) => {
                if(key.toLowerCase().includes("component")){
                    delete state[key]
                }
            })
        }
        localStorage.setItem('back_button', JSON.stringify({
            url: window.location.href,
            state: (state)?state:null,
            tableState: (tableState)?tableState:null,
        }))
        url = url.replaceAll(key, value);
        history.push(url);
    }
    doGotoProfile = (url, key, value, state = null, tableState = null) => {
        const {history} = this.props;
        if(state){
            Object.keys(state).forEach((key) => {
                if(key.toLowerCase().includes("component")){
                    delete state[key]
                }
            })
        }
        localStorage.setItem('back_admin', JSON.stringify({
            url: window.location.href,
            state: (state)?state:null,
            tableState: (tableState)?tableState:null,
        }))
        url = url.replaceAll(key, value);
        history.push(url);
    }
    render() {
        if (!AdminTokenService.getAccessToken())
            return <Redirect to={url(ROUTE_ADMIN_LOGIN, {r: encodeURIComponent(this.props.location.pathname)})} />;

        return <AdminAccountProvider value={{
            accountInfo: this.state.accountInfo,
            role: this.state.role,
            profileInfo: this.state.profileInfo,
            path: this.state.path,
            pageTitle: this.state.pageTitle,
            searchBar: this.state.searchBar,
            doUpdateAccountInfo: this.doUpdateAccountInfo,
            doStartLoading: this.doStartLoading,
            doFinishLoading: this.doFinishLoading,
            doUpdateProfileInfo: this.doUpdateProfileInfo,
            doUpdatePageTitle: this.doUpdatePageTitle,
            doGotoProfile: this.doGotoProfile,
            doGoto: this.doGoto,
            loading: this.state.loading,
            loadingProfile: this.state.loadingProfile
        }}>
            <Route {...this.props} />
        </AdminAccountProvider>;

    }
}

export default withApplicationContext(withRouter(AdminAuthRoute));