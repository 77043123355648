import React, {Component} from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import {withApplicationContext} from "../contexts/ApplicationContext"
import {AppContainer} from "../components/styles/AppStyle";
import Loading from "../components/common/Loading";
import Login from "../scenes/Account/Login";
import Profile from "../scenes/Account/Profile";
import Deposit from "../scenes/Account/Deposit";
import Withdrawal from "../scenes/Account/Withdrawal";
import DepositHistory from "../scenes/Account/DepositHistory";
import WithdrawalHistory from "../scenes/Account/WithdrawalHistory";
import PlayedHistory from "../scenes/Account/PlayedHistory";
import {
    ROUTE_ACCOUNT_DETAILS_CLOSURE,
    ROUTE_ACCOUNT_DETAILS_DELETE,
    ROUTE_ACCOUNT_DETAILS_LIMITS,
    ROUTE_ACCOUNT_DETAILS_SELF_EXCLUSION,
    ROUTE_CREATE_NEW_TICKET,
    ROUTE_FORGET_PASSWORD,
    ROUTE_LOGIN,
    ROUTE_LOGON_HISTORY,
    ROUTE_MY_TICKETS,
    ROUTE_PROFILE_DEPOSIT,
    ROUTE_PROFILE_DEPOSIT_HISTORY,
    ROUTE_PROFILE_PLAYED_HISTORY,
    ROUTE_PROFILE_WITHDRAWAL,
    ROUTE_PROFILE_WITHDRAWAL_HISTORY,
    ROUTE_REGISTER,
    ROUTE_REGISTER_COMPLETE,
    ROUTE_RESET_PASSWORD,
    ROUTE_TICKET_SELECTED,
    ROUTE_UPDATE_EMAIL,
    ROUTE_UPDATE_PASSWORD,
    ROUTE_USER_PROFILE
} from "./routes";
import ForgetPassword from "../scenes/Account/ForgetPassword";
import AuthRoute from "./AuthRoute";
import UpdatePassword from "../scenes/Account/UpdatePassword";
import TrustPaymentDeposit from "../scenes/Account/Deposit/TrustPaymentDeposit";
import UpdateEmail from "../scenes/Account/UpdateEmail";
import SwisscoWithdrawal from "../scenes/Account/Withdrawal/SwisscoWithdrawal";
import Register from "../scenes/Account/Login/Register";
import RegisterComplete from "../scenes/Account/Login/RegisterComplete";
import LogonHistory from "../scenes/Account/LogonHistory";
import NewTicket from "../scenes/Account/Ticketing/NewTicket";
import MyTickets from "../scenes/Account/Ticketing/MyTickets";
import SelectedTicket from "../scenes/Account/Ticketing/SelectedTicket";
import Error404 from "../scenes/Account/Errors/Error404";
import AccountClosure from "../scenes/Account/Details/AccountClosure";
import SelfExclusion from "../scenes/Account/Details/DeleteAccount";
import Limits from "../scenes/Account/Details/Limits";
import DeleteAccount from "../scenes/Account/Details/DeleteAccount";

class AccountRouter extends Component {
    constructor(props) {
        super(props);

    }
    componentDidMount = async () => {
        const {applicationContext} = this.props;
        let lang = await applicationContext.syncLanguage();
        await applicationContext.i18n.changeLanguage(lang);
    }

    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <AppContainer direction={applicationContext.translator("direction")}>
                    {(applicationContext.state.loading)?(<Loading />):(
                        <Switch>
                            <Route path={ROUTE_LOGIN} render={(props) => (<Login level={"user"} />)} />
                            <Route path={ROUTE_REGISTER_COMPLETE} render={(props) => (<RegisterComplete level={"user"} />)} />
                            <Route path={ROUTE_REGISTER} render={(props) => (<Register level={"user"} />)} />
                            <Route path={ROUTE_FORGET_PASSWORD} render={(props) => (<ForgetPassword level={"user"} />)} />
                            <Route path={ROUTE_RESET_PASSWORD} render={(props) => (<ForgetPassword level={"user"} />)} />
                            <Route path={ROUTE_UPDATE_PASSWORD} render={(props) => (<UpdatePassword level={"user"} />)} />
                            <Route path={ROUTE_UPDATE_EMAIL} render={(props) => (<UpdateEmail level={"user"} />)} />
                            <AuthRoute path={ROUTE_USER_PROFILE} render={(props) => (<Profile level={"user"} />)} />
                            <AuthRoute path={ROUTE_PROFILE_DEPOSIT} render={(props) => (process.env.REACT_APP_NAME === "swissco.gaming"?<TrustPaymentDeposit level={"user"}/>:<Deposit level={"user"} />)} />
                            <AuthRoute path={ROUTE_PROFILE_WITHDRAWAL} render={(props) => (process.env.REACT_APP_NAME === "swissco.gaming"?<SwisscoWithdrawal level={"user"}/>:<Withdrawal level={"user"} />)} />
                            <AuthRoute path={ROUTE_PROFILE_WITHDRAWAL} render={(props) => (<Withdrawal level={"user"} />)} />
                            <AuthRoute path={ROUTE_PROFILE_DEPOSIT_HISTORY} render={(props) => (<DepositHistory level={"user"} />)} />
                            <AuthRoute path={ROUTE_PROFILE_WITHDRAWAL_HISTORY} render={(props) => (<WithdrawalHistory level={"user"} />)} />
                            <AuthRoute path={ROUTE_PROFILE_PLAYED_HISTORY} render={(props) => (<PlayedHistory level={"user"} />)} />
                            <AuthRoute path={ROUTE_LOGON_HISTORY} render={(props) => (<LogonHistory level={"user"} />)} />
                            <AuthRoute path={ROUTE_CREATE_NEW_TICKET} render={(props) => (<NewTicket level={"user"} />)} />
                            <AuthRoute path={ROUTE_TICKET_SELECTED} render={(props) => (<SelectedTicket level={"user"} />)} />
                            <AuthRoute path={ROUTE_MY_TICKETS} render={(props) => (<MyTickets level={"user"} />)} />
                            <AuthRoute path={ROUTE_ACCOUNT_DETAILS_CLOSURE} render={(props) => (<AccountClosure level={"user"} />)} />
                            <AuthRoute path={ROUTE_ACCOUNT_DETAILS_DELETE} render={(props) => (<DeleteAccount level={"user"} />)} />
                            <AuthRoute path={ROUTE_ACCOUNT_DETAILS_LIMITS} render={(props) => (<Limits level={"user"} />)} />

                            <AuthRoute path={"/404"} render={(props) => (<Error404 level={"user"} />)} />

                        </Switch>
                    )}

                </AppContainer>
            </React.Fragment>
        );
    }
}

export default withApplicationContext(withRouter(AccountRouter));