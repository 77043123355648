import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import {NavLink, withRouter} from "react-router-dom"
import AccountContainer from "../AccountContainer";
import LoadingContainer from "../../../components/common/LoadingContainer";
import AccountTable from "../../../components/ui/AccountTable";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import DepositService from "../../../services/deposit.service";
import moment from 'moment-timezone';
import {accountCodeParse, numberWithCommas, timezone, utcTime} from "../../../helpers/helpers";
import DepositStatus from "../../../components/ui/DepositStatus";
import {withAlert} from "react-alert";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import {ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW} from "../../../routers/routes";
import {Form, InputGroup} from "react-bootstrap";
import styles from "../../Admin/Journal/Journal.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Calendar from "react-calendar";
class DepositHistory extends Component {
    refForm = React.createRef();
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dialog: false,
            action: "accept",
            row: null,
            index: -1,
            submitting: false,
            date: null,
            datepicker: false,
            keyword: "",
            typingTimeout: null,
            status: "",
            start_date: null,
            end_date: null,
        }
    }
    onChangeDate = (date) => {
        this.setState({
            date,
            start_date: date[0],
            end_date: date[1],
            datepicker: false
        }, this.handleSearch)
    }
    handleSearch = () => {
        this.refTable.search({
            keyword: this.state.keyword,
            status: this.state.status,
            start_date: (this.state.start_date)?moment(new Date(this.state.start_date)).format('YYYY-MM-DD'):null,
            end_date: (this.state.end_date)?moment(new Date(this.state.end_date)).format('YYYY-MM-DD'):null,
        })
    }
    onSearchKeyword = (e) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        self.setState({
            keyword: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.handleSearchKeyword(self.state.keyword);
            }, 1000)
        });
    }
    handleSearchKeyword = (value) => {
        this.refTable.search({
            keyword: value,
            status: this.state.status,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
        }, this.handleSearch)
    }
    render() {
        const {accountContext, applicationContext} = this.props;
        return (
            <LoadingContainer loading={accountContext.loading}>
                <AccountContainer>
                    <div className={"form-center form-search"}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>
                                <a href={"javascript:void(0)"} className={"btn btn-white full-width no-link"} >
                                    <React.Fragment>
                                        {applicationContext.translator("STATUS")}:
                                    </React.Fragment>
                                </a>
                            </InputGroup.Text>
                            <Form.Control
                                as={"select"}
                                className={styles.DropDown}
                                name={"status"}
                                value={this.state.status}
                                onChange={(e) => {
                                    this.setState({
                                        status: e.target.value
                                    }, this.handleSearch)
                                }}

                            >
                                <option value={""}>{applicationContext.translator("ALL")}</option>
                                <option value={"0"}>{applicationContext.translator("Waiting for verify")}</option>
                                <option value={"1"}>{applicationContext.translator("Verified")}</option>
                                <option value={"2"}>{applicationContext.translator("Rejected")}</option>
                            </Form.Control>
                            <InputGroup.Text className={"calendar-container"} id="basic-addon1">
                                <a href={"javascript:void(0)"} onClick={(e) => this.setState({ datepicker: !this.state.datepicker })} className={"btn btn-white full-width"} >
                                    <React.Fragment>
                                        <FontAwesomeIcon icon={"calendar"} /> {this.state.start_date?
                                        <span className={"ml-1"}>
                                        {moment(new Date(this.state.start_date)).format('MMMM DD, YY')}
                                            {this.state.end_date && moment(new Date(this.state.start_date)).format('MMMM DD, YY') !== moment(new Date(this.state.end_date)).format('MMMM DD, YY')?("-" + moment(new Date(this.state.end_date)).format('MMMM DD, YY')):""}
                                    </span>:applicationContext.translator("DATE")}
                                    </React.Fragment>
                                </a>
                                {this.state.datepicker && <div className={"float-calendar"}><Calendar value={this.state.date} selectRange={true} onChange={this.onChangeDate}/></div>}
                            </InputGroup.Text>
                            <Form.Control
                                aria-describedby="basic-addon2"
                                name={"keyword"}
                                onChange={this.onSearchKeyword}
                                disabled={true}
                            />

                        </InputGroup>
                    </div>
                    <div className={"form-center"}>
                        <AccountTable loadData={DepositService.getList} full={true} header={(onSort) => {
                            return <TableRow>
                                <TableColHeader>#</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Date")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Time")}</TableColHeader>
                                {process.env.REACT_APP_NAME !== "swissco.gaming" && <TableColHeader>{applicationContext.translator("Hash Code")}</TableColHeader>}
                                <TableColHeader>{applicationContext.translator("Balance")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Cash-In")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("New Balance")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Status")}</TableColHeader>
                            </TableRow>
                        }}
                        render={(row, index, number) =>
                            <TableRow key={index}>
                                <TableCol>{number}</TableCol>
                                <TableCol>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableCol>
                                <TableCol>{utcTime(row['created_at']).format('HH:mm:ss')}</TableCol>
                                {process.env.REACT_APP_NAME !== "swissco.gaming" && <TableCol><a className={"underline-link yellow-text"} href={"https://tronscan.org/#/transaction/" + row['hash_code']} target={"_blank"}>{row['hash_code']}</a></TableCol>}
                                <TableCol>{row.transaction && numberWithCommas(row['transaction']['balance'], true)}</TableCol>
                                <TableCol>{numberWithCommas(row['amount'], true)}</TableCol>
                                <TableCol>{row.transaction && numberWithCommas(row['transaction']['new_balance'], true)}</TableCol>
                                <TableCol>{<DepositStatus status={row['status']} />}</TableCol>
                            </TableRow>
                        }
                          sort={"id"}
                          align={"desc"}
                          ref={(ref) => this.refTable = ref}
                          mobile={(row, index, number, total, onNext, onPrevious) =>
                              <TableMobileRow key={index}>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Number / Total Records")}</label>
                                      <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                                  </TableMobileCol>
                                  {accountContext.userInfo['is_admin'] && <TableMobileCol>
                                      <label>{applicationContext.translator("Account ID")}</label>
                                      <TableColValue>{accountCodeParse(row.user['account_code'], applicationContext.translator("direction") === "rtl")}</TableColValue>
                                  </TableMobileCol>}
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Date")}</label>
                                      <TableColValue>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Time")}</label>
                                      <TableColValue>{utcTime(row['created_at']).format('hh:mm:ss')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Balance")}</label>
                                      <TableColValue>{row.transaction && numberWithCommas(row['transaction']['balance'], true)}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Cash-In")}</label>
                                      <TableColValue>{numberWithCommas(row['amount'], true)}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("New Balance")}</label>
                                      <TableColValue>{row.transaction && numberWithCommas(row['transaction']['new_balance'], true)}</TableColValue>
                                  </TableMobileCol>

                                  {process.env.REACT_APP_NAME !== "swissco.gaming" && <TableMobileCol>
                                      <label>{applicationContext.translator("Hash Code")}</label>
                                      <TableColValue><a className={"underline-link yellow-text"} href={"https://tronscan.org/#/transaction/" + row['hash_code']} target={"_blank"}>{row['hash_code']}</a></TableColValue>
                                  </TableMobileCol>}
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Status")}</label>
                                      <TableColValue><DepositStatus status={row['status']} /></TableColValue>
                                  </TableMobileCol>
                              </TableMobileRow>}
                        />
                    </div>

                </AccountContainer>
            </LoadingContainer>
        )
    }
}

export default withAlert()((withApplicationContext(withAccountContext(withRouter(DepositHistory)))));