import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import {
    WHEEL_NUMBERS,
    CIRCLE_POSITION_ON_WHEEL,
    TIME_DURATION,
    LOCK_TIME,
    WHEEL_SVG_PATHS, WHEEL_SVG_ARROW
} from "../../../helpers/constants";
import {convertMapToObject, getNeighbours, totalChips} from "../../../helpers/helpers";
import styles from "../GameTable.module.css";
import ContextMenu from "../../../components/ui/ContextMenu/ContextMenu";
import UserService from "../../../services/user.service";
import {withAccountContext} from "../../../contexts/AccountContext";
import classNames from "classnames";
import moment from "moment";
class Wheel extends Component {
    timeDuration = TIME_DURATION;
    timeout = null;
    isPause = false;
    constructor(props) {
        super(props);
        this.state = {
            timer: (props.gameTableContext.state.roundRemainTime)?props.gameTableContext.state.roundRemainTime:this.timeDuration,
            startRotate: false,
            nearCount: 2,
            round: props.gameTableContext.state.roundNumber,
            stopCircle: props.gameTableContext.state.numberHistory[0],
            number: -1,
            hoverNumber: null,
            contextMenu: false,
            contextMenuNumber: null,
            contextX: null,
            contextY: null,
            circleTimeContextMenu: false,
            stop: false
        }

    }
    handleOnContextMenu = (e, number = null) => {
        const {gameTableContext} = this.props;
        if(gameTableContext.state.lockTable) return;
        if(e) e.preventDefault();
        console.log(e)
        if(number){
            this.setState({
                contextMenu: !this.state.contextMenu,
                contextMenuNumber: number,
                ...(e && {contextX: e.pageX}),
                ...(e && {contextY: e.pageY})
            })
        }else{
            this.setState({
                contextMenu: !this.state.contextMenu,
                contextMenuNumber: number,
                ...(e && {contextX: e.pageX}),
                ...(e && {contextY: e.pageY})
            })
        }

    }
    handleOnCircleTimeContextMenu = (e = null) => {
        const {gameTableContext} = this.props;
        if(e) e.preventDefault();
        if(!gameTableContext.state.practice) return;
        this.setState({
            circleTimeContextMenu: !this.state.circleTimeContextMenu,
            ...(e && {contextX: e.pageX}),
            ...(e && {contextY: e.pageY})
        })

    }

    dropChipsFromTable = (dropChips) => {
        dropChips.forEach((chip) => {
            let cloned = document.getElementById(chip.id);
            if(cloned){
                setInterval(() => {
                    if(cloned.offsetTop < 1000){
                        cloned.style.top = cloned.offsetTop + 50 + 'px';
                    }else{
                        // cloned.remove();
                    }
                }, 50)
            }
        });

    }
    refreshTable = (winTotal, newChips, dropChips, unlock = false) => {
        const {gameTableContext} = this.props;
        setTimeout(() => {
            this.dropChipsFromTable(dropChips);
        }, (winTotal === 0)?4000:10)

        setTimeout(() => {
            gameTableContext.onUpdateState({
                lose: dropChips
            });
            if(winTotal > 0 && gameTableContext.state.isMobileVersion){
                setTimeout(() => {
                    gameTableContext.handleOpenWindow("won");
                }, 3000);
            }
        }, 300)

        if(unlock){
            setTimeout(() => {
                gameTableContext.unlockTable(newChips);
            }, (newChips.size === 0)?8000:5000)
        }
    }
    counterWon = (winTotal, newChips, dropChips, balance, newBalance = null) => {
        const {gameTableContext} = this.props;

        if(winTotal === 0) {
            this.refreshTable(winTotal, newChips, dropChips, true)
            return;
        }
        let countdown = null;
        setTimeout(() => {
            this.refreshTable(winTotal, newChips, dropChips);
        }, 5000);

        let countdownTime = (winTotal > 0 && gameTableContext.state.isMobileVersion)?12000:8000;
        setTimeout(() => {
            let audio = gameTableContext.soundPlay("Counter_Number", true);
            let reduce = 1;
            if(winTotal >= 100) reduce = 5 + Math.floor((winTotal / 100));
            if(winTotal >= 200) reduce = 6 + Math.floor((winTotal / 100));
            if(winTotal >= 300) reduce = (7 + Math.floor((winTotal / 200)));
            if(winTotal >= 1000) reduce = (8 + Math.floor(winTotal / 50));
            countdown = setInterval(() => {
                if(winTotal > 0){
                    if(reduce > winTotal) reduce = winTotal;
                    winTotal -= reduce;
                    balance += reduce;
                    gameTableContext.onUpdateState({
                        balance: balance,
                        win: winTotal
                    })
                }else{
                    if(audio)
                        audio.pause();
                    clearInterval(countdown)

                    gameTableContext.soundPlay("Counter_Completed");
                    if(gameTableContext.state.isMobileVersion){
                        setTimeout(() => {
                            gameTableContext.handleCloseWindow("won");
                            setTimeout(() => {
                                gameTableContext.unlockTable(newChips);
                            }, 1500)
                        }, 3000)
                    }else{
                        setTimeout(() => {
                            gameTableContext.unlockTable(newChips);
                        }, 1500)
                    }

                }
            }, 80)
        }, countdownTime)

    }
    doStopTime = () => {
        this.isPause = true
    }
    doStartTime = () => {
        this.isPause = false
    }
    doStartRotate = () => {
        this.setState({
            startRotate: true
        })
    }
    doStopRotate = () => {
        this.setState({
            startRotate: false
        })
    }
    componentDidMount = async () => {
        const {gameTableContext} = this.props;
        let t = setInterval(() => {
            if(this.state.timer > 0){
                if(!this.isPause){
                    let timer = this.state.timer - 1;
                    this.setState({
                        timer
                    }, () => {
                        if(timer <= LOCK_TIME){
                            gameTableContext.onUpdateState({
                                lockTable: true,
                                selectedHover: [],
                                selectedHoverFormula: null,
                                displayText: null
                            })
                            gameTableContext.handleCloseAllWindows();
                        }
                        if(timer === 12 || timer === 6)
                            gameTableContext.soundPlay("beep");
                        if(timer === LOCK_TIME)
                            gameTableContext.soundPlay("noBets");
                        if(timer === 0){
                            if(!gameTableContext.state.practice){
                                gameTableContext.handleRotateFromServer();
                            }
                        }

                    })

                }
                gameTableContext.handleUpdateTimer(this.renderTimer())

            }else{
                this.setState({
                    startRotate: true
                }, () => {
                    gameTableContext.handleUpdateTimer("Finish")
                    if(!this.isPause){
                        this.isPause = true;
                        if(gameTableContext.state.practice){
                            if(!this.timeout){
                                this.timeout = setTimeout(() => {
                                    let rnd = Math.floor(Math.random() * 36);
                                    //let rnd = this.state.number + 1;
                                    if(rnd > 36){
                                        rnd = 0
                                        this.setState({
                                            number: -1
                                        })
                                    }else{
                                        this.setState({
                                            number: rnd
                                        })
                                    }

                                    gameTableContext.soundPlay("ball");
                                    // rnd = 8;

                                    gameTableContext.handleCheckWin(rnd, (balance, newChips, dropChips, winTotal) => {
                                        this.setState({
                                            stopCircle: gameTableContext.state.numberHistory[0],
                                            startRotate: false,
                                            timer: this.timeDuration,
                                            round: this.state.round + 1,
                                        }, () => {
                                            this.counterWon(winTotal, newChips, dropChips, balance);
                                            clearTimeout(this.timeout);
                                            this.timeout = null;
                                            this.isPause = false;
                                        })
                                    })

                                }, 3000)
                            }
                        }else{
                            if(gameTableContext.state.waitNextRound){
                                this.doStopTime();
                                this.setState({
                                    timer: 0
                                }, () => {
                                    gameTableContext.doWaitForNextRound();
                                });
                            }
                        }
                    }
                })



            }

        }, 1000)
    }
    handleCheckWinFromServer = (data) => {
        const {gameTableContext} = this.props;
        gameTableContext.soundPlay("ball");
        gameTableContext.handleCheckWinFromServer(data, (balance, newChips, dropChips, winTotal) => {
            this.setState({
                stopCircle: data.number,
                startRotate: false,
                timer: data['round_remain_seconds'],
                round: data['round_number'] + 1,
            }, () => {
                this.counterWon(winTotal, newChips, dropChips, balance);
                clearTimeout(this.timeout);
                this.timeout = null;
                this.isPause = false;
            })
        })
    }
    handleFromServer = (data) => {
        const {gameTableContext} = this.props;
        gameTableContext.soundPlay("ball");
        gameTableContext.handleFromServer(data, (balance, newChips, dropChips, winTotal, newBalance) => {
            this.setState({
                stopCircle: data.number,
                startRotate: false,
                timer: data['round_remain_seconds'],
                round: data['round_number'] + 1,
            }, () => {
                this.counterWon(winTotal, newChips, dropChips, balance, newBalance);
                clearTimeout(this.timeout);
                this.timeout = null;
                this.isPause = false;
            })
        })
    }
    renderTimer = () => {
        if(this.state.timer === -1)
            return "0:00";

        if(this.state.timer === 60)
            return "1:00";
        if(this.state.timer === 120)
            return "2:00";
        if(this.state.timer === 180)
            return "3:00";
        if(this.state.timer < 60){
            let t = (this.state.timer < 10)?`0${this.state.timer}`:this.state.timer;
            return `0:${t}`;
        }
        if(this.state.timer >= 60 && this.state.timer < 120){
            let t = ((this.state.timer - 60) < 10)?`0${(this.state.timer - 60)}`:this.state.timer - 60;
            return `1:${t}`;
        }
        if(this.state.timer >= 120 && this.state.timer < 180){
            let t = ((this.state.timer - 120) < 10)?`0${(this.state.timer - 120)}`:this.state.timer - 120;
            return `2:${t}`;
        }
    }


    onBet = (number, index) => {
        const {gameTableContext} = this.props;
        if(gameTableContext.state.lockTable) return;
        let numbers = getNeighbours(gameTableContext.state.neighbourBet, number, index);
        gameTableContext.refTable.onBetNumbers(numbers, `neighbours-${number}`);
    }
    onHoverNumbers = (number, index) => {
        const {gameTableContext} = this.props;
        if(gameTableContext.state.lockTable) return;
        this.setState({
            hoverNumber: number
        }, () => {
            gameTableContext.onUpdateState({
                selectedHover: getNeighbours(gameTableContext.state.neighbourBet, number, index),
                selectedHoverFormula: "neighbours",
            })
        })

    }
    onLeaveNumbers = () => {
        const {gameTableContext} = this.props;
        if(gameTableContext.state.lockTable) return;
        this.setState({
            hoverNumber: null
        }, () => {
            gameTableContext.onUpdateState({
                selectedHover: [],
                selectedHoverFormula: null,
            })
        })

    }
    renderNeighboursColor = (number) => {
        const {gameTableContext} = this.props;
        let current = gameTableContext.state.selectedHover[Math.floor(gameTableContext.state.selectedHover.length / 2)];
        if(current == number && (gameTableContext.state.selectedHoverFormula === "straight" || gameTableContext.state.selectedHoverFormula === "neighbours"))
            return "#ced300";
        if(gameTableContext.state.selectedHover.length == 1){
            let selected = gameTableContext.state.selectedHover[0];
            let neighbours = getNeighbours(gameTableContext.state.neighbourBet, selected, WHEEL_NUMBERS.indexOf(selected));
            if(neighbours.includes(number))
                return "#02b051";
        }

        if(gameTableContext.state.selectedHover.includes(number))
            return "#02b051";
        if(gameTableContext.state.chips.has(number)){
            let base = false;
            gameTableContext.state.chips.get(number).forEach((n) => {
                if(n.formula && n.formula === `neighbours-${number}`)
                    base = true
            })
            if(base)
                return "#ced300";
            return "#02b051";
        }
        return "none"

    }
    handleChangeTimeDuration = (time) => {
        this.timeDuration = time
        this.setState({
            timer: this.timeDuration
        }, () => {

            this.handleOnCircleTimeContextMenu();
        })
    }
    onChangeNeighbourBet = (neighbourBet) => {
        const {gameTableContext} = this.props;
        gameTableContext.onUpdateState({
            neighbourBet
        })
    }
    render() {
        const {gameTableContext} = this.props;
        return (
            <React.Fragment>
                {!gameTableContext.state.lockTable && this.state.contextMenu && <ContextMenu fixed show={this.state.contextMenu}
                             onClose={this.handleOnContextMenu}
                             number={this.state.contextMenuNumber}
                             x={this.state.contextX}
                             y={this.state.contextY} />}
                {!gameTableContext.state.lockTable && this.state.circleTimeContextMenu && gameTableContext.state.practice &&
                <ContextMenu fixed show={this.state.circleTimeContextMenu}
                    onClose={this.handleOnCircleTimeContextMenu}
                    x={this.state.contextX}
                    y={this.state.contextY}>
                    <li onClick={(e) => this.handleChangeTimeDuration(30)}>
                        <div>Adjust the time to 30sec</div>
                    </li>
                    <li onClick={(e) => this.handleChangeTimeDuration(60)}>
                        <div>Adjust the time to 60sec</div>
                    </li>
                    <li onClick={(e) => this.handleChangeTimeDuration(90)}>
                        <div>Adjust the time to 90sec</div>
                    </li>
                    <li onClick={(e) => this.handleChangeTimeDuration(120)}>
                        <div>Adjust the time to 120sec</div>
                    </li>
                </ContextMenu>}
                {gameTableContext.state.isMobileVersion && gameTableContext.state.windows.wheel && <div className={styles.NeighboursBar}>
                    <div className={styles.NeighboursBarWrapper}>
                        <button className={classNames(styles.NeighbourButton, gameTableContext.state.neighbourBet === 0 && styles.NeighbourButtonActive)} onClick={(e) => this.onChangeNeighbourBet(0)}>
                            0
                        </button>
                        <button className={classNames(styles.NeighbourButton, gameTableContext.state.neighbourBet === 1 && styles.NeighbourButtonActive)} onClick={(e) => this.onChangeNeighbourBet(1)}>
                            1
                        </button>
                        <button className={classNames(styles.NeighbourButton, gameTableContext.state.neighbourBet === 2 && styles.NeighbourButtonActive)} onClick={(e) => this.onChangeNeighbourBet(2)}>
                            2
                        </button>
                        <button className={classNames(styles.NeighbourButton, gameTableContext.state.neighbourBet === 3 && styles.NeighbourButtonActive)} onClick={(e) => this.onChangeNeighbourBet(3)}>
                            3
                        </button>
                    </div>
                    <label>Neighbours</label>
                </div>}

                <div className={classNames(styles.WheelWrapper, gameTableContext.state.windows.wheel && styles.OpenWheelWrapper)}>
                    {/*<div className={styles.WheelDate}>{moment(new Date()).format('ddd D MMM YYYY')} {moment(new Date()).format('HH:mm:ss')}</div>*/}

                    <div className={styles.WheelWrapperContainer}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            strokeLinejoin="round"
                            display="block"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox={gameTableContext.state.isMobileVersion?(((window.innerWidth > 720 && window.innerWidth <= 1023))?"-40 20 372.388 360.119":"5 70 295 320"):"-20 0 332.388 320.119"}
                            className={styles.WheelSvg}
                        >
                            <defs>
                                <linearGradient id="grad_l_1" x1="0%" x2="100%" y1="0%" y2="100%">
                                    <stop offset="0" stopColor="#0F0F0F"></stop>
                                    <stop offset="0.5" stopColor="#646464"></stop>
                                    <stop offset="1" stopColor="#0F0F0F"></stop>
                                </linearGradient>
                                <linearGradient id="grad_l_2" x1="0%" x2="100%" y1="0%" y2="100%">
                                    <stop offset="0" stopColor="#737373"></stop>
                                    <stop offset="0.5" stopColor="#C8C8C8"></stop>
                                    <stop offset="1" stopColor="#737373"></stop>
                                </linearGradient>
                                <linearGradient id="grad_r_1" x1="10%" x2="70%" y1="10%" y2="70%">
                                    <stop offset="0" stopColor="#FFF" stopOpacity="0.3"></stop>
                                    <stop offset="0.5" stopColor="#FFF" stopOpacity="0.25"></stop>
                                    <stop offset="1" stopColor="#FFF" stopOpacity="0"></stop>
                                </linearGradient>
                                <symbol viewBox="-107 -107 214 214">
                                    <circle r="100" fill="#EEE"></circle>
                                    <circle
                                        r="67.5"
                                        fill="transparent"
                                        stroke="#555"
                                        strokeDasharray="62 20"
                                        strokeWidth="6"
                                    ></circle>
                                    <path d="M70.711 70.711a100 100 0 01-141.422 0l14.142-14.142a80 80 0 01-20.705-35.863l-19.319 5.176A100 100 0 01-25.882-96.593l5.176 19.319a80 80 0 0141.412 0l5.176-19.319A100 100 0 0196.593 25.882l-19.319-5.176a80 80 0 01-20.705 35.863l14.142 14.142M70 0a70 70 0 100 .001M65 0a65 65 0 100 .001z"></path>
                                    <circle
                                        r="103"
                                        fill="transparent"
                                        stroke="#BBB"
                                        strokeWidth="8"
                                    ></circle>
                                </symbol>
                                <symbol viewBox="-55 -55 110 110">
                                    <circle r="53" fill="#050" stroke="#2C2" strokeWidth="4"></circle>
                                </symbol>
                                <symbol viewBox="-2 -2 128 32">
                                    <path stroke="#444" strokeWidth="2" d="M0 0H125V29H0z"></path>
                                </symbol>
                            </defs>
                            <g transform="matrix(.68 0 0 .68 -.496 4.606)">
                                {WHEEL_SVG_PATHS.map((svg, index) => {
                                    return (
                                        <React.Fragment>
                                            <g>
                                                <path fill={svg.path1.fill} stroke="url(#grad_l_2)" strokeOpacity="60%" strokeWidth="2" d="M0 0l-16.961-199.28a200 200 0 0133.922 0z" transform={svg.path1.transform}></path>
                                                <text y="-174" fill="#EEE" stroke="url(#grad_l_1)" strokeWidth="0.2" fontSize="16" textAnchor="middle" style={{ whiteSpace: "pre" }} transform={svg.text.transform}>
                                                    {svg.text.value}
                                                </text>
                                            </g>
                                            <path fill={this.renderNeighboursColor(svg.text.value)} d="M0 0l-14.163-166.398a200 200 0 0128.326 0z" transform={svg.path2.transform}></path>
                                        </React.Fragment>
                                    )
                                })}

                                <circle cx="225" cy="225" r="205" fill="transparent" stroke="url(#grad_l_1)" strokeWidth="10"></circle>
                                <circle cx="225" cy="225" r="199" fill="transparent" stroke="rgba(50,50,50,.5)" strokeWidth="5"></circle>
                                <circle cx="225" cy="225" r="163" fill="transparent" stroke="url(#grad_l_1)" strokeWidth="4"></circle>
                                <circle cx="225" cy="225" r="166" fill="rgba(0,0,0,.7)" stroke="url(#grad_l_1)" strokeWidth="2" opacity="0.4"></circle>
                                <g>
                                    <circle cx={CIRCLE_POSITION_ON_WHEEL[this.state.stopCircle][0]} cy={CIRCLE_POSITION_ON_WHEEL[this.state.stopCircle][1]} r="6" fill="#AAA" stroke="#777"></circle>
                                    <circle cx={CIRCLE_POSITION_ON_WHEEL[this.state.stopCircle][0]} cy={CIRCLE_POSITION_ON_WHEEL[this.state.stopCircle][1]} r="7" fill="url(#grad_r_1)" stroke="url(#grad_l_1)" strokeWidth="0.5"></circle>
                                    {this.state.startRotate && <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="97.3 225 225" to="457.3 225 225" dur="1s" repeatCount="indefinite"/>}
                                </g>

                                <g fill="transparent" cursor="pointer">
                                    {WHEEL_SVG_PATHS.map((svg, index) => {
                                        return (
                                            <path d="M0 0l-17.81-209.243a200 200 0 0135.62 0z" transform={svg.pointer.transform}
                                                  onClick={(e) => this.onBet(svg.text.value.toString(), WHEEL_NUMBERS.indexOf(svg.text.value))}
                                                  onMouseMove={(e) => this.onHoverNumbers(svg.text.value.toString(), WHEEL_NUMBERS.indexOf(svg.text.value))}
                                                  onMouseOut={(e) => this.onLeaveNumbers()}
                                                  onContextMenu={(e) => this.handleOnContextMenu(e, svg.text.value)}
                                            ></path>
                                        )
                                    })}
                                </g>


                                <circle cx="225" cy="225" r="110" fill="#000" stroke="url(#grad_l_1)" strokeWidth="10" onContextMenu={this.handleOnCircleTimeContextMenu} onClick={this.handleOnCircleTimeContextMenu} style={{cursor: 'pointer'}}></circle>
                                {gameTableContext.state.practice && <circle cx="210" cy="155" r="4" fill="#2E2" stroke="url(#grad_l_1)" strokeWidth="0" onContextMenu={this.handleOnCircleTimeContextMenu} onClick={this.handleOnCircleTimeContextMenu} style={{cursor: 'pointer'}}></circle>}
                                {gameTableContext.state.practice && <circle cx="225" cy="155" r="4" fill="#2E2" stroke="url(#grad_l_1)" strokeWidth="0" onContextMenu={this.handleOnCircleTimeContextMenu} onClick={this.handleOnCircleTimeContextMenu} style={{cursor: 'pointer'}}></circle>}
                                {gameTableContext.state.practice && <circle cx="240" cy="155" r="4" fill="#2E2" stroke="url(#grad_l_1)" strokeWidth="0" onContextMenu={this.handleOnCircleTimeContextMenu} onClick={this.handleOnCircleTimeContextMenu} style={{cursor: 'pointer'}}></circle>}
                                <g>
                                    {WHEEL_SVG_PATHS.map((svg, index) => {
                                        return (
                                            <rect x="-15" y="-208.2474487139159" width="30" height="10" fill={this.renderNeighboursColor(svg.text.value)} stroke="none" transform={`translate(225,225) ${svg.rect.transform}`}></rect>
                                        )
                                    })}
                                </g>
                                <g opacity="1" transform={WHEEL_SVG_ARROW[gameTableContext.state.lastNumber]}><polygon points="217,17 225,24, 233,17" fill="#CCC" stroke="#AAA" stroke-width="1"/></g>


                                <text fontFamily="LCD" textAnchor="end" style={{ whiteSpace: "pre" }}
                                      onClick={this.handleOnCircleTimeContextMenu}
                                      onContextMenu={this.handleOnCircleTimeContextMenu}
                                      style={{cursor: 'pointer'}}>
                                    <tspan x="273" y="280" fill="#EFE" fontSize="59" opacity="0.15">
                                        0:00
                                    </tspan>
                                    <tspan x="273" y="280" fill="#2E2" fontSize="59">
                                        {this.renderTimer()}
                                    </tspan>


                                    <tspan x="268" y="190" fill="#2E2" fontSize="21" fontFamily="LCD-Font" letterSpacing="2">
                                        ROUND#
                                    </tspan>

                                    <tspan x="275" y="225" fill="#EFE" fontSize="41" opacity="0.15">
                                        00000
                                    </tspan>
                                    <tspan x="275" y="225" fill="#2E2" fontSize="41">
                                        {this.state.round}
                                    </tspan>

                                </text>
                            </g>
                            <rect width="100%" height="100%" fill="none" opacity="0.1"></rect>
                        </svg>

                    </div>

                </div>
            </React.Fragment>

        );
    }
}

export default withApplicationContext(withGameTableContext((Wheel)));