import React, {Component} from 'react';
import styles from "./LoadingData.module.css";
import {Spinner} from "react-bootstrap";

class LoadingTableData extends Component {

    render() {
        const {loading, children} = this.props;
        return (loading)?(
            <table className={styles.AppLogoContainer}>
                <tr>
                    <td colSpan={"42"} width={"100%"}>
                        <Spinner animation="grow" variant="light" />
                    </td>
                </tr>
            </table>
        ):(
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}

export default LoadingTableData;