import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../../contexts/AdminAccountContext";
import {NavLink, withRouter} from "react-router-dom"
import PanelContainer from "../../PanelContainer";
import AccountTable from "../../../../components/ui/AccountTable/AccountTable";
import AdminService from "../../../../services/admin.service";
import TableRow from "../../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../../components/ui/AccountTable/TableCol";
import {
    ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS_SELECTED,
    ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW,
    ROUTE_ADMIN_USER_ACCOUNT_PLAYED
} from "../../../../routers/routes";
import {accountCodeParse, numberWithCommas, timezone, url, utcTime} from "../../../../helpers/helpers";
import moment from "moment-timezone";
import TableMobileRow from "../../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../../components/ui/AccountTable/TableColValue";
import PageTitleContainer from "../../Search/PageTitleContainer";
import SelectedDailyWithdrawalsSearch from "./SelectedDailyWithdrawalsSearch";
import DepositStatus from "../../../../components/ui/DepositStatus";
class SelectedDailyWithdrawals extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            date: props.match.params.hasOwnProperty('date')?props.match.params.date:null,
            loading: true,
            round: null,
            searchComponent: null
        }
    }

    componentDidMount = async () => {
        const {accountContext} = this.props;
        accountContext.doUpdatePageTitle("Daily Withdrawals");
        this.setState({
            loading: false
        })
    }


    render() {
        const {accountContext} = this.props;
        return (
            <PanelContainer>
                <PageTitleContainer back={true} center={true} searchBar={this.state.searchComponent}/>
                {!this.state.loading &&
                <React.Fragment>
                    {this.state.roundTotalComponent}
                    <div className={"form-center"}>
                        <AccountTable  ref={(ref) => this.refTable = ref} full={true} loadData={AdminService.getWithdrawalList} sort={"created_at"} align={"desc"} header={(onSort, sort, align) => {
                            return <TableRow>
                                <TableColHeader id={"table-cell-06"}>#</TableColHeader>
                                <TableColHeader width={200} id={"table-cell-05"}>Date</TableColHeader>
                                <TableColHeader id={"table-cell-04"}>Time</TableColHeader>
                                <TableColHeader id={"table-cell-03"}>ID#</TableColHeader>
                                <TableColHeader id={"table-cell-02"}>Amount</TableColHeader>
                                <TableColHeader width={180} id={"table-cell-01"}>Status</TableColHeader>
                            </TableRow>
                        }}
                       render={(row, index, number, page) =>
                           <TableRow key={index}>
                               <TableCol>{number}</TableCol>
                               <TableCol>{utcTime(row['created_at']).format((row['created_at'].split("-").length === 3)?'ddd, MMMM DD, YYYY':'MMMM, YYYY')}</TableCol>
                               <TableCol>{utcTime(row['created_at']).format('hh:mm:ss')}</TableCol>
                               <TableCol>
                                   <NavLink className={"underline-link yellow-text"} to={
                                       url(ROUTE_ADMIN_USER_ACCOUNT_PLAYED.replaceAll(":profileId", row['user']['account_code']),
                                           {
                                               bc: encodeURIComponent(url(ROUTE_ADMIN_MONTHLY_DAILY_DEPOSITS_SELECTED.replaceAll(":date", this.state.date), { page }))
                                       })}>
                                       <span>{accountCodeParse(row['user']['account_code'])}</span>
                                   </NavLink>
                               </TableCol>
                               <TableCol><span className={"yellow-text"}>{numberWithCommas(row['amount'], true)}</span></TableCol>
                               <TableCol><DepositStatus status={row['status']}/></TableCol>
                           </TableRow>
                       }
                       mobile={(row, index, number, total, onNext, onPrevious) =>
                           <TableMobileRow key={index}>
                               <TableMobileCol>
                                   <label>Number#</label>
                                   <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number}</TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Date</label>
                                   <TableColValue>{utcTime(row['created_at']).format((row['created_at'].split("-").length === 3)?'ddd, MMMM DD, YYYY':'MMMM, YYYY')}</TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Time</label>
                                   <TableColValue>{utcTime(row['created_at']).format('hh:mm:ss')}</TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Account ID</label>
                                   <TableColValue><NavLink className={"underline-link yellow-text"} to={ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW.replaceAll(":profileId", row['user']['account_code'])}><span>{accountCodeParse(row['user']['account_code'])}</span></NavLink></TableColValue>
                               </TableMobileCol>
                               <TableMobileCol>
                                   <label>Amount</label>
                                   <TableColValue><span className={"yellow-text"}>{numberWithCommas(row['amount'], true)}</span></TableColValue>
                               </TableMobileCol>

                               <TableMobileCol>
                                   <label>Status</label>
                                   <TableColValue><DepositStatus status={row['status']}/></TableColValue>
                               </TableMobileCol>
                           </TableMobileRow>}
                       params={{
                           date: this.state.date
                       }}
                       loaded={() => {
                           this.setState({
                               searchComponent: <SelectedDailyWithdrawalsSearch refTable={this.refTable} />
                           })
                       }}
                    />
                    </div>
                </React.Fragment>
                }
            </PanelContainer>
        )
    }
}

export default withRouter(withAdminAccountContext(SelectedDailyWithdrawals));