import styled from "styled-components";

export const FooterMenuContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 2em;
  background: #242424;
`;
export const FooterMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 700px) {
    display: block;
    text-align: center;
  }
`
export const FooterMenuItem = styled.li`
  padding: 1em 2em;
  & a{
    color: #d0cecf;
    font-weight: 600;
    &:hover{
      color: var(--color-primary);
      transition: all ease-in-out 0.2s;
    }
  }
`
export const FooterCopyrightContainer = styled.div`
  width: 100%;
  padding: 2em;
  text-align: center;
  background: #242424;
  
`
export const ConditionText = styled.p`
  font-size: 0.85em;
  line-height: 1.7em;
  border-bottom: 2px solid #303030;
  padding-bottom: 3em;
  color: #d0cecf;
  
`
export const CopyrightText = styled.p`
  font-size: 0.8em;
  line-height: 1.7em;
  color: #d0cecf;
  direction: ltr;
  
`



