import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import {Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import styles from "./Dashboard.module.css";
import PanelContainer from "../PanelContainer";
import AdminService from "../../../services/admin.service";
import moment from "moment";
import {numberWithCommas} from "../../../helpers/helpers";
import PriceInput from "../../../components/ui/PriceInput";
import PageTitleContainer from "../Search/PageTitleContainer";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import YearlyMonthlyOverviewSearch from "../Reports/YearlyMonthlyOverview/YearlyMonthlyOverviewSearch";
import AccountTable from "../../../components/ui/AccountTable/AccountTable";
import classNames from "classnames";
import {renderPageTitle} from "../../../components/ui/PageTitle";
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            overview: null
        }
    }
    componentDidMount = async () => {
        const {accountContext} = this.props;
        accountContext.doStartLoading(async () => {
            const overview = await AdminService.getOverview({
                ...(this.props.userId && { id: this.props.userId })
            });
            this.handleUpdateOverviewData(overview);
        })

    }
    handleUpdateOverviewData = (data) => {
        const {accountContext} = this.props;
        this.setState({
            overview: data
        }, () => {
            accountContext.doFinishLoading();
        })
    }

    render() {
        const {accountContext} = this.props;
        return (
            <React.Fragment>
                <PanelContainer>

                    {this.state.overview &&
                    <React.Fragment>
                        <div className={"form-center"}>
                            <Form className={styles.FullWidth}>
                                <Form.Group className={"mb-3"}>
                                    <Col className={"start-center-value"}>
                                        <Form.Label className={styles.FormLabel}></Form.Label>
                                        <PageTitleContainer center={true} full={true}/>

                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col className={"start-center-value"}>
                                        <Form.Label className={styles.FormLabel}>Total Members :</Form.Label>
                                        <Form.Control
                                            aria-describedby="basic-addon2"
                                            value={numberWithCommas(this.state.overview['total_members'])}
                                            autoComplete="off"
                                            className="mb-3"
                                        />

                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col className={"start-center-value"}>
                                        <Form.Label className={styles.FormLabel}>Total Deposit :</Form.Label>
                                        <PriceInput amount={this.state.overview['cash_in']} yellow={true} marginBottom={"mb-3"}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col className={"start-center-value"}>
                                        <Form.Label className={styles.FormLabel}>Total Winning :</Form.Label>
                                        <PriceInput amount={this.state.overview['winning']} marginBottom={"mb-3"}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col className={"start-center-value"}>
                                        <Form.Label className={styles.FormLabel}>Total Losing :</Form.Label>
                                        <PriceInput amount={this.state.overview['losing']} marginBottom={"mb-3"}/>

                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col className={"start-center-value"}>
                                        <Form.Label className={styles.FormLabel}>Total Withdrawal :</Form.Label>
                                        <PriceInput amount={this.state.overview['cash_out']} yellow={true} marginBottom={"mb-3"}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col className={"start-center-value"}>
                                        <Form.Label className={styles.FormLabel}>Total not used balance :</Form.Label>
                                        <PriceInput amount={this.state.overview['not_used_balance']} yellow={true} marginBottom={"mb-3"}/>

                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col className={"start-center-value"}>
                                        <Form.Label className={styles.FormLabel}>House balance :</Form.Label>
                                        <PriceInput
                                            amount={this.state.overview['house_balance']}
                                            className={(this.state.overview['house_balance'] === 0)?"yellow-text":((this.state.overview['house_balance'] > 0)?"text-success":"text-danger")}
                                            marginBottom={"mb-3"}/>

                                    </Col>
                                </Form.Group>

                            </Form>
                        </div>
                        <h2 className={styles.Title}>Today Overview</h2>
                        <div className={"form-center"}>

                            <AccountTable full={true} data={[this.state.overview['today']]} sort={"id"} align={"desc"} header={(onSort, sort, align) => {
                                return <TableRow>
                                    <TableColHeader id={"table-cell-08"}>Total Bets</TableColHeader>
                                    <TableColHeader id={"table-cell-07"}>Total Pay-Out</TableColHeader>
                                    <TableColHeader id={"table-cell-06"}>House balance</TableColHeader>
                                    <TableColHeader id={"table-cell-05"}>Total Winning</TableColHeader>
                                    <TableColHeader id={"table-cell-04"}>Total Loosing</TableColHeader>
                                    <TableColHeader id={"table-cell-03"}>House balance</TableColHeader>
                                    <TableColHeader id={"table-cell-02"}>Total Deposit</TableColHeader>
                                    <TableColHeader id={"table-cell-01"}>Total Withdrawal</TableColHeader>


                                </TableRow>
                            }}
                                          render={(row, index, number) => {
                                              return <TableRow key={index}>
                                                  <TableCol><span>{numberWithCommas(row['TotalBets'], true)}</span></TableCol>
                                                  <TableCol><span>{numberWithCommas(row['PayoutAmount'], true)}</span></TableCol>
                                                  <TableCol classes={row['HouseBalance'] <= 0?"red-text":"green-text"}><span>{numberWithCommas(row['HouseBalance'], true)}</span></TableCol>
                                                  <TableCol><span>{numberWithCommas(row['WinningAmount'], true)}</span></TableCol>
                                                  <TableCol><span>{numberWithCommas(row['LosingAmount'], true)}</span></TableCol>
                                                  <TableCol classes={row['WHouseBalance'] <= 0?"red-text":"green-text"}><span>{numberWithCommas(row['WHouseBalance'], true)}</span></TableCol>
                                                  <TableCol><span>{numberWithCommas(row['DepositAmount'], true)}</span></TableCol>
                                                  <TableCol><span>{numberWithCommas(row['WithdrawalAmount'], true)}</span></TableCol>
                                              </TableRow>
                                          }}
                                          mobile={(row, index, number, total, onNext, onPrevious) =>
                                              <TableMobileRow key={index}>
                                                  <TableMobileCol>
                                                      <label>Total Bets</label>
                                                      <TableColValue><span>{numberWithCommas(row['TotalBets'], true)}</span></TableColValue>
                                                  </TableMobileCol>
                                                  <TableMobileCol>
                                                      <label>Total Pay-Outs</label>
                                                      <TableColValue><span>{numberWithCommas(row['PayoutAmount'], true)}</span></TableColValue>
                                                  </TableMobileCol>
                                                  <TableMobileCol>
                                                      <label>House balance</label>
                                                      <TableColValue classes={row['HouseBalance'] <= 0?"red-text":"green-text"}><span>{numberWithCommas(row['HouseBalance'], true)}</span></TableColValue>
                                                  </TableMobileCol>
                                                  <TableMobileCol>
                                                      <label>Total Winning</label>
                                                      <TableColValue><span>{numberWithCommas(row['WinningAmount'], true)}</span></TableColValue>
                                                  </TableMobileCol>
                                                  <TableMobileCol>
                                                      <label>Total Loosing</label>
                                                      <TableColValue><span>{numberWithCommas(row['LosingAmount'], true)}</span></TableColValue>
                                                  </TableMobileCol>
                                                  <TableMobileCol>
                                                      <label>House Balance</label>
                                                      <TableColValue><span>{numberWithCommas(row['WHouseBalance'], true)}</span></TableColValue>
                                                  </TableMobileCol>
                                                  <TableMobileCol>
                                                      <label>Total Deposit</label>
                                                      <TableColValue><span>{numberWithCommas(row['DepositAmount'], true)}</span></TableColValue>
                                                  </TableMobileCol>
                                                  <TableMobileCol>
                                                      <label>Total Withdrawal</label>
                                                      <TableColValue><span>{numberWithCommas(row['WithdrawalAmount'], true)}</span></TableColValue>
                                                  </TableMobileCol>

                                                  <TableMobileCol>
                                                      <TableColValue>
                                                          <button onClick={(e) => this.onLoadSelectedRound(e, row)}>View details ...</button>
                                                      </TableColValue>
                                                  </TableMobileCol>
                                              </TableMobileRow>}
                            />
                        </div>
                    </React.Fragment>

                    }
                </PanelContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(withAdminAccountContext(Dashboard));