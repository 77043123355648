import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {
    SectionContainer,
    SectionTitle,
    SectionBody,
    CenterCol,
    PrimaryButton,
    CenterText,
    SecondaryButton,
    FlexButtons,
    SecondaryLink,
    ContactItem,
    ContactItemTitle,
    ContactItemBody,
    ContactItemIcon, ContactItemText
} from "./style.jsx";
import {Container, Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SectionContainer bg={"gray"}  id={"section-contact"}>
                <Container>
                    <SectionTitle><div className={"wrapper"}>{applicationContext.translator("Contact")}</div></SectionTitle>
                    <SectionBody>
                        <Row>
                            <Col md={4}>
                                <ContactItem>
                                    <ContactItemTitle>{applicationContext.translator("Support team")}:</ContactItemTitle>
                                    <ContactItemBody>
                                        <ContactItemIcon><div><FontAwesomeIcon icon={"envelope"} /></div></ContactItemIcon>
                                        <ContactItemText>
                                            <h3>{applicationContext.translator("E-Mail")}</h3>
                                            <a href={"mailto:support@myoulette.tv"}>support@myoulette.tv</a>
                                            <small>{applicationContext.translator("Support average e-mail response time < 1 working hour")}</small>
                                        </ContactItemText>
                                    </ContactItemBody>
                                </ContactItem>
                            </Col>
                            <Col md={4}>
                                <ContactItem>
                                    <ContactItemTitle>{applicationContext.translator("Accounting")}:</ContactItemTitle>
                                    <ContactItemBody>
                                        <ContactItemIcon><div><FontAwesomeIcon icon={"envelope"} /></div></ContactItemIcon>
                                        <ContactItemText>
                                            <h3>{applicationContext.translator("E-Mail")}</h3>
                                            <a href={"mailto:accounting@myoulette.tv"}>accounting@myoulette.tv</a>
                                        </ContactItemText>
                                    </ContactItemBody>
                                </ContactItem>
                            </Col>
                            <Col md={4}>
                                <ContactItem>
                                    <ContactItemTitle>{applicationContext.translator("General information")}:</ContactItemTitle>
                                    <ContactItemBody>
                                        <ContactItemIcon><div><FontAwesomeIcon icon={"envelope"} /></div></ContactItemIcon>
                                        <ContactItemText>
                                            <h3>{applicationContext.translator("E-Mail")}</h3>
                                            <a href={"mailto:info@myoulette.tv"}>info@myoulette.tv</a>
                                        </ContactItemText>
                                    </ContactItemBody>
                                </ContactItem>
                            </Col>
                            {/*<Col md={12}>*/}
                            {/*    <ContactItem>*/}
                            {/*        <ContactItemBody>*/}
                            {/*            <ContactItemIcon><div><FontAwesomeIcon icon={"map-marker"} /></div></ContactItemIcon>*/}
                            {/*            <ContactItemText>*/}
                            {/*                <h3>{applicationContext.translator("Address")} SWISSCO GAMING B.V.</h3>*/}
                            {/*                <small>Schout Bij Nacht Doormanweg 40, P.O. Box 4745, Willemstad, Curaçao</small>*/}
                            {/*            </ContactItemText>*/}
                            {/*        </ContactItemBody>*/}
                            {/*    </ContactItem>*/}
                            {/*</Col>*/}
                        </Row>
                    </SectionBody>

                </Container>
            </SectionContainer>

        );
    }
}

export default withApplicationContext(ContactUs);