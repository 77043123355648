import styled from "styled-components";
import {Col} from 'react-bootstrap';
import {NavLink} from "react-router-dom";
export const SliderContainer = styled.div`
  width: 100%;
  height: calc(100% - 200px);
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/img/myroullette-slider.jpg");
  background-size: cover;
`;
export const SectionContainer = styled.div`
  width: 100%;
  padding: 3em 0 3em 0;
  @media screen and (max-width: 700px) {
    padding: 3em 1em;
  }
  background: ${(props) => props.bg === "white"?"#fff":(props.bg === "gray"?"#f1f1f1":"#242424")};
  ${(props) => props.bg === "white" || props.bg === "gray"?`
    & .wrapper{
      width: fit-content;
      background: #f7f7f7;
      margin: 0 auto;
      padding: .5em 1em .7em 1em;
      border-radius: 10px;
      min-width: 200px; 
   
    }
  `:`
    color: #d0cecf;
    & .wrapper{
      width: fit-content;
      background: #323232;
      margin: 0 auto;
      padding: .5em 1em .7em 1em;
      border-radius: 10px;
      min-width: 200px;
    }
  `};
  &:last-child{
    padding-bottom: 5em;
  }
`;
export const ResultContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`
export const DrawResultTitle = styled.h5`
  font-size: 2.5em;
  font-weight: 700;
  @media screen and (max-width: 700px) {
    text-align: center;
    font-size: 2em;
  }
  & span{
    display: block;
    font-size: 0.8em;
    margin-bottom: 4px;
  }
`;

export const StepsContainer = styled.div`
  width: 100%;
  
`;

export const CenterCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SectionTitle = styled.h3`
  font-size: 1.8em;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1em;
  position: relative;
  
  @media screen and (max-width: 700px) {
    text-align: center;
  }
`
export const SectionBody = styled.div`
  padding: 1.5em 0;  
  font-size: 1.1em;
  font-weight: 500;
  text-align: justify;
  line-height: 1.7em;
  @media screen and (max-width: 700px) {
    & .sm-order-2{
      order: 2;
    }
    & .sm-order-1{
      order: 1;
    }
  }
`
export const Brand = styled.div`
  text-align: center;
  margin-bottom: 2em;
  & img{
    width: 50%;
    margin-bottom: .7em;
  }
`
export const StepsTitle = styled.h3`
  color: var(--color-secondary);
  font-size: 1.8em;
  font-weight: 700;
  @media screen and (max-width: 700px) {
    text-align: center;
  }
`
export const StepsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 700px) {
    display: block;
  }
`
export const StepItem = styled.li`
  display: flex;
  align-items: center;
  ${(props) => (props.full)?`width: 66%;`:`width: 33%;`}
  margin-top: 1em;
  @media screen and (max-width: 700px) {
    display: block;
    width: 100%;
    text-align: center;
  }
`
export const StepNumber = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  color: #333;
  font-size: 1.5em;
  font-weight: 500;
  border-radius: 100%;
  @media screen and (max-width: 700px) {
    margin: 1em auto;
  }
`
export const StepDescription = styled.div`
  margin: 1em;
  font-size: 1.1em;
  font-weight: 600;
`
export const StepSubDescription = styled.div`
  color: var(--color-secondary);
`


export const GetStarted = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const GetStartedWrapper = styled.div`
  text-align: center;
  & h3{
    font-size: 4em;
    color: #fff;
    font-weight: 700;
    margin-bottom: 1em;
  }
  & a{
    background: #fff;
    color: #333;
    padding: 1em 2em;
    font-weight: 700;
    transition: all ease-in-out .3s;
    font-size: 1.2em;
    &:hover{
      background: var(--color-primary);
      color: #fff;
      transition: all ease-in-out .3s;
    }
  }
`
export const PrimaryButton = styled(NavLink)`
  background: var(--color-primary);
  color: #fff;
  padding: .7em 2em;
  width: fit-content;
  display: inline-block;
  transition: all ease-in-out .3s;
  margin: 1em .3em;
  &:hover{
    background: #fff;
    color: #333;
    transition: all ease-in-out .3s;
  }
`
export const PrimaryLink = styled.a`
  background: var(--color-primary);
  color: #fff;
  padding: .7em 2em;
  width: fit-content;
  display: inline-block;
  transition: all ease-in-out .3s;
  margin: 1em .3em;
  &:hover{
    background: #fff;
    color: #333;
    transition: all ease-in-out .3s;
  }
`
export const SecondaryButton = styled(NavLink)`
  background: var(--color-primary);
  color: #fff;
  padding: .7em 2em;
  width: fit-content;
  display: inline-block;
  transition: all ease-in-out .3s;
  margin: 1em .3em;
  &:hover{
    background: #333;
    color: #fff;
    transition: all ease-in-out .3s;
  }
`
export const SecondaryLink = styled.a`
  background: var(--color-primary);
  color: #fff;
  padding: .7em 2em;
  width: fit-content;
  display: inline-block;
  transition: all ease-in-out .3s;
  margin: 1em .3em;
  &:hover{
    background: #333;
    color: #fff;
    transition: all ease-in-out .3s;
  }
`
export const CenterText = styled.div`
  text-align: center;
`
export const FlexButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Brands = styled.div`
  max-width: 80%;
  margin: 1em auto;
  text-align: center;
`
export const ContactItem = styled.div`
  margin: 1.5em 0;
`
export const ContactItemTitle = styled.h3`
  font-weight: 700;
  margin-bottom: 1em;
  ${(props) => props.ltr && `
    direction: ltr;
    text-align: right;
  `}
  ${(props) => props.center && `
    text-align: center;
  `}
  
`
export const ContactItemBody = styled.div`
  display: flex;
  ${(props) => props.center && `
    text-align: center;
    justify-content: center;
  `}
`
export const ContactItemIcon = styled.div`
  width: 60px;
  display: flex;
  align-items: flex-start;
  justify-items: center;
  & div{
    width: 48px;
    height: 48px;
    border: 2px solid #333;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg{
      font-size: 20px;
    }
  }
`
export const ContactItemText = styled.div`
  & h3{
    font-size: 1em;
    font-weight: 600;
    margin-bottom: .4em;
    text-align: right;
  }
  & a{
    display: block;
    color: #333;
    transition: all ease-in-out .3s;
    &:hover{
      color: var(--color-primary);
      transition: all ease-in-out .3s;
    }
  }
  & small{
    font-size: .8em;
    color: #7a7a7a;
  }
`
export const ColImage = styled.div`
  text-align: center;
  margin: 1em 0;
`