import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {withRouter} from "react-router-dom"
import {Formik} from "formik";
import * as Yup from "yup";
import {Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import {numberWithCommas} from "../../../helpers/helpers";
import {withAlert} from "react-alert";
import styles from "./EnterManuallyWinningNumber.module.css";
import classNames from "classnames";
import PanelContainer from "../PanelContainer";
import AdminService from "../../../services/admin.service";
import PageTitleContainer from "../Search/PageTitleContainer";
import MasterPasswordAuthentication from "../MasterPasswordAuthentication";
class EnterManuallyWinningNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password_authenticated: false,
            password: "",
            source: "automatic",
            winning_number: "",
            successfully: false,
            error: false,
            submitting: false
        }
    }

    handleSubmit = async (values, actions) => {
        this.setState({submitting: true, successfully: false, error: false})
        try{
             let updated = await AdminService.doUpdateWheelSettings({
                 source: values.source,
                 winning_number: values.winning_number,
                 password: this.state.password
            });
            this.setState({
                successfully: true,
                submitting: false,
                error: false,
                source: updated.data.winning_number_source,
                winning_number: updated.data.winning_number
            })
            actions.resetForm();

        }catch (e){
            if(e.response.data.error === "AUTHENTICATION_FAILED"){
                this.setState({
                    error: true,
                    successfully: false,
                    submitting: false
                })
            }else{
                Object.keys(e.response.data.errors).map((field) => {
                    actions.setFieldError(field, e.response.data.errors[field][0])
                })
                this.setState({
                    error: false,
                    successfully: false,
                    submitting: false
                })
            }


        }


    }
    handleAuthenticated = (password, data = null) => {
        this.setState({
            password_authenticated: true,
            password,
            source: data.config.wheel_state
        })
    }
    render() {
        return (
            <PanelContainer>
                <PageTitleContainer center={true} />
                <div className={"form-center"}>
                    {this.state.password_authenticated?
                        <Formik
                            innerRef={(ref) => this.refForm = ref}
                            enableReinitialize={true}
                            initialValues={{
                                source: this.state.source
                            }}
                            validationSchema={Yup.object().shape({

                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit} className={styles.FullWidth}>
                                    <Form.Group as={Row} id={"form-wrapper"}>
                                        <Col md={12} className={"text-center"}>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={6} className={"start-center-value"}>
                                                    <Form.Check
                                                        inline
                                                        label="STUDIO-1 (automatic wheel)"
                                                        name="group1"
                                                        value={"automatic"}
                                                        defaultChecked={values.source === "automatic"}
                                                        onChange={(e) => {
                                                            if(e.target.checked)
                                                                setFieldValue('source', e.target.value)
                                                        }}
                                                        type={"radio"}
                                                        id={`inline-radio-1`}
                                                    />
                                                </Col>
                                                <Col md={6} className={"start-center-value position-relative"}>
                                                    <Form.Check
                                                        inline
                                                        label="STUDIO-2 (LIVE Croupier)"
                                                        name="group1"
                                                        value={"studio"}
                                                        defaultChecked={values.source === "studio"}
                                                        onChange={(e) => {
                                                            if(e.target.checked)
                                                                setFieldValue('source', e.target.value)
                                                        }}
                                                        type={"radio"}
                                                        id={`inline-radio-2`}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Col>
                                                    <Form.Group as={Row} className={"d-flex justify-content-center"}>
                                                        <button className={"btn btn-account full-width"} disabled={this.state.submitting} type={"submit"}>{this.state.submitting?<Spinner animation="grow" size={"sm"} variant="light" />:"Save"}</button>
                                                    </Form.Group>

                                                    {this.state.successfully && <Form.Group as={Row} className={classNames(styles.TextAlert, "green-text")}>
                                                        <Form.Text className={classNames(styles.TextAlert, "green-text")}>
                                                            The wheel configuration has been updated successfully.
                                                        </Form.Text>

                                                    </Form.Group>}
                                                    {this.state.error && <Form.Group as={Row} className={classNames(styles.TextAlert, "red-text")}>
                                                        <Form.Text className={classNames(styles.TextAlert, "red-text")}>
                                                            You need master password for charge user's accounts
                                                        </Form.Text>

                                                    </Form.Group>}
                                                </Col>
                                            </Form.Group>

                                        </Col>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                        :
                        <MasterPasswordAuthentication handleAuthenticated={this.handleAuthenticated}/>
                    }
                </div>
            </PanelContainer>
        )
    }
}

export default withAlert()(withRouter(withAdminAccountContext(EnterManuallyWinningNumber)));