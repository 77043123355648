import React, {Component} from 'react';
import {
    FooterMenuContainer,
    FooterMenu,
    FooterMenuItem,
    FooterCopyrightContainer,
    ConditionText,
    CopyrightText
} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {Container} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import moment from "moment/moment";
class Footer extends Component {

    render() {
        const {applicationContext} = this.props;

        return (
            <React.Fragment>
                <FooterMenuContainer>
                    <Container>
                        <FooterMenu>
                            <FooterMenuItem><NavLink to={"/responsible-gaming"}>{applicationContext.translator("Responsible Gaming")}</NavLink></FooterMenuItem>
                            <FooterMenuItem><NavLink to={"/terms-and-conditions"}>{applicationContext.translator("Terms and conditions")}</NavLink></FooterMenuItem>
                            <FooterMenuItem><NavLink to={"/self-exclusion"}>{applicationContext.translator("Self Exclusion")}</NavLink></FooterMenuItem>
                            <FooterMenuItem><NavLink to={"/dispute-resolution"}>{applicationContext.translator("Dispute Resolution")}</NavLink></FooterMenuItem>
                            <FooterMenuItem><NavLink to={"/anti-money-laundering"}>{applicationContext.translator("Anti-money laundering")}</NavLink></FooterMenuItem>

                        </FooterMenu>
                        <FooterMenu>
                            <FooterMenuItem><a href={"/assets/files/GLI-Report.pdf"} target={"_blank"}>{applicationContext.translator("Fairness & RNG Testing Methods")}</a></FooterMenuItem>
                            <FooterMenuItem><NavLink to={"/kyc-policies"}>{applicationContext.translator("KYC Policies, Privacy & Management of Personal Data")}</NavLink></FooterMenuItem>
                            <FooterMenuItem><NavLink to={"/accounts-payouts-bonuses"}>{applicationContext.translator("Accounts, Payouts & Bonuses")}</NavLink></FooterMenuItem>
                        </FooterMenu>
                    </Container>
                </FooterMenuContainer>
                <FooterCopyrightContainer>
                    <Container>
                        <img src={"/assets/img/my-roulette-light-logo.png"} width={150} style={{ marginBottom: '1em' }}/>
                        <ConditionText>
                            <span>{applicationContext.translator("In order to register for this website, the user is required to accept the General Terms and Conditions.")}</span><br/>
                            <span>{applicationContext.translator("In the event the General Terms and Conditions are updated, existing users may choose to discontinue using the products and services before the said update shall become effective,")}</span><br/>
                            <span>{applicationContext.translator("which is a minimum of two weeks after it has been announced.")}</span><br/>
                        </ConditionText>
                        <CopyrightText>
                            MyRoulette.tv © {moment(new Date()).format('YYYY')} All rights reserved
                        </CopyrightText>
                    </Container>
                </FooterCopyrightContainer>

            </React.Fragment>
        );
    }
}
Footer.propTypes = {

}
export default withApplicationContext(Footer);