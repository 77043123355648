import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import {NavLink, withRouter} from "react-router-dom"
import AccountContainer from "../AccountContainer";
import {withAlert} from "react-alert";
import styled from "./Ticketing.module.css";
import LoadingContainer from "../../../components/common/LoadingContainer";
import {withProfileContext} from "../../../contexts/ProfileContext";
import {Form, InputGroup} from "react-bootstrap";
import styles from "../../Admin/Journal/Journal.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import Calendar from "react-calendar";
import AccountTable from "../../../components/ui/AccountTable/AccountTable";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import TicketsService from "../../../services/tickets.service";
import TicketStatus from "../../../components/ui/TicketStatus";
import { ROUTE_TICKET_SELECTED } from "../../../routers/routes";
import TicketPriority from "../../../components/ui/TicketPriority";
import {timezone, utcTime} from "../../../helpers/helpers";

class MyTickets extends Component {
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount = async () => {
        const {accountContext, profileContext} = this.props;
        let context = (this.props.userId)?profileContext:accountContext;

    }
    onChangeDate = (date) => {
        this.setState({
            date,
            start_date: date[0],
            end_date: date[1],
            datepicker: false
        }, this.handleSearch)
    }
    handleSearch = () => {
        this.refTable.search({
            status: this.state.status,
            keyword: this.state.keyword,
            start_date: (this.state.start_date)?moment(new Date(this.state.start_date)).format('YYYY-MM-DD'):null,
            end_date: (this.state.end_date)?moment(new Date(this.state.end_date)).format('YYYY-MM-DD'):null,
        })
    }
    onSearchKeyword = (e) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        self.setState({
            keyword: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.handleSearch();
            }, 1000)
        });
    }
    onLoadSelected = (e, row) => {
        const {accountContext, history} = this.props;
        e.preventDefault();
        accountContext.doGoto(ROUTE_TICKET_SELECTED, ":ticketId", row['id']);
    }
    render() {
        const {accountContext, applicationContext} = this.props;

        return (
            <LoadingContainer loading={accountContext.loading}>
                <AccountContainer profile={this.props.userId}>
                    <div className={"form-center form-search"}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>
                                <a href={"javascript:void(0)"} className={"btn btn-white full-width no-link"} >
                                    <React.Fragment>
                                        {applicationContext.translator("STATUS")}:
                                    </React.Fragment>
                                </a>
                            </InputGroup.Text>
                            <Form.Control
                                as={"select"}
                                className={styles.DropDown}
                                name={"status"}
                                value={this.state.status}
                                onChange={(e) => {
                                    this.setState({
                                        status: e.target.value
                                    }, this.handleSearch)
                                }}

                            >
                                <option value={""}>{applicationContext.translator("ALL")}</option>
                                <option value={"0"}>{applicationContext.translator("New")}</option>
                                <option value={"1"}>{applicationContext.translator("Answered")}</option>
                                <option value={"2"}>{applicationContext.translator("Open")}</option>
                                <option value={"3"}>{applicationContext.translator("Resolved")}</option>
                            </Form.Control>
                            <InputGroup.Text className={"calendar-container"} id="basic-addon1">
                                <a href={"javascript:void(0)"} onClick={(e) => this.setState({ datepicker: !this.state.datepicker })} className={"btn btn-white full-width"} >
                                    <React.Fragment>
                                        <FontAwesomeIcon icon={"calendar"} /> {this.state.start_date?
                                        <span className={"ml-1"}>
                                        {moment(new Date(this.state.start_date)).format('MMMM DD, YY')}
                                            {this.state.end_date && moment(new Date(this.state.start_date)).format('MMMM DD, YY') !== moment(new Date(this.state.end_date)).format('MMMM DD, YY')?("-" + moment(new Date(this.state.end_date)).format('MMMM DD, YY')):""}
                                    </span>:applicationContext.translator("DATE")}
                                    </React.Fragment>
                                </a>
                                {this.state.datepicker && <div className={"float-calendar"}><Calendar value={this.state.date} selectRange={true} onChange={this.onChangeDate}/></div>}
                            </InputGroup.Text>
                            <Form.Control
                                aria-describedby="basic-addon2"
                                name={"keyword"}
                                onChange={this.onSearchKeyword}
                                placeholder={applicationContext.translator("SEARCH BY TICKET ID#")}
                            />

                        </InputGroup>
                    </div>
                    <div className={"form-center"}>
                        <AccountTable loadData={TicketsService.getList} full={true} header={(onSort) => {
                            return <TableRow>
                                <TableColHeader>#</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Ticket ID")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Date")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Time")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Subject")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Priority")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Team")}</TableColHeader>
                                <TableColHeader>{applicationContext.translator("Status")}</TableColHeader>
                            </TableRow>
                        }}
                          render={(row, index, number) =>
                              <TableRow key={index} onClick={(e) => this.onLoadSelected(e, row)}>
                                  <TableCol>{number}</TableCol>
                                  <TableCol>#{row['id']}</TableCol>
                                  <TableCol>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableCol>
                                  <TableCol>{utcTime(row['created_at']).format('HH:mm:ss')}</TableCol>
                                  <TableCol>{row['subject']}</TableCol>
                                  <TableCol><TicketPriority priority={row['priority']} /></TableCol>
                                  <TableCol>{applicationContext.translator(row['team']['name'])}</TableCol>
                                  <TableCol><TicketStatus status={row['status']} /></TableCol>
                              </TableRow>
                          }
                          sort={"id"}
                          align={"desc"}
                          ref={(ref) => this.refTable = ref}
                          mobile={(row, index, number, total, onNext, onPrevious) =>
                              <TableMobileRow key={index}>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Number / Total Records")}</label>
                                      <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Ticket ID")}</label>
                                      <TableColValue>#{row['id']}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Date")}</label>
                                      <TableColValue>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Time")}</label>
                                      <TableColValue>{utcTime(row['created_at']).format('hh:mm:ss')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Subject")}</label>
                                      <TableColValue>{row['subject']}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Priority")}</label>
                                      <TableColValue><TicketPriority priority={row['priority']} /></TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Team")}</label>
                                      <TableColValue>{applicationContext.translator(row['team']['name'])}</TableColValue>
                                  </TableMobileCol>

                                  <TableMobileCol>
                                      <label>{applicationContext.translator("Status")}</label>
                                      <TableColValue>{row['status']['label']}</TableColValue>
                                  </TableMobileCol>
                              </TableMobileRow>}
                        />
                    </div>
                </AccountContainer>
            </LoadingContainer>

        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(withProfileContext(MyTickets)))));