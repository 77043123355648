import React, {Component} from 'react';
import {withAdminAccountContext} from "../../../contexts/AdminAccountContext";
import {NavLink, withRouter} from "react-router-dom"
import AccountTable from "../../../components/ui/AccountTable";
import TableRow from "../../../components/ui/AccountTable/TableRow";
import TableColHeader from "../../../components/ui/AccountTable/TableColHeader";
import TableCol from "../../../components/ui/AccountTable/TableCol";
import moment from 'moment-timezone';
import {accountCodeParse, numberWithCommas, parseErrorMessage, timezone, url, utcTime} from "../../../helpers/helpers";
import DepositStatus from "../../../components/ui/DepositStatus";
import {Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik} from "formik";
import * as Yup from "yup";
import Popup from "../../../components/ui/Popup";
import {withAlert} from "react-alert";
import Calendar from "react-calendar";
import {ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW} from "../../../routers/routes";
import styles from "../Journal/Journal.module.css";
import TableMobileRow from "../../../components/ui/AccountTable/TableMobileRow";
import TableMobileCol from "../../../components/ui/AccountTable/TableMobileCol";
import TableColValue from "../../../components/ui/AccountTable/TableColValue";
import PanelContainer from "../PanelContainer";
import AdminService from "../../../services/admin.service";
import {ROLE_CEG_ADMIN, ROLE_SUPER_ADMIN} from "../../../helpers/constants";
import PageTitleContainer from "../Search/PageTitleContainer";
import WithdrawalStatus from "../../../components/ui/WithdrawalStatus";
class Deposits extends Component {
    refForm = React.createRef();
    refTable = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dialog: false,
            detailsDialog: false,
            detailsDialogRow: null,
            action: "accept",
            row: null,
            index: -1,
            submitting: false,
            date: null,
            datepicker: false,
            keyword: "",
            typingTimeout: null,
            status: "",
            start_date: null,
            end_date: null,
        }
    }
    onCloseDetailsPopup = (e = null) => {
        this.setState({
            detailsDialog: false,
            detailsDialogRow: null
        })
    }
    onOpenDetailsPopup = (e, row) => {
        e.preventDefault();
        this.setState({
            detailsDialog: true,
            detailsDialogRow: row,
        })
    }
    onClosePopup = (e = null) => {
        if(this.state.submitting) return;
        this.setState({
            dialog: false,
            action: "accept",
            index: -1,
            row: null,
        })
    }
    onOpenPopup = (e, action, row, index) => {
        e.preventDefault();
        this.setState({
            dialog: true,
            action,
            index,
            row,
        }, () => {
            this.refInput.select()
        })
    }
    onSubmitPopup = (e) => {
        this.refForm.submitForm();
    }
    handleSubmit = async (values, actions) => {
        const {applicationContext, alert, history} = this.props;
        if(this.state.submitting) return;
        this.setState({submitting: true});
        try {
            const response  = await AdminService.doUpdateDeposit({
                id: this.state.row.id,
                status: (this.state.action == "accept")?1:2,
                description: values.description
            });
            this.setState({
                dialog: false,
                action: "accept",
                row: null
            });
            this.refTable.doUpdateRow(this.state.index, response.data);
            alert.success(response.message);

        } catch (err) {
            alert.error(parseErrorMessage(err));
        }
        this.setState({submitting: false})
    }
    onChangeDate = (date) => {
        this.setState({
            date,
            start_date: date[0],
            end_date: date[1],
            datepicker: false
        }, this.handleSearch)
    }
    handleSearch = () => {
        this.refTable.search({
            keyword: this.state.keyword,
            start_date: (this.state.start_date)?moment(new Date(this.state.start_date)).format('YYYY-MM-DD'):null,
            end_date: (this.state.end_date)?moment(new Date(this.state.end_date)).format('YYYY-MM-DD'):null,
        })
    }
    onSearchKeyword = (e) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        self.setState({
            keyword: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.handleSearchKeyword(self.state.keyword);
            }, 1000)
        });
    }
    handleSearchKeyword = (value) => {
        this.refTable.search({
            keyword: value,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
        }, this.handleSearch)
    }
    render() {
        const {accountContext} = this.props;
        return (
            <PanelContainer>
                <PageTitleContainer center={true} fullTitleWidth={500}/>
                {accountContext.role === ROLE_SUPER_ADMIN && <div className={"form-center form-search"}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>
                            <a href={"javascript:void(0)"} className={"btn btn-white full-width no-link"} >
                                <React.Fragment>
                                    STATUS:
                                </React.Fragment>
                            </a>
                        </InputGroup.Text>
                        <Form.Control
                            as={"select"}
                            className={styles.DropDown}
                            name={"status"}
                            value={this.state.status}
                            onChange={(e) => {
                                this.setState({
                                    status: e.target.value
                                }, () => {
                                    this.refTable.refresh();
                                })
                            }}

                        >
                            <option value={""}>ALL</option>
                            <option value={"0"}>Waiting for verify</option>
                            <option value={"1"}>Verified</option>
                            <option value={"2"}>Rejected</option>
                        </Form.Control>
                        <InputGroup.Text className={"calendar-container"} id="basic-addon1">
                            <a href={"javascript:void(0)"} onClick={(e) => this.setState({ datepicker: !this.state.datepicker })} className={"btn btn-white full-width"} >
                                <React.Fragment>
                                    <FontAwesomeIcon icon={"calendar"} /> {this.state.start_date?
                                    <span className={"ml-1"}>
                                        {moment(new Date(this.state.start_date)).format('MMMM DD, YY')}
                                        {this.state.end_date && moment(new Date(this.state.start_date)).format('MMMM DD, YY') !== moment(new Date(this.state.end_date)).format('MMMM DD, YY')?("-" + moment(new Date(this.state.end_date)).format('MMMM DD, YY')):""}
                                    </span>:"DATE"}
                                </React.Fragment>
                            </a>
                            {this.state.datepicker && <div className={"float-calendar"}><Calendar value={this.state.date} selectRange={true} onChange={this.onChangeDate}/></div>}
                        </InputGroup.Text>
                        <Form.Control
                            aria-describedby="basic-addon2"
                            name={"keyword"}
                            onChange={this.onSearchKeyword}
                            placeholder={process.env.REACT_APP_NAME !== "swissco.gaming"?"SEARCH BY ID# | TRC20 CODE | HASH CODE | E-EMAIL":"SEARCH BY ID# | E-EMAIL"}
                        />

                    </InputGroup>
                </div>}
                <div className={"form-center"}>
                    <AccountTable loadData={AdminService.getDepositList} sort={"id"} align={"desc"} full={true}  header={(onSort, sort, align) => {
                        return <TableRow>
                            <TableColHeader sort={"id"} align={align} sorted={sort} onSort={onSort} width={"100"}>#</TableColHeader>
                            <TableColHeader>Date</TableColHeader>
                            <TableColHeader>Time</TableColHeader>
                            {accountContext.role === ROLE_SUPER_ADMIN && <TableColHeader width={"150"}>Account ID</TableColHeader>}
                            <TableColHeader>Balance</TableColHeader>
                            <TableColHeader>Amount</TableColHeader>
                            <TableColHeader>New Balance</TableColHeader>
                            {accountContext.role === ROLE_SUPER_ADMIN && <TableColHeader id={"table-cell-02"}>Approved</TableColHeader>}
                            {accountContext.role === ROLE_SUPER_ADMIN && <TableColHeader id={"table-cell-01"}>Declined</TableColHeader>}
                            {accountContext.role !== ROLE_SUPER_ADMIN && <TableColHeader>Status</TableColHeader>}
                        </TableRow>
                    }}
                      render={(row, index, number) =>
                          <TableRow key={index}>
                              <TableCol>{number}</TableCol>
                              <TableCol>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableCol>
                              <TableCol>{utcTime(row['created_at']).format('hh:mm:ss')}</TableCol>
                              {accountContext.role === ROLE_SUPER_ADMIN && <TableCol width={"100"} yellow={true}>
                                  {accountCodeParse(row.user['account_code'])}
                              </TableCol>}
                              <TableCol>{row['transaction']?numberWithCommas(row['transaction']['balance'], true):""}</TableCol>
                              <TableCol>
                                  {accountContext.role === ROLE_SUPER_ADMIN && <a href={"javascript:void(0)"} className={"underline-link yellow-text"} onClick={(e) => this.onOpenDetailsPopup(e, row)}>
                                      {numberWithCommas(row['amount'], true)}
                                  </a>}
                                  {accountContext.role !== ROLE_SUPER_ADMIN && numberWithCommas(row['amount'], true)}
                              </TableCol>
                              <TableCol>{row['transaction']?numberWithCommas(row['transaction']['new_balance'], true):""}</TableCol>
                              {accountContext.role === ROLE_SUPER_ADMIN && <TableCol>
                                  <React.Fragment>
                                      {row['status'] === 0 && <span className={"green-text hover-green"} onClick={(e) => this.onOpenPopup(e, "accept", row, index)} >APPROVED</span>}
                                      {row['status'] === 1 && <span className={"green-text"}>APPROVED</span>}
                                      {row['status'] === 2 && <span className={"gray-text hover-green"} onClick={(e) => this.onOpenPopup(e, "accept", row, index)} >APPROVED</span>}
                                  </React.Fragment>
                              </TableCol>}
                              {accountContext.role === ROLE_SUPER_ADMIN && <TableCol>
                                  <React.Fragment>
                                      {row['status'] === 0 && <span className={"red-text hover-red"} onClick={(e) => this.onOpenPopup(e, "reject", row, index)} >DECLINED</span>}
                                      {row['status'] === 1 && <span className={"gray-text hover-red"}  onClick={(e) => this.onOpenPopup(e, "reject", row, index)}>DECLINED</span>}
                                      {row['status'] === 2 && <span className={"red-text"}>DECLINED</span>}
                                  </React.Fragment>
                              </TableCol>}
                              {accountContext.role !== ROLE_SUPER_ADMIN && <TableCol>
                                  <WithdrawalStatus status={row['status']}/>
                              </TableCol>}

                          </TableRow>}
                            ref={(ref) => this.refTable = ref} params={{
                                ...(accountContext.profileInfo && { user_id: accountContext.profileInfo['id'] }),
                                status: this.state.status
                            }}
                          mobile={(row, index, number, total, onNext, onPrevious) =>
                              <TableMobileRow key={index}>
                                  <TableMobileCol>
                                      <label>Number</label>
                                      <TableColValue pagination={true} onNext={onNext} onPrevious={onPrevious}>{number} / {total}</TableColValue>
                                  </TableMobileCol>
                                  {accountContext.role === ROLE_SUPER_ADMIN && <TableMobileCol>
                                      <label>ID NUMBER</label>
                                      <TableColValue>{accountCodeParse(row.user['account_code'])}</TableColValue>
                                  </TableMobileCol>}
                                  <TableMobileCol>
                                      <label>Date</label>
                                      <TableColValue>{utcTime(row['created_at']).format('ddd, MMMM DD, YYYY')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Time</label>
                                      <TableColValue>{utcTime(row['created_at']).format('hh:mm:ss')}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Balance</label>
                                      <TableColValue>{row['transaction']?numberWithCommas(row['transaction']['balance'], true):""}</TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>Amount</label>
                                      <TableColValue>
                                          {accountContext.role === ROLE_SUPER_ADMIN && <a href={"javascript:void(0)"} className={"underline-link yellow-text"} onClick={(e) => this.onOpenDetailsPopup(e, row)}>
                                              {numberWithCommas(row['amount'], true)}
                                          </a>}
                                          {accountContext.role !== ROLE_SUPER_ADMIN && numberWithCommas(row['amount'], true)}
                                      </TableColValue>
                                  </TableMobileCol>
                                  <TableMobileCol>
                                      <label>New Balance</label>
                                      <TableColValue>{row['transaction']?numberWithCommas(row['transaction']['new_balance'], true):""}</TableColValue>
                                  </TableMobileCol>
                                  {accountContext.role !== ROLE_SUPER_ADMIN && <TableMobileCol>
                                      <label>Status</label>
                                      <TableColValue><WithdrawalStatus status={row['status']}/></TableColValue>
                                  </TableMobileCol>}
                              </TableMobileRow>}
                    />
                </div>
                <Popup
                    show={this.state.dialog}
                    onClose={this.onClosePopup}
                    onSubmit={this.onSubmitPopup}
                    SubmitCaption={"Yes, I'm sure"}
                    SubmitButtonClass={"btn-success"}
                    CancelCaption={"Cancel"}
                    SubmittingLoading={this.state.submitting}
                    title={"Confirm"}
                    description={this.state.action == "accept"?"Are you sure to accept this transaction and charge user balance?":"Are you sure to reject this transaction?"}
                    force={true}
                >
                    <Formik
                        innerRef={(ref) => this.refForm = ref}
                        initialValues={{
                            description: ""
                        }}
                        validationSchema={this.state.action == "reject" && Yup.object().shape({
                            description: Yup.string().required("You must enter a reason for reject transaction")
                        })}
                        onSubmit={this.handleSubmit}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea"
                                                  rows={5}
                                                  name={"description"}
                                                  onChange={handleChange}
                                                  value={values.description}
                                                  isValid={touched.description && !errors.description}
                                                  isInvalid={touched.description && errors.description}
                                                  onFocus={(e) => {
                                                      e.target.select()
                                                  }}
                                                  autoComplete="off"
                                                  ref={(ref) => this.refInput = ref}
                                    />
                                    {touched.description && <Form.Text className="text-danger mt-2">
                                        {errors.description}
                                    </Form.Text>}
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>

                </Popup>
                <Popup
                    show={this.state.detailsDialog}
                    onClose={this.onCloseDetailsPopup}
                    CancelCaption={"Close"}
                    title={"Deposit Details"}
                >
                    {this.state.detailsDialogRow && <table className={"details-table"}>
                        <tr>
                            <td width={150}>NUMBER# :</td>
                            <td>{this.state.detailsDialogRow['id']}</td>
                        </tr>
                        <tr>
                            <td>DATE :</td>
                            <td>{utcTime(this.state.detailsDialogRow['created_at']).format('ddd, MMMM DD, YYYY')}</td>
                        </tr>
                        <tr>
                            <td>TIME :</td>
                            <td>{utcTime(this.state.detailsDialogRow['created_at']).format('hh:mm:ss')}</td>
                        </tr>
                        <tr>
                            <td>Account ID :</td>
                            <td>
                                <NavLink className={"underline-link yellow-text"} to={ROUTE_ADMIN_USER_ACCOUNT_OVERVIEW.replaceAll(":profileId", this.state.detailsDialogRow['user']['account_code'])}>
                                    {accountCodeParse(this.state.detailsDialogRow.user['account_code'])}
                                </NavLink>
                            </td>
                        </tr>
                        <tr>
                            <td>Amount :</td>
                            <td>{numberWithCommas(this.state.detailsDialogRow['amount'], true)}</td>
                        </tr>
                        <tr>
                            <td>Balance :</td>
                            <td>{this.state.detailsDialogRow.transaction?numberWithCommas(this.state.detailsDialogRow['transaction']['balance'], true):numberWithCommas(this.state.detailsDialogRow['user']['balance'], true)}</td>
                        </tr>
                        <tr>
                            <td>New Balance :</td>
                            <td>{this.state.detailsDialogRow.transaction?numberWithCommas(this.state.detailsDialogRow['transaction']['new_balance'], true):numberWithCommas(this.state.detailsDialogRow['user']['balance'] + this.state.detailsDialogRow['amount'], true)}</td>
                        </tr>
                        {process.env.REACT_APP_NAME !== "swissco.gaming" && <tr>
                            <td>Wallet Address :</td>
                            <td>{this.state.detailsDialogRow['dest_wallet_code']}</td>
                        </tr>}
                        {process.env.REACT_APP_NAME !== "swissco.gaming" && <tr>
                            <td>Hash Code :</td>
                            <td><a className={"underline-link yellow-text"} href={"https://tronscan.org/#/transaction/" + this.state.detailsDialogRow['hash_code']} target={"_blank"}>{this.state.detailsDialogRow['hash_code']}</a></td>
                        </tr>}
                        <tr>
                            <td>Status :</td>
                            <td><DepositStatus status={this.state.detailsDialogRow['status']}/></td>
                        </tr>
                        <tr>
                            <td>Description :</td>
                            <td>{this.state.detailsDialogRow['description']}</td>
                        </tr>
                        <tr>
                            <td>Actions :</td>
                            <td>
                                <div className={"start-center-value"}>
                                    {this.state.detailsDialogRow['status'] === 0 && <span className={"btn btn-outline btn-outline-green"} onClick={(e) => this.onOpenPopup(e, "accept", this.state.detailsDialogRow, 0)} >APPROVED</span>}
                                    {this.state.detailsDialogRow['status'] === 2 && <span className={"btn btn-outline btn-outline-green"} onClick={(e) => this.onOpenPopup(e, "accept", this.state.detailsDialogRow, 0)} >APPROVED</span>}
                                    {this.state.detailsDialogRow['status'] === 0 && <span className={"btn btn-outline btn-outline-red"} onClick={(e) => this.onOpenPopup(e, "reject", this.state.detailsDialogRow, 0)} >DECLINED</span>}
                                    {this.state.detailsDialogRow['status'] === 1 && <span className={"btn btn-outline btn-outline-red"}  onClick={(e) => this.onOpenPopup(e, "reject", this.state.detailsDialogRow, 0)}>DECLINED</span>}
                                </div>
                            </td>
                        </tr>
                    </table>}
                </Popup>
            </PanelContainer>
        )
    }
}

export default withAlert()(withRouter(withAdminAccountContext(Deposits)));