import React, {Component} from 'react';
import styles from "./ContextMenu.module.css";
import classNames from "classnames";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withGameTableContext} from "../../../contexts/GameTableContext";
import {getChipsByFormula, getNeighbours, sumChipsByFormula} from "../../../helpers/helpers";
import {CUSTOM_FORMULA, WHEEL_NUMBERS} from "../../../helpers/constants";
class ContextMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            neighbours: false,
            close: false,
            height: 0
        }
        this.Menu = React.createRef();
    }
    componentDidMount() {
        this.setState({
            height: this.Menu.clientHeight
        })
    }

    onRemoveChipById = (id) => {
        const {gameTableContext, number} = this.props;
        let chips = gameTableContext.state.chips;
        let tableHistory = gameTableContext.state.tableHistory;
        if(chips.has(number)){
            let listOfChips = [];
            let values = 0;
            chips.get(number).forEach((c, index) => {
                if(c.id !== id) {
                    listOfChips.push(c);
                }else{
                    tableHistory.map((h, ih) => {
                        if(h.id === c.id){
                            tableHistory.splice(ih, 1);
                        }
                    })
                    tableHistory.push({
                        ...c,
                        number,
                        action: "ADD"
                    });
                    values += c.chipSelected
                }
            })
            if(listOfChips.length > 0)
                chips.set(number, listOfChips);
            else
                chips.delete(number)
            let table = (chips.size === 0)?"won":"top5";
            gameTableContext.onUpdateState({
                chips,
                table,
                tableHistory,
                balance: gameTableContext.state.balance + values
            })
        }
        this.props.onClose();

    }
    onAllRemoveChips = () => {
        const {gameTableContext, number} = this.props;
        let chips = gameTableContext.state.chips;
        let tableHistory = gameTableContext.state.tableHistory;
        let values = sumChipsByFormula(chips, number);
        chips.get(number).forEach((c, index) => {
            tableHistory.map((h, ih) => {
                if(h.id === c.id){
                    tableHistory.splice(ih, 1);
                }
            })
            tableHistory.push({
                ...c,
                number,
                action: "ADD"
            });
        })
        chips.delete(number);
        let table = (chips.size === 0)?"won":"top5";
        gameTableContext.onUpdateState({
            chips,
            table,
            tableHistory,
            balance: gameTableContext.state.balance + values
        })
        this.props.onClose();
    }
    onRemoveNeighboursChips = (number) => {
        const {gameTableContext} = this.props;
        let numbers = getNeighbours(gameTableContext.state.neighbourBet, number, WHEEL_NUMBERS.indexOf(number));
        let chips = gameTableContext.state.chips;
        let tableHistory = gameTableContext.state.tableHistory;
        let values = 0;
        let chipsHistory = [];
        numbers.forEach((n) => {
            if(chips.has(n)){
                chips.get(n).forEach((c, index) => {
                    tableHistory.map((h, ih) => {
                        if(h.id === c.id){
                            tableHistory.splice(ih, 1);
                        }
                    })
                    chipsHistory.push({
                        ...c,
                        number: n
                    });
                    values += c.chipSelected
                });
                chips.delete(n)
            }
        })
        tableHistory.push({
            chips: chipsHistory,
            formula: `neighbours-${number}`,
            action: "ADD_GROUP"
        });
        let table = (chips.size === 0)?"won":"top5";
        gameTableContext.onUpdateState({
            chips,
            table,
            tableHistory,
            balance: gameTableContext.state.balance + values
        })
        this.props.onClose();
    }
    onBetOnNeighbours = (e) => {
        const {gameTableContext, number} = this.props;
        let numbers = getNeighbours(gameTableContext.state.neighbourBet, number, WHEEL_NUMBERS.indexOf(number));
        gameTableContext.refTable.onBetNumbers(numbers, `neighbours-${number}`,  true);
        this.props.onClose();

    }
    checkHasNeighbours = (number) => {
        const {gameTableContext} = this.props;
        if(typeof number !== "number") return false;
        let numbers = getNeighbours(gameTableContext.state.neighbourBet, number, WHEEL_NUMBERS.indexOf(number));
        let find = 0;
        numbers.map((n) => gameTableContext.state.chips.has(n) && find++)
        return numbers.length === find;
    }
    onBetOutside = (chip) => {
        const {gameTableContext, number} = this.props;
        gameTableContext.refTable.onBet(number, null,  chip, true);
        this.props.onClose();
    }
    renderTableMenuItems = (chips) => {
        const {number, gameTableContext, applicationContext} = this.props;
        return (
            <React.Fragment>
                {typeof number === "number" && <li onClick={(e) => this.onBetOnNeighbours(e)}>
                    <div>{applicationContext.translator("#{number} Neighbours").replaceAll("{number}", number)}</div>
                </li>}
                {this.checkHasNeighbours(number) &&
                <li onClick={(e) => this.onRemoveNeighboursChips(number)}>
                    <div>{applicationContext.translator("Remove Neighbours")}</div>
                </li>
                }
                {CUSTOM_FORMULA.includes(number) && <React.Fragment>
                    {sumChipsByFormula(gameTableContext.state.chips, number) < 100 && <li onClick={(e) => this.onBetOutside(50)}>
                        <div>{applicationContext.translator("Play outside bet with 50$")}</div>
                    </li>}
                    {sumChipsByFormula(gameTableContext.state.chips, number) === 0 && <li onClick={(e) => this.onBetOutside(100)}>
                        <div>{applicationContext.translator("Play outside bet with 100$")}</div>
                    </li>}
                </React.Fragment>}
                {chips.map((chip) => {
                    return <li onClick={(e) => this.onRemoveChipById(chip.id)}>
                        <div>{applicationContext.translator("Remove {value}$ chips").replaceAll("{value}", chip.value)}</div>
                    </li>
                })}
                {chips.length > 1 && <li onClick={(e) => this.onAllRemoveChips()}>
                    <div>Remove all chips</div>
                </li>}
            </React.Fragment>
        )
    }
    render() {
        const {applicationContext, number, children, x, y, onClose, gameTableContext, showOnTop, showOnRight, showOnCenter, fixed} = this.props;
        let chips = (!children)?getChipsByFormula(gameTableContext.state.chips, number):[];
        let stylePosition = {};
        if(showOnTop) stylePosition.top = `calc(50% - ${this.state.height}px)`;
        if(showOnRight) stylePosition.right = `calc(50% - 230px)`;
        if(showOnCenter) stylePosition.right = `calc(50% - 115px)`;
        return (
            <React.Fragment>
                <div className={classNames(styles.ContextMenuOverlay)} onClick={onClose}></div>

                <div className={classNames(styles.ContextMenu, fixed && styles.ContextMenuFixed)}
                     ref={(ref) => this.Menu = ref}
                     style={(x && y)?{right: 'auto', top: `${y}px`, left: `${((x - 100 < 0)?(20):((x + 200 > window.innerWidth)?(window.innerWidth - 220):(x - 100)))}px`}:stylePosition}
                     id={`context-menu-${number}`}>
                    <div className={styles.ContextBody}>
                        <div className={styles.ContextBodyWrapper}>
                            <ul className={applicationContext.translator("direction") === "rtl" && "rtl-section"}>
                                {!children && this.renderTableMenuItems(chips)}
                                {children}
                            </ul>
                            {!children && <div className={classNames(styles.ContextBodyTotal, applicationContext.translator("direction") === "rtl" && "rtl-section")}>
                                <div className={applicationContext.translator("direction") === "rtl" && "rtl-section"}>{applicationContext.translator("Total chips")}: </div><span>{sumChipsByFormula(gameTableContext.state.chips, number)}$</span>
                            </div>}

                        </div>

                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default withApplicationContext(withGameTableContext(ContextMenu));