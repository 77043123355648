import React, {Component} from "react";
import styles from "./AccountTable.module.css";
import classNames from "classnames";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class TableMobileCol extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        const { children} = this.props
        return (
            <div className={classNames(styles.TableMobileCell)}>
                {children}
            </div>

        );
    }
}
export default withApplicationContext(TableMobileCol);