import api from "./api";

const page = (locale, slug) => {
    let config = {
        auth: {
            username: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_ID,
            password: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_SECRET
        }
    };
    return api.get(`/api/pages/show/${locale}/${slug}`, config).then((response) => {
        return response.data;
    });
};


const SearchService = {
    page
};

export default SearchService;